import React from 'react'
import { SvgProps } from '../../model/types'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const SpinnerWrapper = styled.svg`
	animation: ${spin} 3s linear infinite;
`

export const Spinner: React.FC<SvgProps> = props => {
	return (
		<SpinnerWrapper
			xmlns="http://www.w3.org/2000/svg"
			width={props.size || '40'}
			height={props.size || '40'}
			viewBox="0 0 160 160"
			fill="none"
			{...props}
		>
			<path
				d="M80 144C44.6538 144 16 115.346 16 80C16 44.6538 44.6538 16 80 16"
				stroke="url(#paint0_linear_4694_792)"
				strokeWidth="12"
			/>
			<path
				d="M80 144C115.346 144 144 115.346 144 80.0001C144 44.6539 115.346 16.0001 80 16.0001"
				stroke="url(#paint1_linear_4694_792)"
				strokeWidth="12"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_4694_792"
					x1="80"
					y1="23"
					x2="80"
					y2="150"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#8AB9D5" />
					<stop offset="0.484375" stopColor="#1E66AF" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_4694_792"
					x1="80"
					y1="25.5001"
					x2="80"
					y2="139"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#8AB9D5" />
					<stop offset="1" stopColor="#1185A8" stopOpacity="0" />
				</linearGradient>
			</defs>
		</SpinnerWrapper>
	)
}
