import React from 'react'
import styled from 'styled-components'
import { PageWrapper } from '../styleguide/CommonPageComponents'
import theme from '../styleguide/theme'
import { IMAGES } from '../utils/assetRegistry'

const Container = styled(PageWrapper)`
	background-color: ${theme.palette.background.secondary};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const Logo = styled.img`
	width: 33%;
	height: auto;
	display: block;
	margin-bottom: ${theme.spacing(4)};
`

const Error = styled.div`
	color: ${theme.palette.grey[500]};
	font-size: 0.8rem;
	width: 90%;
	margin: ${theme.spacing(5)} auto ${theme.spacing(4)};
	text-align: center;
	&:first-letter {
		text-transform: uppercase;
	}
`

const MissingUserErrorPage = ({ message }: { message: string }) => {
	return (
		<Container>
			<Logo alt="Luxottica Optometrist" src={IMAGES.CONNECT_LOGO_URL} />

			<Error>
				<div>{message}</div>
			</Error>
		</Container>
	)
}

export default MissingUserErrorPage
