import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ContactsState } from '../../model/model'
import { Contact } from '../../model/contact'

import { contactsSliceName } from './constants'

export const contactsInitialState: ContactsState = {
	contacts: [],
}

export const contactsSlice = createSlice({
	name: contactsSliceName,
	initialState: contactsInitialState,
	reducers: {
		setContacts: (state, { payload: contacts }: PayloadAction<Contact[]>) => {
			state.contacts = contacts
		},
		addContact: (state, { payload: newContact }: PayloadAction<Contact>) => {
			state.contacts = [...state.contacts, newContact]
		},
		editContact: (
			state,
			{ payload: contactToUpdate }: PayloadAction<Contact>,
		) => {
			state.contacts = state.contacts.map(contact =>
				contact._id === contactToUpdate._id ? contactToUpdate : contact,
			)
		},
		removeContact: (state, { payload: contactId }: PayloadAction<string>) => {
			state.contacts = state.contacts.filter(({ _id }) => _id !== contactId)
		},
	},
})

export const { setContacts, addContact, editContact, removeContact } =
	contactsSlice.actions

export const contactsReducer = contactsSlice.reducer
