import React, { ReactNode } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { LocalizationProvider as LocalizationProviderPro } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { pickDateFnsLocale } from './libs/time'
import { useTeloSelector } from './store'
import { selectAppLang } from './features/app/selectors'

type TeloLocalizationProps = {
	children?: ReactNode
}

export const TeloLocalization = ({ children }: TeloLocalizationProps) => {
	const lang = useTeloSelector(selectAppLang)
	const locale = pickDateFnsLocale(lang)

	return (
		<LocalizationProviderPro
			dateAdapter={AdapterDateFns}
			adapterLocale={locale}
		>
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
				{children}
			</LocalizationProvider>
		</LocalizationProviderPro>
	)
}
