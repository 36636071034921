import React, { FC, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { Notification as NotificationModel } from '../../model/model'

import { playDing } from './utils/play-ding'
import { NotificationAlert } from './NotificationAlert'
import { StandardNotification } from './StandardNotification'

export interface NotificationProps {
	notification: NotificationModel
	onClose: () => void
}

export const Notification: FC<NotificationProps> = ({
	notification: {
		isAlert = false,
		_id,
		colored,
		playSound,
		soundOnly,
		title,
		messageIsLabelKey,
		message,
		anchorOrigin,
		severity,
		isIconVisible,
		actions,
		titleIsLabelKey,
	},
	onClose,
}) => {
	const { t } = useTranslation()

	useEffect(() => {
		if (playSound) {
			playDing()
		}
	}, [playSound])

	// * Use soundOnly flag to conditionally opt-out message box
	if (soundOnly) {
		return null
	}

	const notificationMessage =
		messageIsLabelKey && message ? t(message) : message

	const notificationTitle = titleIsLabelKey && title ? t(title) : title

	if (isAlert) {
		return (
			<NotificationAlert
				title={notificationTitle}
				message={notificationMessage}
				anchorOrigin={anchorOrigin}
				severity={severity!}
				isIconVisible={isIconVisible}
				onClose={onClose}
				actions={actions}
			/>
		)
	}

	return (
		<StandardNotification
			id={_id}
			message={notificationMessage}
			colored={colored}
			onClose={onClose}
		/>
	)
}
