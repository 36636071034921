import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Id } from '../../model/model'
import { Practice, PracticesState } from '../../model/practice'

const initialState = null as PracticesState

export const slice = createSlice({
	name: 'panels',
	initialState,
	reducers: {
		_setPractice: (state, { payload }: PayloadAction<Practice>) => {
			const practiceIndex = state?.findIndex(({ _id }) => _id === payload._id)
			if (state && practiceIndex !== undefined && practiceIndex !== -1) {
				state[practiceIndex] = payload
			} else {
				return (state || []).concat(payload)
			}
		},
		_removePractice: (state, { payload }: PayloadAction<Id>) =>
			state?.filter(({ _id }) => _id !== payload),
		_setPractices: (_, { payload }: PayloadAction<Practice[]>) => payload,
	},
})

export default slice.reducer
