import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../model/model'

export const selectAvailableToggleWasClicked = createSelector(
	(state: RootState) => state.worklist.availableToggleWasClicked,
	result => result,
)

export const selectShowAvailableToggleLoader = createSelector(
	(state: RootState) => state.worklist.showAvailableToggleLoader,
	result => result,
)

export const selectWorklistFilters = (state: RootState) =>
	state.worklist.worklistFilters

export const selectCurrentPaginationPage = (state: RootState) =>
	state.worklist.currentPaginationPage

export const selectStatusFilters = createSelector(
	selectWorklistFilters,
	({ status }) => status,
)
