import { AzureCommunicationData } from '../model/azureCommunication'
import { EclipsMetadata, ECLIPS_KEY_METADATA } from '../model/eclips'
import { AuthState } from '../model/model'
import { generateUID } from './uuid'

const APP_LOGIN_DATA = 'tel-app-login-data'
const IN_STORE = 'tel-in-store'
const AZURE_COMMUNICATION_DATA = 'tel-azure-communication-data'
const SESSION_ID = 'tel-session-id'
const ROOM_DEVICE = 'tel-room-device'
const OTT_MODE = 'tel-ott-mode'
const STORE_ID = 'tel-store-id'
const INACTIVITY_MODAL_SHOWN = 'inactivity-modal-shown'

export const setEclipsMetadata = (metadata: EclipsMetadata) => {
	window.localStorage.setItem(ECLIPS_KEY_METADATA, JSON.stringify(metadata))
}

export const getEclipsMetadata = () => {
	const metadataRaw = window.localStorage.getItem(ECLIPS_KEY_METADATA)
	const metadata: EclipsMetadata | undefined = metadataRaw
		? JSON.parse(metadataRaw)
		: undefined
	return metadata
}

export const deleteEclipsMetadata = () => {
	console.log('deleteEclipsMetadata', 'deleting', ECLIPS_KEY_METADATA)
	window.localStorage.removeItem(ECLIPS_KEY_METADATA)
}

export const setIntersessionAppLoginData = (
	loginData: Omit<AuthState, 'loginStatus'>,
) => {
	window.localStorage.setItem(APP_LOGIN_DATA, JSON.stringify(loginData))
}

export const getIntersessionAppLoginData = () => {
	const loginData = window.localStorage.getItem(APP_LOGIN_DATA)
	return loginData ? JSON.parse(loginData) : undefined
}

export const delIntersessionInfo = () => {
	console.log('delIntersessionInfo', 'deleting', APP_LOGIN_DATA)
	window.localStorage.removeItem(APP_LOGIN_DATA)
}

export const setIntersessionInStore = (inStore: boolean) => {
	window.localStorage.setItem(IN_STORE, JSON.stringify(inStore))
}

export const getIntersessionInStore = (): boolean => {
	const inStore = window.localStorage.getItem(IN_STORE)
	if (!inStore) {
		setIntersessionInStore(false)
	}
	return inStore ? JSON.parse(inStore) : false
}

export const setIntersessionRoomDevice = (available: boolean) => {
	window.localStorage.setItem(ROOM_DEVICE, JSON.stringify(available))
}

export const deleteIntersessionRoomDevice = () => {
	window.localStorage.removeItem(ROOM_DEVICE)
}

export const getIntersessionRoomDevice = (): boolean => {
	const roomDeviceAvailable = window.localStorage.getItem(ROOM_DEVICE)

	return roomDeviceAvailable ? JSON.parse(roomDeviceAvailable) : undefined
}

export const setIntersessionAzureCommunicationData = (
	data: AzureCommunicationData,
) => {
	window.localStorage.setItem(AZURE_COMMUNICATION_DATA, JSON.stringify(data))
}

export const getIntersessionAzureCommunicationData =
	(): AzureCommunicationData => {
		const data = window.localStorage.getItem(AZURE_COMMUNICATION_DATA)
		return data ? JSON.parse(data) : undefined
	}

export const delIntersessionAzureCommunicationData = () => {
	window.localStorage.removeItem(AZURE_COMMUNICATION_DATA)
}

export const createSessionId = () => {
	const sessionId = generateUID()
	window.sessionStorage.setItem(SESSION_ID, sessionId)
	return sessionId
}

export const getSessionId = () => {
	return window.sessionStorage.getItem(SESSION_ID)
}

export const setIntersessionOttMode = (ottMode: boolean) => {
	window.localStorage.setItem(OTT_MODE, JSON.stringify(ottMode))
}

export const getIntersessionOttMode = (): boolean => {
	const ottMode = localStorage.getItem(OTT_MODE)

	const isInvalid = !ottMode || ['null', 'undefined'].includes(ottMode)
	const ottModeValue = localStorage.getItem(OTT_MODE) === 'true'

	if (isInvalid) {
		setIntersessionOttMode(false)
	}

	return !isInvalid && ottMode ? ottModeValue : false
}

export const setIntersessionStoreId = (storeId: string) => {
	window.localStorage.setItem(STORE_ID, storeId)
}

export const getIntersessionStoreId = () => {
	return window.localStorage.getItem(STORE_ID)
}

export const removeIntersessionStoreId = () => {
	window.localStorage.removeItem(STORE_ID)
}

export const setIntersessionInactivityModalShown = (
	inactivityModalShown: boolean,
) => {
	window.localStorage.setItem(
		INACTIVITY_MODAL_SHOWN,
		JSON.stringify(inactivityModalShown),
	)
}

export const getIntersessionInactivityModalShown = (): boolean => {
	const inactivityModalShown = window.localStorage.getItem(
		INACTIVITY_MODAL_SHOWN,
	)

	return inactivityModalShown ? JSON.parse(inactivityModalShown) : undefined
}

export const deleteIntersessionInactivityModalShown = () => {
	window.localStorage.removeItem(INACTIVITY_MODAL_SHOWN)
}
