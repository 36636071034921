import React from 'react'
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material'
import theme from './theme'
import styled from 'styled-components'

import { TeloDatePicker } from '../components/CustomDatePicker/TeloDatePicker'
import { TeloDatePickerProps } from '../components/CustomDatePicker/TeloDatePicker'

export type DatePickerProps = TeloDatePickerProps & {
	className?: string
}

// for more information about customisation, pls refer to https://material-ui-pickers.dev/guides/css-overrides
// paying attention on typescript section
// for more info about MuiTheme, refer to https://material-ui.com/customization/theming/
const materialTheme = createTheme({
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					fontFamily: 'inherit',
					padding: 0,
					justifyContent: 'flex-start',
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					padding: 0,
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					fontFamily: theme.typography.fontFamily,
				},
			},
		},
		MuiModal: {
			styleOverrides: {
				root: {
					maxWidth: '450px',
				},
			},
		},
		MuiPickersDay: {
			styleOverrides: {
				day: {},
				daySelected: {
					backgroundColor: theme.palette.secondary.main,
				},
				dayDisabled: {},
				current: {
					borderColor: theme.palette.grey[700],
					borderWidth: '1px',
					borderStyle: 'solid',
				},
			},
		},
		MuiPickersToolbar: {
			styleOverrides: {
				toolbar: {
					fontFamily: theme.typography.fontFamily,
					width: '180px',
					backgroundColor: theme.palette.secondary.main,
				},
				toolbarLandscape: {
					padding: theme.spacing(2),
					width: '100%',
					maxWidth: 'inherit',
				},
			},
		},
		MuiPickersYear: {
			styleOverrides: {
				root: {
					flex: 0,
					display: 'inline-block',
					width: '25%',
					textAlign: 'center',
				},
				yearSelected: {
					margin: 0,
					display: 'inline-flex',
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: theme.palette.secondary.main,
					color: 'white',
					fontWeight: 'bold',
					borderRadius: '25px',
					fontSize: '1rem',
				},
			},
		},
		MuiPickersCalendarHeader: {
			styleOverrides: {
				switchHeader: {
					textTransform: 'capitalize',
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					fontFamily: theme.typography.fontFamily,
				},
				body1: {
					fontFamily: theme.typography.fontFamily,
				},
				body2: {
					fontFamily: theme.typography.fontFamily,
				},
				caption: {
					fontFamily: theme.typography.fontFamily,
				},
				subtitle1: {
					fontFamily: theme.typography.fontFamily,
				},
				h6: {
					fontFamily: theme.typography.fontFamily,
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					lineHeight: '1em',
				},
			},
		},
	},
})

const StyledDatePicker = styled(TeloDatePicker)`
	border-radius: 5px;
	background-color: white;

	.Mui-disabled.Mui-disabled .MuiOutlinedInput-notchedOutline,
	.MuiOutlinedInput-notchedOutline.Mui-disabled,
	.MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
		border: 1px solid ${theme.palette.grey[900]};
		background-color: ${theme.palette.grey[100]};
	}
`

const DatePicker: React.FC<DatePickerProps> = ({
	className,
	format,
	...otherProps
}) => {
	return (
		<div className={className}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={materialTheme}>
					<StyledDatePicker {...otherProps} format={format} />
				</ThemeProvider>
			</StyledEngineProvider>
		</div>
	)
}

export default DatePicker
