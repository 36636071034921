import React from 'react'
import styled from 'styled-components'
import theme from './theme'

import {
	Snackbar as MSnackbar,
	SnackbarOrigin,
	SnackbarProps,
} from '@mui/material'

const defaultAnchorOrigin: SnackbarOrigin = {
	vertical: 'bottom',
	horizontal: 'left',
}

interface ExtendedSnackbarProps {
	$colored?: boolean
}

const StyledSnackbar = styled<
	React.ComponentType<SnackbarProps & ExtendedSnackbarProps>
>(MSnackbar)`
	${props =>
		props.$colored &&
		`
		.MuiPaper-root {
			background-color: ${theme.palette.secondary.main};
		}
	`}
`
const Snackbar: React.FC<SnackbarProps & ExtendedSnackbarProps> = ({
	children,
	anchorOrigin = defaultAnchorOrigin,
	...props
}) => {
	return (
		<StyledSnackbar anchorOrigin={anchorOrigin} {...props}>
			{children}
		</StyledSnackbar>
	)
}

// Use the below exports to efficiently customize
// alignment of the snackbar. Default can be omitted
export { defaultAnchorOrigin }
export type { SnackbarOrigin }

export default Snackbar
