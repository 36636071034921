import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReportFilters } from '../../model/model'

const initialState: ReportFilters = {
	isLEManager: undefined,
	userName: undefined,
	name: undefined,
	type: undefined,
	selectedStores: [],
	storeIds: undefined,
	dateFrom: undefined,
	dateTo: undefined,
	page: 0,
	pages: 0,
	total: 0,
}

export const slice = createSlice({
	name: 'reportFilters',
	initialState,
	reducers: {
		_loadFilters: (state, { payload }: PayloadAction<ReportFilters>) => payload,
	},
})

export default slice.reducer
