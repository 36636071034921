import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { Feature, FeatureName } from '../model/features'
import { unversionedApiUrl } from './common'

const TAG = 'queue'
export const featuresApi = createApi({
	reducerPath: 'featuresApi',
	tagTypes: [TAG] as FeatureName[],
	baseQuery: staggeredBaseQueryWithBailOut(`${unversionedApiUrl}`),
	endpoints: builder => ({
		getFeatures: builder.query<Feature, { feature: FeatureName }>({
			query: ({ feature }) => ({
				url: `/v1/features/${feature}`,
			}),
			providesTags: (result, error, arg) => [arg.feature],
		}),
		setFeatures: builder.mutation<
			Feature,
			{ feature: FeatureName; enabled: boolean }
		>({
			query: ({ feature, enabled }) => ({
				url: `/v1/features/${feature}`,
				method: 'PUT',
				body: JSON.stringify({
					enabled,
				}),
			}),
			invalidatesTags: (result, error, arg) => [arg.feature],
		}),
	}),
})

export const { useGetFeaturesQuery, useSetFeaturesMutation } = featuresApi
