import { subDays } from 'date-fns'
import {
	getAllReports,
	getFilteredReports,
	postReport,
	removeReportApi,
} from '../../apiCalls'
import { convertDateForQueryString } from '../../libs/time'
import { ExportForm } from '../../model/export'
import {
	AppThunk,
	AppThunkPromise,
	TeloDispatch,
	TeloGetState,
} from '../../store'
import errorActions from '../errors/actions'
import { Report, ReportResponse } from '../../model/report'
import { slice } from './slice'
import { slice as sliceFilters } from '../reportFilters/slice'
import { selectFilters } from '../reportFilters/selectors'

const filterChecked = (entries: any[]) => {
	//should be [string, boolean][]
	return entries.filter(e => e[1]).map(e => e[0])
}

const getExamReport =
	(values: ExportForm): AppThunk =>
	async (dispatch: TeloDispatch, getState: TeloGetState) => {
		let dateFrom = values.dateFrom
		let dateTo = values.dateTo
		if (values.period !== 'dateRange') {
			const d = new Date()
			dateTo = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
			if (values.period === 'today') {
				const d = new Date()
				dateFrom = new Date(
					Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()),
				)
			} else {
				const d = new Date()
				dateTo = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
				dateFrom = subDays(
					dateTo,
					values.period === 'last30days'
						? 30
						: values.period === 'last90days'
						? 90
						: 7,
				)
			}
		}
		if (dateFrom && dateTo) {
			const valuesGeneralInformation = Object.entries(values.generalInformation)
			const valuesAppointment = Object.entries(values.appointment)
			const valuesPhase = Object.entries(values.phase)
			const valuesOperator = Object.entries(values.operator)
			const fields = filterChecked(valuesGeneralInformation)
				.concat(filterChecked(valuesAppointment))
				.concat(filterChecked(valuesPhase))
				.concat(filterChecked(valuesOperator))
			const categorizedFields = {
				generalInformation: Object.keys(values.generalInformation).reduce(
					(x: string[], k) => {
						if ((values.generalInformation as any)[k]) {
							x.push(k)
						}
						return x
					},
					[],
				),
				appointment: Object.keys(values.appointment).reduce(
					(x: string[], k) => {
						if ((values.appointment as any)[k]) {
							x.push(k)
						}
						return x
					},
					[],
				),
				phase: Object.keys(values.phase).reduce((x: string[], k) => {
					if ((values.phase as any)[k]) {
						x.push(k)
					}
					return x
				}, []),
				operator: Object.keys(values.operator).reduce((x: string[], k) => {
					if ((values.operator as any)[k]) {
						x.push(k)
					}
					return x
				}, []),
			}

			postReport(
				values.name || '',
				dateFrom,
				dateTo,
				values.selectedStores.map(s => s.value),
				values.reportType || 'full',
				fields,
				categorizedFields,
			).then(report => report)
		} else {
			dispatch(errorActions.setUiError(new Error('No date selected')))
		}
	}

const fetchAllReports = (): AppThunk => (dispatch: TeloDispatch) => {
	getAllReports().then((response: ReportResponse) => {
		dispatch(slice.actions._loadReports(response))
	})
}

const filterReports = (): AppThunk => (dispatch: TeloDispatch, getState) => {
	const state = getState()
	const {
		isLEManager,
		userName,
		name,
		type,
		storeIds,
		dateFrom,
		dateTo,
		page,
	} = selectFilters(state)

	getFilteredReports(
		isLEManager,
		userName,
		name,
		type,
		storeIds,
		dateFrom,
		dateTo,
		page,
	).then((response: ReportResponse) => {
		if (!response) return
		dispatch(slice.actions._loadReports(response))
		dispatch(
			sliceFilters.actions._loadFilters({
				...state.reportFilters,
				total: response.total,
				pages: response.pages,
			}),
		)
	})
}

const removeReport =
	(id: string): AppThunkPromise =>
	() =>
		removeReportApi(id).then(() => {})

const reportsActions = {
	getExamReport,
	fetchAllReports,
	filterReports,
	removeReport,
}

export default reportsActions
