import { BasePath, GetFieldDataQuery, Path } from '../../../formFields/model'
import {
	getFetchAssessment,
	getFetchExam,
	getFetchExamWarnings,
	getFetchInstrumentData,
	getFetchInstrumentsInRoom,
	getFetchStages,
	getFetchUser,
} from '../../../hooks/throtteledFetchActions'
import { RootState } from '../../../model/model'
import { TeloNavigationFn } from '../../../routing/teloRouter'
import { fieldsDataApi } from '../../../services/fieldsData'
import { fieldsDataMetaApi } from '../../../services/fieldsDataMeta'
import { WORKLIST_CACHE_TAG, worklistApi } from '../../../services/worklist'
import { TeloDispatch, TeloGetState } from '../../../store'
import { selectSessionId } from '../../app/selectors'
import { selectUsername } from '../../auth/selectors'
import notificationsActions from '../../notifications/actions'
import { selectNotification } from '../../notifications/selectors'
import {
	AddPatientExportFn,
	AddNotificationFn,
	ExamsInStoreUpdatedData,
	FetchExamFn,
	FindNotificationFn,
	RemoveNotificationFn,
} from '../teloSocketTypes'

import {
	RoomSocketArgs,
	connectToSocketRoom,
	connectToSocketRoomNoType,
	disconnectFromSocketRoomNoType,
} from './roomSocket'
import {
	FetchAssessmentFn,
	FetchExamWarningsFn,
	FetchFieldsDataFn,
	FetchInstrumentDataFn,
	FetchInstrumentsInRoomFn,
	FetchStagesFn,
	FetchUserFn,
	InstrumentInRoomMessageData,
	InstrumentMessageData,
	RoomSocketType,
	UpdateQueueFeatureFlagFn,
} from './roomSocketTypes'
import appointmentsActions from '../../appointments/actions'
import appActions from '../../app/actions'
import patientExportActions from '../../patientExport/actions'

const clearFieldDataCacheForPathAndIndex = (
	state: RootState,
	dispatch: TeloDispatch,
	data: {
		examId: string
		fields: { path: Path; index?: number | undefined }[]
	},
) => {
	Object.values(state.fieldsDataApi.queries)
		.filter(query => {
			if (query?.endpointName !== 'getFieldData') {
				return false
			}

			const args = (query as GetFieldDataQuery).originalArgs
			return data.fields.some(
				field =>
					args.examId === data.examId &&
					args.index === field.index &&
					args.path === field.path,
			)
		})
		.forEach(query => {
			const { examId, path, index } = (query as GetFieldDataQuery).originalArgs

			// This action will invalidate that cache entry
			dispatch(
				fieldsDataApi.endpoints.getFieldData.initiate(
					{ examId, path, index },
					{ forceRefetch: true },
				),
			)
		})
}

const clearFieldDataMetaCache = (
	dispatch: TeloDispatch,
	examId: string,
	basePaths: BasePath[],
) => {
	basePaths.forEach(basePath => {
		dispatch(
			fieldsDataMetaApi.endpoints.getBasePathInfo.initiate(
				{
					examId,
					basePath,
				},
				{ forceRefetch: true },
			),
		)
	})
}

const updateNextArrayIndexCache = (
	dispatch: TeloDispatch,
	examId: string,
	basePaths: BasePath[],
) => {
	basePaths.forEach(basePath => {
		dispatch(
			fieldsDataMetaApi.endpoints.getArrayNextValidIndex.initiate(
				{
					examId,
					basePath,
				},
				{ forceRefetch: true },
			),
		)
	})
}

const addNewIndexToCache = (
	state: RootState,
	dispatch: TeloDispatch,
	data: {
		examId: string
		fields: { path: Path; index?: number | undefined }[]
	},
) => {
	const { examId, fields } = data

	const indexesByBasePath = fields.reduce((result, field) => {
		if (field.index === undefined) {
			return result
		}

		const basePath = field.path.split('.')[0]
		if (!result[basePath]) {
			result[basePath] = []
		}

		result[basePath] = [...new Set(result[basePath].concat(field.index))].sort(
			(a, b) => a - b,
		)
		return result
	}, {} as Record<string, number[]>)

	const cachedQueries = state.fieldsDataMetaApi.queries
	Object.values(cachedQueries)
		.filter(query => {
			if (query?.endpointName !== 'getBasePathInfo') {
				return false
			}

			const args = (query as GetArrayInfoQuery).originalArgs
			return (
				args.examId === examId && indexesByBasePath[args.basePath] !== undefined
			)
		})
		.forEach(query => {
			if (!query) {
				return
			}

			const { basePath } = (query as GetArrayInfoQuery).originalArgs

			const newIndexes = indexesByBasePath[basePath].filter(
				index =>
					!(query as GetArrayInfoQuery).data?.validIndexes.includes(index),
			)

			if (newIndexes.length) {
				dispatch(
					fieldsDataMetaApi.util.updateQueryData(
						'getBasePathInfo',
						{ examId, basePath },
						draft => {
							Object.assign(draft, {
								validIndexes: [
									...new Set(draft.validIndexes.concat(newIndexes)),
								].sort((a, b) => a - b),
							})
						},
					),
				)
			}
		})
}

const clearFieldDataCacheForBasePathAndIndex = (
	state: RootState,
	dispatch: TeloDispatch,
	data: { examId: string; basePath: string; index: number },
) => {
	Object.values(state.fieldsDataApi.queries)
		.filter(query => {
			if (query?.endpointName !== 'getFieldData') {
				return false
			}

			const args = (query as GetFieldDataQuery).originalArgs
			return (
				args.examId === data.examId &&
				args.index === data.index &&
				args.path.startsWith(data.basePath)
			)
		})
		.forEach(query => {
			const { examId, path, index } = (query as GetFieldDataQuery).originalArgs

			// This action will invalidate that cache entry
			dispatch(
				fieldsDataApi.endpoints.getFieldData.initiate(
					{ examId, path, index },
					{ forceRefetch: true },
				),
			)
		})
}

type GetArrayInfoQuery = {
	originalArgs: {
		examId: string
		basePath: BasePath
	}
	data?: {
		validIndexes: number[]
	}
}

const removeDeletedIndexFromCache = (
	dispatch: TeloDispatch,
	{
		examId,
		basePath,
		index,
	}: {
		examId: string
		basePath: BasePath
		index: number
	},
) => {
	dispatch(
		fieldsDataMetaApi.util.updateQueryData(
			'getBasePathInfo',
			{ examId, basePath },
			draft => {
				Object.assign(draft, {
					validIndexes: draft.validIndexes.filter(
						validIndex => validIndex !== index,
					),
				})
			},
		),
	)
}

const newConnectToSocketArgs = (
	dispatch: TeloDispatch,
	getState: TeloGetState,
	navigate: TeloNavigationFn,
): Omit<RoomSocketArgs, 'roomName'> => {
	const sessionId = selectSessionId(getState())

	const fetchAssessment: FetchAssessmentFn = (assessmentId: string) => {
		getFetchAssessment(assessmentId, dispatch)()
	}

	const fetchUser: FetchUserFn = (username: string) => {
		getFetchUser(username, dispatch, navigate)()
	}

	const fetchStages: FetchStagesFn = (storeId: string) => {
		getFetchStages(storeId, dispatch)()
	}

	const fetchExam: FetchExamFn = (examId: string) => {
		getFetchExam(examId, true, dispatch)()
	}

	const invalidateWorklistCache = () => {
		// No need to clear the worklist cache if not in the worklist page
		// since when the user will go to the worklist page the cache is invalidated
		if (window.location.pathname.includes('/worklist')) {
			// when existing exams updates there is no need to force the refresh of the appointments
			dispatch(appointmentsActions._setForceRefresh(false))
			dispatch(worklistApi.util.invalidateTags([WORKLIST_CACHE_TAG]))
			dispatch(appointmentsActions._setForceRefresh(true))
		}
	}

	const fetchExamWarnings: FetchExamWarningsFn = (examId: string) => {
		getFetchExamWarnings(examId, dispatch)()
	}

	const fetchInstrumentData: FetchInstrumentDataFn = ({
		examId,
		instrumentType,
	}: InstrumentMessageData) => {
		getFetchInstrumentData(examId, instrumentType, dispatch)()
	}

	const fetchInstrumentsInRoom: FetchInstrumentsInRoomFn = ({
		roomId,
		stageId,
		storeId,
	}: InstrumentInRoomMessageData) => {
		getFetchInstrumentsInRoom(storeId, stageId, roomId, dispatch)()
	}

	const addPatientExport: AddPatientExportFn = patientExport => {
		dispatch(patientExportActions.handlePatientExportDone(patientExport))
	}

	const addNotification: AddNotificationFn = notification => {
		dispatch(notificationsActions.addNotification(notification))
	}

	const removeNotification: RemoveNotificationFn = (notificationId: string) => {
		dispatch(notificationsActions.removeNotification(notificationId))
	}

	const findNotification: FindNotificationFn = (notificationId: string) =>
		selectNotification(notificationId)(getState()) ?? null

	const fetchFieldsData: FetchFieldsDataFn = data => {
		const state = getState()
		const username = selectUsername(state)

		if (username === data.updatedBy) {
			return
		}

		switch (data.type) {
			case 'fields-updated': {
				clearFieldDataCacheForPathAndIndex(state, dispatch, data)
				addNewIndexToCache(state, dispatch, data)
				const basePaths = [
					...new Set(data.fields.map(({ path }) => path.split('.')[0])),
				] as BasePath[]
				updateNextArrayIndexCache(dispatch, data.examId, basePaths)
				clearFieldDataMetaCache(dispatch, data.examId, basePaths)

				break
			}

			case 'index-deleted': {
				clearFieldDataCacheForBasePathAndIndex(state, dispatch, data)
				removeDeletedIndexFromCache(dispatch, data)
				clearFieldDataMetaCache(dispatch, data.examId, [data.basePath])

				break
			}
		}
	}

	const updateQueueFeatureFlag: UpdateQueueFeatureFlagFn = (
		enabled: boolean,
	) => {
		dispatch(appActions.setQueueAvailable(enabled))
	}

	return {
		fetchAssessment,
		fetchExam,
		fetchExamWarnings,
		fetchInstrumentData,
		fetchInstrumentsInRoom,
		fetchStages,
		fetchUser,
		sessionId,
		addNotification,
		removeNotification,
		findNotification,
		fetchFieldsData,
		invalidateWorklistCache,
		updateQueueFeatureFlag,
		addPatientExport,
	}
}

export const connectToRoom =
	(roomType: RoomSocketType, roomName: string, navigate: TeloNavigationFn) =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		if (!roomName) {
			return
		}

		connectToSocketRoom(roomType, {
			...newConnectToSocketArgs(dispatch, getState, navigate),
			roomName,
		})
	}

export const connectToRoomNoType =
	(roomName: string, navigate: TeloNavigationFn) =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		if (!roomName) {
			return
		}

		connectToSocketRoomNoType({
			...newConnectToSocketArgs(dispatch, getState, navigate),
			roomName,
		})
	}

export const disconnectFromRoom = (roomName: string) => () =>
	disconnectFromSocketRoomNoType(roomName)
