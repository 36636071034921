import React from 'react'

import { SvgProps } from '../../model/types'

const TriangleAlertIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			xmlns="http://www.w3.org/2000/svg"
			fill="currentColor"
			{...props}
		>
			<path d="M15.0007 19.166C15.0007 19.387 14.9129 19.599 14.7566 19.7553C14.6003 19.9116 14.3883 19.9993 14.1673 19.9993C13.9463 19.9993 13.7343 19.9116 13.5781 19.7553C13.4218 19.599 13.334 19.387 13.334 19.166C13.334 18.945 13.4218 18.733 13.5781 18.5768C13.7343 18.4205 13.9463 18.3327 14.1673 18.3327C14.3883 18.3327 14.6003 18.4205 14.7566 18.5768C14.9129 18.733 15.0007 18.945 15.0007 19.166ZM14.7923 12.291C14.7923 12.1253 14.7265 11.9663 14.6093 11.8491C14.492 11.7319 14.3331 11.666 14.1673 11.666C14.0016 11.666 13.8426 11.7319 13.7254 11.8491C13.6082 11.9663 13.5423 12.1253 13.5423 12.291V16.041C13.5423 16.2068 13.6082 16.3657 13.7254 16.483C13.8426 16.6002 14.0016 16.666 14.1673 16.666C14.3331 16.666 14.492 16.6002 14.6093 16.483C14.7265 16.3657 14.7923 16.2068 14.7923 16.041V12.291Z" />
			<path d="M12.4373 7.67907C13.2069 6.32573 15.1265 6.32573 15.8961 7.67907L23.0624 20.281C23.832 21.636 22.8706 23.3307 21.3321 23.3307H7.00129C5.46207 23.3307 4.50146 21.636 5.27107 20.281L12.4373 7.67907ZM14.8588 8.2895C14.7883 8.16636 14.6873 8.06417 14.5659 7.99314C14.4444 7.92212 14.3068 7.88474 14.1667 7.88474C14.0266 7.88474 13.889 7.92212 13.7675 7.99314C13.6461 8.06417 13.5451 8.16636 13.4746 8.2895L6.3092 20.8915C6.23938 21.0151 6.2027 21.1553 6.20284 21.2979C6.20298 21.4405 6.23993 21.5805 6.31 21.7041C6.38007 21.8276 6.4808 21.9302 6.60212 22.0017C6.72343 22.0732 6.86108 22.1111 7.00129 22.1115H21.3321C21.4722 22.111 21.6098 22.0731 21.731 22.0017C21.8522 21.9302 21.9528 21.8277 22.0229 21.7043C22.0929 21.5809 22.1299 21.4409 22.1301 21.2985C22.1304 21.156 22.0939 21.0159 22.0242 20.8923L14.8588 8.2895Z" />
		</svg>
	)
}

export default TriangleAlertIcon
