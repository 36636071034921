import React from 'react'

import { SvgProps } from '../../model/types'

const InfoIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 20 20"
			height="18"
			width="18"
			fill="currentColor"
			{...props}
		>
			<path d="M10 1.29c4.783 0 8.71 3.874 8.71 8.71 0 4.81-3.896 8.71-8.71 8.71A8.707 8.707 0 011.29 10 8.71 8.71 0 0110 1.29zM10 0C4.478 0 0 4.48 0 10c0 5.524 4.478 10 10 10s10-4.476 10-10c0-5.52-4.478-10-10-10zm-.463 4.839a.484.484 0 00-.484.504l.282 6.774a.484.484 0 00.484.464h.362c.26 0 .473-.205.483-.464l.283-6.774a.484.484 0 00-.484-.504zM10 13.387a1.129 1.129 0 110 2.258 1.129 1.129 0 010-2.258z" />
		</svg>
	)
}

export default InfoIcon
