import React from 'react'
import { SvgProps } from '../../model/types'

const HeartFilledIcon = (props: SvgProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="currentColor"
			{...props}
		>
			<path
				fill={props.fill}
				d="M15.4113 3.1675C17.1576 4.18492 18.3855 6.25053 18.3314 8.66071C18.2173 13.7498 11.2498 17.4998 9.99984 17.4998C8.74984 17.4998 1.78235 13.7498 1.66822 8.66071C1.61417 6.25053 2.84205 4.18492 4.5883 3.1675C6.2216 2.2159 8.27329 2.21079 9.99984 3.61519C11.7264 2.21079 13.7781 2.2159 15.4113 3.1675Z"
			/>
		</svg>
	)
}

export default HeartFilledIcon
