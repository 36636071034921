import React from 'react'

import { SvgProps } from '../../model/types'

const VideoIcon: React.FC<
	SvgProps & { height?: string; width?: string; viewBox?: string }
> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="currentColor"
			{...props}
		>
			<path d="M13.3333 3.33333C14.212 3.33333 14.9318 4.01323 14.9954 4.87561L15 4.99999V6.8413L16.7453 5.76614C17.4104 5.35643 18.2591 5.7979 18.3287 6.55283L18.3333 6.65304V13.3472C18.3333 14.1283 17.5122 14.6194 16.833 14.2827L16.7453 14.234L15 13.1588V15C15 15.8787 14.3201 16.5985 13.4577 16.6621L13.3333 16.6667H3.33329C2.45466 16.6667 1.73482 15.9868 1.6712 15.1244L1.66663 15V4.99999C1.66663 4.12136 2.34652 3.40152 3.20891 3.3379L3.33329 3.33333H13.3333ZM13.3333 4.99999H3.33329V15H13.3333V4.99999ZM16.6666 7.77212L15 8.79883V11.2013L16.6666 12.2281V7.77212Z"></path>
		</svg>
	)
}

export default VideoIcon
