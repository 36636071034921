import {
	STATUS_PROGRESS,
	STATUS_TODO,
	STATUS_EVALUATING,
} from '../libs/pr-constants'

const COMPLETED = 'completed'

export const remapStatusForApi = (data: any) => {
	const { status, ...otherData } = data

	if (status === STATUS_PROGRESS) {
		return {
			status: COMPLETED,
			renew_result: STATUS_EVALUATING,
			...otherData,
		}
	}

	if (status === STATUS_TODO) {
		return { status: COMPLETED, renew_result: status, ...otherData }
	}

	return { status: COMPLETED, renew_result: status, ...otherData }
}
