import React from 'react'

import { SvgProps } from '../../model/types'

const ChevronRightIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="currentColor"
			{...props}
		>
			<path d="M13.0894 9.41074C13.4148 9.73615 13.4148 10.2638 13.0894 10.5892L8.37531 15.3033C8.04991 15.6287 7.52228 15.6287 7.19683 15.3033C6.8714 14.9778 6.8714 14.4502 7.19683 14.1247L11.3216 9.99999L7.19683 5.8752C6.8714 5.54976 6.8714 5.02212 7.19683 4.69669C7.52228 4.37125 8.04991 4.37125 8.37531 4.69669L13.0894 9.41074Z" />
		</svg>
	)
}

export default ChevronRightIcon
