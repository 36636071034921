import React, { FC } from 'react'

import { SnackbarProps } from '@mui/material/Snackbar'

import { identity } from 'lodash/fp'

import Alert, { TeloAlertActions } from '../../styleguide/Alert'
import { NotificationSeverity } from '../../model/model'

import { defaultNotificationAlertAnchorOrigin } from './constants'
import {
	NotificationAlertSnackbar,
	NotificationAlertTitle,
	NotificationAlertContainer,
} from './NotificationAlert.styled'

export type NotificationAlertProps = SnackbarProps & {
	severity: NotificationSeverity
	message?: string
	title?: string
	isIconVisible?: boolean
	onClose: () => void
	actions?: TeloAlertActions
}

export const NotificationAlert: FC<NotificationAlertProps> = ({
	isIconVisible = true,
	anchorOrigin = defaultNotificationAlertAnchorOrigin,
	message,
	title,
	severity,
	onClose = identity,
	actions,
}) => (
	<NotificationAlertSnackbar anchorOrigin={anchorOrigin} open>
		<Alert
			severity={severity}
			isIconVisible={isIconVisible}
			onClose={onClose}
			actions={actions}
		>
			<NotificationAlertContainer>
				{title && (
					<NotificationAlertTitle severity={severity}>
						{title}
					</NotificationAlertTitle>
				)}
				{message}
			</NotificationAlertContainer>
		</Alert>
	</NotificationAlertSnackbar>
)
