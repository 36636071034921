import { io, Socket } from 'socket.io-client'
import appConfig from '../../../config'
import { newTeloSocketConnector } from '../teloSocketConnector'
import { disconnectTeloSocket } from '../teloSocketRegistry'
import { TeloSocket } from '../teloSocketTypes'
import { basicDisconnectSocket, isSocketConnected } from '../teloSocketUtils'
import {
	OnUserOnlineStatusChangeFn,
	UserOnlineStatus,
} from './userOnlineStatusSocketTypes'

type UserOnlineStatusSocket = TeloSocket
type SocketPlatform = 'connect' | 'cockpit'
type UserOnlineStatusSocketArgs = {
	username: string
	platform: SocketPlatform
	onUserOnlineStatusChange: OnUserOnlineStatusChangeFn
}

const newSocket = ({
	username,
	platform,
	onUserOnlineStatusChange,
}: UserOnlineStatusSocketArgs): UserOnlineStatusSocket => {
	let socket: Socket | null = null

	const connect = () => {
		if (isSocketConnected(socket)) {
			return
		}
		socket = io(appConfig.socketUrl, {
			query: { keepalive: 'get', username, platform },
			transports: ['websocket'],
		})

		socket.on('KEEPALIVE', (status: UserOnlineStatus) => {
			if (isSocketConnected(socket)) {
				onUserOnlineStatusChange(status === 'online')
			}
		})
	}

	const disconnect = () => {
		basicDisconnectSocket(socket)
		socket = null
	}

	return { connect, disconnect }
}

const SOCKET_KEY = 'useronlinestatus'

const socketConnector = newTeloSocketConnector()

export function connectToSocketUserOnlineStatus(
	args: UserOnlineStatusSocketArgs,
): void {
	socketConnector.connectSocket({
		socketKey: SOCKET_KEY,
		newSocket: () => newSocket(args),
	})
}

export function disconnectFromSocketUserOnlineStatus(): void {
	disconnectTeloSocket(SOCKET_KEY)
}
