import { ExamApi, Order } from '../../model/exam'

import { DoctorVisualFieldsPayload } from './model'

export const getSaveDoctorVisualFieldsDataPayload = (
	username: string,
	interpretation: DoctorVisualFieldsPayload,
	confrontational: DoctorVisualFieldsPayload,
): Partial<ExamApi> => ({
	doctorInterpretation: {
		OD: {
			full: interpretation.odCheck,
			note: {
				text: interpretation.odNote,
				authorUsername: username,
				timestamp: new Date(),
			},
		},
		OS: {
			full: interpretation.osCheck,
			note: {
				text: interpretation.osNote,
				authorUsername: username,
				timestamp: new Date(),
			},
		},
	},
	confrontationalVF: {
		OD: {
			ftfc: confrontational.odCheck,
			note: {
				text: confrontational.odNote,
				authorUsername: username,
				timestamp: new Date(),
			},
		},
		OS: {
			ftfc: confrontational.osCheck,
			note: {
				text: confrontational.osNote,
				authorUsername: username,
				timestamp: new Date(),
			},
		},
	},
})

export const getOrderFileNameLabel = (order: Order): string => {
	if (order.orderType === 'device') {
		return ` - ${order.device}`
	}

	return order.reasonCpt ? ` - ${order.reasonCpt}` : ''
}
