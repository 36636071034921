import { selectExam } from '../features/exams/selectors'
import { selectStore } from '../features/stores/selectors'
import { RootState } from '../model/model'
import store from '../store'
import { getCommonHeaders } from './fetchJson'

export const getSystemHeaders = (
	organizationId?: string,
	addContentType?: boolean,
) => {
	const state = store.getState() as RootState
	const headers = getCommonHeaders({ addContentType })

	const examId = state.exams.currentExamId?._id

	let upriseOrgId: string | undefined

	if (organizationId) {
		upriseOrgId = organizationId
	} else if (examId) {
		const exam = selectExam(examId)(state)
		upriseOrgId = exam?.store.fhirOrganizationUpriseId
	}
	if (!upriseOrgId) {
		const store = selectStore(state)
		upriseOrgId = store?.fhirOrganizationUpriseId
	}

	return {
		...headers,
		'x-uprise-org-id': upriseOrgId || '',
	}
}
