import { getAssessment, getAssessments, getStates } from '../../apiCalls'
import { Id } from '../../model/model'
import { AppThunk, TeloDispatch } from '../../store'
import { slice } from './slice'

const fetchStates = (): AppThunk => (dispatch: TeloDispatch) => {
	getStates().then(
		states => states && dispatch(slice.actions._loadStates(states)),
	)
}

const fetchAssessments = (): AppThunk => (dispatch: TeloDispatch) => {
	getAssessments().then(
		assessments =>
			assessments && dispatch(slice.actions._loadAssessments(assessments)),
	)
}

const fetchAssessment =
	(id: Id): AppThunk =>
	(dispatch: TeloDispatch) => {
		getAssessment(id).then(
			assessment =>
				assessment && dispatch(slice.actions._loadAssessment(assessment)),
		)
	}

const renewalActions = {
	fetchAssessments,
	fetchStates,
	fetchAssessment,
}

export default renewalActions
