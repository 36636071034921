import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Assessment, RenewalState, State } from '../../model/renewal'

const initialState: RenewalState = {}

export const slice = createSlice({
	name: 'renewal',
	initialState,
	reducers: {
		_loadAssessments: (state, { payload }: PayloadAction<Assessment[]>) => {
			state.assessments = payload.reduce((result, assessment) => {
				result[assessment._id] = assessment
				return result
			}, {} as { [id: string]: Assessment })
		},
		_loadAssessment: (state, { payload }: PayloadAction<Assessment>) => {
			;(state.assessments || {})[payload._id] = payload
		},
		_loadStates: (state, { payload }: PayloadAction<State[]>) => {
			state.states = payload
		},
	},
})

export default slice.reducer
