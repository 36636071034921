import { ExamStatus } from './exam'
import { Id } from './model'
import { LensAddOn } from './lensAddOn'
import { LensAddOnsData, LensDesignData, LensMaterialData } from './manual'
import { RefusalReasonType } from './refusalReason'

export type BaseValue = 'BI' | 'BO' | 'BU' | 'BD' | 'in' | 'out' | 'down' | 'up'

export type EyeApi = 'L' | 'R' | 'B'

export type ModeApi = 'day' | 'night'
export type PrismValueApi = {
	base: BaseValue
	value: string
}
export type LensmeterSingleEyeApi = Partial<{
	sphere: string
	cylinder: string
	axis: string
	add: string
	prism: {
		x?: PrismValueApi
		y?: PrismValueApi
	}
}>

export type VisualAcuityBothEyeApi = {
	distance: string
	distanceAdditional: string
	near: string
	nearAdditional: string
}

export type VisualAcuitySingleEyeApi = VisualAcuityBothEyeApi & {
	distancePH: string
	nearPH: string
}

export type VisualAcuityDataApi = {
	OD?: VisualAcuitySingleEyeApi
	OS?: VisualAcuitySingleEyeApi
	BOTH?: VisualAcuityBothEyeApi
}

export type LensmeterDataWithAccuracyApi = {
	accuracy: Accuracy
	L?: LensmeterSingleEyeApi
	R?: LensmeterSingleEyeApi
}

export type LensType =
	| ''
	| 'SingleVisionDistance'
	| 'Progressive'
	| 'Bifocal'
	| 'Computer'
	| 'SingleVisionNear'
	| 'Trifocal'
	| 'Sunglasses'

export type LensmeterDataApi = {
	default: LensmeterDataWithAccuracyApi
	visualAcuity: VisualAcuityDataApi
	other?: LensmeterDataWithAccuracyApi
	examStatus?: ExamStatus
	use: string
	note: string
	lensType: LensType
	lensAddOns?: LensAddOn[]
}

export type HistoricalRXDataApi = {
	default: LensmeterDataWithAccuracyApi
	visualAcuity: VisualAcuityDataApi
	other?: LensmeterDataWithAccuracyApi
	examStatus?: ExamStatus
	use: string
	note: string
	lensType: LensType
	lensAddOns?: LensAddOnsData
	lensMaterial?: LensMaterialData
	lensDesign?: LensDesignData
}

export type AutorefractionSingleEyeApi = {
	sphere: string
	cylinder: string
	axis: string
	pupils: string
}

export type AutorefractionDataWithAccuracyApi = {
	accuracy: Accuracy
	L?: AutorefractionSingleEyeApi
	R?: AutorefractionSingleEyeApi
}

export type AutorefractionDataDayOrNightApi = {
	default: AutorefractionDataWithAccuracyApi
	other?: AutorefractionDataWithAccuracyApi
}

export type AutorefractionDataApi = {
	day: AutorefractionDataDayOrNightApi
	night?: AutorefractionDataDayOrNightApi
	pupillaryDistance?: string
	topography?: RemoteMediaApi[]
	summary?: RemoteMediaApi[]
	crystalline?: RemoteMediaApi[]
	visionSimulation?: RemoteMediaApi[]
	accommodation?: {
		R: { equivalentSphereAmplitude: number }
		L: { equivalentSphereAmplitude: number }
	}
}

export type KeratometerSingleEyeApi = {
	R1: {
		power: string
		axis: string
	}
	R2: {
		power: string
		axis: string
	}
}

export type KeratometerDataWithAccuracyApi = {
	accuracy: Accuracy
	L: KeratometerSingleEyeApi
	R: KeratometerSingleEyeApi
}

export type KeratometerDataDayOrNightApi = {
	default: KeratometerDataWithAccuracyApi
	other?: KeratometerDataWithAccuracyApi
}

export type KeratometerDataApi = {
	day: KeratometerDataDayOrNightApi
	night?: KeratometerDataDayOrNightApi
	examStatus?: ExamStatus
	topography?: RemoteMediaApi[]
}

export type TonometerMeasureApi = {
	iop1?: string
	iop2?: string
	iopc?: string
}

export type TonometerDataApi = {
	L: TonometerMeasureApi
	R: TonometerMeasureApi
	acquisitionTime?: number
	examStatus?: ExamStatus
	pachymetry?: PachymetryDataApi
	method?: string
	notes?: string
}

export type AddDescriptionValues =
	| 'HI'
	| 'HIGH'
	| 'MED'
	| 'MEDIUM'
	| 'MID'
	| 'LO'
	| 'LOW'
	| 'D'
	| 'N'
	| ''

export type PhoropterSingleEyeApi = {
	sphere: string
	cylinder: string
	axis: string
	add: string
	prism: {
		x: PrismValueApi
		y: PrismValueApi
	}
	va: string
	vaAdditional?: number
	description: AddDescriptionValues
}

export type Accuracy = '0.01' | '0.25'
export type PhoropterDataWithAccuracyApi = {
	accuracy: Accuracy
	L?: PhoropterSingleEyeApi
	R?: PhoropterSingleEyeApi
	B: {
		va: {
			near: string
			nearAdditional?: number
			distance: string
			distanceAdditional?: number
		}
	}
}

export type PhoropterDataDayOrNightApi = {
	default: PhoropterDataWithAccuracyApi
	other?: PhoropterDataWithAccuracyApi
}

export type PhoropterDataPartApi = {
	day: PhoropterDataDayOrNightApi
	night?: PhoropterDataDayOrNightApi
	note: string
	ph?: {
		R: string
		L: string
		B: string
	}
	glare?: {
		R: string
		L: string
		B: string
	}
}

export type PhoropterDataApi = {
	subjectiveRefraction?: PhoropterDataPartApi
	cycloplegicRefraction?: PhoropterDataPartApi
	finalRx?: PhoropterDataPartApi
	contactLenses?: PhoropterDataPartApi
	pupillaryDistance?: string
	sensitivity?: number
}

export type RemoteMediaApi = {
	name: string
	eye: EyeApi
	mode?: ModeApi
	format: string
	path?: string
	data?: string
	note?: string
	status?: ExamStatus
	createdAt?: string
	contactLensesCode?: Id
}

export type RemoteInstrumentEyeDetails = {
	media: RemoteMediaApi[]
	note?: string
}

export type RemoteInstrumentApi = {
	L: RemoteInstrumentEyeDetails
	R: RemoteInstrumentEyeDetails
}

export type SlitLampDataApi = {
	L: {
		media: RemoteMediaApi[]
		note: string
	}
	R: {
		media: RemoteMediaApi[]
		note: string
	}
}

export type RetinalImagingDataApi = RemoteInstrumentApi & {
	examStatus?: ExamStatus
}

export type VisualFieldsDataApi = {
	L: {
		media: RemoteMediaApi[]
		note: string
	}
	R: {
		media: RemoteMediaApi[]
		note: string
	}
}

export type PachymetryDataApi = {
	L: {
		media: RemoteMediaApi[]
		note?: string
	}
	R: {
		media: RemoteMediaApi[]
		note?: string
	}
}

export type InstrumentErrorApi = {
	message: string
	code: string
}

export type BiometerDataApi = {
	L: {
		axialLength: number
	}
	R: {
		axialLength: number
	}
}

type OCTEyeDataApi = {
	media: RemoteMediaApi[]
	note: string
	rnfl?: number
	gcl?: number
	macThickness?: number
	axialLength?: number
}

export type OCTDataApi = {
	L: OCTEyeDataApi
	R: OCTEyeDataApi
	examStatus?: ExamStatus
	unableToPerformExam?: boolean
		unableToPerformExamReason?: (RefusalReasonType & {
			code: string;
			description: string;
		} & {
			key: string;
		}) | {}
}

export type InstrumentDataApi =
	| LensmeterDataApi
	| HistoricalRXDataApi
	| AutorefractionDataApi
	| KeratometerDataApi
	| TonometerDataApi
	| PhoropterDataApi
	| VisualFieldsDataApi
	| SlitLampDataApi
	| RetinalImagingDataApi
	| InstrumentErrorApi
	| BiometerDataApi
	| OCTDataApi

export enum InstrumentApiType {
	LM = 'LM',
	AR = 'AR',
	KM = 'KM',
	NT = 'NT',
	PH = 'PH',
	VF = 'VF',
	OCT = 'OCT',
	SL = 'SL',
	RI = 'RI',
}

export const instrumentTypes = [
	'LM',
	'AR',
	'KM',
	'NT',
	'PH',
	'VF',
	'OCT',
	'SL',
	'RI',
] as const

export type InstrumentType = (typeof instrumentTypes)[number]

export type SaveInstrumentDataResponse = {
	examId: Id
	examData: InstrumentDataApi
	examType: InstrumentType
	updatedAt: Date
}

export type ExamContactLensesCodesApi = {
	L: string
	R: string
}

export type GetInstrumentDataResponseApi = {
	examData?: InstrumentDataApi
	examStatus?: ExamStatus
	updatedAt?: string
}

export type BasicInstrumentDataResponseApi = {
	examId: string
	examType: InstrumentType
	examStatus?: string
	updatedAt?: string
}

export type InstrumentDataResponseApi = BasicInstrumentDataResponseApi & {
	examData?: InstrumentDataApi
}
