import { createSelector } from '@reduxjs/toolkit'
import { GetExternalIdentifierSource } from '../../libs/utils'
import { RootState } from '../../model/model'
import { Practice, PracticesStore } from '../../model/practice'
import { Optional } from '../../utils/utilityTypes'
import { selectStoreId } from '../app/selectors'
import { selectExam } from '../exams/selectors'
import { selectUser } from '../users/selectors'
import { ContactLensesCatalogue } from '../../model/contactLenses'

export const selectPractices = (state: RootState): Practice[] =>
	state.practices || []

export const selectPractice =
	(practiceId: Optional<string>) =>
	(state: RootState): Optional<Practice> => {
		if (!practiceId) {
			return null
		}
		return state.practices?.find(({ _id }) => _id === practiceId)
	}

export const selectPracticesByIds =
	(practiceIds: string[]) =>
	(state: RootState): Practice[] => {
		return state.practices?.filter(({ _id }) => practiceIds.includes(_id)) || []
	}

export const selectPracticeByExternalId =
	(externalId: string) =>
	(state: RootState): Practice | undefined => {
		return state.practices?.find(({ stores }) =>
			stores.some(
				s =>
					s.externalIds &&
					(s.externalIds.find(id => id.source === GetExternalIdentifierSource())
						?.code ||
						'' === externalId),
			),
		)
	}

export const selectStorePractice =
	(storeId: string) =>
	(state: RootState): Practice[] => {
		return (
			state.practices?.filter(p => p.stores?.find(s => s._id === storeId)) || []
		)
	}

export const selectPracticesStoreByStoreId =
	(storeId: string) =>
	(state: RootState): PracticesStore | undefined => {
		const practice = state.practices?.find(p =>
			p.stores.find(s => s._id === storeId),
		)
		return practice && practice.stores.find(s => s._id === storeId)
	}

export const selectPracticeByStoreId =
	(storeId: string) =>
	(state: RootState): Practice | undefined => {
		return state.practices?.find(p => p.stores?.find(s => s._id === storeId))
	}

export const selectPracticeById =
	(practiceId: string) =>
	(state: RootState): Practice | undefined => {
		return state.practices?.find(p => p._id === practiceId)
	}

export const selectPracticeByName =
	(practiceName: string) =>
	(state: RootState): Practice | undefined => {
		return state.practices?.find(p => p.name === practiceName)
	}

export const selectPracticeByStoresIds =
	(storeIds: string[]) =>
	(state: RootState): Practice[] | undefined => {
		return state.practices?.filter(p =>
			p.stores.find(s => storeIds.includes(s._id)),
		)
	}

export const selectPracticeByExamId =
	(examId: string) =>
	(state: RootState): Practice | undefined => {
		const exam = selectExam(examId)(state)
		if (!exam) return undefined
		return state.practices?.find(p =>
			p.stores.find(s => s._id === exam.store._id),
		)
	}

export const selectContactLensesCatalogueByExamId = (examId?: string) =>
	createSelector(selectPracticeByExamId(examId ?? ''), practice => {
		const catalogue = practice?.contactLensesCatalogueUprise
			? 'uprise'
			: 'internal'
		return catalogue as ContactLensesCatalogue
	})

export const selectAllowEditExamsWithinPractice = (examId: string) =>
	createSelector(
		selectPracticeByExamId(examId),
		practice => practice?.modifyExamsWithinPractice,
	)

export const selectCurrentPractice = (state: RootState) => {
	const storeId = selectStoreId(state)

	const practice = selectPracticeByStoreId(storeId)(state)

	return practice
}

export const selectAllUserPractices = (state: RootState): Practice[] => {
	const user = selectUser(state)
	if (!user) {
		return []
	}
	const userPracticeIds = user.practices.map(({ practice }) => practice)
	const userStoreIds = user.stores.map(({ store }) => store._id)

	const allPractices = selectPractices(state)
	const userPractices = allPractices.filter(p => {
		const practiceStoresIds = p.stores.map(({ _id }) => _id)
		return (
			userPracticeIds.includes(p._id) ||
			userStoreIds.some(id => practiceStoresIds.includes(id))
		)
	})

	return userPractices
}

export const selectAllUserPracticesIds = createSelector(
	selectAllUserPractices,
	practices => {
		return practices.map(({ _id }) => _id)
	},
)

export const selectAllUserPracticesManager = (state: RootState): Practice[] => {
	const user = selectUser(state)
	if (!user) {
		return []
	}
	const allPractices = selectPractices(state)
	const userPractices = allPractices.filter(({ _id }) => {
		const userPractice = user.practices.find(
			({ practice, privileges }) =>
				practice === _id && privileges.includes('LegalEntityManager'),
		)
		return !!userPractice
	})
	return userPractices
}

export const selectIsUserAssignedToPractice = (examId: string) =>
	createSelector(
		selectAllUserPractices,
		selectPracticeByExamId(examId),
		(userPractices, examPractice) => {
			const userPracticesIds: string[] = userPractices.flatMap(p => p._id)
			return userPracticesIds.includes(examPractice?._id || '')
		},
	)
