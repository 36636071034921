import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Timezone } from '../../model/timezone'
import { TimezonesState } from '../../model/model'

const initialState: TimezonesState = {
	timezones: [],
}

export const slice = createSlice({
	name: 'timezones',
	initialState,
	reducers: {
		_loadTimezones: (state, { payload }: PayloadAction<Timezone[]>) => {
			state.timezones = payload
		},
	},
})

export default slice.reducer
