import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { InternalPatient, InternalPatientStore } from '../../model/patient'

const initialState: InternalPatientStore = {}

export const slice = createSlice({
	name: 'patients',
	initialState,
	reducers: {
		_setPatients: (state, { payload }: PayloadAction<InternalPatient[]>) => {
			state = payload.reduce((result, patient) => {
				result[patient._id] = patient
				return result
			}, {} as InternalPatientStore)
		},
		_setPatient: (state, { payload }: PayloadAction<InternalPatient>) => {
			state[payload._id] = payload
		},
	},
})

export default slice.reducer
