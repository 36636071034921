import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from './common'
import {
	NewRefreshTokenBody,
	NewRefreshTokenOnStoreChange,
} from '../model/renewToken'
import { getCommonHeaders } from '../libs/fetchJson'

const RenewToken_TAG = 'renew-token'

export const renewTokenApi = createApi({
	reducerPath: 'renewTokenApi',
	tagTypes: [RenewToken_TAG],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		updateRefreshToken: builder.mutation<
			NewRefreshTokenOnStoreChange,
			NewRefreshTokenBody
		>({
			query: body => ({
				url: `/v1/renew-token`,
				method: 'POST',
				headers: getCommonHeaders({ addContentType: true }),
				credentials: 'include',
				body,
			}),
			invalidatesTags: [RenewToken_TAG],
		}),
	}),
})

export const { useUpdateRefreshTokenMutation } = renewTokenApi
