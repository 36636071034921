import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DiagnosiPlan } from "../../model/diagnosiPlans"

import { DiagnosiPlansState, Id } from '../../model/model'

const initialState: DiagnosiPlansState = {
	catalog: [],
}

export const slice = createSlice({
	name: 'diagnosiPlans',
	initialState,
	reducers: {
		_loadPlans: (
			state,
			{ payload }: PayloadAction<DiagnosiPlan[]>,
		) => {
			state.catalog = payload
		},
		_addNewPlan: (
			state,
			{ payload }: PayloadAction<DiagnosiPlan>,
		) => {
			state.catalog.push(payload)
		},
		_deletePlan: (
			state,
			{ payload }: PayloadAction<Id>,
		) => {
			state.catalog.splice(state.catalog.findIndex(({ _id }) => _id === payload), 1)
		},
		_loadPlan: (
			state,
			{ payload }: PayloadAction<DiagnosiPlan>,
		) => {
			state.catalog
				.splice(state.catalog.findIndex(({ _id }) => _id === payload._id), 1)
				.push(payload)
		},
	},
})

export default slice.reducer
