import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { StatesState } from '../../model/model'
import { State } from '../../model/state'

const initialState: StatesState = {
	states: [],
}

export const slice = createSlice({
	name: 'states',
	initialState,
	reducers: {
		_loadStates: (state, { payload }: PayloadAction<State[]>) => {
			state.states = payload
		},
	},
})

export default slice.reducer
