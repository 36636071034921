import { createApi } from '@reduxjs/toolkit/query/react'

import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from './common'

const ORDERType_TAG = 'order'

export type AttachSesMessagesInput = {
	messageIds: string[]
	examId: string
	orderId: string
	resultId: string
}

export const ordersApi = createApi({
	reducerPath: 'ordersApi',
	tagTypes: [ORDERType_TAG],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		attachSesMessages: builder.mutation<void, AttachSesMessagesInput>({
			query: body => ({
				url: `/v3/exam/${body.examId}/orders/${body.orderId}/results/${body.resultId}/attach-ses-messages`,
				method: 'POST',
				body,
			}),
		}),
	}),
})

export const { useAttachSesMessagesMutation } = ordersApi
