import { CONTAINER_NAME } from './model/types'
import config from './config'

export const apiRoutes = {
	accommodation: 'accommodation',
	alerts: 'alerts',
	allergies: 'allergies',
	appointments: 'appointments',
	assets: 'assets',
	coremedia: 'coremedia',
	countries: 'countries',
	cpt: 'cpt',
	symptoms: 'symptoms',
	dashboard: 'dashboard',
	exams: 'exams',
	icd10: 'icd10',
	internalPatients: 'internal-patients',
	log: 'log',
	login: 'login',
	logout: 'logout',
	renewToken: 'renew-token',
	mc: 'missionControl',
	modifiers: 'modifiers',
	myopia: 'myopia',
	office: 'office',
	panels: 'panels',
	patients: 'patients',
	pr: 'pr',
	practices: 'practices',
	prescriptions: 'prescriptions',
	reports: 'reports',
	signatures: 'signatures',
	snomedct: 'snomedct',
	states: 'states',
	stores: 'stores',
	summary: 'summary',
	timezones: 'timezones',
	trendsovertime: 'tot',
	troubleShooting: 'core-media-ts',
	users: 'users',
	vaScales: 'va-scales',
	visionTherapy: 'visionTherapy',
	reasons: 'reasons',
}

export const api = {
	getDocumentsFromReference: (
		docReference: string,
		container: CONTAINER_NAME,
	) =>
		`${config.apiUrl}/${
			apiRoutes.exams
		}/documents/${container}/${docReference}?t=${Date.now()}`,
}
