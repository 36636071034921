import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  Room, Stage, Store,
  StoresState
} from '../../model/store'

const initialState: StoresState = {}

export const slice = createSlice({
	name: 'stores',
	initialState,
	reducers: {
		_setStore: (state, { payload }: PayloadAction<Store>) => {
			state[payload._id] = payload
		},
		_removeStore: (state, { payload }: PayloadAction<Store>) => {
			delete state[payload._id]
		},
		_setStores: (state, { payload }: PayloadAction<Store[]>) => {
			payload.forEach(store => (state[store._id] = store))
		},
		_setStages: (
			state,
			{ payload }: PayloadAction<{ storeId: string; stages: Stage[] }>,
		) => {
			const { storeId, stages } = payload
			if (state[storeId]) {
				state[storeId].stages = stages
			}
		},
		_setRoom: (
			state,
			{ payload }: PayloadAction<{ storeId: string; room: Room }>,
		) => {
			const { storeId, room } = payload
			const stageIndex = state[storeId]?.stages.findIndex(
				({ _id }) => _id === room.stageId,
			)
			const roomIndex = state[storeId].stages[stageIndex].rooms.findIndex(
				({ _id }) => _id === room._id,
			)
			state[storeId].stages[stageIndex].rooms[roomIndex] = room
		},
		_addStage: (
			state,
			{ payload }: PayloadAction<{ storeId: string; stage: Stage }>,
		) => {
			const { storeId, stage } = payload
			state[storeId].stages = state[storeId].stages.concat(stage)
		},
		_replaceStage: (
			state,
			{ payload }: PayloadAction<{ storeId: string; stage: Stage }>,
		) => {
			const { storeId, stage } = payload
			state[storeId].stages = state[storeId].stages.map(oldStage =>
				oldStage._id === stage._id ? stage : oldStage,
			)
		},
		_addRoom: (
			state,
			{ payload }: PayloadAction<{ storeId: string; room: Room }>,
		) => {
			const { storeId, room } = payload
			state[storeId].stages = state[storeId].stages.map(oldStage =>
				oldStage._id === room.stageId
					? { ...oldStage, rooms: oldStage.rooms.concat(room) }
					: oldStage,
			)
		},
		_replaceRoom: (
			state,
			{ payload }: PayloadAction<{ storeId: string; room: Room }>,
		) => {
			const { storeId, room } = payload
			state[storeId].stages = state[storeId].stages.map(oldStage =>
				oldStage._id === room.stageId
					? {
						...oldStage,
						rooms: oldStage.rooms.map(oldRoom =>
							oldRoom._id === room._id ? room : oldRoom,
						),
					}
					: oldStage,
			)
		},
		_savePdfDocument: (
			state,
			{
				payload,
			}: PayloadAction<{ storeId: string; doc: string; name: string }>,
		) => {
			const { storeId, doc } = payload
			state[storeId.toString()].blobDocumentUrl = doc
		},
		_clearFileUrl: (state, { payload }: PayloadAction<{ storeId: string }>) => {
			const { storeId } = payload
			state[storeId.toString()].blobDocumentUrl = ''
		},
	},
})

export default slice.reducer
