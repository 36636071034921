import { prop } from 'lodash/fp'
import { createSelector } from '@reduxjs/toolkit'

import { PrescriptionState, RootState } from '../../model/model'
import { PatientSignatureSource } from '../../model/prescription'

export const selectPrescription = (state: RootState): PrescriptionState =>
	state.prescription

export const selectPatientSignatureSource = createSelector<
	[(state: RootState) => PrescriptionState],
	PatientSignatureSource | undefined
>(
	selectPrescription,
	prop<PrescriptionState, 'patientSignatureSource'>('patientSignatureSource'),
)

export const selectLoadingPrescription = createSelector<
	[(state: RootState) => PrescriptionState],
	boolean
>(
	selectPrescription,
	prop<PrescriptionState, 'loadingPrescription'>('loadingPrescription'),
)

export const selectContactLensesPresent = createSelector<
	[(state: RootState) => PrescriptionState],
	boolean | undefined
>(
	selectPrescription,
	prop<PrescriptionState, 'contactLensesPresent'>('contactLensesPresent'),
)

export const selectUpdatePrescriptionNeeded = createSelector<
	[(state: RootState) => PrescriptionState],
	boolean | undefined
>(
	selectPrescription,
	prop<PrescriptionState, 'updatePrescriptionNeeded'>(
		'updatePrescriptionNeeded',
	),
)

export const selectIsPrescriptionEmpty = createSelector<
	[(state: RootState) => PrescriptionState],
	boolean | undefined
>(
	selectPrescription,
	prop<PrescriptionState, 'isPrescriptionEmpty'>('isPrescriptionEmpty'),
)

export const selectInvalidExpirationPrescription = createSelector<
	[(state: RootState) => PrescriptionState],
	boolean | undefined
>(
	selectPrescription,
	prop<PrescriptionState, 'invalidExpiration'>('invalidExpiration'),
)
