export const getStoreIdFromUrl = () => getUrlSegment(['store'])

const getUrlSegment = (preSegments: string[]) => {
	const urlSegments = window.location.pathname.split('/').slice(1)
	const preSegmentIndex = urlSegments.findIndex(segment =>
		preSegments.includes(segment),
	)
	return preSegmentIndex > -1 && urlSegments.length >= preSegmentIndex + 2
		? urlSegments[preSegmentIndex + 1]
		: undefined
}

export const getAppointmentIdFromUrl = () =>
	getUrlSegment(['check-in', 'patient-profile'])

interface Params {
	[paramName: string]: any
}
export const buildQueryString = (params: Params) => {
	const qs = Object.entries(params)
		.filter(
			([_, value]) =>
				(value || value === 0) && (!Array.isArray(value) || value.length),
		)
		.flatMap(([key, value]) =>
			Array.isArray(value) ? [[key, value.join(',')]] : [[key, value]],
		)
		.map(([key, value]) => `${key}=${value}`)
		.join('&')

	return qs.length ? `?${qs}` : ''
}
