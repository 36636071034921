import { createSelector } from '@reduxjs/toolkit'

import {
	AppointmentPrivacyPolicyDocumentSigned,
	PrivacyPolicyDocumentGuarantor,
} from '../../model/appointment'
import {
	Id,
	PatientGuarantor,
	RootState,
	SignDocumentsState,
} from '../../model/model'
import { SignedDocumentByExamArgs } from './model'

export const selectSignDocuments = (state: RootState): SignDocumentsState =>
	state.signDocuments

export const selectSignedDocumentsByExam = createSelector<
	[(state: RootState) => SignDocumentsState, (_: RootState, examId: Id) => Id],
	AppointmentPrivacyPolicyDocumentSigned[] | []
>(
	selectSignDocuments,
	(_, examId) => examId,
	({ signedDocuments }, examId) => signedDocuments[examId] || [],
)

export const selectSignedDocumentByExam = createSelector<
	[
		(state: RootState) => SignDocumentsState,
		(_: RootState, args: SignedDocumentByExamArgs) => SignedDocumentByExamArgs,
	],
	AppointmentPrivacyPolicyDocumentSigned | undefined
>(
	selectSignDocuments,
	(_, args) => args,
	({ signedDocuments }, { examId, docId }) =>
		signedDocuments[examId]?.find(doc => doc.documentId === docId),
)

export const selectConsentGuarantorByPatientId =
	(patientId: Id) =>
	(state: RootState): PrivacyPolicyDocumentGuarantor | undefined => {
		return state.signDocuments.patientGuarantor[patientId]
	}
