import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Id, TasksState } from '../../model/model'
import { Task } from '../../model/task'

const initialState: TasksState = []

export const slice = createSlice({
	name: 'tasks',
	initialState,
	reducers: {
		_loadTasks: (state, { payload }: PayloadAction<Task[]>) => payload,
		_loadTask: (state, { payload }: PayloadAction<Task>) => {
			const index = state.findIndex(({ _id }) => _id === payload._id)
			if (index > -1) {
				state[index] = payload
			} else {
				state.push(payload)
			}
		},
		_deleteTask: (state, { payload }: PayloadAction<Id>) =>
			state.filter(({ _id }) => _id !== payload),
	},
})

export default slice.reducer
