import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import dialogActions from '../features/dialog/actions'
import { selectDialog } from '../features/dialog/selectors'
import storesActions from '../features/stores/actions'
import { pxToRem } from '../libs/style'
import { DeleteRoomType } from '../model/dialog'
import { useTeloDispatch, useTeloSelector } from '../store'
import Button from '../styleguide/buttons/Button'
import DialogActions from '../styleguide/dialog/DialogActions'
import DialogContent from '../styleguide/dialog/DialogContent'
import DialogContentText from '../styleguide/dialog/DialogContentText'

const DialogContentTextStyled = styled(DialogContentText)`
	min-width: ${pxToRem(299)}rem;
`

const isDeleteRoomDialog = (dialog: any): dialog is DeleteRoomType =>
	'room' in dialog && dialog.type === 'deleteRoom' && 'storeId' in dialog

const DeleteRoomDialog = () => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()
	const dialog = useTeloSelector(selectDialog)

	return !isDeleteRoomDialog(dialog) ? null : (
		<>
			<DialogContent>
				<DialogContentTextStyled>
					{t('admin.deleteRoomQuestion').replace('%%1', dialog.room.name)}
				</DialogContentTextStyled>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => dispatch(dialogActions.closeDialog())}
					variant="outlined"
				>
					{t('app.cancel')}
				</Button>
				<Button
					onClick={() => {
						dispatch(
							storesActions.deleteRoomAction(
								dialog.storeId,
								dialog.room.stageId,
								dialog.room._id,
							),
						).finally(() => dispatch(dialogActions.closeDialog()))
					}}
					autoFocus
					variant="contained"
				>
					{t('appointment.yesDelete')}
				</Button>
			</DialogActions>
		</>
	)
}

export default DeleteRoomDialog
