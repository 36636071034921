import { css } from 'styled-components'

export const pulseAnimation = css`
	@keyframes pulse {
		0% {
			opacity: 0.2;
		}

		70% {
			opacity: 1;
		}

		100% {
			opacity: 0.2;
		}
	}
`
