import { getExamDocumentsFromReferenceApi } from '../apiCalls'

export const convertToBase64 = (file: Blob) => {
	return new Promise((resolve, _) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = () => ''
	})
}

export const getBlobFromAttachmentRef = async (ref: string) => {
	// old attachments were written to MongoDB as base64 binary, which was bad
	// starting from v17.0 it was refactored to store files in a blob storage
	// so, old approach for backward compatibility to open old base64 attachments:
	if (ref.includes('base64')) {
		const base64Response = await fetch(ref)
		return await base64Response.blob()
	}

	// and new approach with file in blob storage
	return await getExamDocumentsFromReferenceApi(ref, 'medicalreports')
}
