import { omit } from 'lodash'
import {
	addStoreToPracticeApi,
	createPracticeApi,
	deleteLELogoApi,
	deletePracticeApi,
	enablePracticeAlertCategoryApi,
	enablePracticeCDSApi,
	enablePracticeTaskApi,
	getPracticeByStoreApi,
	getPracticesApi,
	removeStoreFromPracticeApi,
	saveLELogoApi,
	updatePracticeApi,
	updatePracticeManagersApi,
	updateTimeoutsPracticeApi,
	upsertPracticeSurgeonsApi,
} from '../../apiCalls'
import { CDS_RULE } from '../../model/cds'
import { Id } from '../../model/model'
import {
	CreatePracticePayload,
	Practice,
	Surgeon,
	UpdatePracticePayload,
} from '../../model/practice'
import { TeloNavigationFn } from '../../routing/teloRouter'
import { AppThunk, AppThunkPromise, TeloDispatch } from '../../store'
import appActions from '../app/actions'
import notificationsActions from '../notifications/actions'
import { slice } from './slice'
import { TimeoutConfig } from '../../model/timeoutConfig'

const getPractices = (): AppThunk => (dispatch: TeloDispatch) => {
	getPracticesApi().then(
		practices => practices && dispatch(slice.actions._setPractices(practices)),
	)
}

const getPractice =
	(practiceId: Id): AppThunk =>
	(dispatch: TeloDispatch) => {
		getPracticesApi({ id: practiceId }).then(
			practices =>
				practices &&
				practices[0] &&
				dispatch(slice.actions._setPractice(practices[0])),
		)
	}

const getPracticeByStore =
	(storeId: Id): AppThunk =>
	(dispatch: TeloDispatch) => {
		getPracticeByStoreApi(storeId).then(practice => {
			if (practice) {
				dispatch(appActions.setPracticeExternalId(practice._id))
				dispatch(slice.actions._setPractice(practice))
			}
		})
	}

const createPractice =
	(
		practiceToSave: CreatePracticePayload,
		newStaff: string[],
		navigate: TeloNavigationFn,
	): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		createPracticeApi(omit(practiceToSave, 'logo'))
			.then(practice => {
				if (practice && practice.logo && !practiceToSave.logo)
					return deleteLELogoApi(practice._id)
				else if (practice && practiceToSave.logo)
					return saveLELogoApi(practice._id, practiceToSave.logo)
				else return practice
			})
			.then(practice => updatePracticeManagersApi(practice._id, [], newStaff))
			.then(practice => {
				if (practice) {
					dispatch(slice.actions._setPractice(practice))
					dispatch(
						notificationsActions.addNotification({
							type: 'success',
							message: 'legalEntity.createSuccess',
							autoClose: true,
							messageIsLabelKey: true,
						}),
					)

					navigate('/admin?selectedTab=practices')
				} else {
					dispatch(
						notificationsActions.addNotification({
							type: 'error',
							message: 'legalEntity.createFailed',
							autoClose: true,
							messageIsLabelKey: true,
						}),
					)
				}
			})

const deletePractice =
	(practiceId: Id): AppThunk =>
	(dispatch: TeloDispatch) => {
		deletePracticeApi(practiceId).then(
			deletedCount =>
				deletedCount && dispatch(slice.actions._removePractice(practiceId)),
		)
	}

const updatePractice =
	(
		practiceToSave: UpdatePracticePayload,
		navigate: TeloNavigationFn,
		deletedUsers: string[] = [],
		newStaff: string[] = [],
		redirect: boolean = true,
		showNotification: boolean = true,
	): AppThunkPromise<Practice | void> =>
	(dispatch: TeloDispatch) =>
		updatePracticeApi(omit(practiceToSave, 'logo'))
			.then(practice => {
				if (!practice) {
					return practice
				}
				if (practice.logo && !practiceToSave.logo) {
					return deleteLELogoApi(practice._id)
				}

				if (
					practiceToSave.logo &&
					(!practice.logo || practiceToSave.logo !== practice.logo)
				) {
					return saveLELogoApi(practice._id, practiceToSave.logo)
				}

				return practice
			})
			.then(practice => {
				if (!!deletedUsers.length || !!newStaff.length) {
					return updatePracticeManagersApi(
						practiceToSave._id,
						deletedUsers,
						newStaff,
					)
				}
				return practice
			})
			.then(practice => {
				if (practice) {
					dispatch(slice.actions._setPractice(practice))
					if (showNotification === true) {
						dispatch(
							notificationsActions.addNotification({
								type: 'success',
								message: 'legalEntity.updateSuccess',
								autoClose: true,
								messageIsLabelKey: true,
							}),
						)
					}

					if (redirect) {
						navigate('/admin?selectedTab=practices')
					}
				} else {
					dispatch(
						notificationsActions.addNotification({
							type: 'error',
							message: 'legalEntity.updateFailed',
							autoClose: true,
							messageIsLabelKey: true,
						}),
					)
				}
			})

const addStoreToPractice =
	({
		practiceId,
		storeId,
	}: {
		practiceId: Id
		storeId: Id
	}): AppThunkPromise<Practice | void> =>
	(dispatch: TeloDispatch) =>
		addStoreToPracticeApi({ practiceId, storeId }).then(practice => {
			practice && dispatch(slice.actions._setPractice(practice))
		})

const removeStoreFromPractice =
	({
		practiceId,
		storeId,
	}: {
		practiceId: Id
		storeId: Id
	}): AppThunkPromise<Practice | void> =>
	(dispatch: TeloDispatch) =>
		removeStoreFromPracticeApi({ practiceId, storeId }).then(practice => {
			practice && dispatch(slice.actions._setPractice(practice))
		})

const enablePracticeAlertCategory =
	(
		id: string,
		value: boolean,
		alertId: string,
	): AppThunkPromise<Practice | void> =>
	(dispatch: TeloDispatch) =>
		enablePracticeAlertCategoryApi(id, value, alertId).then(practice => {
			practice && dispatch(slice.actions._setPractice(practice))
		})

const enablePracticeTask =
	(
		id: string,
		value: boolean,
		practiceId: string,
	): AppThunkPromise<Practice | void> =>
	(dispatch: TeloDispatch) =>
		enablePracticeTaskApi(id, value, practiceId).then(practice => {
			practice && dispatch(slice.actions._setPractice(practice))
		})

const enablePracticeCDS =
	(
		id: CDS_RULE,
		value: boolean,
		practiceId: string,
	): AppThunkPromise<Practice | void> =>
	(dispatch: TeloDispatch) =>
		enablePracticeCDSApi(id, value, practiceId).then(practice => {
			practice && dispatch(slice.actions._setPractice(practice))
		})

const upsertSurgeons =
	(surgeons: Surgeon[], practiceId: string): AppThunkPromise<Practice | void> =>
	(dispatch: TeloDispatch) =>
		upsertPracticeSurgeonsApi(practiceId, surgeons).then(practice => {
			practice && dispatch(slice.actions._setPractice(practice))
		})

const updateTimeouts =
	(
		practiceId: string,
		timeouts: TimeoutConfig,
	): AppThunkPromise<Practice | void> =>
	(dispatch: TeloDispatch) =>
		updateTimeoutsPracticeApi(practiceId, timeouts).then(practice => {
			practice && dispatch(slice.actions._setPractice(practice))
		})

const practicesActions = {
	getPractices,
	getPractice,
	getPracticeByStore,
	createPractice,
	deletePractice,
	updatePractice,
	addStoreToPractice,
	removeStoreFromPractice,
	enablePracticeAlertCategory,
	enablePracticeTask,
	enablePracticeCDS,
	upsertSurgeons,
	updateTimeouts,
}

export default practicesActions
