import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getIntersessionAzureCommunicationData } from '../../libs/intersession'
import { AzureCommunicationData } from '../../model/azureCommunication'
import {
	AzureCallee,
	AzureCommunicationState,
	AzureStatusIndicator,
	LoginStatus,
} from '../../model/model'

const blankState = {
	token: '',
	expiresIn: 0,
	communicationUserId: '',
}
const intersessionData = getIntersessionAzureCommunicationData() || blankState

const initialState: AzureCommunicationState = {
	data: intersessionData,
	loginStatus: intersessionData.token ? 'logged in' : 'not logged in',
	teloCallStatus: 'NONE',
	callee: {
		username: '',
		online: false,
	},
}

export const slice = createSlice({
	name: 'azure',
	initialState,
	reducers: {
		setAzureConnectionAuth: (
			state,
			{ payload }: PayloadAction<AzureCommunicationData>,
		) => {
			state.data = payload
			state.loginStatus = payload.token ? 'logged in' : 'not logged in'
		},
		setAzureConnectionStatus: (
			state,
			{ payload }: PayloadAction<LoginStatus>,
		) => {
			state.loginStatus = payload
		},
		setTeloCallStatus: (
			state,
			{ payload }: PayloadAction<'NONE' | 'STARTED' | 'ENDED' | 'FORCE_ENDED'>,
		) => {
			state.teloCallStatus = payload
		},
		setCalleeOnlineStatus: (state, { payload }: PayloadAction<AzureCallee>) => {
			state.callee = payload
		},
		delAzureConnectionAuth: _ => ({
			data: blankState,
			loginStatus: 'not logged in',
			statusIndicator: {
				active: false,
				show: false,
				error: false,
			},
			teloCallStatus: 'NONE',
			callee: {
				username: '',
				online: false,
			},
		}),
	},
})

export default slice.reducer
