import React from 'react'
import { BoxProps } from '@mui/material'
import styled from 'styled-components'
import AlertFilled from '../icons/AlertFilled'
import theme from '../theme'
import Box from '../Box'
import { pxToRem } from '../../libs/style'

const StyledWrapper = styled.div`
	display: flex;
	align-items: center;
	font-size: ${pxToRem(12)}rem;
	color: ${theme.palette.error.main};

	&.mt-05 {
		margin-top: ${theme.spacing(0.5)};
	}
`

const StyledAlertFilledIcon = styled(AlertFilled)`
	fill: ${theme.palette.error.main};
	line-height: 0;
	margin-left: ${theme.spacing(1)};
	margin-right: ${theme.spacing(1)};
`

export const InputHelperTextWithIcon: React.FC<BoxProps> = ({
	children,
	className,
}) => (
	<StyledWrapper className={className}>
		<StyledAlertFilledIcon />
		<Box component="span">{children}</Box>
	</StyledWrapper>
)
