import React from 'react'

import { SvgProps } from '../../model/types'

const AlertSquaredIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="currentColor"
			{...props}
		>
			<path d="M12.7614 1.66666C13.2034 1.66666 13.6274 1.84225 13.9399 2.15481L17.8451 6.06006C18.1577 6.37261 18.3333 6.79654 18.3333 7.23856V12.7614C18.3333 13.2034 18.1577 13.6274 17.8451 13.9399L13.9399 17.8452C13.6273 18.1577 13.2034 18.3333 12.7614 18.3333H7.23853C6.79651 18.3333 6.37258 18.1577 6.06003 17.8452L2.15478 13.9399C1.84222 13.6273 1.66663 13.2034 1.66663 12.7614V7.23856C1.66663 6.79654 1.84222 6.37261 2.15478 6.06006L6.06003 2.15481C6.37258 1.84225 6.79651 1.66666 7.23853 1.66666H12.7614ZM12.7614 3.33332H7.23853L3.33329 7.23856V12.7614L7.23853 16.6667H12.7614L16.6666 12.7614V7.23856L12.7614 3.33332ZM9.99996 12.5C10.4602 12.5 10.8333 12.8731 10.8333 13.3333C10.8333 13.7936 10.4602 14.1667 9.99996 14.1667C9.53971 14.1667 9.16663 13.7936 9.16663 13.3333C9.16663 12.8731 9.53971 12.5 9.99996 12.5ZM9.99996 4.99999C10.4602 4.99999 10.8333 5.37309 10.8333 5.83332V10.8333C10.8333 11.2936 10.4602 11.6667 9.99996 11.6667C9.53971 11.6667 9.16663 11.2936 9.16663 10.8333V5.83332C9.16663 5.37309 9.53971 4.99999 9.99996 4.99999Z" />
		</svg>
	)
}

export default AlertSquaredIcon
