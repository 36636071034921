import { AccuracyValue, RX_EYES } from './instruments'
import { Id } from './model'
import { LensAddOn } from './lensAddOn'
import { CLMeasureValue } from './exam'

export type HOrient = 'BO' | 'BI' | ''

export type VOrient = 'BU' | 'BD' | ''

export type Eye = 'OS' | 'OD'

export interface LensDataSingleEye {
	sphere: string | null
	cyl: string | null
	axis: string
	add: string
	hPrism: string
	vPrism: string
	hOrient: HOrient
	vOrient: VOrient
	balance?: boolean
}

export interface LensDataWithAccuracy {
	accuracy: AccuracyValue
	OD: LensDataSingleEye
	OS: LensDataSingleEye
}

export interface LensData {
	default: LensDataWithAccuracy
	other?: LensDataWithAccuracy
	ph?: {
		R: string
		L: string
		B: string
	}
	glare?: {
		R: string
		L: string
		B: string
	}
}

export interface PrescriptionGeneralUse {
	LensType: {
		SingleVision: boolean
		SingleVisionWithPowerBoost: boolean
		Progressive: boolean
		ProgressiveForDominantHand: boolean
		Bifocal: boolean
		Trifocal: boolean
	}
	UseInstructions: {
		FullTime: boolean
		AsNeeded: boolean
		Near: boolean
		Distance: boolean
	}
	LensRecommendations: {
		HighIndex: boolean
		Polycarbonate: boolean
		AntiReflective: boolean
		Photochromic: boolean
		BlueLight: boolean
	}
	LensData: LensData
	SelectedEye: RX_EYES
	LensAddOns?: LensAddOn[]
	LensMaterial?: string
	LensDesign?: string
	LensDistanceVA: {
		OS?: string
		OD?: string
	}
	LensNearVA: {
		OS?: string
		OD?: string
	}
}

export interface PrescriptionSunglasses {
	LensType: {
		SingleVision: boolean
		Progressive: boolean
		Bifocal: boolean
		Trifocal: boolean
		NonRx: boolean
	}
	UseInstructions: {
		AllYear: boolean
		Distance: boolean
		OverContantLenses: boolean
	}
	LensRecommendations: {
		HighIndex: boolean
		Polycarbonate: boolean
		Polarized: boolean
		BacksideAntiReflective: boolean
	}
	LensData: LensData
	SelectedEye: RX_EYES
	LensAddOns?: LensAddOn[]
	LensMaterial?: string
	LensDesign?: string
	LensDistanceVA: {
		OS?: string
		OD?: string
	}
	LensNearVA: {
		OS?: string
		OD?: string
	}
}

export interface PrescriptionComputer {
	LensType: {
		SingleVision: boolean
		SingleVisionWithPowerBoost: boolean
		ComputerWorkspace: boolean
		Progressive: boolean
		ProgressiveForDominantHand: boolean
		Bifocal: boolean
	}
	UseInstructions: {
		Computer: boolean
		AsNeeded: boolean
	}
	LensRecommendations: {
		HighIndex: boolean
		Polycarbonate: boolean
		AntiReflective: boolean
		Photochromic: boolean
		BlueLight: boolean
	}
	LensData: LensData
	SelectedEye: RX_EYES
	LensAddOns?: LensAddOn[]
	LensMaterial?: string
	LensDesign?: string
	LensDistanceVA: {
		OS?: string
		OD?: string
	}
	LensNearVA: {
		OS?: string
		OD?: string
	}
}

export interface PrescriptionReading {
	LensType: {
		SingleVisionWithPowerBoost: boolean
	}
	UseInstructions: {
		Reading: boolean
	}
	LensRecommendations: {
		HighIndex: boolean
		Polycarbonate: boolean
		AntiReflective: boolean
		Photochromic: boolean
		BlueLight: boolean
	}
	LensData: LensData
	SelectedEye: RX_EYES
	LensAddOns?: LensAddOn[]
	LensMaterial?: string
	LensDesign?: string
	LensDistanceVA: {
		OS?: string
		OD?: string
	}
	LensNearVA: {
		OS?: string
		OD?: string
	}
}

export interface PrescriptionNight {
	LensType: {
		SingleVision: boolean
		SingleVisionWithPowerBoost: boolean
		Progressive: boolean
		ProgressiveForDominantHand: boolean
		Bifocal: boolean
		Trifocal: boolean
	}
	UseInstructions: {
		AtNight: boolean
		Near: boolean
		Distance: boolean
	}
	LensRecommendations: {
		HighIndex: boolean
		Polycarbonate: boolean
		AntiReflective: boolean
		Photochromic: boolean
		BlueLight: boolean
	}
	LensData: LensData
	SelectedEye: RX_EYES
	LensAddOns?: LensAddOn[]
	LensMaterial?: string
	LensDesign?: string
	LensDistanceVA: {
		OS?: string
		OD?: string
	}
	LensNearVA: {
		OS?: string
		OD?: string
	}
}

export interface PrescriptionSport {
	LensType: {
		SingleVision: boolean
		Progressive: boolean
		Bifocal: boolean
		Trifocal: boolean
		NonRx: boolean
	}
	UseInstructions: {
		AsNeeded: boolean
		Distance: boolean
		OverContanctLenses: boolean
	}
	LensRecommendations: {
		HighIndex: boolean
		Polycarbonate: boolean
		Polarized: boolean
		BacksideAntiReflective: boolean
	}
	LensData: LensData
	SelectedEye: RX_EYES
	LensAddOns?: LensAddOn[]
	LensMaterial?: string
	LensDesign?: string
	LensDistanceVA: {
		OS?: string
		OD?: string
	}
	LensNearVA: {
		OS?: string
		OD?: string
	}
}

export interface PrescriptionSafety {
	LensType: {
		SingleVision: boolean
		SingleVisionWithPowerBoost: boolean
		Progressive: boolean
		ProgressiveForDominantHand: boolean
		Bifocal: boolean
		Trifocal: boolean
	}
	UseInstructions: {
		FullTime: boolean
		AsNeeded: boolean
		Near: boolean
		Distance: boolean
	}
	LensRecommendations: {
		ImpactResistant: boolean
		HighIndex: boolean
		Polycarbonate: boolean
		AntiReflective: boolean
		Photochromic: boolean
		BlueLight: boolean
	}
	LensData: LensData
	SelectedEye: RX_EYES
	LensAddOns?: LensAddOn[]
	LensMaterial?: string
	LensDesign?: string
	LensDistanceVA: {
		OS?: string
		OD?: string
	}
	LensNearVA: {
		OS?: string
		OD?: string
	}
}

export interface PrescriptionContactLenses {
	OD: PrescriptionContactLensesSingleEye
	OS: PrescriptionContactLensesSingleEye
	useSameProduct: boolean
	selectedEyes: RX_EYES
	fromExam?: boolean
	custom?: boolean
}

export type ReplacementFrequency =
	| 'Daily'
	| 'Weekly'
	| 'Weeks2'
	| 'Weeks4'
	| 'Months2'
	| 'Months3'
	| 'Months6'
	| 'Yearly'

export type Cleaning =
	| 'OptifreeReplenish'
	| 'OptifreePureMoist'
	| 'Renu'
	| 'Biotrue'
	| 'Complete'
	| 'Revitalens'
	| 'Clearcare'
	| 'Aosept'
	| 'BostonGPSystem'

export type Monovision =
	| 'RightEyeDistance'
	| 'RightEyeNear'
	| 'LeftEyeDistance'
	| 'LeftEyeNear'

export interface PrescriptionContactLensesSingleEye {
	LensType: string
	LensProduct?: string
	SuggestedLensProduct?: string
	Manufacter?: string
	Monovision?: Monovision
	DisinfectingSolution?: string
	Quantity?: string
	Measure: CLMeasureValue

	Replenishment?: string
	WearSchedule?: string
	ReplacementFrequency: ReplacementFrequency[]
	Cleaning: Cleaning[]

	AdditionalParameters?: string
	DesignName?: string
	Material?: string
}

export interface PrescriptionLegal {
	ExpirationDateGlassesLensRx: string | null
	ExpirationDateContactLensRx: string | null
	ApprovedforContactLenses: boolean
}

export interface PrescriptionDataGlasses {
	GeneralUse: PrescriptionGeneralUse[]
	Sunglasses: PrescriptionSunglasses[]
	Computer: PrescriptionComputer[]
	Reading: PrescriptionReading[]
	Night: PrescriptionNight[]
	Sport: PrescriptionSport[]
	Safety: PrescriptionSafety[]
}

export interface PrescriptionDataArray extends PrescriptionDataGlasses {
	ContactLenses: PrescriptionContactLenses[]
}

export interface PrescriptionData extends PrescriptionDataArray {
	Legal: PrescriptionLegal
}

export type PrescriptionDataWithExamId = PrescriptionData & {
	currentExamId?: string
}

export type PrescriptionContactLensesDataTempPayload = {
	id: string
	data: PrescriptionContactLenses[]
}

export enum PatientSignatureOption {
	LocalTechnician = 'localTechnician',
	LocalDevice = 'localDevice',
	Remote = 'remote',
}

export type PatientSignatureSource =
	| 'localTechnician'
	| 'localDevice'
	| 'remote'

export interface Prescription {
	_id: Id
	content?: string
	examId: Id
	userId: Id
	fullname: string
	birthDate: Date
	email: string
	phone: string
	filename: string
	mimeType: string
	data: PrescriptionData
	signed?: boolean
	sent?: boolean
	blobDocumentUrl?: string
	patientSignatureStatus?: 'requested' | 'fulfilled'
	patientSignatureSource?: PatientSignatureSource
	patientSignatureMandatory?: 'Required' | 'Optional' | 'Not Requested' | ''
}

export interface SummaryPrescription extends Prescription {
	createdOn: string
	state: string
	createdAt: string
	updatedAt: string
	container: string
}
