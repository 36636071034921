import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Country } from '../../model/country'
import { CountriesState } from '../../model/model'

const initialState: CountriesState = {
	countries: [],
}

export const slice = createSlice({
	name: 'countries',
	initialState,
	reducers: {
		_loadCountries: (state, { payload }: PayloadAction<Country[]>) => {
			state.countries = payload
		},
	},
})

export default slice.reducer
