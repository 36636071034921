import { CONTAINER_NAME } from '../model/types'

export const documentContainer: Record<CONTAINER_NAME, CONTAINER_NAME> = {
	ppd: 'ppd',
	documents: 'documents',
	charts: 'charts',
	medicalreports: 'medicalreports',
	orders: 'orders',
	myopia: 'myopia',
	surgeries: 'surgeries',
} as const

export enum FileType {
	pdf = 'application/pdf',
	png = 'image/png',
	jpg = 'image/jpg',
	jpeg = 'image/jpeg',
	gif = 'image/gif',
	mp4 = 'video/mp4',
	xml = 'application/xml',
}

export enum FileExtension {
	pdf = 'pdf',
	png = 'png',
	jpg = 'jpg',
	jpeg = 'jpeg',
	gif = 'gif',
	webp = 'webp',
	mp4 = 'mp4',
	dcm = 'dcm',
	xml = 'xml',
}

export const imageFileExtensions: Extract<
	FileExtension,
	'jpg' | 'jpeg' | 'png' | 'webp' | 'gif'
>[] = [
	FileExtension.jpg,
	FileExtension.jpeg,
	FileExtension.png,
	FileExtension.webp,
	FileExtension.gif,
]
