import { add, getTime } from 'date-fns'

import { PrivacyPolicyDocumentDurationUnit } from '../../model/store'

import { AdminDocumentsFormValues, NewPrivacyDocumentFormValues } from './types'
import { defaultDocumentDuration } from './constants'

export const getPrivacyDocumentExpirationDate = (
	duration?: number,
	durationUnit?: PrivacyPolicyDocumentDurationUnit,
): string => {
	const documentDuration = duration ?? defaultDocumentDuration
	const unit = durationUnit ?? PrivacyPolicyDocumentDurationUnit.years

	const expirationDate = add(new Date(), {
		[unit]: documentDuration,
	})

	return new Date(getTime(expirationDate)).toISOString()
}

export const getAddStoreDocumentPayload = ({
	duration,
	durationUnit,
	...values
}: Omit<AdminDocumentsFormValues, 'type'>): NewPrivacyDocumentFormValues => ({
	...values,
	duration:
		duration && durationUnit ? Number(duration) : defaultDocumentDuration,
	durationUnit:
		duration && durationUnit
			? durationUnit
			: PrivacyPolicyDocumentDurationUnit.years,
})

export const getUpdateStoreDocumentPayload = ({
	duration,
	durationUnit,
	...values
}: Omit<AdminDocumentsFormValues, 'type'>): NewPrivacyDocumentFormValues => {
	return {
		...getAddStoreDocumentPayload({ duration, durationUnit, ...values }),
		...values,
	}
}
