import { createSelector } from '@reduxjs/toolkit'
import { STATUS_TODO } from '../../libs/pr-constants'

import { Id, RootState } from '../../model/model'
import { Assessment } from '../../model/renewal'

export const selectStates = (state: RootState) => state.renewal.states || []

export const selectAssessmentsRaw = (state: RootState) =>
	state.renewal.assessments || {}

export const selectAssessments = createSelector(
	selectAssessmentsRaw,
	assessments => Object.values(assessments),
)

export const selectRenewalDataLoading = (state: RootState) =>
	!state.renewal.assessments || !state.renewal.states

export const selectPendingAsessments = createSelector(
	selectAssessments,
	(assessments: Assessment[]) =>
		assessments.filter(
			ass => (ass.status as 'todo' | string) === STATUS_TODO && ass,
		),
)
