import { generateUID } from '../../libs/uuid'
import { Notification } from '../../model/model'
import { TeloDispatch, TeloGetState } from '../../store'
import errorsActions from '../errors/actions'

import { notificationAutoCloseDelay } from './constants'
import { selectNotification } from './selectors'
import { slice } from './slice'
import { AddNotificationPayload } from './types'

const addNotification =
	<N extends Notification>(notification: AddNotificationPayload<N>) =>
	(dispatch: TeloDispatch) => {
		// Clear out colored notifications first
		// To avoid dense shadow effect
		if (notification.colored) {
			dispatch(slice.actions._removeColoredNotifications())
		}

		const _id = notification.id || generateUID()

		const notificationToStore = {
			...notification,
			_id,
		} as Notification

		dispatch(slice.actions._addNotification(notificationToStore))

		if (notification.autoClose) {
			setTimeout(() => {
				dispatch(slice.actions._removeNotification(_id))
			}, notification.autoCloseDelay || notificationAutoCloseDelay)
		}
	}

const removeNotification =
	(notificationId: string) =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const notification = selectNotification(notificationId)(state)
		notification?.errorId &&
			notification.errorType === 'ui' &&
			dispatch(errorsActions.removeUiError(notification.errorId))
		notification?.errorId &&
			notification.errorType === 'http' &&
			dispatch(errorsActions.removeHttpError(notification.errorId))

		dispatch(slice.actions._removeNotification(notificationId))
	}

const notificationsActions = {
	removeNotification,
	addNotification,
}

export default notificationsActions
