import React, { FC } from 'react'

import Snackbar from '../../styleguide/Snackbar'

import { NotificationCloseButton } from './NotificationCloseButton'

export interface StandardNotificationProps {
	id: string
	message?: string
	colored?: boolean
	onClose: () => void
}

export const StandardNotification: FC<StandardNotificationProps> = ({
	id,
	message,
	colored,
	onClose,
}) => (
	<Snackbar
		message={message}
		$colored={colored}
		action={<NotificationCloseButton id={id} onClose={onClose} />}
		open
	/>
)
