import { createApi } from '@reduxjs/toolkit/query/react'
import qs from 'qs'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { FhirPatient } from '../model/patient'
import { unversionedApiUrl } from './common'

export const fhirPatientsApi = createApi({
	reducerPath: 'fhirPatientsApi',
	baseQuery: staggeredBaseQueryWithBailOut(`${unversionedApiUrl}`),
	endpoints: builder => ({
		searchPatients: builder.query<
			FhirPatient[],
			{
				name: string
				surname: string
				birthDate?: string
				storeId: string
			}
		>({
			query: query => ({
				url: `/v1/fhir-patients/search?${qs.stringify(query)}`,
			}),
		}),
		createPatient: builder.mutation<FhirPatient, Omit<FhirPatient, 'fhirId'>>({
			query: patient => ({
				url: '/v1/fhir-patients',
				method: 'POST',
				body: JSON.stringify(patient),
			}),
		}),
	}),
})

export const { useSearchPatientsQuery, useCreatePatientMutation } =
	fhirPatientsApi
