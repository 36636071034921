import { FormHelperText, IconButton, Popper, TextField } from '@mui/material'
import Autocomplete, {
	AutocompleteChangeReason,
} from '@mui/material/Autocomplete'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { capitalizeFirstLetter } from '../../../libs/utils'
import Checkbox from '../../../styleguide/Checkbox'
import CircularProgress from '../../../styleguide/CircularProgress'
import {
	FlexOption,
	OptionLabel,
} from '../../../styleguide/CommonPageComponents'
import FormControl from '../../../styleguide/FormControl'
import { InputHelperTextWithIcon } from '../../../styleguide/forms/InputHelperTextWithIcon'
import ChevronDownIcon from '../../../styleguide/icons/ChevronDownIcon'
import CloseIcon from '../../../styleguide/icons/CloseIcon'
import HeartFilledIcon from '../../../styleguide/icons/HeartFilledIcon'
import HeartIcon from '../../../styleguide/icons/HeartIcon'
import MenuItem from '../../../styleguide/MenuItem'
import Select from '../../../styleguide/Select'
import theme from '../../../styleguide/theme'
import InputLabel from './../../../styleguide/InputLabel'
import Button from './../../../styleguide/buttons/Button'
import { pxToRem } from '../../../libs/style'

const StyledInvisiblePopper = styled(Popper)`
	display: none;
`

const StyledTextField = styled(TextField)<{
	$isVisualAcuityField?: boolean
	$hasValue?: boolean
}>`
	.MuiInputBase-root .MuiOutlinedInput-notchedOutline {
		border-color: ${props =>
			props.$hasValue ? theme.palette.primary.main : theme.palette.grey[600]};
	}

	.MuiInputBase-root.Mui-error.Mui-focused::before {
		content: ' ';
		position: absolute;
		inset: -4px;
		border: 2px solid ${theme.palette.secondary.light};
		border-radius: ${pxToRem(14)}rem;
	}

	.MuiOutlinedInput-root:hover fieldset {
		border-color: ${theme.palette.primary.main};
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: ${theme.palette.primary.main};
	}

	.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
		border-color: ${theme.palette.error.light};
	}

	.warning .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
		border-color: ${theme.palette.orange.main} !important;
	}

	.MuiFormHelperText-root {
		margin-left: 0;
		margin-right: 0;
	}

	.MuiFormLabel-root.MuiInputLabel-root.Mui-error {
		margin: 2px -5px -2px;
		padding: 0 10px;
	}

	.MuiInputBase-input {
		z-index: 1;
	}

	.MuiInputBase-input.MuiOutlinedInput-input {
		&::placeholder {
			color: ${theme.palette.greyCustom.main};
			opacity: 1;
			&::first-letter {
				text-transform: uppercase;
			}
		}

		&.Mui-disabled {
			-webkit-text-fill-color: ${theme.palette.greyCustom.main};
		}
	}
	.MuiInputBase-root,
	.MuiOutlinedInput-notchedOutline {
		border-radius: 12px;
		font-size: 0.875rem;

		legend {
			font-size: 0.75rem;
		}
	}

	.MuiInputBase-root.Mui-disabled {
		background-color: ${theme.palette.grey[100]};
	}

	${props =>
		props.$isVisualAcuityField
			? `& .MuiOutlinedInput-notchedOutline {
				border: 1px solid ${theme.palette.grey[700]};

			}

			& .MuiButtonBase-root > svg {
				fill: ${theme.palette.grey[700]};
			}`
			: ''}
	background-color: ${theme.palette.common.white};
`

const FavoriteCheckbox = styled(Checkbox)`
	margin-right: 0;

	span {
		&::first-letter {
			text-transform: uppercase;
		}
	}
`

const Container = styled.div`
	width: 100%;
	min-width: 0;
`

const Header = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-bottom: ${theme.spacing(1)};
`

const StyledIconButton = styled(IconButton)`
	padding: 0;
	margin: 0;
	margin-left: auto;
`

const NotFoundWrapperDiv = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

export type OptionWithKey<T> = T & {
	key: string | number
}

export type GenericAutocompleteControlledProps<T> = {
	additionalFieldsToUpdate?: Record<string, string>
	additionalFieldToFilter?: keyof T & string
	className?: string
	codePropName: keyof T & string
	disabled?: boolean
	fieldName: string
	isLoading: boolean
	isVisualAcuityField?: boolean
	options: OptionWithKey<T>[]
	placeholder: string
	label?: string
	query: string
	required?: boolean
	showErrorManually?: boolean
	showErrorOnlyIfTouched?: boolean
	typeAtLeastCharactersText?: string
	queryLengthForSearch?: number
	useDefaultEndAdornment?: boolean
	getOptionLabel: (option: OptionWithKey<T>) => string
	onChangeText: (query: string) => void
	onSelect?: (code: T | null) => void
	saveOnChange?: () => void
	showFavourites?: boolean
	getIsFavourite?: (option: T) => boolean
	setOnlyFavourites?: (toogle: boolean) => void
	filterFavorite?: boolean
	hasAddFavourite?: boolean
	setFavourite?: any
	setFavouriteResult?: any
	disableClearable?: boolean
	outsideLabel?: string
	wipeQuery?: () => void
	getOptionDisabled?: (option: T) => boolean
	upriseOrgId?: string
	onAddFreeTextItem?: (text: string) => void
	isResetedValue?: boolean
	enableClearOnSelect?: boolean
	noAutoComplete?: boolean
}

const GenericAutocompleteControlled = <T,>({
	additionalFieldsToUpdate,
	additionalFieldToFilter,
	codePropName,
	disabled = false,
	fieldName,
	isLoading,
	isVisualAcuityField,
	options,
	placeholder,
	query,
	required = false,
	showErrorManually,
	showErrorOnlyIfTouched = false,
	typeAtLeastCharactersText,
	queryLengthForSearch = 3,
	useDefaultEndAdornment,
	getOptionLabel,
	onChangeText,
	onSelect,
	saveOnChange,
	showFavourites = false,
	getIsFavourite,
	setOnlyFavourites,
	filterFavorite,
	setFavourite,
	setFavouriteResult,
	disableClearable = false,
	wipeQuery,
	getOptionDisabled = () => false,
	upriseOrgId,
	label,
	onAddFreeTextItem,
	isResetedValue,
	enableClearOnSelect = false,
	noAutoComplete,
}: GenericAutocompleteControlledProps<T>) => {
	const { t } = useTranslation()
	const context = useFormikContext()
	const fieldValue = get(context.values, fieldName)
	const fieldError = showErrorManually
		? t('validation.fieldInvalid')
		: get(context.errors, fieldName)

	const fieldTouched = !!get(context.touched, fieldName)
	const showError = showErrorOnlyIfTouched
		? !!fieldError && fieldTouched
		: !!fieldError

	let codePropValue: string | undefined =
		typeof fieldValue === 'object' ? fieldValue?.[codePropName] : fieldValue

	if (!codePropValue && Array.isArray(fieldValue) && fieldValue.length > 0) {
		codePropValue = fieldValue[0][codePropName]
	}
	const additionalValueToFilter =
		additionalFieldToFilter !== undefined
			? (get(context.values, additionalFieldToFilter) as unknown as string)
			: undefined

	const value: OptionWithKey<T> | null = useMemo(() => {
		if (!codePropValue) {
			return null
		}

		const returnValue = options.find(o => {
			if (String(o[codePropName]) !== codePropValue) {
				return false
			}

			if (
				additionalFieldToFilter !== undefined &&
				String(o[additionalFieldToFilter]) !== additionalValueToFilter
			) {
				return false
			}

			return true
		})

		if (!returnValue) {
			return null
		}

		return returnValue
	}, [
		options,
		codePropValue,
		codePropName,
		additionalFieldToFilter,
		additionalValueToFilter,
	])

	const inputValue = noAutoComplete
		? query
		: !!value
		? getOptionLabel(value)
		: query

	const [focus, setFocus] = useState(!!inputValue)
	const [open, setOpen] = useState(false)

	const blur = () => {
		// setTimeout 0 is needed to blur after the interaction with the Select ended
		setTimeout(() => {
			setFocus(false)
		}, 0)
	}

	typeAtLeastCharactersText =
		typeAtLeastCharactersText || t('doctor.typeAtLeast3Chars')
	const noOptionsText =
		query.length < queryLengthForSearch && !filterFavorite ? (
			typeAtLeastCharactersText
		) : !options.length ? (
			<NotFoundWrapperDiv>
				{t('forms.noOptionFound')}{' '}
				{onAddFreeTextItem && (
					<Button
						variant="outlined"
						onClick={() => {
							onAddFreeTextItem(query)
							onChangeText('')
							setOpen(false)
						}}
					>
						Add as free text
					</Button>
				)}
			</NotFoundWrapperDiv>
		) : (
			t('app.loadingDDM')
		)

	const OptionRender = (option: OptionWithKey<T>) => (
		<FlexOption className="autocompleteOption">
			<OptionLabel>{getOptionLabel(option)}</OptionLabel>
			<>
				{showFavourites && getIsFavourite ? (
					<StyledIconButton
						className="action-button"
						type="button"
						disabled={disabled}
						onClick={_ => {
							_.preventDefault()
							_.stopPropagation()

							if (codePropName in option && 'Favourite' in option) {
								setLoadingFavorite(true)
								setFavourite({
									code: option?.[codePropName],
									favourite: !option.Favourite,
									upriseOrgId,
								})
							}
						}}
					>
						{getIsFavourite(option) ? (
							<HeartFilledIcon fill={theme.palette.grey[900]} />
						) : (
							<HeartIcon />
						)}
					</StyledIconButton>
				) : (
					<></>
				)}
			</>
		</FlexOption>
	)

	const onChange = (
		value: OptionWithKey<T> | null,
		reason?: AutocompleteChangeReason,
	) => {
		context.setFieldValue(fieldName, value?.[codePropName] ?? '')
		context.setFieldTouched(fieldName, true)

		if (reason === 'clear') {
			onSelect?.(null)
		}

		if (onSelect && value) {
			onSelect(value)
			if (enableClearOnSelect) {
				setTimeout(() => {
					onChange(null)
				}, 0)
			}
			return
		}

		if (additionalFieldsToUpdate) {
			Object.keys(additionalFieldsToUpdate).forEach(key => {
				const valueToSet = get(value, additionalFieldsToUpdate[key])
				context.setFieldValue(key, valueToSet)
				context.setFieldTouched(key, true)
			})
		}

		if (saveOnChange) {
			setTimeout(() => saveOnChange())
		}
	}

	const [loadingFavourite, setLoadingFavorite] = useState(false)

	useEffect(() => {
		if (
			setFavouriteResult?.status === 'pending' ||
			setFavouriteResult?.isLoading
		) {
			setLoadingFavorite(true)
		} else if (setFavouriteResult?.status === 'fulfilled' && !isLoading) {
			setLoadingFavorite(false)
			setFavouriteResult.reset()
		}
	}, [setFavouriteResult, isLoading])

	useEffect(() => {
		if (isResetedValue) {
			onChangeText('')
		}
		//eslint-disable-next-line
	}, [isResetedValue])

	return (
		<Container>
			{showFavourites && setOnlyFavourites && (
				<Header>
					<FavoriteCheckbox
						disabled={setFavouriteResult?.isLoading || isLoading || disabled}
						label={t('app.favouritesOnly')}
						checked={filterFavorite}
						onChange={() => setOnlyFavourites(!filterFavorite)}
					/>
				</Header>
			)}
			{!filterFavorite && (
				<Autocomplete
					open={open}
					onOpen={() => setOpen(true)}
					onClose={() => setOpen(false)}
					sx={{ position: 'relative' }}
					value={value}
					inputValue={inputValue}
					id="generic-autocomplete"
					data-testid="generic-autocomplete"
					getOptionLabel={(option: OptionWithKey<T>) => getOptionLabel(option)}
					getOptionDisabled={(option: OptionWithKey<T>) =>
						getOptionDisabled(option)
					}
					renderOption={(
						liProps: React.HTMLAttributes<HTMLLIElement>,
						option: OptionWithKey<T>,
					) => (
						<li {...liProps} key={option.key}>
							{OptionRender(option)}
						</li>
					)}
					PopperComponent={showErrorManually ? StyledInvisiblePopper : Popper}
					onInputChange={(_, newInputValue, reason: string) => {
						if (reason === 'input') {
							onChangeText(newInputValue)
						} else {
							onChangeText('')
						}
					}}
					onChange={(
						_,
						value: OptionWithKey<T> | null,
						reason: AutocompleteChangeReason,
					) => {
						onChange(value, reason)
						if (wipeQuery) wipeQuery()
					}}
					onBlur={() => {
						context.setFieldTouched(fieldName, true)
						setFocus(false)
					}}
					blurOnSelect
					disabled={disabled}
					options={options}
					loading={isLoading}
					noOptionsText={noOptionsText}
					disableClearable={disableClearable}
					popupIcon={
						<ChevronDownIcon sx={{ color: theme.palette.primary.main }} />
					}
					renderInput={params => {
						let customInputProps: any = {}

						// If the property endAdornment is present inside InputProps
						// it will not render the default adornment(the "X" button to delete selection)
						if (!useDefaultEndAdornment) {
							customInputProps.endAdornment = null
						}
						if (isLoading) {
							customInputProps.endAdornment = (
								<CircularProgress color="inherit" size={20} />
							)
						}
						if (label) {
							customInputProps.placeholder = placeholder
						}
						return (
							<>
								<StyledTextField
									{...params}
									label={label || placeholder}
									required={required}
									variant="outlined"
									InputProps={{
										...params.InputProps,
										...customInputProps,
									}}
									error={showError}
									$isVisualAcuityField={isVisualAcuityField}
									$hasValue={value !== null}
									onFocus={() => setFocus(true)}
									onBlur={() => setFocus(false)}
								/>
								{showError && (
									<InputLabel error={true}>
										<InputHelperTextWithIcon>
											{capitalizeFirstLetter(
												t(
													typeof fieldError === 'string'
														? fieldError
														: JSON.stringify(fieldError),
												),
											)}
										</InputHelperTextWithIcon>
									</InputLabel>
								)}
							</>
						)
					}}
				/>
			)}
			{filterFavorite && (
				<FormControl focused={focus} fullWidth>
					<InputLabel shrink={focus || !!value} error={showError}>
						{placeholder}
					</InputLabel>
					<Select
						disabled={disabled}
						label={placeholder}
						placeholder={placeholder}
						value={value?.key || ''}
						onFocus={() => setFocus(true)}
						onBlur={blur}
						onClose={blur}
						onChange={e => {
							const selectedCode = e.target.value
							const selectedIcd = options.find(
								o => o[codePropName] === selectedCode,
							)
							onChange(selectedIcd || null)
						}}
						className="automcompleteFavSelect"
						error={showError}
						endAdornment={
							!!value?.key ? (
								<IconButton
									className="selectClearBtn"
									onClick={() => onChange(null)}
								>
									<CloseIcon />
								</IconButton>
							) : (
								<></>
							)
						}
					>
						{options?.map(option => (
							<MenuItem
								key={option.key}
								value={option.key}
								className="ellipsis-span"
								disabled={getOptionDisabled(option)}
							>
								{OptionRender(option)}
							</MenuItem>
						))}
					</Select>
					{showError && (
						<FormHelperText error={true}>
							{t(fieldError || 'errors.fieldRequired')}
						</FormHelperText>
					)}
				</FormControl>
			)}
		</Container>
	)
}

export default GenericAutocompleteControlled
