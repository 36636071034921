import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { Store } from '../model/store'
import { unversionedApiUrl } from './common'

export const storesApi = createApi({
	reducerPath: 'storesApi',
	baseQuery: staggeredBaseQueryWithBailOut(`${unversionedApiUrl}`),
	endpoints: builder => ({
		getStore: builder.query<Store, { storeId: string }>({
			query: ({ storeId }) => ({
				url: `/v1/stores/${storeId}`,
			}),
		}),
	}),
})

export const { useGetStoreQuery } = storesApi
