import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from './common'
import { createApi } from '@reduxjs/toolkit/query/react'
import { DifFormValues } from '../components/DigitalIntakeForm/types'

const SERVICE_REDUCER_PATH = 'difApi' as const

const DIF_TAG = 'Dif' as const

type DifQueryArgs = {
	examId: string
}

type GetDifReturnType = DifFormValues & {}

type UpsertDifReturnType = {
	success: boolean
}

export const difApi = createApi({
	reducerPath: SERVICE_REDUCER_PATH,
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	tagTypes: [DIF_TAG],
	endpoints: builder => ({
		getDif: builder.query<GetDifReturnType, DifQueryArgs>({
			query: ({ examId }) => ({
				method: 'GET',
				url: `v3/exams/${examId}/dif`,
			}),
			providesTags: [DIF_TAG],
		}),
		upsertDif: builder.mutation<UpsertDifReturnType, DifQueryArgs>({
			query: ({ examId, ...body }) => ({
				method: 'PATCH',
				url: `v3/exams/${examId}/dif`,
				body,
			}),
			invalidatesTags: result => (result?.success ? [DIF_TAG] : []),
		}),
	}),
})

export const { useGetDifQuery, useUpsertDifMutation } = difApi
