import React from 'react'

import { SvgProps } from '../../model/types'

const HomeIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 19.998 17.5"
			height="18"
			width="18"
			fill="currentColor"
			{...props}
		>
			<path d="M19.88 7.701l-2.382-1.946V2.731a.234.234 0 00-.234-.234h-.782a.234.234 0 00-.234.234v2.005L10.787.278A1.465 1.465 0 009.998 0c-.245 0-.598.125-.789.278L.115 7.701A.354.354 0 000 7.943c0 .062.031.15.07.197l.394.485a.354.354 0 00.243.115c.06 0 .148-.03.195-.069l1.596-1.303v9.492c0 .345.28.625.625.625h5c.345 0 .625-.28.625-.625v-5l2.5.011v5.004c0 .345.28.625.625.625l5-.013c.345 0 .625-.28.625-.625V7.368l1.596 1.303a.363.363 0 00.197.07c.082 0 .19-.051.243-.115l.394-.485a.356.356 0 00.07-.196.354.354 0 00-.117-.244zm-3.632-1.353l-.004 9.892-3.746.012v-5.007a.628.628 0 00-.623-.625l-3.752-.01a.625.625 0 00-.625.624v5.006h-3.75V6.348l6.25-5.1z" />
		</svg>
	)
}

export default HomeIcon
