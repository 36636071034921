import React from 'react'

import { SvgProps } from '../../model/types'

const LockIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 20 20"
			width="20"
			height="20"
			fill="currentColor"
			{...props}
		>
			<path
				fillRule="evenodd"
				d="M9,13.8046973 C9.33147321,13.8046973 9.60267857,13.5410264 9.60267857,13.2187619 L9.60267857,11.3437688 C9.60267857,11.0215043 9.33147321,10.7578334 9,10.7578334 C8.66852679,10.7578334 8.39732143,11.0215043 8.39732143,11.3437688 L8.39732143,13.2187619 C8.39732143,13.5410264 8.66852679,13.8046973 9,13.8046973 Z M15.75,9.46877558 C15.75,8.69241122 15.1021205,8.06253069 14.3035714,8.06253069 L13.8214286,8.06253069 L13.8214286,6.23148267 C13.8214286,3.6416369 11.678918,1.50883788 9.01506696,1.5 C6.34821429,1.49127126 4.17857143,3.59769174 4.17857143,6.18753752 L4.17857143,8.06253069 L3.69642857,8.06253069 C2.89787946,8.06253069 2.25,8.69241122 2.25,9.46877558 L2.25,15.0937551 C2.25,15.8701195 2.89787946,16.5 3.69642857,16.5 L14.3035714,16.5 C15.1021205,16.5 15.75,15.8701195 15.75,15.0937551 L15.75,9.46877558 Z M5.14285714,8.06253069 L5.14285714,6.18753752 C5.14285714,4.11919711 6.87255641,2.43755116 9,2.43755116 C11.1274436,2.43755116 12.8571429,4.11919711 12.8571429,6.18753752 L12.8571429,8.06253069 L5.14285714,8.06253069 Z M14.7857143,15.0937551 C14.7857143,15.351561 14.5687441,15.5625034 14.3035714,15.5625034 L3.69642857,15.5625034 C3.43125589,15.5625034 3.21428571,15.351561 3.21428571,15.0937551 L3.21428571,9.46877558 C3.21428571,9.21096974 3.43125589,9.00002728 3.69642857,9.00002728 L14.3035714,9.00002728 C14.5687441,9.00002728 14.7857143,9.21096974 14.7857143,9.46877558 L14.7857143,15.0937551 Z"
			/>
		</svg>
	)
}

export default LockIcon
