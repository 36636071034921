import { useEffect } from 'react'
import {
	selectTroubleShootingDataTransfer,
	selectTroubleShootingLogin,
	selectTroubleShootingVideoConference,
} from '../features/troubleShooting/selectors'
import { useTeloDispatch, useTeloSelector } from '../store'
import { isEmpty } from 'lodash'
import troubleShootingActions from '../features/troubleShooting/actions'

export const useFetchTroubleShooting = () => {
	const dispatch = useTeloDispatch()
	const troubleShootingLogin = useTeloSelector(selectTroubleShootingLogin)
	const troubleShootingVideoConference = useTeloSelector(
		selectTroubleShootingVideoConference,
	)
	const troubleShootingDataTransfer = useTeloSelector(
		selectTroubleShootingDataTransfer,
	)

	useEffect(() => {
		if (
			isEmpty(troubleShootingLogin) ||
			isEmpty(troubleShootingVideoConference) ||
			isEmpty(troubleShootingDataTransfer)
		) {
			dispatch(troubleShootingActions.fetchTroubleShootingData())
		}
	}, [
		troubleShootingLogin,
		troubleShootingVideoConference,
		troubleShootingDataTransfer,
		dispatch,
	])
}
