import { memoize, throttle } from 'lodash'

import examsActions from '../features/exams/actions'
import renewalActions from '../features/renewal/actions'
import reportsActions from '../features/reports/actions'
import storesActions from '../features/stores/actions'
import usersActions from '../features/users/actions'
import { InstrumentType } from '../model/instrumentsApi'
import { Id } from '../model/model'
import { TeloNavigationFn } from '../routing/teloRouter'
import { TeloDispatch } from '../store'

const THROTTLE_TIME = 1000

// These actions are memoized and throttled in order to avoid them from being duplicated,
// for instance because they are called by polling o socket hooks invoked more than once on a page by different components

export const getFetchExam = memoize(
	(examId: Id, notBlockingError403 = false, dispatch: TeloDispatch) =>
		throttle(
			() => {
				dispatch(examsActions.fetchExam(examId, notBlockingError403))
			},
			THROTTLE_TIME,
			{ trailing: true },
		),
)

export const getFetchUser = (
	username: string,
	dispatch: TeloDispatch,
	navigate: TeloNavigationFn,
) =>
	throttle(
		() => {
			dispatch(usersActions.fetchUser(username, navigate))
		},
		THROTTLE_TIME,
		{ trailing: false },
	)

export const getFetchAssessments = memoize((dispatch: TeloDispatch) =>
	throttle(
		() => {
			dispatch(renewalActions.fetchAssessments())
		},
		THROTTLE_TIME,
		{ trailing: false },
	),
)

export const getFetchAssessment = memoize(
	(assessmentId: Id, dispatch: TeloDispatch) =>
		throttle(
			() => {
				dispatch(renewalActions.fetchAssessment(assessmentId))
			},
			THROTTLE_TIME,
			{ trailing: false },
		),
)

export const getFetchInstrumentData = memoize(
	(examId: string, instrumentType: InstrumentType, dispatch: TeloDispatch) =>
		throttle(
			() => {
				dispatch(examsActions.fetchInstrumentData(examId, instrumentType))
			},
			THROTTLE_TIME,
			{ trailing: false },
		),
	(examId: string, instrumentType: InstrumentType) =>
		`${examId}_${instrumentType}`,
)

export const getFetchStages = memoize(
	(storeId: string, dispatch: TeloDispatch) =>
		throttle(
			() => {
				dispatch(storesActions.loadInstrumentsRoomsAction(storeId))
			},
			THROTTLE_TIME,
			{ trailing: false },
		),
)

export const getFetchInstrumentsInRoom = memoize(
	(storeId: string, stageId: Id, roomId: Id, dispatch: TeloDispatch) =>
		throttle(
			() => {
				dispatch(
					storesActions.checklNewInstrumentsInRoom(storeId, stageId, roomId),
				)
			},
			THROTTLE_TIME,
			{ trailing: false },
		),
	(storeId: string, stageId: Id, roomId: Id) =>
		`${storeId}_${stageId}_${roomId}`,
)

export const getFetchExamWarnings = memoize(
	(examId: Id, dispatch: TeloDispatch) =>
		throttle(
			() => {
				dispatch(examsActions.fetchExamWarnings(examId))
			},
			THROTTLE_TIME,
			{ trailing: false },
		),
)

export const getFetchReports = memoize((dispatch: TeloDispatch) =>
	throttle(
		() => {
			dispatch(reportsActions.filterReports())
		},
		THROTTLE_TIME,
		{ trailing: false },
	),
)
