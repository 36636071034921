import { STATUS_PROGRESS, STATUS_EVALUATING } from '../libs/pr-constants'

export const remapStatusForApp = (data: any) => {
	const { status, renew_result, ...otherData } = data
	if (renew_result === STATUS_EVALUATING) {
		return { status: STATUS_PROGRESS, ...otherData }
	}

	return {
		status: renew_result,
		...otherData,
	}
}
