import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RoomSelectionPage } from '../../model/store'

const initialState: RoomSelectionPage = {
	pretest: true,
	refraction: true,
	contactLenses: true,
	ocularHealth: true,
}

export const slice = createSlice({
	name: 'roomSelectionPages',
	initialState,
	reducers: {
		_setRoomSelection: (
			state,
			{ payload }: PayloadAction<RoomSelectionPage>,
		) => ({
			...state,
			...payload,
		}),
	},
})

export default slice.reducer
