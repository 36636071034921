/*
	All assets must be properly prefixed with SERVING_URL.
	If not, asset load will break running MFEs.
	See README-MFE.md for a detailed explaination.

	This is the file where all assets must be collected and properly prefixed.
	Don't load assets directly. An ESLint rule is provided to avoid this runtime error.
*/
/* eslint-disable telo/no-direct-asset-load */

import { prefixWithURL } from './assetUtils'

export const IMAGES = {
	CONNECT_LOGO_URL: prefixWithURL('/assets/Logo_Connect_Black.svg'),
	VISIONX_LOGO_URL: prefixWithURL('/assets/visionx_logo.svg'),
	RENEWAL_CONNECT_LOGO_URL: prefixWithURL(
		'/assets/Logo_Connect_Consultations_Black.svg',
	),
	LUXOTTICA_LOGO_URL: prefixWithURL('/assets/luxottica_Logo_Connect_Black.svg'),
	IPAD_ELEMENTS_CONTENT_BACKGROUND: prefixWithURL(
		'/assets/images/i-pad-elements-content-background-1.webp',
	),
	EMPTY_STATE: prefixWithURL('/assets/images/image-empty-state.svg'),
	DOCTOR_NOTIFICATION: prefixWithURL(
		'/assets/images/ott/doctorNotification.png',
	),
	STEPPER_COMPLETED: prefixWithURL('/assets/images/ott/stepper_completed.svg'),
	STEPPER_ACTIVE: prefixWithURL('/assets/images/ott/stepper_active.svg'),
	AZURE_ICON: prefixWithURL('/assets/images/azure-icon_20.png'),
	CONTACT_LENSES: prefixWithURL('/assets/images/contact-lens.webp'),
	DIF: prefixWithURL('/assets/images/ott/dif.png'),
}

export const FONTS = {
	IVAR_DISPLAY_ITALIC_WOFF2: prefixWithURL(
		'/assets/fonts/IvarDisplay-Italic.woff2',
	),
	IVAR_DISPLAY_ITALIC_TTF: prefixWithURL(
		'/assets/fonts/IvarDisplay-Italic.ttf',
	),
	IVAR_DISPLAY_ITALIC_SVG: prefixWithURL(
		'/assets/fonts/IvarDisplay-Italic.svg#IvarDisplay-Italic',
	),
	OPTIKER_K_WOFF2: prefixWithURL('/assets/fonts/Optiker-K.woff2'),
	NHAAS_GROTESK_TXSTD_RG: prefixWithURL(
		'/assets/fonts/NHaasGroteskTXStd-55Rg.otf',
	),
	NHAAS_GROTESK_TXSTD_IT: prefixWithURL(
		'/assets/fonts/NHaasGroteskTXStd-56It.otf',
	),
	NHAAS_GROTESK_TXSTD_MD: prefixWithURL(
		'/assets/fonts/NHaasGroteskTXStd-65Md.otf',
	),
	NHAAS_GROTESK_TXSTD_MDIT: prefixWithURL(
		'/assets/fonts/NHaasGroteskTXStd-66MdIt.otf',
	),
	NHAAS_GROTESK_TXSTD_BD: prefixWithURL(
		'/assets/fonts/NHaasGroteskTXStd-75Bd.otf',
	),
	NHAAS_GROTESK_TXSTD_BDIT: prefixWithURL(
		'/assets/fonts/NHaasGroteskTXStd-76BdIt.otf',
	),
	POPPINS_BOLD_TTF: prefixWithURL('/assets/fonts/Poppins-Bold.ttf'),
	POPPINS_BOLDITALIC_TTF: prefixWithURL('/assets/fonts/Poppins-BoldItalic.ttf'),
	POPPINS_ITALIC_TTF: prefixWithURL('/assets/fonts/Poppins-Italic.ttf'),
	POPPINS_MEDIUM_TTF: prefixWithURL('/assets/fonts/Poppins-Medium.ttf'),
	POPPINS_MEDIUM_ITALIC_TTF: prefixWithURL(
		'/assets/fonts/Poppins-MediumItalic.ttf',
	),
	POPPINS_REGULAR_TTF: prefixWithURL('/assets/fonts/Poppins-Regular.ttf'),
}

export const SOUNDS = {
	DING_URL: prefixWithURL('/assets/ding-sound.wav'),
}

export const FILES = {
	OTT_PRIVACY_FACSIMILE_PDF: prefixWithURL(
		'/assets/images/ott/OTT_Privacy_FACSIMILE.pdf',
	),
	OTT_PRESCRIPTION_FACSIMILE_PDF: prefixWithURL(
		'/assets/images/ott/OTT_Prescription_FACSIMILE.pdf',
	),
	OTT_EYE_HEALTH_FACSIMILE_PDF: prefixWithURL(
		'/assets/images/ott/OTT_EyeHealth_FACSIMILE.pdf',
	),
	OTT_CHART_FACSIMILE_PDF: prefixWithURL(
		'/assets/images/ott/OTT_Chart_FACSIMILE.pdf',
	),
}
