import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DiagnosiPlan } from '../../model/diagnosiPlans'

import { MedicalReportTemplatesState, Id } from '../../model/model'
import { MedicalReportTemplate } from '../../model/medicalReportTemplates'

const initialState: MedicalReportTemplatesState = {
	catalog: [],
}

export const slice = createSlice({
	name: 'medicalReportTemplates',
	initialState,
	reducers: {
		_loadMRTs: (state, { payload }: PayloadAction<MedicalReportTemplate[]>) => {
			state.catalog = payload
		},
		_deleteTemplate: (state, { payload }: PayloadAction<Id>) => {
			const index = state.catalog.findIndex(({ _id }) => _id === payload)
			if (index > -1) {
				state.catalog.splice(index, 1)
			}
		},
		_loadTemplate: (
			state,
			{ payload }: PayloadAction<MedicalReportTemplate>,
		) => {
			const index = state.catalog.findIndex(({ _id }) => _id === payload._id)
			if (index > -1) {
				state.catalog[index] = payload
			} else {
				state.catalog.push(payload)
			}
		},
	},
})

export default slice.reducer
