import styled from 'styled-components'
import { pxToRem } from '../libs/style'
import theme from '../styleguide/theme'

const Disclaimer = styled.div`
	font-size: ${pxToRem(14)}rem;
	text-align: left;
	margin: ${theme.spacing(2.5)};
`

export default Disclaimer
