import { css, styled as muiStyled } from '@mui/material/styles'
import MDialog, { DialogProps as MDialogProps } from '@mui/material/Dialog'
import { pxToRem } from '../../libs/style'
export type MuiDialogProps = MDialogProps & {
	isBorderTopVisible?: boolean
}

export const dialogTitleStyles = css`
	background-color: #20233d;
	color: #fff;
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 500;
	line-height: 120% /* 1.35rem */;
	letter-spacing: -0.01125rem;
`

export const MuiDialog = muiStyled(MDialog, {
	shouldForwardProp: prop => prop !== 'isBorderTopVisible',
})<MuiDialogProps>(({ theme: { palette }, isBorderTopVisible = true }) => ({
	'& .MuiPaper-root': {
		backgroundColor: 'transparent',
		borderRadius: `${pxToRem(12)}rem`,
	},

	'& .MuiDialogTitle-root': {
		...dialogTitleStyles,
		borderRadius: '1rem 1rem 0 0',
	},

	'& .MuiDialogContent-root': {
		backgroundColor: palette.common.white,
		padding: '1.5rem',
	},

	'& .MuiDialogActions-root': {
		borderTop: isBorderTopVisible ? `1px solid ${palette.grey[300]}` : 'none',
		backgroundColor: palette.common.white,
		padding: '1.5rem',
	},
}))
