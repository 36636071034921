import { DialogContent } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { selectUsername } from '../features/auth/selectors'
import dialogActions from '../features/dialog/actions'
import { selectDialog } from '../features/dialog/selectors'
import examsActions from '../features/exams/actions'
import { selectPracticeByExamId } from '../features/practices/selectors'
import prescriptionsActions from '../features/prescriptions/actions'
import { convertSingleUnit } from '../libs/time'
import { DoctorEndsWithoutRxDialogType } from '../model/dialog'
import { useTeloDispatch, useTeloSelector } from '../store'
import Button from '../styleguide/buttons/Button'
import IconButton from '../styleguide/buttons/IconButton'
import Dialog from '../styleguide/dialog/Dialog'
import DialogActions from '../styleguide/dialog/DialogActions'
import DialogTitle from '../styleguide/dialog/DialogTitle'
import Checkbox from '../styleguide/forms/Checkbox'
import Input from '../styleguide/forms/Input'
import CloseIcon from '../styleguide/icons/CloseIcon'
import theme from '../styleguide/theme'
import Yup from '../yup'

const Modal = styled(Dialog)`
	z-index: 1471 !important;
`

const ParagraphWithCarriageReturns = styled.div`
	margin-bottom: 1rem;
	white-space: pre-wrap;
	font-size: ${theme.spacing(2)};
	line-height: ${theme.spacing(2.5)};
	color: ${theme.palette.greyAlpha['900']};
`

const APSubTitle = styled.div`
	margin-bottom: ${theme.spacing(2)};
	font-size: ${theme.spacing(2)};
	color: ${theme.palette.greyAlpha['900']};
`

const APTextArea = styled(Field)`
	background-color: ${theme.palette.background.default};
`

const StyledForm = styled(Form)`
	width: 100%;
`

const StyledCheckboxContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 20px;
	.MuiFormControlLabel-label {
		position: relative;
		top: 2px;
		text-transform: uppercase;
	}
`

const reasonSchema = Yup.object().shape({
	notes: Yup.string().required(),
})

type Reason = {
	notes: string
	generateChart: boolean
}

const isEndWithoutRxDialog = (
	dialog: any,
): dialog is DoctorEndsWithoutRxDialogType =>
	dialog.type === 'doctorEndsWithoutRx' && !!dialog.examId

const DoctorEndsWithoutRxModal = () => {
	const { t } = useTranslation()
	const dialog = useTeloSelector(selectDialog)
	const dispatch = useTeloDispatch()
	const initialValues: Reason = {
		notes: '',
		generateChart: false,
	}
	const username = useTeloSelector(selectUsername)
	const examId = (dialog as DoctorEndsWithoutRxDialogType).examId
	const open = isEndWithoutRxDialog(dialog)

	const practice = useTeloSelector(selectPracticeByExamId(examId))
	const { setTo: chartCreationTime, unit: chartCreationUnit } =
		practice?.chartCreation || {}

	const handleSubmit = async (values: Reason) => {
		dispatch(prescriptionsActions.clearPrescriptionDisplay())
		await dispatch(
			examsActions.saveExamEndedWithoutRxNotes({
				examId,
				noRxNotes: values.notes,
			}),
		)

		await dispatch(
			examsActions.setExamStatus({
				id: examId,
				status: 'DoctorEnded',
				username: username,
			}),
		)
		if (values.generateChart) {
			await dispatch(examsActions.generateChart(examId))
		}
	}

	return isEndWithoutRxDialog(dialog) ? (
		<Dialog open={open}>
			<Formik
				validationSchema={reasonSchema}
				initialValues={initialValues}
				validateOnChange={true}
				validateOnBlur={true}
				validateOnMount={true}
				onSubmit={values => {
					handleSubmit(values)
					dispatch(dialogActions.closeDialog())
				}}
			>
				{({ values, submitForm }) => (
					<>
						<DialogTitle onClose={() => dispatch(dialogActions.closeDialog())}>
							{t('exam.endWithoutRx.cta')}
						</DialogTitle>
						<DialogContent>
							<ParagraphWithCarriageReturns>
								{practice?.autoChartCreation
									? t('exam.endWithoutRx.disclaimerWithAutoChart', {
											time: chartCreationTime,
											unit: convertSingleUnit(
												chartCreationUnit,
												chartCreationTime,
											),
									  })
									: t('exam.endWithoutRx.disclaimerWithoutAutoChart')}
							</ParagraphWithCarriageReturns>

							<StyledForm>
								<div>
									<APSubTitle>{t('exam.pleaseSpecifyReason')}*</APSubTitle>
									<APTextArea
										component={Input}
										name={`notes`}
										fullWidth
										maxLength={1000}
										multiline
										rows={4}
										placeholder={t('exam.endWithoutRx.placeholderReason')}
									/>
								</div>
								<StyledCheckboxContainer>
									<Field
										component={Checkbox}
										label={t('doctor.EndExamDialogCheckbox')}
										name="generateChart"
									/>
								</StyledCheckboxContainer>
							</StyledForm>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={() => dispatch(dialogActions.closeDialog())}
								variant="outlined"
							>
								{t('app.cancel')}
							</Button>
							<Button
								disabled={!values.notes}
								onClick={() => {
									submitForm()
								}}
							>
								{t('app.proceed')}
							</Button>
						</DialogActions>
					</>
				)}
			</Formik>
		</Dialog>
	) : null
}

export default DoctorEndsWithoutRxModal
