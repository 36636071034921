import React from 'react'
import styled from 'styled-components'

import {
	FormControlLabel,
	FormControlLabelProps,
	Switch as SwitchBase,
} from '@mui/material'

import theme from './theme'

export const FormControlLabelStyled = styled(FormControlLabel)`
	margin: 0;

	&.MuiFormControlLabel-labelPlacementStart {
		.MuiSwitch-root {
			margin-left: ${theme.spacing(2)};
		}

		.MuiFormControlLabel-label {
			margin-left: 0;
		}
	}

	&.MuiFormControlLabel-labelPlacementEnd {
		.MuiSwitch-root {
			margin-right: 0.5rem;
		}
	}

	.MuiFormControlLabel-label {
		font-size: 0.875rem;
	}

	.MuiFormControlLabel-label::first-letter {
		text-transform: uppercase;
	}
`

export const SwitchStyled = styled(SwitchBase)`
	width: ${theme.spacing(3.5)};
	height: ${theme.spacing(2)};
	padding: 0;
	display: 'flex';
	border-radius: ${theme.spacing(1)};

	.MuiSwitch-switchBase {
		padding: 2px;
		color: ${theme.palette.greyCustom.main};

		&.Mui-checked {
			transform: translateX(12px);
			color: ${theme.palette.common.white};

			+ .MuiSwitch-track {
				opacity: 1;
				background-color: ${theme.palette.primary.main};
				border-color: ${theme.palette.primary.main};
			}
		}

		&.Mui-disabled {
			color: ${theme.palette.common.white};

			+ .MuiSwitch-track {
				opacity: 0.5;
			}
		}
	}

	.MuiSwitch-thumb {
		width: 12px;
		height: 12px;
		box-shadow: none;
		background-color: white;
	}

	.MuiSwitch-track {
		border: 1px solid ${theme.palette.greyCustom.light};
		border-radius: 16 / 2;
		opacity: 1;
		background-color: ${theme.palette.greyCustom.light};
		height: auto;
	}
`

interface Props extends Omit<FormControlLabelProps, 'control'> {}

const Switch: React.FC<Props> = ({
	onChange,
	onBlur,
	name,
	checked,
	value,
	...props
}) => {
	return (
		<FormControlLabelStyled
			control={
				<SwitchStyled
					data-testid="switch-input"
					onChange={onChange}
					onBlur={(e: any) => onBlur && onBlur(e)}
					name={name}
					checked={checked}
					value={value}
				/>
			}
			{...props}
		/>
	)
}

export default Switch
