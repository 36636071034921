import { createApi } from '@reduxjs/toolkit/query/react'
import { useDispatch } from 'react-redux'

import config from '../config'
import { BasePath } from '../formFields/model'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { Id } from '../model/model'
import { TeloDispatch } from '../store'

const ACQUIRE_MEDIA_INDEX = 'ACQUIRE_MEDIA_INDEX'

export const fieldsDataMetaApi = createApi({
	reducerPath: 'fieldsDataMetaApi',
	tagTypes: [ACQUIRE_MEDIA_INDEX],
	baseQuery: staggeredBaseQueryWithBailOut(config.apiUrl),
	endpoints: builder => ({
		getBasePathInfo: builder.query<
			{
				hasValues: boolean
				hasNonEmptyValues: boolean
				hasNight: boolean
				hasOtherAccuracy: boolean
				validIndexes: number[]
			},
			{ examId: Id; basePath: BasePath }
		>({
			query: ({ examId, basePath }) => ({
				url: `/exams/${examId}/fields-data/base-path/${encodeURIComponent(
					basePath,
				)}/info`,
			}),
			keepUnusedDataFor: 0,
		}),

		getArrayNextValidIndex: builder.query<
			{ nextValidIndex: number },
			{ examId: Id; basePath: BasePath }
		>({
			query: ({ examId, basePath }) => ({
				url: `/exams/${examId}/fields-data/base-path/${encodeURIComponent(
					basePath,
				)}/next-valid-index`,
			}),
		}),

		deleteArrayIndex: builder.mutation<
			boolean,
			{ examId: Id; basePath: BasePath; index: number }
		>({
			query: ({ examId, basePath, index }) => ({
				url: `/exams/${examId}/fields-data/base-path/${encodeURIComponent(
					basePath,
				)}/index/${index}`,
				method: 'DELETE',
			}),
			async onQueryStarted({ examId, basePath }, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled
					dispatch(
						fieldsDataMetaApi.util.updateQueryData(
							'getBasePathInfo',
							{ examId, basePath },
							draft => {
								Object.assign(draft, data)
							},
						),
					)
				} catch {}
			},
		}),

		getAcquireMediaIndex: builder.query<number | null, { examId: Id }>({
			query: ({ examId }) => ({
				url: `/exams/${examId}/fields-data/acquire-media-index`,
			}),
			providesTags: [ACQUIRE_MEDIA_INDEX],
		}),

		setAcquireMediaIndex: builder.mutation<
			number,
			{ examId: Id; index: number }
		>({
			query: ({ examId, index }) => ({
				url: `/exams/${examId}/fields-data/acquire-media-index/${index}`,
				method: 'PUT',
				body: JSON.stringify({}),
			}),
			invalidatesTags: [ACQUIRE_MEDIA_INDEX],
		}),

		deleteAcquireMediaIndex: builder.mutation<boolean, { examId: Id }>({
			query: ({ examId }) => ({
				url: `/exams/${examId}/fields-data/acquire-media-index`,
				method: 'DELETE',
			}),
			invalidatesTags: [ACQUIRE_MEDIA_INDEX],
		}),
	}),
})

export const { useGetBasePathInfoQuery } = fieldsDataMetaApi

export const useDeleteArrayIndexMutation = (examId: Id, basePath: BasePath) => {
	const [_mutation, result] = fieldsDataMetaApi.useDeleteArrayIndexMutation()

	const dispatch: TeloDispatch = useDispatch()

	const mutation = (index: number) => {
		return _mutation({
			examId,
			basePath,
			index,
		}).then(result => {
			if ('data' in result && result.data === true) {
				dispatch(
					fieldsDataMetaApi.util.updateQueryData(
						'getBasePathInfo',
						{ examId, basePath },
						draft => {
							Object.assign(draft, {
								validIndexes: draft.validIndexes.filter(i => i !== index),
							})
						},
					),
				)
			}
		})
	}
	return [mutation, result] as const
}

export const {
	useGetArrayNextValidIndexQuery,
	useGetAcquireMediaIndexQuery,
	useSetAcquireMediaIndexMutation,
	useDeleteAcquireMediaIndexMutation,
} = fieldsDataMetaApi
