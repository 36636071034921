import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import Box from '@mui/material/Box'
import { TypographyProps } from '@mui/material/Typography'

import Text from '../typography/Text'
import theme from '../theme'

export type TeloAccordionSubtitleProps = TypographyProps & {
	$subtitle: ReactNode | ReactNode[]
}

export const TeloAccordionSubtitle = styled<FC<TeloAccordionSubtitleProps>>(
	props => <Box display="flex" {...props} />,
).attrs<TeloAccordionSubtitleProps>(
	({ $subtitle }: TeloAccordionSubtitleProps) => {
		const subtitleList = Array.isArray($subtitle)
			? $subtitle.filter(Boolean)
			: [$subtitle]

		const subtitles = subtitleList.map((t, i) => (
			<Text
				key={`accordion-subtitle-${i}}]`}
				ml={1}
				display="flex"
				color="greyCustom.main"
				textTransform="capitalize"
			>
				{t}
			</Text>
		))

		return {
			children: subtitles,
		}
	},
)<TeloAccordionSubtitleProps>`
	& :is(p):not(:last-child) {
		padding-right: ${theme.spacing(1)};
		border-right: 1px solid ${theme.palette.grey[500]};
	}
`
