import { TeloDestination, useTeloRouter } from './teloRouter'

type TeloNavigateToProps = {
	to: TeloDestination
}

export const TeloNavigateTo = ({ to }: TeloNavigateToProps) => {
	const { navigate: teloNavigate } = useTeloRouter()
	teloNavigate(to)
	return null
}
