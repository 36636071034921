import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import appConfig from '../../config'
import { getIntersessionAppLoginData } from '../../libs/intersession'
import { AuthState, LoginStatus } from '../../model/model'
import { Role } from '../../model/users'

const blankState: Omit<AuthState, 'loginStatus'> = {
	token: appConfig.login.bypassLogin ? 'fakeToken' : '',
	eclipsUserVerified: false,
	expirationDate: appConfig.login.bypassLogin
		? new Date(1745483456000).toString()
		: '',
	role: appConfig.login.bypassLogin
		? (appConfig.login.fakeUserRole as Role)
		: 'Guest',
	authenticationError: false,
	missingUserError: '',
}

const intersessionData = getIntersessionAppLoginData() || blankState
// 	window.location.href.indexOf('/eclips/') === -1
// 		? getIntersessionAppLoginData() || blankState
// 		: blankState

const initialState: AuthState = {
	...intersessionData,
	loginStatus: intersessionData.token ? 'logged in' : 'not logged in',
}

export const slice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAppLoginData: (
			_,
			{ payload }: PayloadAction<Omit<AuthState, 'loginStatus'>>,
		) => ({
			...payload,
			loginStatus: payload.token ? 'logged in' : 'not logged in',
		}),
		setAppLoginStatus: (state, { payload }: PayloadAction<LoginStatus>) => {
			state.loginStatus = payload
		},
		delAppLoginData: _ => ({ ...blankState, loginStatus: 'not logged in' }),
		setRoles: (state, { payload }: PayloadAction<Role>) => {
			state.role = payload
		},
		setAuthenticationError: (state, { payload }: PayloadAction<boolean>) => {
			state.authenticationError = payload
		},
		setMissingUserError: (state, { payload }: PayloadAction<string>) => {
			state.missingUserError = payload
		},
	},
})

export default slice.reducer
