import React from 'react'

import { SvgProps } from '../../model/types'

const TargetIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			width="20px"
			height="20px"
			viewBox="0 0 20 20"
			version="1.1"
			{...props}
		>
			<g
				id="RD---Desktop-"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="RD-Dashboard-&gt;-Today's-patients-Tab-2.2"
					transform="translate(-824.000000, -21.000000)"
				>
					<g
						id="gps_fixed_black_24dp"
						transform="translate(823.916667, 20.916667)"
					>
						<polygon
							id="Path"
							points="0 0 20.1666667 0 20.1666667 20.1666667 0 20.1666667"
						></polygon>
						<path
							d="M10.0833333,6.72222222 C8.22631944,6.72222222 6.72222222,8.22631944 6.72222222,10.0833333 C6.72222222,11.9403472 8.22631944,13.4444444 10.0833333,13.4444444 C11.9403472,13.4444444 13.4444444,11.9403472 13.4444444,10.0833333 C13.4444444,8.22631944 11.9403472,6.72222222 10.0833333,6.72222222 Z M17.5954167,9.24305556 C17.2088889,5.73909722 14.4275694,2.95777778 10.9236111,2.57125 L10.9236111,0.840277778 L9.24305556,0.840277778 L9.24305556,2.57125 C5.73909722,2.95777778 2.95777778,5.73909722 2.57125,9.24305556 L0.840277778,9.24305556 L0.840277778,10.9236111 L2.57125,10.9236111 C2.95777778,14.4275694 5.73909722,17.2088889 9.24305556,17.5954167 L9.24305556,19.3263889 L10.9236111,19.3263889 L10.9236111,17.5954167 C14.4275694,17.2088889 17.2088889,14.4275694 17.5954167,10.9236111 L19.3263889,10.9236111 L19.3263889,9.24305556 L17.5954167,9.24305556 Z M10.0833333,15.9652778 C6.83145833,15.9652778 4.20138889,13.3352083 4.20138889,10.0833333 C4.20138889,6.83145833 6.83145833,4.20138889 10.0833333,4.20138889 C13.3352083,4.20138889 15.9652778,6.83145833 15.9652778,10.0833333 C15.9652778,13.3352083 13.3352083,15.9652778 10.0833333,15.9652778 Z"
							id="Shape"
							fill={props.fill ? props.fill : '#1F2327'}
							fillRule="nonzero"
						></path>
					</g>
				</g>
			</g>
		</svg>
	)
}

export default TargetIcon
