import React from 'react'
import i18n from 'i18next'
import Switch from '../../styleguide/Switch'

const DebugModeSwitch: React.FC = () => {
	const [initialLanguage] = (i18n.languages || ['enq']).slice(-1)
	return (
		<Switch
			labelPlacement="start"
			label={'Debug'}
			onChange={(_, checked) =>
				i18n.changeLanguage(checked === true ? 'cimode' : initialLanguage)
			}
		/>
	)
}

export { DebugModeSwitch }
