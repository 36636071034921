import styled from 'styled-components'
import { pxToRem } from '../../libs/style'
import Button from '../../styleguide/buttons/Button'
import Input from '../../styleguide/Input'

export const StyledTopRow = styled.div`
	padding: 0 ${pxToRem(16)}rem;
`

export const StyledTitleAndClose = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: ${pxToRem(25)}rem;
`

export const StyledTitle = styled.h1`
	font-size: ${pxToRem(18)}rem;
	font-weight: normal;
	line-height: 1;
`

export const StyledCloseIcon = styled(Button)`
	min-width: initial;
	padding: ${pxToRem(5)}rem 0;
`

export const StyledAccordions = styled.div`
	max-height: calc(70vh - ${pxToRem(180)}rem);
	overflow-y: auto;
`

export const StyledSearch = styled(Input)`
	width: 100%;
	margin-bottom: ${pxToRem(15)}rem;
	.MuiOutlinedInput-root {
		border-radius: ${pxToRem(28)}rem;
		color: #4072ee;
		.MuiOutlinedInput-input {
			height: ${pxToRem(12)}rem;
			font-size: ${pxToRem(14)}rem;
			color: #1a1a1a;
		}
		.MuiOutlinedInput-notchedOutline {
			border-color: rgba(0, 0, 0, 0.25);
		}
	}
`

export const StyledIssuesTitle = styled.h3`
	width: 100%;
	margin-bottom: ${pxToRem(15)}rem;
	padding: ${pxToRem(5)}rem ${pxToRem(15)}rem;
	background-color: #e7ebed;
	font-size: ${pxToRem(14)}rem;
`

export const StyledSingleAccordion = styled.div`
	display: flex;
	flex-direction: column;
`

export const StyledIssue = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: ${pxToRem(15)}rem;
	line-height: 1.35;
	&:last-of-type {
		margin-bottom: 0;
	}
	h4,
	p,
	li {
		font-size: ${pxToRem(14)}rem;
		word-break: break-word;
	}
	h4 {
		margin-bottom: ${pxToRem(10)}rem;
	}
	ol {
		list-style-position: inside;
	}
	p,
	li {
		color: #82888a;
	}
`

export const StyledActions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`

export const StyledTo = styled.div`
	display: flex;
	flex-direction: row;
	p {
		margin-right: ${pxToRem(5)}rem;
	}
`
