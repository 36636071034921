import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectAppLang } from '../features/app/selectors'
import { ExamWithStore, selectDateFormat } from '../features/stores/selectors'
import { formatDateInTimeZone, formatDate, parseToDate } from '../libs/time'
import { Optional } from '../utils/utilityTypes'

export type ADate = Date | string | number
export type DateFormatterFn = (date: ADate, format?: string) => string
type DateFormatterWithTimezoneFn = (date: ADate, timezone: string) => string
export type DateParserFn = (date: Optional<ADate>) => Optional<Date>

export type DateFormatter = {
	parseDate: DateParserFn
	formatDate: DateFormatterFn
	formatDatetime: DateFormatterFn
	formatUTCDate: DateFormatterFn
	formatDateWithTimezone: DateFormatterWithTimezoneFn
	dateFormat: string
}

export const useDateFormatter = (exam?: ExamWithStore): DateFormatter => {
	const dateFormat = useSelector(selectDateFormat(exam))
	const lang = useSelector(selectAppLang)
	const formatDateFn = useCallback(
		(date: ADate, format?: string) =>
			date ? formatDate(date, format || dateFormat, lang) : '',
		[dateFormat, lang],
	)
	const formatDateWithTimezoneFn = useCallback(
		(date: ADate, timezone: string) =>
			formatDateInTimeZone(date, dateFormat, timezone),
		[dateFormat],
	)
	const formatUTCDateFn = useCallback(
		(date: ADate) => formatDateInTimeZone(date, dateFormat, 'UTC'),
		[dateFormat],
	)

	const parseDateFn = useCallback(
		(date: Optional<ADate>): Optional<Date> => parseToDate(date),
		[],
	)

	const formatDateTimeFn = useCallback(
		(date: ADate) => formatDate(date, dateFormat + ' hh:mm a', lang),
		[dateFormat, lang],
	)

	const formatter = useMemo(
		() => ({
			dateFormat,
			formatDate: formatDateFn,
			formatDatetime: formatDateTimeFn,
			formatDateWithTimezone: formatDateWithTimezoneFn,
			formatUTCDate: formatUTCDateFn,
			parseDate: parseDateFn,
		}),
		[
			dateFormat,
			formatDateFn,
			formatDateWithTimezoneFn,
			formatUTCDateFn,
			parseDateFn,
			formatDateTimeFn,
		],
	)

	return formatter
}
