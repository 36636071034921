import React from 'react'

import { SvgProps } from '../../model/types'

const SearchIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 18 18"
			height="18"
			width="18"
			fill="currentColor"
			{...props}
		>
			<path
				clipRule="evenodd"
				d="M17.082 17.876l.794-.794a.422.422 0 000-.598l-4.266-4.268a.417.417 0 00-.3-.123h-.463A7.311 7.311 0 007.312 0 7.311 7.311 0 000 7.313a7.311 7.311 0 0012.093 5.534v.463c0 .112.046.218.123.299l4.268 4.267a.422.422 0 00.598 0zm-9.77-4.939a5.623 5.623 0 01-5.625-5.625 5.623 5.623 0 015.625-5.625 5.623 5.623 0 015.625 5.625 5.623 5.623 0 01-5.625 5.625z"
			/>
		</svg>
	)
}

export default SearchIcon
