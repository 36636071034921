import { useState, useEffect } from "react";
import { getEclipsMetadata } from "../libs/intersession";
import { ECLIPS_KEY_METADATA } from "../model/eclips";

const useEclipsMetadata = () => {
	const [eclipseMetadata, setEclipseMetadata] = useState(getEclipsMetadata())

	useEffect(() => {
		const localStorageEventHandler = (e: StorageEvent) => {
			if (e.key !== ECLIPS_KEY_METADATA) return;

			setEclipseMetadata(getEclipsMetadata());
		}

	  	window.addEventListener('storage', localStorageEventHandler)
	
	  	return () => {
			window.removeEventListener('storage', localStorageEventHandler)
	  	}
	}, [])
	
	return eclipseMetadata;
}

export default useEclipsMetadata;