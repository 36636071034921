import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
	ClinicalDataSection,
	CockpitState,
	SelectedMedia,
	SelectedMediaMap,
	clinicalDataSections,
	SharePrescriptionToCockpitKeyOtherData,
	DiseasesAndTreatmentsSection,
	VideoStatusType,
	SelectedGraphData,
	SharePrescriptionToCockpitKey,
} from '../../model/cockpit'
import { InstrumentRemoteMedia } from '../../model/instruments'

const initialState: CockpitState = {
	screenshareFrame: '',
	selectedMedia: {} as SelectedMediaMap,
	sharingMedia: {
		OD: null,
		OS: null,
		both: null,
	},
	sharingOn: '',
	fullscreen: undefined,
	sharingGraph: undefined,
	totGraph: undefined,
}

clinicalDataSections.forEach(section => {
	initialState.selectedMedia[section] = {
		OD: null,
		OS: null,
		both: null,
	}
})

export const slice = createSlice({
	name: 'cockpit',
	initialState,
	reducers: {
		setScreenshareFrame: (
			state,
			{ payload }: PayloadAction<{ head: string; body: string }>,
		) => {
			state.screenshareFrame = `<html>${payload.head}${payload.body}</html>`
		},
		_setSharingMedia: (state, { payload }: PayloadAction<SelectedMedia>) => {
			state.sharingMedia = payload
		},
		setSelectedMedia: (
			state,
			{
				payload,
			}: PayloadAction<{
				selectedMedia: SelectedMedia
				section:
					| ClinicalDataSection
					| SharePrescriptionToCockpitKeyOtherData
					| SharePrescriptionToCockpitKey
					| DiseasesAndTreatmentsSection
			}>,
		) => {
			state.selectedMedia[payload.section] = payload.selectedMedia
		},
		_setSharingOn: (state, { payload }: PayloadAction<string>) => {
			state.sharingOn = payload
		},
		_setCockpitFullscreen: (
			state,
			{ payload }: PayloadAction<Partial<InstrumentRemoteMedia> | undefined>,
		) => {
			state.fullscreen = payload
		},
		setVideoStatus: (
			state,
			{ payload }: PayloadAction<VideoStatusType | undefined>,
		) => {
			state.videoStatus = payload
		},
		setShareGraph: (state, { payload }: PayloadAction<SelectedGraphData>) => {
			state.sharingGraph = payload
		},
		_clearShareGraph: state => {
			state.sharingGraph = undefined
		},
		setTotGraph: (state, { payload }: PayloadAction<string | undefined>) => {
			state.totGraph = payload
		},
	},
})

export default slice.reducer
