import { io, Socket } from 'socket.io-client'
import appConfig from '../../../config'
import { BasePath, Path } from '../../../formFields/model'
import { SocketMessagesMap } from '../../../model/socket'
import {
	newTeloSocketConnector,
	TeloSocketConnector,
} from '../teloSocketConnector'
import { connectTeloSocket, disconnectTeloSocket } from '../teloSocketRegistry'
import {
	AddNotificationFn,
	AddPatientExportFn,
	ExamIdData,
	ExamsInStoreUpdatedData,
	FetchExamFn,
	FindNotificationFn,
	InvalidateWorklistCacheFn,
	RemoveNotificationFn,
	TeloSocket,
} from '../teloSocketTypes'
import { basicDisconnectSocket, isSocketConnected } from '../teloSocketUtils'
import { onConnect, onConnectError } from './roomSocketConnectionMessages'
import {
	onAssessmentMessage,
	onExamMessage,
	onExamsInStoreMessage,
	onFieldsUpdatesMessage,
	onInstrumentInRoomMessage,
	onInstrumentMessage,
	onStageMessage,
	onUserMessage,
	onWarningMessage,
	onPatientExport,
} from './roomSocketListeners'
import {
	AssessmentMessageData,
	FetchAssessmentFn,
	FetchExamWarningsFn,
	FetchFieldsDataFn,
	FetchInstrumentDataFn,
	FetchInstrumentsInRoomFn,
	FetchStagesFn,
	FetchUserFn,
	InstrumentInRoomMessageData,
	InstrumentMessageData,
	RoomSocketType,
	StageMessageData,
	UpdateQueueFeatureFlagFn,
	UserMessageData,
} from './roomSocketTypes'
import {
	socketCommonOptions,
	socketReconnectionFailedNotification,
} from '../../../libs/socket'
import { FeatureTogglePayload } from '../../../model/features'
import { ExportState } from '../../patientExport/slice'

export type RoomSocketArgs = {
	roomName: string
	sessionId: string
	findNotification: FindNotificationFn
	addNotification: AddNotificationFn
	removeNotification: RemoveNotificationFn
	fetchUser: FetchUserFn
	fetchStages: FetchStagesFn
	fetchAssessment: FetchAssessmentFn
	fetchExam: FetchExamFn
	fetchExamWarnings: FetchExamWarningsFn
	fetchInstrumentData: FetchInstrumentDataFn
	fetchInstrumentsInRoom: FetchInstrumentsInRoomFn
	fetchFieldsData: FetchFieldsDataFn
	invalidateWorklistCache: InvalidateWorklistCacheFn
	updateQueueFeatureFlag: UpdateQueueFeatureFlagFn
	addPatientExport: AddPatientExportFn
}

const newSocket = (args: RoomSocketArgs): TeloSocket => {
	let socket: Socket | null = null
	const { roomName } = args

	const validateRooms = (messagesMap: SocketMessagesMap): void => {
		const roomNamesRegExpValidation = Object.values(messagesMap).map(
			({ roomNameRegExpValidation }) => roomNameRegExpValidation,
		)

		if (
			!roomNamesRegExpValidation.some(roomNameRegExpValidation =>
				new RegExp(roomNameRegExpValidation).test(roomName),
			)
		) {
			throw new Error(`bad room name ${roomName}`)
		}
	}

	const onSocketMessagesMap = (
		messagesMap: SocketMessagesMap,
		args: RoomSocketArgs,
	): void => {
		if (!isSocketConnected(socket)) {
			return
		}
		validateRooms(messagesMap)

		const {
			user,
			stage,
			assessment,
			exam,
			instrument,
			instrumentInRoom,
			warning,
			examsInStore,
			fieldsData,
			features,
			patientExport,
		} = messagesMap

		const {
			addNotification,
			fetchUser,
			sessionId,
			fetchStages,
			fetchAssessment,
			fetchExam,
			fetchExamWarnings,
			fetchInstrumentData,
			fetchInstrumentsInRoom,
			fetchFieldsData,
			invalidateWorklistCache,
			updateQueueFeatureFlag,
			addPatientExport,
		} = args

		socket!.on(user.msgToFe, (data: UserMessageData) => {
			onUserMessage({ data, addNotification, fetchUser, sessionId })
		})
		socket!.on(stage.msgToFe, (data: StageMessageData) => {
			onStageMessage({ data, fetchStages, sessionId })
		})
		socket!.on(assessment.msgToFe, (data: AssessmentMessageData) => {
			onAssessmentMessage({ data, fetchAssessment, sessionId })
		})
		socket!.on(exam.msgToFe, (data: ExamIdData) => {
			onExamMessage({ data, fetchExam, sessionId })
		})
		socket!.on(warning.msgToFe, (data: ExamIdData) => {
			onWarningMessage({ data, fetchExamWarnings, sessionId })
		})
		socket!.on(instrument.msgToFe, (data: InstrumentMessageData) => {
			onInstrumentMessage({ data, fetchInstrumentData, sessionId })
		})
		socket!.on(
			instrumentInRoom.msgToFe,
			(data: InstrumentInRoomMessageData) => {
				onInstrumentInRoomMessage({ data, fetchInstrumentsInRoom, sessionId })
			},
		)
		socket!.on(examsInStore.msgToFe, (data: ExamsInStoreUpdatedData) => {
			onExamsInStoreMessage({
				data,
				invalidateWorklistCache,
				sessionId,
			})
		})
		socket!.on(
			fieldsData.msgToFe,
			(
				data:
					| {
							examId: string
							updatedBy: string
							fields: { path: Path; index?: number }[]
							type: 'fields-updated'
					  }
					| {
							examId: string
							updatedBy: string
							basePath: BasePath
							index: number
							type: 'index-deleted'
					  },
			) => {
				onFieldsUpdatesMessage({ data, fetchFieldsData })
			},
		)

		socket!.on(features.msgToFe, (data: FeatureTogglePayload) => {
			if (data.queue) {
				updateQueueFeatureFlag(data.queue.enabled)
			}
		})

		socket!.on(patientExport.msgToFe, (data: ExportState) => {
			onPatientExport({ data, addPatientExport })
		})
	}

	const connect = (): void => {
		if (isSocketConnected(socket)) {
			return
		}
		const { addNotification, findNotification, removeNotification } = args

		socket = io(appConfig.socketUrl, {
			...socketCommonOptions,
			query: { roomName: roomName },
		})

		socket.io.on('reconnect_failed', () => {
			addNotification(socketReconnectionFailedNotification)
		})

		socket.on('connect_error', () => {
			if (isSocketConnected(socket)) {
				onConnectError({
					addNotification,
					findNotification,
					isSocketConnected: () => isSocketConnected(socket),
				})
			}
		})
		socket.on('connect', () => {
			if (isSocketConnected(socket)) {
				onConnect(removeNotification)
			}
		})
		socket.once('socket-messages-map', (messagesMap: SocketMessagesMap) => {
			onSocketMessagesMap(messagesMap, args)
		})
	}

	const disconnect = () => {
		basicDisconnectSocket(socket)
		socket = null
	}

	return { connect, disconnect }
}

const typeToConnectorMap = new Map<RoomSocketType, TeloSocketConnector>()
export function connectToSocketRoom(
	type: RoomSocketType,
	args: RoomSocketArgs,
): void {
	if (!typeToConnectorMap.has(type)) {
		typeToConnectorMap.set(type, newTeloSocketConnector())
	}
	const socketConnector = typeToConnectorMap.get(type)!

	const { roomName } = args
	socketConnector.connectSocket({
		socketKey: roomName,
		newSocket: () => newSocket(args),
	})
}

export function connectToSocketRoomNoType(args: RoomSocketArgs): void {
	const { roomName } = args

	connectTeloSocket({
		socketKey: roomName,
		newSocket: () => newSocket(args),
	})
}

export function disconnectFromSocketRoomNoType(roomName: string): void {
	disconnectTeloSocket(roomName)
}
