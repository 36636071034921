import React, { useEffect } from 'react'
import { DialogProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

import Dialog from '../../styleguide/dialog/Dialog'
import {
	ButtonPanel,
	ButtonStyled,
	HeaderPanel,
	DialogBody,
	DialogContentStyled,
} from './style'
import TriangleAlertIcon from '../../styleguide/icons/TriangleAlertIcon'
import { useRetryAssignationMutation } from '../../services/queue'
import { playDing } from '../../features/notifications/utils/play-ding'

type QueueNotificationsDialogProps = Omit<
	DialogProps,
	'children' | 'content'
> & {
	title: string
	message: string
	examId: string
	playSound?: boolean
}

const QueueNotificationsDialog = ({
	title,
	message,
	examId,
	playSound,
	...modalProps
}: QueueNotificationsDialogProps) => {
	const { t } = useTranslation()

	useEffect(() => {
		if (modalProps.open && playSound) {
			playDing()
		}
	}, [modalProps.open, playSound])

	const [retryAssignation] = useRetryAssignationMutation()

	return (
		<Dialog {...modalProps}>
			<DialogContentStyled>
				<HeaderPanel>
					<TriangleAlertIcon />
					<span>{title}</span>
				</HeaderPanel>
				<DialogBody>
					{message}
					<ButtonPanel>
						<ButtonStyled
							type="button"
							variant="text"
							color="secondary"
							underlined={true}
							onClick={e => {
								retryAssignation({ examId })
								modalProps.onClose?.(e, 'backdropClick')
							}}
						>
							{t('app.retry')}
						</ButtonStyled>
					</ButtonPanel>
				</DialogBody>
			</DialogContentStyled>
		</Dialog>
	)
}

export default QueueNotificationsDialog
