import styled from 'styled-components'
import { pxToRem } from '../../libs/style'
import theme from '../../styleguide/theme'

export const StyledDatePickerWrapper = styled.div`
	.MuiInputBase-root {
		padding-right: 0;
		border-radius: ${theme.spacing(1.5)};

		.MuiInputBase-input {
			width: 80%;
			padding: ${theme.spacing(1.5)};
		}

		.MuiInputAdornment-root {
			width: 20%;

			button {
				margin: 0 auto;

				svg {
					width: ${pxToRem(20)}rem;
				}
			}
		}
	}
`
