import styled from 'styled-components'
import theme from '../../styleguide/theme'
import { pxToRem } from '../../libs/style'
import DialogContent from '../../styleguide/dialog/DialogContent'
import Button from '../../styleguide/buttons/Button'

export const BannerWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: ${pxToRem(756)}rem;
	background-color: ${theme.palette.secondary.main};
	border-radius: ${theme.shape.borderRadius}px;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
	padding: ${theme.spacing(2)};
	color: ${theme.palette.secondary.contrastText};
	font-size: ${pxToRem(16)}rem;
	line-height: ${pxToRem(21)}rem;
`

export const CloseButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${pxToRem(24)}rem;
	height: ${pxToRem(24)}rem;
	margin-left: ${theme.spacing(2)};
	cursor: pointer;
`

export const DialogBody = styled.div`
	font-size: ${pxToRem(14)}rem;
	line-height: ${pxToRem(20)}rem;
	padding: ${theme.spacing(2)} 0 ${theme.spacing(1)};
`

export const DialogContentStyled = styled(DialogContent)`
	width: ${pxToRem(460)}rem;
	padding: ${theme.spacing(3)};
`

export const HeaderPanel = styled.div`
	display: flex;
	align-items: center;
	line-height: ${pxToRem(20)}rem;

	> svg {
		fill: red;
	}
`

export const ButtonPanel = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;

	button {
		&:not(:first-child) {
			margin-left: ${theme.spacing(1)};
		}
	}
`

export const ButtonStyled = styled(Button)`
	font-weight: bold;

	span {
		text-transform: uppercase;
		border-bottom: none;
	}
`
