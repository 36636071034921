import { ErrorToLog, HttpError } from '../../model/model'
import { TeloDispatch } from '../../store'
import { slice } from './slice'

const _logToApi = (error: ErrorToLog) => () => {
	console.error(error)
}

const setUiError = (error: Error) => (dispatch: TeloDispatch) => {
	dispatch(
		_logToApi({
			message: error.message || 'unknown error',
			stack: error.stack || '',
			origin: 'internal-ui',
			externalUrl: '',
			internalUrl: window.location.href,
		}),
	)
	// JSON.parse/JSON.stringify is necessary because the error object is not serializable, ad so cannot be store into redux as it is
	dispatch(slice.actions._setUiError(JSON.parse(JSON.stringify(error))))
}

export const setHttpError = (error: HttpError) => (dispatch: TeloDispatch) => {
	dispatch(
		_logToApi({
			...error,
			stack: '',
		}),
	)
	dispatch(slice.actions._setHttpError(error))
}

const errorsActions = {
	...slice.actions,
	setUiError,
	setHttpError,
}

export default errorsActions
