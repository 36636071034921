import {
	ClinicalDataSection,
	clinicalDataSections,
	DiseasesAndTreatmentsSection,
	SelectedMedia,
	SharePrescriptionToCockpitKey,
	SharePrescriptionToCockpitKeyOtherData,
} from '../../model/cockpit'
import { InstrumentRemoteMedia } from '../../model/instruments'
import { AppThunk, TeloDispatch } from '../../store'
import prescriptionsActions from '../prescriptions/actions'
import uiActions from '../ui/actions'
import { slice } from './slice'
const clearCockpitDisplay = (): AppThunk => (dispatch: TeloDispatch) => {
	dispatch(prescriptionsActions.clearPrescriptionDisplay())
	dispatch(
		slice.actions._setSharingMedia({
			OD: null,
			OS: null,
			both: null,
		}),
	)
	dispatch(slice.actions._setSharingOn(''))
	dispatch(uiActions.setSelectedPrescritpionAsset(''))
	dispatch(slice.actions._setCockpitFullscreen(undefined))
}

const _setSharingMedia =
	(sharingMedia: SelectedMedia): AppThunk =>
	(dispatch: TeloDispatch) => {
		dispatch(slice.actions._setSharingMedia(sharingMedia))
	}

const setSelectedMedia =
	(
		selectedMedia: SelectedMedia,
		section:
			| ClinicalDataSection
			| SharePrescriptionToCockpitKeyOtherData
			| SharePrescriptionToCockpitKey
			| DiseasesAndTreatmentsSection,
	): AppThunk =>
	(dispatch: TeloDispatch) => {
		dispatch(slice.actions.setSelectedMedia({ selectedMedia, section }))
	}

const resetSelectedMedia = (): AppThunk => (dispatch: TeloDispatch) => {
	const selectedMedia = { OD: null, OS: null, both: null }
	clinicalDataSections.map(section =>
		dispatch(slice.actions.setSelectedMedia({ selectedMedia, section })),
	)
}

const _setSharingOn =
	(element: string): AppThunk =>
	(dispatch: TeloDispatch) => {
		dispatch(slice.actions._setSharingOn(element))
	}

const setCockpitFullscreen =
	(element: Partial<InstrumentRemoteMedia> | undefined): AppThunk =>
	(dispatch: TeloDispatch) => {
		dispatch(slice.actions._setCockpitFullscreen(element))
	}

const cockpitActions = {
	...slice.actions,
	clearCockpitDisplay,
	_setSharingMedia,
	setSelectedMedia,
	_setSharingOn,
	setCockpitFullscreen,
	resetSelectedMedia,
}

export default cockpitActions
