import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { createApi } from '@reduxjs/toolkit/query/react'
import { unversionedApiUrl } from './common'

export const reasonsAPI = createApi({
	reducerPath: 'reasonsAPI',
	baseQuery: staggeredBaseQueryWithBailOut(`${unversionedApiUrl}`),
	endpoints: builder => ({
		getFilteredRefusalReasons: builder.query({
			query: ({ filter, type }) => ({
				url: `v1/reasons?q=${filter}&type=${type}&enabled=true`,
			}),
		}),
	}),
})

export const { useGetFilteredRefusalReasonsQuery } = reasonsAPI
