import { Id, RootState } from '../../model/model'

export const selectActiveSignRequests = (examId: Id) => (state: RootState) =>
	state.signRequests.filter(
		signRequest => signRequest.examId === examId && signRequest.active,
	)

export const selectReferralFormSignState = (examId: Id) => (state: RootState) =>
	state.signRequests.find(
		signRequest =>
			signRequest.examId === examId && signRequest.type === 'referralForm',
	)

export const selectOrderReferralSignState =
	(examId: Id, orderId: Id | undefined) => (state: RootState) =>
		state.signRequests.find(
			signRequest =>
				signRequest.examId === examId &&
				signRequest.orderId === orderId &&
				signRequest.type === 'orderReferral',
		)

export const selectContactLensesSignState =
	(examId: Id) => (state: RootState) =>
		state.signRequests.find(
			signRequest =>
				signRequest.examId === examId && signRequest.type === 'contactLenses',
		)

export const selectContactLensesSignature =
	(examId: Id) => (state: RootState) =>
		state.signRequests.find(
			signRequest =>
				signRequest.examId === examId && signRequest.type === 'contactLenses',
		)?.signature
