import React from 'react'
import { useDateFormatter } from '../hooks/useDateFormatter'
import DatePicker, { DatePickerProps } from './DatePicker'

export type FormattedDatePickerProps = Omit<DatePickerProps, 'format' | 'lang'>

const FormattedDatePicker = function FormattedDatePicker(
	props: FormattedDatePickerProps,
) {
	const { dateFormat } = useDateFormatter()

	return <DatePicker {...props} format={dateFormat} />
}

export default FormattedDatePicker
