import { compose, find, prop, values } from 'lodash/fp'
import { createSelector } from '@reduxjs/toolkit'

import {
	Id,
	Prescriptions,
	PrescriptionsState,
	RootState,
} from '../../model/model'
import { Prescription } from '../../model/prescription'

export const selectPrescriptions = prop<RootState, 'prescriptions'>(
	'prescriptions',
)

export const selectPrescriptionsList = createSelector<
	[(state: RootState) => PrescriptionsState],
	Prescriptions
>(selectPrescriptions, prop<PrescriptionsState, 'list'>('list'))

export const selectPrescriptionById = createSelector<
	[
		(state: RootState) => Prescriptions,
		(_: RootState, prescriptionId: Id) => Id,
	],
	Prescription | undefined
>(
	selectPrescriptionsList,
	(_, prescriptionId) => prescriptionId,
	(prescriptionList, prescriptionId) => prescriptionList[prescriptionId],
)

export const selectPrescriptionByExamId = createSelector<
	[
		(state: RootState) => Prescriptions,
		(_: RootState, examId?: Id) => Id | undefined,
	],
	Prescription | undefined
>(
	selectPrescriptionsList,
	(_, examId) => examId,
	(prescriptionList, examId) =>
		compose<[Prescriptions], Prescription[], Prescription | undefined>(
			find({ examId } as Prescription),
			values,
		)(prescriptionList),
)

export const selectPrescriptionByExamId2 =
	(examId?: Id) => (state: RootState) =>
		Object.values(state.prescriptions.list).find(
			prescription => prescription.examId === examId,
		)

export const selectIsSharing =
	(key: string, index: number) => (state: RootState) =>
		state.prescriptions.inShare === `${key}${index}` &&
		state.cockpit.sharingOn === key

export const selectIsDirty = () => (state: RootState) =>
	!!state.prescriptions.isDirty

export const selectHasErrors = () => (state: RootState) =>
	!!state.prescriptions.hasErrors
