import { io, Socket } from 'socket.io-client'
import appConfig from '../../../config'
import {
	socketCommonOptions,
	socketReconnectionFailedNotification,
} from '../../../libs/socket'
import { newTeloSocketConnector } from '../teloSocketConnector'
import {
	AddNotificationFn,
	ExamIdData,
	TeloSocket,
	UpdateWorklistFn,
} from '../teloSocketTypes'
import {
	basicDisconnectSocket,
	checkOrigin,
	isSocketConnected,
} from '../teloSocketUtils'

type DoctorExamsInStoreSocketArgs = {
	storeId: string
	doctorUsername: string
	sessionId: string
	updateWorklist: UpdateWorklistFn
	addNotification: AddNotificationFn
}

const newSocket = ({
	doctorUsername,
	storeId,
	updateWorklist,
	sessionId,
	addNotification,
}: DoctorExamsInStoreSocketArgs): TeloSocket => {
	let socket: Socket | null = null

	const connect = (): void => {
		if (isSocketConnected(socket)) {
			return
		}

		socket = io(appConfig.socketUrl, {
			...socketCommonOptions,
			query: { storesId: storeId, doctorUsername },
		})

		socket.io.on('reconnect_failed', () => {
			addNotification(socketReconnectionFailedNotification)
		})

		socket.on('exams-in-store-update-available', (data: ExamIdData) => {
			onDoctorExamsUpdateAvailableMessage({ data, sessionId, updateWorklist })
		})
	}

	const disconnect = () => {
		basicDisconnectSocket(socket)
		socket = null
	}

	return { connect, disconnect }
}

type DoctorExamsUpdateAvailableMessageArgs = {
	data: ExamIdData
	sessionId: string
	updateWorklist: UpdateWorklistFn
}

const onDoctorExamsUpdateAvailableMessage = (
	args: DoctorExamsUpdateAvailableMessageArgs,
): void => {
	const { data, sessionId, updateWorklist } = args
	const sameOrigin = checkOrigin(sessionId, data)
	if (sameOrigin === true) {
		return
	}

	updateWorklist()
}

const socketConnector = newTeloSocketConnector()

export function connectToSocketDoctorStore(
	args: DoctorExamsInStoreSocketArgs,
): void {
	socketConnector.connectSocket({
		socketKey: getSocketKey(args),
		newSocket: () => newSocket(args),
	})
}

type DoctorStoreKey = {
	storeId: string
	doctorUsername: string
}

const getSocketKey = ({ doctorUsername, storeId }: DoctorStoreKey): string => {
	const key: DoctorStoreKey = { doctorUsername, storeId }
	return JSON.stringify(key)
}
