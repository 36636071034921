import { createApi } from '@reduxjs/toolkit/query/react'
import qs from 'qs'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from './common'
import { ServiceBaseUrl } from './serviceApi'
import { ServicesApiTag, ServicesReducerPath } from './constants'
import { SymptomsUpdatePartial, Symptom } from '../model/symptoms'
import { useDebounce } from '../hooks/useDebounce'
import { getCommonHeaders } from '../libs/fetchJson'
type ResponseData = { data: Symptom[]; totalEntries: number }
export const symptomsApi = createApi({
	reducerPath: ServicesReducerPath.SymptomsApi,
	tagTypes: [ServicesApiTag.Symptoms],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		get: builder.query<
			ResponseData,
			{ limit?: number; page?: number; q?: string; admin?: boolean | string }
		>({
			query: query => ({
				url: `${ServiceBaseUrl.Symptoms}?${qs.stringify(query)}`,
				headers: getCommonHeaders({ addContentType: true }),
			}),
			providesTags: [ServicesApiTag.Symptoms],
		}),

		createSymptoms: builder.mutation<void, SymptomsUpdatePartial>({
			query: body => ({
				url: `${ServiceBaseUrl.Symptoms}`,
				method: 'POST',
				body: JSON.stringify(body),
				headers: getCommonHeaders({ addContentType: true }),
			}),
			invalidatesTags: [ServicesApiTag.Symptoms],
		}),

		updateSymptoms: builder.mutation<void, SymptomsUpdatePartial>({
			query: body => {
				const { _id, ...updateData } = body

				return {
					url: `${ServiceBaseUrl.Symptoms}/${_id}`,
					method: 'PUT',
					body: JSON.stringify(updateData),
					headers: getCommonHeaders({ addContentType: true }),
				}
			},
			invalidatesTags: [ServicesApiTag.Symptoms],
		}),

		enableSymptoms: builder.mutation<
			void,
			{ id: string; updateData: Omit<Symptom, '_id'>; value: boolean }
		>({
			query: ({ id, value, updateData }) => ({
				url: `${ServiceBaseUrl.Symptoms}/${id}`,
				method: 'PATCH',
				body: JSON.stringify({
					...updateData,
					enabled: value,
				}),
			}),
			invalidatesTags: [ServicesApiTag.Symptoms],
		}),
	}),
})

const useGetDebounced = (query: {
	q: string
	limit?: number
	page?: number
	admin?: boolean | string
}) => {
	const debouncedQuery = useDebounce(query.q, 500)
	return symptomsApi.useGetQuery(
		{ ...query, q: debouncedQuery },
		{
			refetchOnMountOrArgChange: true,
		},
	)
}

export const useSymptomsApi = {
	getDebounced: useGetDebounced,
}

export const {
	useGetQuery,
	useCreateSymptomsMutation,
	useUpdateSymptomsMutation,
	useEnableSymptomsMutation,
} = symptomsApi
