import config from '../../../config'
import { fetchJson, getCommonHeaders } from '../../../libs/fetchJson'
import { ExamApi } from '../../../model/exam'

export const getEPrescripingInputAPI = (examId: string) =>
	fetch(`${config.apiUrlV2}/exams/${examId}/eprescribing`, {
		method: 'GET',
		headers: {
			...getCommonHeaders({}),
		},
	}).then(res => res.text())

export const setMedicationsAPI = (examId: string) =>
	fetchJson<ExamApi>(
		`${config.apiUrlV2}/exams/${examId}/medications`,
		{
			method: 'PATCH',
			body: JSON.stringify({}),
		},
		'errors.connection',
	)
export const getNewCropStatusInput = (storeId: string, username: string) =>
	fetch(`${config.apiUrlV2}/exams/${storeId}/eprescribing/${username}`, {
		method: 'GET',
		headers: {
			...getCommonHeaders({}),
		},
	}).then(res => res.text())

export const fetchNewCropTasks = (storeId: string) =>
	fetch(`${config.apiUrlV2}/exams/${storeId}/tasks`, {
		headers: getCommonHeaders({}),
	})
		.then(response => response.text())
		.then(res => {
			try {
				return Number(res)
			} catch (error) {
				console.error(error)
				return 0
			}
		})
