import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import { WorklistExam } from '../../model/exam'

import { WorklistFiltersType, WorklistState } from '../../model/model'
import { worklistApi } from '../../services/worklist'

export const initialState: WorklistState = {
	worklistFilters: {
		dateFrom: new Date().toISOString(),
		technician: ['all'],
		chartStatus: 'all',
		dateRange: false,
		onlyRemote: false,
		cpt: [],
		icd10: [],
		loinc: [],
		snomed: [],
		dateTo: null,
		status: [],
		patientSignatureStatus: 'all',
	},
	currentPaginationPage: 0,
	paginationTotalPages: 0,
	availableToggleWasClicked: false,
	showAvailableToggleLoader: false,
}

const { getWorklist } = worklistApi.endpoints

export const slice = createSlice({
	name: 'worklist',
	initialState,
	reducers: {
		setFilters: (
			state,
			{ payload }: PayloadAction<Partial<WorklistFiltersType>>,
		) => {
			state.worklistFilters = {
				...current(state.worklistFilters),
				...payload,
			}
			state.currentPaginationPage = 0
		},
		resetFilters: state => {
			state.worklistFilters = { ...initialState.worklistFilters }
			state.currentPaginationPage = 0
		},
		setCurrentPaginationPage: (state, { payload }: PayloadAction<number>) => {
			state.currentPaginationPage = payload
		},
		setAvailableToggleWasClicked: (
			state,
			{ payload }: PayloadAction<boolean>,
		) => {
			state.availableToggleWasClicked = payload
		},
		setShowAvailableToggleLoader: (
			state,
			{ payload }: PayloadAction<boolean>,
		) => {
			state.showAvailableToggleLoader = payload
		},
	},
	extraReducers: builder => {
		builder.addMatcher(
			getWorklist.matchFulfilled,
			(
				state,
				{
					payload,
				}: PayloadAction<{ exams: WorklistExam[]; totalResults: number }>,
			) => {
				state.paginationTotalPages = Math.ceil(payload.totalResults / 10)
			},
		)
	},
})

export default slice.reducer
