import {
	compose,
	defaultTo,
	find,
	fromPairs,
	isEmpty,
	set,
	prop,
	toPairs,
	map,
} from 'lodash/fp'

import { ExamCondition, ExamConditionSections } from '../../model/examCondition'

export const getConditionValueToUpdateByKeyAndPath =
	<CV extends Record<string, any>, FV extends Record<string, any>>(
		conditionValues: CV,
		formValues: FV,
		shouldReplaceValues: boolean,
		key: keyof CV | keyof FV,
	) =>
	(path: string): string => {
		const formValue = prop(path, formValues[key as keyof FV])

		return shouldReplaceValues || isEmpty(formValue)
			? prop(path, conditionValues[key as keyof CV])
			: formValue
	}

export const getConditionSectionsById = (
	_id: string,
	conditions: ExamCondition[],
): ExamConditionSections | Record<string, never> =>
	compose<
		[ExamCondition[]],
		ExamCondition | undefined,
		ExamConditionSections | undefined,
		ExamConditionSections | Record<string, never>
	>(
		defaultTo({}),
		prop<ExamCondition, 'sections'>('sections'),
		find<ExamCondition>({ _id }),
	)(conditions)

export const getReplacedEmptyFormValues = compose<
	[Record<string, any>],
	[string, any][],
	[string, string][],
	Record<string, string>
>(
	fromPairs,
	map<[string, any], [string, string]>(([path]) => [path, '']),
	toPairs,
)
