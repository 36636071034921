import { createApi } from '@reduxjs/toolkit/query/react'

import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { SystemManufacturer } from '../model/manufacturer'
import { unversionedApiUrl } from './common'

const ManufacturerType_TAG = 'manufacturer'

export const manufacturerApi = createApi({
	reducerPath: 'manufacturerApi',
	tagTypes: [ManufacturerType_TAG],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getManufacturerList: builder.query<SystemManufacturer[], void>({
			query: () => ({
				url: `/system/v1/manufacturer`,
			}),
			providesTags: [ManufacturerType_TAG],
		}),
	}),
})

export const { useGetManufacturerListQuery } = manufacturerApi
