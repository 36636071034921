import React, { FC, MouseEvent } from 'react'

import {
	DialogTitle as MDialogTitle,
	DialogTitleProps as MuiDialogTitleProps,
	IconButton,
} from '@mui/material'

import styled, { FlattenSimpleInterpolation } from 'styled-components'

import { pxToRem } from '../../libs/style'
import CloseIcon from '../icons/CloseIcon'
import theme from '../theme'
import Capitalize from '../typography/Capitalize'

const StyledDialogTitle = styled(MDialogTitle)<{
	$customStyle?: FlattenSimpleInterpolation
}>`
	background-color: ${theme.palette.background.secondary};
	color: ${theme.palette.grey[700]};
	font-size: ${pxToRem(24)}rem;
	font-family: 'Poppins';
	display: flex;
	justify-content: space-between;
	align-items: center;

	${({ $customStyle }) => $customStyle};
`

const OutlinedButton = styled(IconButton)`
	border: solid 1px ${theme.palette.grey[700]};
	border-radius: 50%;
	background-color: ${theme.palette.common.white};
	color: ${theme.palette.grey[700]};
`

const ContainedButton = styled(IconButton)`
	color: ${theme.palette.common.white};
	padding: 0;
`

export interface DialogTitleProps extends MuiDialogTitleProps {
	closeButtonVariant?: 'outlined' | 'contained'
	capitalize?: boolean
	onClose?: (event: MouseEvent) => void
	customStyle?: FlattenSimpleInterpolation
}

const DialogTitle: FC<DialogTitleProps> = ({
	onClose,
	closeButtonVariant = 'contained',
	customStyle,
	capitalize,
	children,
	...props
}) => {
	const CloseButton =
		closeButtonVariant === 'contained' ? ContainedButton : OutlinedButton

	return (
		<StyledDialogTitle $customStyle={customStyle} {...props}>
			{capitalize ? <Capitalize>{children}</Capitalize> : children}
			{onClose ? (
				<CloseButton aria-label="close" onClick={onClose}>
					<CloseIcon />
				</CloseButton>
			) : null}
		</StyledDialogTitle>
	)
}

export default DialogTitle
