import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'

import { unversionedApiUrl } from './common'
import { ServicesEndpointName, ServicesReducerPath } from './constants'
import { ServiceBaseUrl } from './serviceApi'

interface CheckInactivityLoginQuery {
	username: string
	password: string
}

export const inactivityLoginApi = createApi({
	reducerPath: ServicesReducerPath.InactivityLoginApi,
	baseQuery: staggeredBaseQueryWithBailOut(`${unversionedApiUrl}`),
	endpoints: builder => ({
		[ServicesEndpointName.CheckInactivityLogin]: builder.mutation<
			string,
			CheckInactivityLoginQuery
		>({
			query: ({ username, password }: CheckInactivityLoginQuery) => ({
				url: ServiceBaseUrl.CheckCredentials,
				method: 'POST',
				body: { username, password },
			}),
		}),
	}),
})

export const { useCheckInactivityLoginMutation } = inactivityLoginApi
