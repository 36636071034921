import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Button from '../../styleguide/buttons/Button'
import Dialog from '../../styleguide/dialog/Dialog'
import DialogActions from '../../styleguide/dialog/DialogActions'
import DialogContent from '../../styleguide/dialog/DialogContent'

const Actions = styled(DialogActions)`
	display: flex;
	justify-content: flex-end;
`

export const PatientNotFoundModal = ({
	open,
	onClose,
}: {
	open: boolean
	onClose: () => void
}) => {
	const { t } = useTranslation()

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogContent>{t('patient.noPatientFoundText')}</DialogContent>
			<Actions>
				<Button
					data-testid="patient-not-found"
					variant="text"
					color="secondary"
					onClick={onClose}
				>
					{t('app.okay')}
				</Button>
			</Actions>
		</Dialog>
	)
}
