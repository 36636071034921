import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Diagnosis } from '../../components/AssessmentAndPlan/types'
import { ChatbotState } from '../../model/chatbot'
import { ExamApi, Exam } from '../../model/exam'
import { DifForm } from '../../components/DigitalIntakeForm/types'

const initialState: ChatbotState = {
	diagnosi: undefined,
	clipboard: undefined,
	aiSection: undefined,
	aiRetinoscopySection: undefined,
}

export const slice = createSlice({
	name: 'chatbot',
	initialState,
	reducers: {
		setICDToRecommend: (
			state,
			{ payload }: PayloadAction<Diagnosis | undefined>,
		) => {
			state.diagnosi = payload
		},
		setAISectionData: (
			state,
			{
				payload,
			}: PayloadAction<
				| Record<string, unknown>
				| Partial<ExamApi>
				| Partial<Exam>
				| DifForm
				| undefined
				| any
			>,
		) => {
			state.aiSection = payload
		},
		setAIRetinoscopyData: (
			state,
			{
				payload,
			}: PayloadAction<
				| Record<string, unknown>
				| Partial<ExamApi>
				| Partial<Exam>
				| DifForm
				| undefined
				| any
			>,
		) => {
			state.aiRetinoscopySection = payload
		},
		setClipboardData: (
			state,
			{ payload }: PayloadAction<string | undefined>,
		) => {
			state.clipboard = payload
		},
	},
})

export default slice.reducer
