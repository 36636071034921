import config from '../config'

export type HeaderParams = {
	upriseOrgId?: string
}

export type FilterParams = HeaderParams & {
	filter: string
	propNames?: string[]
	caseSignificanceId?: string
	favourites?: boolean
}

export const unversionedApiUrl = config.apiUrl.replace('/v1', '')
