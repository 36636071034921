import React from 'react'

import { SvgProps } from '../../model/types'

const ArrowRightIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="currentColor"
			{...props}
		>
			<path
				d="M12.2558 4.69681L16.9698 9.41084C17.2953 9.73626 17.2953 10.2639 16.9698 10.5893L12.2558 15.3034C11.9304 15.6288 11.4027 15.6288 11.0773 15.3034C10.7518 14.978 10.7518 14.4503 11.0773 14.1249L14.3687 10.8334H3.33321C2.87298 10.8334 2.49988 10.4603 2.49988 10.0001C2.49988 9.53984 2.87298 9.16676 3.33321 9.16676H14.3687L11.0773 5.87532C10.7518 5.54988 10.7518 5.02224 11.0773 4.69681C11.4027 4.37137 11.9304 4.37137 12.2558 4.69681Z"
				id="Fill-4"
				transform="translate(10.067090, 10.084301) scale(-1, 1) rotate(-180.000000) translate(-10.067090, -10.084301) "
			/>
		</svg>
	)
}

export default ArrowRightIcon
