import { createContext, useContext } from 'react'
import { ReadTeloParamsFn, SetTeloParamsFn } from './teloRouterUtils'

export type TeloPath = {
	pathname: string
}
export type TeloLocation = {
	pathname: string
	search: string
	state: any
}
export type TeloNavigationOptions = { state: any }
export type TeloDestination = string | number | (TeloPath & { search?: string })
export type TeloNavigationFn = (
	to: TeloDestination,
	options?: TeloNavigationOptions,
) => void
export type GetTeloLocationFn = () => TeloLocation
export type TeloURLSearchParams = { searchParams: URLSearchParams }
export type OnNavigationRequestFn = (
	toPath: string,
	options?: TeloNavigationOptions,
) => void
export type GetAllTeloSearchParamsFn = () => TeloURLSearchParams
export type ReadTeloRouteParamsFn = ReadTeloParamsFn

export type TeloRouterContextHandles = {
	navigate: TeloNavigationFn
	getLocation: GetTeloLocationFn
	readSearchParams: ReadTeloParamsFn
	setSearchParams: SetTeloParamsFn
	getAllSearchParams: GetAllTeloSearchParamsFn
	readRouteParams: ReadTeloRouteParamsFn
}

export const TeloRouterContext = createContext<TeloRouterContextHandles | null>(
	null,
)

export const useTeloRouter = (): TeloRouterContextHandles => {
	const ctxHandles = useContext(TeloRouterContext)
	if (!ctxHandles) {
		throw new Error('Cannot use telo router outside a context')
	}
	return ctxHandles
}

export const toPath = (to: Omit<TeloDestination, number>): string => {
	if (typeof to === 'string') {
		return to
	}
	const asPath = to as TeloPath
	if (!asPath?.pathname) {
		throw new Error('Cannot navigate to an empty pathname')
	}
	return asPath.pathname
}
