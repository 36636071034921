import { createSelector } from '@reduxjs/toolkit'
import { compose, filter, flatMap, isEmpty, uniq } from 'lodash/fp'

import { Id, RootState } from '../../model/model'
import { Panel, PanelWithDoctorName } from '../../model/panel'
import { User } from '../../model/users'
import { selectStoreId } from '../app/selectors'
import { selectUsername, selectUserRole } from '../auth/selectors'
import { selectIsGlobalAdmin, selectUsers } from '../users/selectors'

export const selectPanels = (state: RootState): Panel[] => state.panels || []

export const selectPanelsWithDoctorsName = createSelector<
	[(state: RootState) => Panel[], (state: RootState) => User[]],
	PanelWithDoctorName[]
>(selectPanels, selectUsers, (panels, users): PanelWithDoctorName[] =>
	panels.map(panel => ({
		...panel,
		doctors: panel.doctors.map(doctor => {
			const doctorData = users.find(user => user.username === doctor.username)
			return {
				...doctor,
				name: doctorData?.name,
				surname: doctorData?.surname,
			}
		}),
	})),
)

export const selectPanelsStoreIds = createSelector<
	[(state: RootState) => Panel[]],
	Id[]
>(selectPanels, compose<[Panel[]], Id[], Id[]>(uniq, flatMap('stores')))

export const selectPanelsWithQueueStoreIds = createSelector<
	[(state: RootState) => Panel[]],
	Id[]
>(
	selectPanels,
	compose<[Panel[]], Panel[], Id[], Id[]>(
		uniq,
		flatMap('stores'),
		filter<Panel>('hasQueue'),
	),
)

export const selectPanel = createSelector<
	[
		(state: RootState) => Panel[],
		(_: RootState, patientId?: string) => string | undefined,
	],
	Panel | undefined
>(
	selectPanels,
	(_, panelId) => panelId,
	(panels, panelId) => panels?.find(({ _id }) => _id === panelId),
)

export const selectDoctorPanel = createSelector<
	[(state: RootState) => string, (state: RootState) => Panel[]],
	Panel[]
>(selectUsername, selectPanels, (username, panels) =>
	panels.filter(panel =>
		panel.doctors.find(doctor => doctor.username === username),
	),
)

export const selectPanelsByDoctorUsername = createSelector<
	[(state: RootState) => Panel[], (_: RootState, username: string) => string],
	Panel[]
>(
	selectPanels,
	(_, username) => username,
	(panels, username) =>
		panels.filter(panel =>
			panel.doctors.find(doctor => doctor.username === username),
		),
)

export const selectDoctorPanelsStoresIds = createSelector<
	[(state: RootState) => Panel[]],
	string[]
>(selectDoctorPanel, doctorPanels =>
	doctorPanels.flatMap(({ stores }) => stores),
)

export const selectPanelsByStoreId = createSelector<
	[(state: RootState) => Panel[], (_: RootState, storeId: string) => string],
	Panel[]
>(
	selectPanels,
	(_, storeId) => storeId,
	(panels, storeId) =>
		panels.filter(panel => panel.stores.find(store => store === storeId)),
)

export const selectPanelsByStoreIds = createSelector<
	[
		(state: RootState) => Panel[],
		(state: RootState) => boolean,
		(_: RootState, storeIds: string[] | undefined) => string[] | undefined,
	],
	Panel[]
>(
	selectPanels,
	selectIsGlobalAdmin,
	(_, storeIds) => storeIds,
	(panels, isGlobalAdmin, storeIds) => {
		if (isGlobalAdmin) {
			return panels.filter(({ stores }) => !isEmpty(stores))
		}

		if (!storeIds) {
			return []
		}

		return panels.filter(({ stores }) =>
			stores.some(storeId => storeIds.includes(storeId)),
		)
	},
)

export const selectIsPanelHasQueueByStoreId = createSelector<
	[(state: RootState, storeId: string) => Panel[]],
	boolean
>(selectPanelsByStoreId, panels => !!panels.find(panel => panel.hasQueue))

export const selectUserQueuePanelsIds = createSelector(
	selectUsername,
	selectUserRole,
	selectStoreId,
	selectPanels,
	(currentUsername, userRole, selectedStoreId, panels) => {
		return panels
			.filter(panel => {
				const hasQueue = panel.hasQueue

				switch (userRole) {
					case 'Technician': {
						const matchesStore = selectedStoreId
							? panel.stores.includes(selectedStoreId)
							: true
						return hasQueue && matchesStore
					}
					case 'Doctor': {
						const matchesUser = panel.doctors.some(
							({ username }) => username === currentUsername,
						)
						return hasQueue && matchesUser
					}
					default:
						return hasQueue
				}
			})
			.map(panel => panel._id)
	},
)
