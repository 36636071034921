import { timeoutsApi } from '../../services/timeouts'
import { AppThunk, TeloDispatch } from '../../store'
import { slice } from './slice'

export const getTimeouts = (): AppThunk => async (dispatch: TeloDispatch) => {
	dispatch(timeoutsApi.endpoints.getTimeouts.initiate()).then(result => {
		if ('data' in result) {
			const remoteConfig = result.data?.[0]

			remoteConfig && dispatch(slice.actions._setTimeout(remoteConfig))
		}
	})
}

const timeoutsActions = {
	...slice.actions,
	getTimeouts,
}

export default timeoutsActions
