import i18n from 'i18next'
import { fr, enUS } from 'date-fns/locale'

export const formatName = ({
	name,
	surname,
}: {
	name: string
	surname: string
}): string => {
	return `${name} ${surname}`
}

export const formatAddress = ({
	address,
	postalCode,
	province,
	city,
}: {
	address?: string
	postalCode?: string
	province?: string
	city?: string
}): string => {
	const addressParts = []
	if (address) {
		addressParts.push(address)
	}
	if (postalCode) {
		addressParts.push(postalCode)
	}
	if (province) {
		addressParts.push(province)
	}
	if (city) {
		addressParts.push(city)
	}
	return addressParts.join(', ')
}

export const getDateFnsLocale = () => {
	const locale = i18n.language
	switch (locale) {
		case 'fr':
		case 'fr-FR':
			return fr
		default:
			return enUS
	}
}
