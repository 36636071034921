import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from './common'
import { DifListItem } from '../components/DigitalIntakeForm/types'
import {
	HousingStatus,
	HousingStatusBody,
	PatchHousingStatusBody,
} from '../model/housingStatus'

export interface HousingStatusDifList extends DifListItem {
	isDisabled: boolean
}

export const housingStatusesApi = createApi({
	reducerPath: 'housingStatusesApi',
	tagTypes: ['HousingStatus'],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getHousingStatusesForDropdown: builder.query<HousingStatusDifList[], void>({
			query: () => ({
				url: 'v1/housing-status',
			}),
			transformResponse: (response: HousingStatus[]): HousingStatusDifList[] =>
				response
					? response.map(status => ({
							label: status.description,
							value: status.code,
							isDisabled: !status.enabled,
					  }))
					: [],
			providesTags: ['HousingStatus'],
		}),

		getHousingStatuses: builder.query<HousingStatus[], void>({
			query: () => ({
				url: 'v1/housing-status',
			}),
			providesTags: ['HousingStatus'],
		}),

		createHousingStatus: builder.mutation<HousingStatus, HousingStatusBody>({
			query: body => ({
				url: 'v1/housing-status',
				method: 'POST',
				body,
			}),
			invalidatesTags: ['HousingStatus'],
		}),

		updateHousingStatus: builder.mutation<
			HousingStatus,
			{ id: string; body: PatchHousingStatusBody }
		>({
			query: ({ id, body }) => ({
				url: `v1/housing-status/${id}`,
				method: 'PATCH',
				body,
			}),
			invalidatesTags: ['HousingStatus'],
		}),

		toggleHousingStatusEnabled: builder.mutation<
			HousingStatus,
			{ id: string; enabled: boolean; modifiedByUserId: string }
		>({
			query: ({ id, enabled, modifiedByUserId }) => ({
				url: `v1/housing-status/${id}`,
				method: 'PATCH',
				body: { enabled, modifiedByUserId },
			}),
			invalidatesTags: ['HousingStatus'],
		}),
	}),
})

export const {
	useGetHousingStatusesQuery,
	useGetHousingStatusesForDropdownQuery,
	useCreateHousingStatusMutation,
	useUpdateHousingStatusMutation,
	useToggleHousingStatusEnabledMutation,
} = housingStatusesApi
