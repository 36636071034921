import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { string } from 'yup'

import { AuthState } from '../../model/model'

type QueueState = {
	noDoctorAvailableModalInfo: {
		show: boolean
		examId: string
		patient: {
			name: string
			surname: string
		}
	}
	assignedExams: {
		examId: string
		patientName: string
		doctorName: string
	}[]
	timedoutExams: {
		examId: string
		patientName: string
		doctors: string[]
	}[]
}
const initialState: QueueState = {
	noDoctorAvailableModalInfo: {
		show: false,
		examId: '',
		patient: {
			name: '',
			surname: '',
		},
	},
	assignedExams: [],
	timedoutExams: [],
}

export const slice = createSlice({
	name: 'queue',
	initialState,
	reducers: {
		setNoDoctorAvailableModalInfo: (
			state,
			{ payload }: PayloadAction<QueueState['noDoctorAvailableModalInfo']>,
		) => {
			state.noDoctorAvailableModalInfo = payload
		},
		addExamAssigned: (
			state,
			{ payload }: PayloadAction<QueueState['assignedExams'][0]>,
		) => {
			state.assignedExams.push(payload)
		},
		removeExamAssigned: (
			state,
			{ payload }: PayloadAction<{ examId: string }>,
		) => {
			state.assignedExams = state.assignedExams.filter(
				exam => exam.examId !== payload.examId,
			)
		},
		addExamTimedOut: (
			state,
			{ payload }: PayloadAction<QueueState['timedoutExams'][0]>,
		) => {
			state.timedoutExams.push(payload)
		},
		removeExamTimedOut: (
			state,
			{ payload }: PayloadAction<{ examId: string }>,
		) => {
			state.timedoutExams = state.timedoutExams.filter(
				exam => exam.examId !== payload.examId,
			)
		},
	},
})

export default slice.reducer
