import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ordersSliceName } from './constants'
import { ComponentName, TestValues } from '../../model/model'

type OrdersState = {
	selectedTests: string[]
	testToDelete: string[]
	testsValues: TestValues
	initialTestsValues: TestValues
	errors: boolean
}

const initialState: OrdersState = {
	selectedTests: [],
	testToDelete: [],
	testsValues: {} as TestValues,
	initialTestsValues: {} as TestValues,
	errors: false,
}

export const ordersSlice = createSlice({
	name: ordersSliceName,
	initialState,
	reducers: {
		setSelectedTests: (state, action: PayloadAction<string[]>) => {
			state.selectedTests = action.payload
		},
		setTestToDelete: (state, action: PayloadAction<string[]>) => {
			state.testToDelete = action.payload
		},
		setTestsValues: (
			state,
			{ payload }: PayloadAction<{ key: ComponentName; values: any }>,
		) => {
			const { key, values } = payload

			if (!state.initialTestsValues.hasOwnProperty(key)) {
				state.initialTestsValues = {
					...state.initialTestsValues,
					[key]: values,
				}
			}

			state.testsValues = {
				...state.testsValues,
				[key]: values,
			}
		},
		setTestsErrors: (state, { payload }: PayloadAction<boolean>) => {
			state.errors = payload
		},
	},
})

export const orders = ordersSlice.reducer
