import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DiagnosisCatalogData } from '../../model/exam'
import { DiagnosisState } from '../../model/model'

const initialState: DiagnosisState = {
	catalog: [],
}

export const slice = createSlice({
	name: 'diagnosis',
	initialState,
	reducers: {
		_loadDiagnosisICDCatalog: (
			state,
			{ payload }: PayloadAction<DiagnosisCatalogData[]>,
		) => {
			state.catalog = payload
		},
	},
})

export default slice.reducer
