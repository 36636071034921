import {
	selectAppLang,
	selectOnline,
	selectSessionId,
	selectStoreId,
} from '../features/app/selectors'
import { selectAuthToken } from '../features/auth/selectors'
import errorsActions from '../features/errors/actions'
import notificationsActions from '../features/notifications/actions'
import { ErrorOrigin, HttpError, HttpErrorCode } from '../model/model'
import store from '../store'
import { handle401Error } from './auth'

const SUCCESS_CODES = [200, 201, 204]

export const isHttpError = (error: unknown): error is HttpError =>
	typeof error === 'object' &&
	error !== null &&
	!('isSuccessCode' in error) &&
	'status' in error &&
	'message' in error &&
	'internalUrl' in error &&
	'origin' in error &&
	'externalUrl' in error

const isJsError = (error: unknown): error is Error => error instanceof Error

const decodeStatus = (status: string | number): HttpErrorCode => {
	const statusNumber = Number(status)
	switch (statusNumber) {
		case 400:
		case 401:
		case 403:
		case 404:
		case 405:
		case 408:
		case 500:
			return statusNumber
		default:
			return 500
	}
}

const decodeOrigin = (origin: string | undefined): ErrorOrigin => {
	switch (origin) {
		case 'internal-api':
		case 'internal-ui':
		case 'external':
		case 'WCS':
		case 'unknown':
			return origin
		default:
			return 'unknown'
	}
}

export const getCommonHeaders = ({
	addContentType = false,
}: {
	addContentType?: boolean
}) => {
	const state = store.getState()
	const token = selectAuthToken(state)
	const lang = selectAppLang(state)
	const storeId = selectStoreId(state)
	const sessionId = selectSessionId(state)

	let headers: Record<string, string> = {
		accept: 'application/json',
		authorization: `Bearer ${token}`,
		lang: lang || 'en-US',
		'x-store': storeId || '0',
		'session-id': sessionId,
	}

	if (addContentType === true) {
		headers['Content-type'] = 'application/json'
	}

	return headers
}

export const decodeRes = async (res: Response) => {
	const isSuccessCode = SUCCESS_CODES.includes(res.status)
	if (res.status === 204) {
		// - If res status is 204 the API call succeded but there is no data sent back in the response body, so res.json() will fail
		return {
			status: 204,
			message: 'No Content',
			internalUrl: res.url,
			externalUrl: '',
			origin: 'internal-api',
			stack: '',
			isSuccessCode: isSuccessCode,
		}
	}
	try {
		const parsedRes = await res.json()
		return parsedRes
	} catch (error: unknown) {
		// res.json() can fail if:
		// - The API call failed. In this case we keep res status and statusText that explain why
		// - The API call succeded but response is not a valid JSON. In this case we use custom status code and text
		// in these cases we construct an httpError objet to pass down
		const httpError: HttpError = {
			status: isSuccessCode ? 500 : decodeStatus(res.status),
			message:
				isSuccessCode && error instanceof Error
					? `The API response is not a valid JSON: ${error.message}`
					: res.statusText,
			internalUrl: res.url,
			externalUrl: '',
			origin: 'internal-api',
			stack: '',
		}
		return httpError
	}
}

export const decodeResMayNotBeJson = async <T>(
	res: Response,
	typeGuard: (x: any) => x is T,
): Promise<T | HttpError> => {
	const dataText = await res.text()

	let data: string | T = dataText
	let decodeErrorMessage = ''
	try {
		data = JSON.parse(dataText)
	} catch (e) {
		decodeErrorMessage = `The response ${dataText} is not a valid JSON`
	}

	if (
		SUCCESS_CODES.includes(res.status) &&
		!decodeErrorMessage &&
		typeGuard(data)
	) {
		return data
	}

	const httpError: HttpError = {
		status: SUCCESS_CODES.includes(res.status) ? 500 : decodeStatus(res.status),
		message: [res.statusText, data, decodeErrorMessage]
			.filter(a => a)
			.join(' - '),
		internalUrl: res.url,
		externalUrl: '',
		origin: 'internal-api',
		stack: '',
	}
	return httpError
}

let notificationErrorCount: number = 1
const LIMIT_COUNT: number = 5
const openNotification = (countEnabled: boolean) => {
	if (countEnabled)
		notificationErrorCount === LIMIT_COUNT
			? (notificationErrorCount = 1)
			: notificationErrorCount++
	return notificationErrorCount === 1
}

const handleFetchError = (
	countEnabled: boolean,
	error: unknown,
	nonBlockingErrorKey?: string,
	nonBlockingAutoCloseDelay?: number,
) => {
	// catch error in previous code
	return nonBlockingErrorKey
		? openNotification(countEnabled)
			? store.dispatch<any>(
					notificationsActions.addNotification({
						type: 'error',
						message: nonBlockingErrorKey,
						autoClose: true,
						errorType: 'http',
						messageIsLabelKey: true,
						autoCloseDelay: nonBlockingAutoCloseDelay,
					}),
			  )
			: undefined
		: isHttpError(error)
		? store.dispatch<any>(errorsActions.setHttpError(error))
		: isJsError(error)
		? store.dispatch<any>(errorsActions.setUiError(error))
		: store.dispatch<any>(
				errorsActions.setUiError(new Error('Unknown error in fetchJson')),
		  )
}

export const fetchBlob = (
	input: RequestInfo,
	init?: RequestInit,
	// if nonBlockingErrorKey is passed then errors trigger notifications instead of blocking errors
	nonBlockingErrorKey?: string,
	countEnabled: boolean = false,
	nonBlockingAutoCloseDelay?: number,
): Promise<void | Blob> => {
	const state = store.getState()
	if (!selectOnline(state)) {
		return new Promise(() => {})
	}
	const addContentType = init && init.method !== 'GET' ? true : false
	const headers = getCommonHeaders({ addContentType })

	const call = window
		.fetch(
			input,
			Object.assign(
				{
					headers,
				},
				init || {},
			),
		)
		.then(async res => {
			// API responded with an error
			// NOTE: API error response *must* comply to HttpError interface
			if (!SUCCESS_CODES.includes(res.status)) {
				const httpError: HttpError = {
					message: res.statusText,
					internalUrl: res.url,
					externalUrl: '',
					stack: '',
					nonBlockingErrorKey: nonBlockingErrorKey,
					status: decodeStatus(res.status),
					origin: 'internal-api',
				}
				return nonBlockingErrorKey
					? openNotification(countEnabled)
						? store.dispatch<any>(errorsActions.setHttpError(httpError))
						: undefined
					: store.dispatch<any>(errorsActions.setHttpError(httpError))
			}
			// Everything's fine
			notificationErrorCount = 1
			return await res.blob()
		})
		.catch((error: unknown) => {
			handleFetchError(
				countEnabled,
				error,
				nonBlockingErrorKey,
				nonBlockingAutoCloseDelay,
			)
		})

	return call
}

export const fetchJson = <T = void>(
	input: RequestInfo,
	init?: RequestInit,
	// if nonBlockingErrorKey is passed then errors trigger notifications instead of blocking errors
	nonBlockingErrorKey?: string,
	countEnabled: boolean = false,
	nonBlockingAutoCloseDelay?: number,
	notBlockingError403: boolean = false,
	ignoreError: boolean = false,
): Promise<T> => {
	const state = store.getState()

	if (!selectOnline(state)) {
		return new Promise(() => {})
	}

	const addContentType = init && init.method !== 'GET' ? true : false
	const headers = getCommonHeaders({ addContentType })

	return window
		.fetch(
			input,
			Object.assign(
				{
					headers,
				},
				init || {},
			),
		)
		.then(handle401Error)
		.then(res => {
			const decodedRes = decodeRes(res)
			return decodedRes.then((decodedRes: any) => {
				if (decodeStatus(res.status) === 403 && notBlockingError403) {
					return undefined
				}
				// httpError detected during JSON decoding
				if (isHttpError(decodedRes)) {
					return nonBlockingErrorKey
						? openNotification(countEnabled)
							? store.dispatch<any>(
									notificationsActions.addNotification({
										type: 'error',
										message: nonBlockingErrorKey,
										autoClose: true,
										errorType: 'http',
										messageIsLabelKey: true,
										autoCloseDelay: nonBlockingAutoCloseDelay,
									}),
							  )
							: undefined
						: store.dispatch<any>(errorsActions.setHttpError(decodedRes))
				}

				// API responded with an error
				// NOTE: API error response *must* comply to HttpError interface
				if (!SUCCESS_CODES.includes(res.status)) {
					const httpError: HttpError = {
						message: res.statusText,
						internalUrl: res.url,
						externalUrl: '',
						stack: '',
						nonBlockingErrorKey: nonBlockingErrorKey,
						...decodedRes,
						status: decodeStatus(res.status),
						origin: decodeOrigin(decodedRes.origin),
					}
					return nonBlockingErrorKey
						? openNotification(countEnabled)
							? store.dispatch<any>(errorsActions.setHttpError(httpError))
							: undefined
						: store.dispatch<any>(errorsActions.setHttpError(httpError))
				}
				// Everything's fine
				notificationErrorCount = 1
				return decodedRes as T
			})
		})
		.catch((error: unknown) => {
			if (ignoreError) {
				return error
			}

			return handleFetchError(
				countEnabled,
				error,
				nonBlockingErrorKey,
				nonBlockingAutoCloseDelay,
			)
		})
}
