import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SelectedUsersState } from '../../model/model'

const initialState: SelectedUsersState = {
	usersId: [],
}

export const slice = createSlice({
	name: 'selectedUsers',
	initialState,
	reducers: {
		_selectUser: (state, { payload }: PayloadAction<string>) => {
			state.usersId = state.usersId.includes(payload)
				? state.usersId.filter(userId => userId !== payload)
				: [...state.usersId, payload]
		},
		_removeSelectedUsers: state => {
			state.usersId = []
		},
	},
})

export default slice.reducer
