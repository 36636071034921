import { PrescriptionState } from '../../model/model'

export const prescriptionSliceName = 'prescription'

export const prescriptionInitialState: PrescriptionState = {
	updatePrescriptionNeeded: false,
	patientSignatureSource: undefined,
	loadingPrescription: false,
	contactLensesPresent: undefined,
	isPrescriptionEmpty: undefined,
	invalidExpiration: undefined,
}
