import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Id, ReferralDoctorsState } from '../../model/model'
import { ReferralDoctor } from '../../model/referralDoctors'

const initialState: ReferralDoctorsState = {}

export const slice = createSlice({
	name: 'referralDoctors',
	initialState,
	reducers: {
		_loadStoreReferralDoctors: (
			state,
			{
				payload,
			}: PayloadAction<{ storeId: string; referralDoctors: ReferralDoctor[] }>,
		) => {
			state[payload.storeId] = payload.referralDoctors
		},
		_setReferralDoctor: (state, { payload }: PayloadAction<ReferralDoctor>) => {
			state[payload.store]
				? state[payload.store].splice(
						state[payload.store].findIndex(({ _id }) => _id === payload._id),
						1,
						payload,
				  )
				: (state[payload.store] = [payload])
		},
		_removeReferralDoctor: (
			state,
			{
				payload,
			}: PayloadAction<{
				storeId: string
				referralDoctorId: Id
			}>,
		) => {
			const index = state[payload.storeId]?.findIndex(
				r => r._id === payload.referralDoctorId,
			)
			if (index >= 0) state[payload.storeId].splice(index, 1)
		},
	},
})

export default slice.reducer
