import { useEffect } from 'react'
import {
	connectToNotifications,
	sendDoctorStartedNotification as sendDoctorStartedNotificationAction,
	sendNewExamNotification as sendNewExamNotificationAction,
} from '../features/socket/notifications/notificationsAction'
import { useTeloDispatch } from '../store'

const useNotification = () => {
	const dispatch = useTeloDispatch()
	useEffect(() => {
		dispatch(connectToNotifications())
	}, [dispatch])
}

export const useSendNotifications = () => {
	const dispatch = useTeloDispatch()

	const sendDoctorStartedNotification = (username: string): void => {
		dispatch(sendDoctorStartedNotificationAction(username))
	}

	const sendNewExamNotification = (username: string, isRemote: boolean) => {
		dispatch(sendNewExamNotificationAction(username, isRemote))
	}

	return { sendDoctorStartedNotification, sendNewExamNotification }
}

export default useNotification
