import { RootState } from '../../model/model'

export const selectHttpErrors = (state: RootState) => state.errors.http
export const selectUiErrors = (state: RootState) => state.errors.ui
export const selectLoginError = (state: RootState) => state.errors.login
export const selectMissingAuthErrors = (state: RootState) =>
	state.errors.missingAuth
export const invalidValuesError = (state: RootState) =>
	state.errors.invalidValues
export const resetErrors = (state: RootState) => state.errors.resetErrors
