import { createApi } from '@reduxjs/toolkit/query/react'

import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from './common'
import { ServiceBaseUrl } from './serviceApi'
import { ServicesApiTag, ServicesReducerPath } from './constants'
import { getSystemHeaders } from '../libs/system'
import { CodingOrdersCPT } from '../model/cpt'

export const codingApi = createApi({
	reducerPath: ServicesReducerPath.CodingApi,
	tagTypes: [ServicesApiTag.Coding],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getOrdersByExamId: builder.query<CodingOrdersCPT[], { examId: string }>({
			query: ({ examId }) => ({
				url: `${ServiceBaseUrl.Coding}/orders/exam/${examId}`,
				headers: getSystemHeaders(),
			}),
		}),
	}),
})

export const useGetCodingOrdersByExamIdQuery = (
	params: { examId: string },
	skip?: boolean,
) => {
	return codingApi.useGetOrdersByExamIdQuery(params, {
		skip: skip || !params.examId,
	})
}
