import { getServingURL } from './readEnv'

/**
 * Prefix the asset URL with a relative path in case of Connect SPA build.
 * In case of MFEs, prefix it with the URL of the web server the MFE is served from,
 * otherwise the browser will try to load it from the host application web server
 *
 * @param assetUrl the relative or absolute URL path
 * @returns the URL to be used to download such asset
 */
export function prefixWithURL(assetUrl: string): string {
	const servingUrl = getServingURL()

	if (!servingUrl) {
		return assetUrl
	}
	// transform ""./my/url" and "/another/url" to "my/url" and "another/url" respectively
	const cleared = assetUrl.replace(/^\.?\//g, '')

	return `${servingUrl}/${cleared}`
}
