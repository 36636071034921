import React from 'react'

import { selectUserRole } from '../features/auth/selectors'
import { selectExam } from '../features/exams/selectors'
import { getUserDashboard } from '../libs/auth'
import { ExamStatus } from '../model/exam'
import { TeloNavigateTo } from '../routing/TeloNavigateTo'
import { useTeloRouter } from '../routing/teloRouter'
import { useTeloSelector } from '../store'

const mapPathsToStatus: { [k: string]: ExamStatus[] } = {
	'/store/:storeId/pretest/:examId': [
		'IntakeFormEnded',
		'PreTestStarted',
		'Paused',
	],
	'/exam-attribution/:examId': [
		'IntakeFormEnded', // TEL-1956
		'PreTestStarted',
		'PreTestFinished',
		'Paused',
		'LensTrialQuit',
		'DoctorEndedWithLensTrial',
		'RefractionistStarted',
		'RefractionistEnded',
	],
	'/refractionist/call/:examId': ['RefractionistStarted'],
	'/refractionist/exam/:examId': ['RefractionistStarted'],
	'/doctor/call/:examId': [
		'RefractionistEnded',
		'DoctorModeSelected',
		'DoctorStarted',
		'Paused',
	],
	'/doctor/exam/:examId': [
		'RefractionistEnded',
		'DoctorModeSelected',
		'DoctorStarted',
		'Paused',
		// Added this state for tel-2287
		'Uncompleted',
		// Added this two states for tel-1844
		'Interrupted',
		'Waiting',
		// Adding ending states so to support 1681
		'Ended',
		'DoctorEndedWithLensTrial',
		'LensTrialQuit',
		'DoctorEnded',
	],
}

const CheckExamStatusAndPathMatch: React.FC<{ paths: string[] }> = ({
	paths,
}) => {
	const { readRouteParams, getLocation } = useTeloRouter()
	const { examId, storeId } = readRouteParams(
		{ name: 'examId', required: false },
		{ name: 'storeId', required: false },
	)
	const userRole = useTeloSelector(selectUserRole)
	const exam = useTeloSelector(selectExam(examId || ''))
	const { state }: { state: { fromDoctorSelectionPage?: boolean } } =
		getLocation()
	const fromDoctorSelect = (state && state.fromDoctorSelectionPage) || false
	const isExamPageCoherentWithExamStatus =
		!exam ||
		(exam &&
			paths.every(
				path =>
					!mapPathsToStatus[path] ||
					!mapPathsToStatus[path].length ||
					mapPathsToStatus[path].includes(exam.status),
			))
	const isExamRefractionistInterrupted =
		!!exam &&
		exam?.history[exam?.history.length - 1] &&
		exam?.history[exam?.history.length - 1].currentStatus === 'Interrupted' &&
		exam?.history[exam?.history.length - 1].prevStatus ===
			'RefractionistStarted' &&
		paths.every(path =>
			['/refractionist/call/:examId', '/refractionist/exam/:examId'].includes(
				path,
			),
		)
	if (
		!isExamPageCoherentWithExamStatus &&
		!isExamRefractionistInterrupted &&
		!fromDoctorSelect
	) {
		return <TeloNavigateTo to={getUserDashboard(userRole, storeId!)} />
	} else {
		return null
	}
}

export default CheckExamStatusAndPathMatch
function readRouteParams(
	arg0: { name: string; required: boolean },
	arg1: { name: string; required: boolean },
): { examId: any; storeId: any } {
	throw new Error('Function not implemented.')
}
