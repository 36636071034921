import React from 'react'
import styled from 'styled-components'
import { Spinner as CircularProgress } from '../styleguide/icons/Spinner'
import { ChildrenProps, WaiterConfigProps } from './utilityTypes'

const WaiterContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`

type ChildrenOrError = WaiterConfigProps & {
	renderChildrenWhen: boolean
	error?: boolean
}

type WaiterProps = ChildrenProps & ChildrenOrError

export const Waiter = ({
	children,
	renderChildrenWhen,
	error,
	hideWaiter,
	waiterSize,
}: WaiterProps) => {
	if (error) {
		return null
	}
	if (renderChildrenWhen) {
		return <>{children}</>
	}
	if (hideWaiter) {
		return null
	}
	return (
		<WaiterContainer className={`mfeWaiter ${waiterSize ?? 'regular'}`}>
			<CircularProgress />
		</WaiterContainer>
	)
}
