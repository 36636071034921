import { AppThunk, TeloDispatch } from '../../store'
import { slice } from './slice'

const setSummaryActiveTab =
	(activeTab: number): AppThunk =>
	(dispatch: TeloDispatch) =>
		dispatch(slice.actions.setSummaryActiveTab(activeTab))

const uiActions = {
	...slice.actions,
	setSummaryActiveTab,
}

export const { setDoctorExamPageActivePanel } = uiActions

export default uiActions
