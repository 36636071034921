import React, { Suspense } from 'react'
import { connect } from 'react-redux'
import { RootState, UiError } from '../../model/model'
import { selectHttpErrors, selectUiErrors } from './selectors'
import { TeloDispatch } from '../../store'
import ErrorPage from '../../pages/ErrorPage'
import CircularProgress from '../../styleguide/CircularProgress'

type UIErrorsProps = {
	uiErrors: UiError[]
	children?: React.ReactNode
}

const UIErrors = ({ uiErrors, children }: UIErrorsProps) => {
	if (!uiErrors.length) {
		return <React.Fragment>{children}</React.Fragment>
	} else {
		return (
			<Suspense fallback={<CircularProgress />}>
				<ErrorPage />
			</Suspense>
		)
	}
}

const mapStateToProps = (state: RootState) => ({
	httpErrors: selectHttpErrors(state),
	uiErrors: selectUiErrors(state),
})

const mapDispatchToProps = (dispatch: TeloDispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(UIErrors)
