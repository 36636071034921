import { FileExtension, FileType, imageFileExtensions } from './constants'

export const getFileExtension = (fileName: string): FileExtension | null => {
	const [ext] = fileName.split('.').slice(-1)

	return ext in FileExtension ? (ext as FileExtension) : null
}

export const getIsFileAnImage = (fileName?: string): boolean => {
	if (!fileName) {
		return false
	}

	const ext = getFileExtension(fileName)

	return imageFileExtensions.some(imgExt => imgExt === ext)
}

export const getFileNameWithoutExtension = (name: string) =>
	name.substring(0, name.lastIndexOf('.')) || name

export const getBlobForPdf = async (response: Response): Promise<Blob> => {
	const byteArray = await response.arrayBuffer()

	return new Blob([byteArray], { type: FileType.pdf })
}

export const getBlobFromResponse = async (
	ref: string,
	response: Response,
): Promise<Blob> => {
	const ext = getFileExtension(ref)

	if (ext === FileExtension.pdf) {
		return await getBlobForPdf(response)
	}

	return await response.blob()
}

export const downloadFile = (url: string, name: string): void => {
	const link = document.createElement('a')

	link.href = url
	link.download = name
	link.click()
	link.remove()
}
