import { AppThunk } from '../../store'
import { slice } from './slice'

const closeChatbot = (): AppThunk => dispatch => {
	dispatch(slice.actions.setICDToRecommend(undefined))
}

const chatbotActions = { ...slice.actions, closeChatbot }

export default chatbotActions
