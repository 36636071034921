import {
	AvailableGraph,
	CalculatedAxialLengthData,
	CalculatedSphereEqData,
} from '../components/DoctorRefractionTab/graphs/interfaces/graph.interface'
import {
	AccuracyKey,
	HOrient,
	InstrumentRemoteMedia,
	PhoropterDataPart,
	TrendsOverTimeInstrument,
	VOrient,
} from './instruments'
import { Id } from './model'
import {
	PrescriptionComputer,
	PrescriptionContactLenses,
	PrescriptionGeneralUse,
	PrescriptionNight,
	PrescriptionReading,
	PrescriptionSport,
	PrescriptionSunglasses,
} from './prescription'

export type SelectedMediaMap = {
	[key in
		| ClinicalDataSection
		| SharePrescriptionToCockpitKeyOtherData
		| SharePrescriptionToCockpitKey
		| DiseasesAndTreatmentsSection]: SelectedMedia
}

export type VideoStatusType = {
	startTime?: number
	endTime?: number
	startFlag?: number
	endFlag?: number
}

export type CockpitState = {
	screenshareFrame: string
	selectedMedia: SelectedMediaMap
	sharingMedia: SelectedMedia
	sharingOn: string
	fullscreen?: Partial<InstrumentRemoteMedia>
	videoStatus?: VideoStatusType
	sharingGraph?: SelectedGraphData
	totGraph?: string
}

export const clinicalDataSections = [
	'topography',
	'tonometer',
	'crystallineLens',
	'visualFields',
	'videoSlitLamp',
	'retinalImaging',
	'visionSimulation',
	'trendsOverTime',
] as const

export type ClinicalDataSection = (typeof clinicalDataSections)[number]

export type ClinicalDataSectionProps = {
	examId: Id
	handleShare: (share: boolean) => void
}

export const diseasesAndTreatmentsSection = [
	'cataract',
	'cornea',
	'externalDiseases',
	'glaucoma',
	'pediatricsStrabismus',
	'refractiveErrors',
	'retina',
] as const

export type DiseasesAndTreatmentsSection =
	(typeof diseasesAndTreatmentsSection)[number]

export type CockpitShareSectionMessage =
	| 'start-sharing-section'
	| 'stop-sharing-section'

export type CockpitShareDataMessage = 'start-sharing-data' | 'stop-sharing-data'

export type CockpitControlVideoMessage = 'play-video' | 'pause-video'

export type SectionPayload = {
	name:
		| ClinicalDataSection
		| SharePrescriptionToCockpitKeyOtherData
		| SharePrescriptionToCockpitKey
		| DiseasesAndTreatmentsSection
		| ShareMyopiaData
		| TrendsOverTimeData
}

export type SelectedMedia = {
	OD: null | Partial<InstrumentRemoteMedia>
	OS: null | Partial<InstrumentRemoteMedia>
	both: null | Partial<InstrumentRemoteMedia>
}

export type TopographyDataPayload = SelectedMedia

export type SharePrescriptionToCockpitKey =
	| 'prescription-contact-lenses'
	| 'prescription-contact-lenses-select'
	| 'prescription-general-use'
	| 'prescription-sunglasses'
	| 'prescription-computer'
	| 'prescription-reading'
	| 'prescription-night'
	| 'prescription-sport'
	| 'prescription-safety'
	| ''

export type SharePrescriptionToCockpitKeyOtherData =
	| 'prescription-contact-lenses-other'
	| 'prescription-general-use-other'
	| 'prescription-sunglasses-other'
	| 'prescription-computer-other'
	| 'prescription-reading-other'
	| 'prescription-night-other'
	| 'prescription-sport-other'
	| 'prescription-safety-other'
	| ''

export type ShareClinicalContentSummary = 'clinical-content-summary'

export type ShareMyopiaData =
	| 'myopia-refraction-analysis'
	| 'myopia-axial-length-analysis'
	| 'myopia-refraction'
	| ''
	| 'myopia-summary'
	| 'myopia-summary-zoom'

export type TrendsOverTimeData = 'trends-over-time' | 'trends-over-time-zoom'

export type PrescriptionShareContent =
	| ((
			| PrescriptionGeneralUse
			| PrescriptionSunglasses
			| PrescriptionComputer
			| PrescriptionReading
			| PrescriptionNight
			| PrescriptionSport
	  ) & {
			accuracy: AccuracyKey
	  })
	| PrescriptionContactLenses
	| PhoropterDataPart

export type ClinicalContentSummaryEye = {
	sphere?: string
	cylinder?: string
	axis: string
	add: string
	hPrism: string
	hOrient: HOrient
	vPrism: string
	vOrient: VOrient
	topographyImgUrl?: string
	slitLampImgUrl?: string
	retinalImagingImgUrl?: string
}

export type ClinicalContentSummaryPayload = {
	OD: ClinicalContentSummaryEye
	OS: ClinicalContentSummaryEye
}

export type ClinicalContentSummary = 'clinical-content-summary'

export type PayloadContent =
	| TopographyDataPayload
	| PrescriptionShareContent
	| ClinicalContentSummaryPayload
	| TrendsOverTimeGraphData
	| SelectedGraphData

export type DataPayload = {
	section:
		| ClinicalDataSection
		| SharePrescriptionToCockpitKey
		| SharePrescriptionToCockpitKeyOtherData
		| DiseasesAndTreatmentsSection
		| ClinicalContentSummary
		| ShareMyopiaData
		| TrendsOverTimeData
		| ClinicalContentSummary
	content: PayloadContent
}

export type CockpitStatus = {
	active: boolean
	disabled: boolean
}

export type CockpitContentItem = {
	label: string
	type: 'video' | 'image' | 'text'
	value: string
}

export type CockpitContentAddon = {
	label: string
	items: CockpitContentItem[] | CockpitContentAddon[]
}

export type CockpitContent = {
	product: string
	addons: CockpitContentAddon[]
}

export type MyopiaGraphEye = 'L' | 'R' | 'B'

export type SelectedGraphData = {
	eye?: MyopiaGraphEye
	right?: CalculatedAxialLengthData | CalculatedSphereEqData
	left?: CalculatedAxialLengthData | CalculatedSphereEqData
	graph?: AvailableGraph
}

export type MyopiaSummaryGraph = SelectedGraphData & {
	type?: 'BI' | 'PH'
}

export type instrumentType = 'sphere' | 'cyl' | 'spheq' | 'add'

export type TrendsOverTimeGraphData = {
	instrument?: instrumentType
	tot: TrendsOverTimeInstrument[]
}
