import React from 'react'
import { useTranslation } from 'react-i18next'
import { selectUserRole } from '../features/auth/selectors'
import dialogActions from '../features/dialog/actions'
import { selectDialog } from '../features/dialog/selectors'
import examsActions from '../features/exams/actions'
import { ChangeStatusWarningDialogType } from '../model/dialog'
import { useTeloDispatch, useTeloSelector } from '../store'
import Button from '../styleguide/buttons/Button'
import DialogActions from '../styleguide/dialog/DialogActions'
import DialogContent from '../styleguide/dialog/DialogContent'

const isChangeStatusDialog = (
	dialog: any,
): dialog is ChangeStatusWarningDialogType =>
	dialog.type === 'changeStatusWarningDialog'

const ChangeStatusWarningDialog = () => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()
	const dialog = useTeloSelector(selectDialog)
	const role = useTeloSelector(selectUserRole)

	return !isChangeStatusDialog(dialog) ? null : (
		<>
			<DialogContent>
				<div>{t('app.localTechnicianStatusWarningTitle')}</div>
				<br />
				<div>{t('app.localTechnicianStatusWarningDescription')}</div>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => dispatch(dialogActions.closeDialog())}
					variant="outlined"
				>
					{t('app.cancel')}
				</Button>
				<Button
					onClick={() => {
						dispatch(
							examsActions.setExamStatus(
								{
									id: dialog.examId,
									status: dialog.status,
									username: dialog.username,
								},
								role,
							),
						).finally(() => dispatch(dialogActions.closeDialog()))
					}}
					autoFocus
					variant="contained"
				>
					{t('app.proceed')}
				</Button>
			</DialogActions>
		</>
	)
}

export default ChangeStatusWarningDialog
