import React from 'react'

import { SvgProps } from '../../model/types'

const EditIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="currentColor"
			{...props}
		>
			<path d="M11.58 2.52499C12.1966 1.90837 13.1762 1.87592 13.831 2.42763L13.937 2.52499L17.4725 6.06052C18.0891 6.67714 18.1216 7.65671 17.5699 8.31156L17.4725 8.41757L8.87779 17.0123C8.59994 17.2902 8.23412 17.4598 7.84581 17.494L7.69927 17.5005H3.33874C2.90492 17.5005 2.54771 17.1722 2.50201 16.7505L2.49707 16.6588V12.2982C2.49707 11.9054 2.63581 11.5268 2.88616 11.2279L2.98522 11.1197L11.58 2.52499ZM10.1418 6.32018L4.16374 12.2982V15.8338H7.69927L13.6774 9.85574L10.1418 6.32018ZM12.7585 3.7035L11.3203 5.14167L14.8559 8.67724L16.294 7.23903L12.7585 3.7035Z" />
		</svg>
	)
}

export default EditIcon
