import { doLogoutApi, getLoginTokenApi } from '../../apiCalls'
import appConfig from '../../config'
import { redirectToNam } from '../../libs/auth'
import { isHttpError } from '../../libs/fetchJson'
import {
	delIntersessionInfo,
	deleteEclipsMetadata,
	getEclipsMetadata,
	setIntersessionAppLoginData,
	deleteIntersessionInactivityModalShown,
} from '../../libs/intersession'
import { HttpError, LoginResponse } from '../../model/model'
import { TeloNavigationFn } from '../../routing/teloRouter'
import { AppThunk, AppThunkPromise, TeloDispatch } from '../../store'
import azureCommunicationActions from '../azureCommunication/actions'
import errorsActions from '../errors/actions'
import { deleteIntersessionRoomDevice } from './../../libs/intersession'

import { getDecodedTokenData } from './helpers'
import { slice } from './slice'
import { renewTokenApi } from '../../services/renewToken'

const convertErrorToText = (error: unknown): string => {
	if (typeof error === 'string') {
		return error
	}

	if (error instanceof Error) {
		return error.message
	}

	return 'Unknown error'
}

const getLoginTokenAction =
	(
		code: string,
		redirectUri: string,
		navigate: TeloNavigationFn,
		eclipsUsername?: string,
	) =>
	(dispatch: TeloDispatch) => {
		deleteIntersessionInactivityModalShown()

		dispatch(slice.actions.setAppLoginStatus('logging in'))
		getLoginTokenApi(code, redirectUri, eclipsUsername)
			.then((decodedRes: LoginResponse | HttpError) => {
				if (isHttpError(decodedRes)) {
					if (eclipsUsername !== undefined) {
						navigate('/logout')
						return
					}
					const isMissingUserError =
						decodedRes.status === 403 &&
						'message' in decodedRes &&
						typeof decodedRes.message === 'string' &&
						decodedRes.message.includes('is not a Connect User')
					return isMissingUserError
						? dispatch(authActions.setMissingUserError(decodedRes.message))
						: dispatch(errorsActions.setHttpError(decodedRes))
				}

				dispatch(errorsActions.resetLoginError())

				const token = decodedRes.token

				const appLoginData = getDecodedTokenData(token, eclipsUsername)

				dispatch(slice.actions.setAppLoginData(appLoginData))
				setIntersessionAppLoginData(appLoginData)
			})
			.catch((error: unknown) => {
				dispatch(
					errorsActions.setLoginError({
						message: 'unauthorized',
						details: convertErrorToText(error),
					}),
				)
			})
	}

const logoutAction =
	(preserveEclipsData: boolean = false): AppThunkPromise =>
	(dispatch: TeloDispatch) => {
		const eclipsMetadata = getEclipsMetadata()
		const isAppInsideEclips = !!eclipsMetadata

		console.log(
			'logoutAction, doing logout. preserveEclipsData:',
			preserveEclipsData,
			'. isAppInsideEclips:',
			isAppInsideEclips,
		)
		const logoutPromise =
			isAppInsideEclips !== true ? doLogoutApi() : Promise.resolve()

		return logoutPromise
			.catch(() => {
				// if token is expired the logout will fail with 401, just ignore it
			})
			.finally(() => {
				if (preserveEclipsData === false) {
					deleteEclipsMetadata()
				}
				console.log(
					'logoutAction, calling deleteIntersessionRoomDevice. preserveEclipsData:',
					preserveEclipsData,
				)
				delIntersessionInfo()
				deleteIntersessionRoomDevice()
				deleteIntersessionInactivityModalShown()

				dispatch(azureCommunicationActions.resetAzureCommunication())
				if (isAppInsideEclips !== true) {
					window.location.href = appConfig.logout
				} else {
					redirectToNam()
				}
			})
	}

export const setNewRefreshToken =
	(username: string, storeId?: string): AppThunk =>
	async (dispatch: TeloDispatch) => {
		try {
			dispatch(
				renewTokenApi.endpoints.updateRefreshToken.initiate({
					username,
					storeId,
				}),
			).then(result => {
				if ('data' in result) {
					const { token } = result.data

					const appLoginData = getDecodedTokenData(token)
					dispatch(slice.actions.setAppLoginData(appLoginData))
					setIntersessionAppLoginData(appLoginData)
				}
			})
		} catch (e: any) {
			dispatch(
				errorsActions.setLoginError({
					message: 'Renew access token is unsuccessful',
					details: `Access token error ${e.message}`,
				}),
			)
		}
	}

const authActions = {
	...slice.actions,
	getLoginTokenAction,
	logoutAction,
	setNewRefreshToken,
}

export default authActions
