import { useCallback, useEffect } from 'react'
import {
	connectToVisualSimulation,
	emitVisualSimulationShare,
} from '../features/socket/visualSimulation/visualSimulationActions'
import { Id } from '../model/model'
import { useTeloDispatch } from '../store'

const useVisualSimulation = (examId: Id) => {
	const dispatch = useTeloDispatch()

	useEffect(() => {
		dispatch(connectToVisualSimulation(examId))
	}, [examId, dispatch])

	const share = useCallback(
		(active: boolean) => {
			dispatch(emitVisualSimulationShare(active))
		},
		[dispatch],
	)

	return share
}

export default useVisualSimulation
