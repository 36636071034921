import {
	compose,
	cond,
	constant,
	flatMap,
	isEmpty,
	negate,
	set,
	some,
	stubTrue,
	values,
} from 'lodash/fp'

import {
	CLEvaluation,
	CLEvaluationValue,
	CLOverRefraction,
	CLOverRefractionValue,
	ContactLenses,
} from '../../model/exam'

import {
	ContactLenseConditionSection,
	ContactLensesConditionValues,
} from './types'
import { getConditionValueToUpdateByKeyAndPath } from './commonConditions'

export const formatOverRefractionValues = (
	conditionValues: ContactLensesConditionValues,
	formValues: ContactLenses,
	shouldReplaceValues: boolean,
): CLOverRefraction => {
	const getValueToUpdate = getConditionValueToUpdateByKeyAndPath(
		conditionValues,
		formValues,
		shouldReplaceValues,
		ContactLenseConditionSection.OverRefraction,
	)

	const updateOverRefractionValues = compose(
		set('OD.axis', getValueToUpdate('OD.axis')),
		set('OD.cylinder', getValueToUpdate('OD.cylinder')),
		set('OD.sphere', getValueToUpdate('OD.sphere')),
		set('OS.axis', getValueToUpdate('OS.axis')),
		set('OS.cylinder', getValueToUpdate('OS.cylinder')),
		set('OS.sphere', getValueToUpdate('OS.sphere')),
	)

	return cond<CLOverRefraction | undefined, CLOverRefraction>([
		[isEmpty, constant(formValues.overRefraction)],
		[stubTrue, constant(updateOverRefractionValues(formValues.overRefraction))],
	])(conditionValues.overRefraction)
}

export const formatEvaluationValues = (
	conditionValues: ContactLensesConditionValues,
	formValues: ContactLenses,
	shouldReplaceValues: boolean,
): CLEvaluation => {
	const getValueToUpdate = getConditionValueToUpdateByKeyAndPath(
		conditionValues,
		formValues,
		shouldReplaceValues,
		ContactLenseConditionSection.Evaluation,
	)

	const updateEvaluationValues = compose(
		set('OD.alignment', getValueToUpdate('OD.alignment')),
		set('OD.centration', getValueToUpdate('OD.centration')),
		set('OD.comfort', getValueToUpdate('OD.comfort')),
		set('OD.movement', getValueToUpdate('OD.movement')),
		set('OD.rotation', getValueToUpdate('OD.rotation')),
		set('OD.rotationDirection', getValueToUpdate('OD.rotationDirection')),
		set('OD.coverage', getValueToUpdate('OD.coverage')),
		set('OS.alignment', getValueToUpdate('OS.alignment')),
		set('OS.centration', getValueToUpdate('OS.centration')),
		set('OS.comfort', getValueToUpdate('OS.comfort')),
		set('OS.movement', getValueToUpdate('OS.movement')),
		set('OS.rotation', getValueToUpdate('OS.rotation')),
		set('OS.rotationDirection', getValueToUpdate('OS.rotationDirection')),
		set('OS.coverage', getValueToUpdate('OS.coverage')),
	)

	return cond<CLEvaluation | undefined, CLEvaluation>([
		[isEmpty, constant(formValues.evaluation)],
		[stubTrue, constant(updateEvaluationValues(formValues.evaluation))],
	])(conditionValues.evaluation)
}

export const formatContactLensesConditionValues = (
	conditionValues: ContactLensesConditionValues,
	formValues: ContactLenses,
	shouldReplaceValues: boolean,
): ContactLensesConditionValues => {
	const evaluation = formatEvaluationValues(
		conditionValues,
		formValues,
		shouldReplaceValues,
	)

	const overRefraction = formatOverRefractionValues(
		conditionValues,
		formValues,
		shouldReplaceValues,
	)

	return {
		evaluation,
		overRefraction,
	}
}

export const getShouldShowContactLensesConditionsDialog = compose<
	[[CLOverRefraction, CLEvaluation]],
	(string | null)[],
	boolean
>(
	some<string | null>(negate(isEmpty)),
	flatMap<CLOverRefraction | CLEvaluation, string | null>(
		compose<
			[CLOverRefraction | CLEvaluation],
			(CLOverRefractionValue | CLEvaluationValue)[],
			(string | null)[]
		>(flatMap(values), values),
	),
)

export const updateContactLensesFormValues = (
	tab: number,
	values: ContactLenses[],
	conditionValues: ContactLensesConditionValues,
	conditionId: string,
	shouldReplaceValues: boolean,
): ContactLenses[] => {
	const formattedCLValues = formatContactLensesConditionValues(
		conditionValues,
		values[tab],
		shouldReplaceValues,
	)

	return values.map((v, i) =>
		i === tab
			? {
					...v,
					...formattedCLValues,
					condition: conditionId,
					replaceValues: '',
			  }
			: v,
	)
}
