import {
	connectTeloSocket,
	disconnectTeloSocket,
	getTeloSocket,
} from './teloSocketRegistry'
import { TeloSocket } from './teloSocketTypes'

export type TeloSocketConnector = {
	connectSocket: (args: ConnectionArgs) => void
	getSocket: <T extends TeloSocket>() => T | null
}

export type ConnectionArgs = {
	socketKey: string
	newSocket: () => TeloSocket
}

export const newTeloSocketConnector = (): TeloSocketConnector => {
	let lastKey: string | null = null
	const connectSocket = ({ socketKey: newKey, newSocket }: ConnectionArgs) => {
		if (lastKey != null && newKey !== lastKey) {
			disconnectTeloSocket(lastKey)
		}
		if (newKey !== lastKey) {
			lastKey = newKey
			connectTeloSocket({
				socketKey: newKey,
				newSocket,
			})
		}
	}

	const getSocket = <T extends TeloSocket>(): T | null => {
		return lastKey != null ? getTeloSocket<T>(lastKey) : null
	}

	return {
		connectSocket,
		getSocket,
	}
}
