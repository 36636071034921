import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { VaScaleState } from '../../model/model'
import { VAScaleConversion } from '../../model/vaScales'

const initialState: VaScaleState = {
	scales: [],
}

export const slice = createSlice({
	name: 'vaScales',
	initialState,
	reducers: {
		_loadScales: (state, { payload }: PayloadAction<VAScaleConversion[]>) => {
			state.scales = payload
		},
	},
})

export default slice.reducer
