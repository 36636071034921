import React, { ErrorInfo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import config from '../config'
import authActions from '../features/auth/actions'
import {
	selectTroubleShootingDataTransfer,
	selectTroubleShootingLogin,
	selectTroubleShootingVideoConference,
} from '../features/troubleShooting/selectors'
import TroubleShooting from '../features/troubleShooting/TroubleShooting'
import { useFetchTroubleShooting } from '../hooks/useFetchTroubleShooting'
import { isGeminiRegion } from '../libs/region'
import { useTeloRouter } from '../routing/teloRouter'
import { useTeloDispatch, useTeloSelector } from '../store'
import Button from '../styleguide/buttons/Button'
import { HelpersWrapper, PageWrapper } from '../styleguide/CommonPageComponents'
import theme from '../styleguide/theme'
import { IMAGES } from '../utils/assetRegistry'
import { deleteIntersessionInactivityModalShown } from '../libs/intersession'

const Container = styled(PageWrapper)`
	background-color: ${theme.palette.background.secondary};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const Logo = styled.img`
	width: 33%;
	height: auto;
	display: block;
	margin-bottom: ${theme.spacing(4)};
`

const Error = styled.div`
	color: ${theme.palette.grey[500]};
	font-size: 0.8rem;
	width: 90%;
	margin: ${theme.spacing(5)} auto ${theme.spacing(4)};
	text-align: center;
	&:first-letter {
		text-transform: uppercase;
	}

	> * {
		margin-bottom: ${theme.spacing(2)};
	}

	details p {
		text-align: left;
	}
`

const Buttons = styled.div`
	display: flex;
	justify-content: center;
	> *:first-child {
		margin-right: ${theme.spacing(3)};
	}
`

const ErrorBoundaryPage = ({
	error,
	errorInfo,
}: {
	error: Error | null
	errorInfo: ErrorInfo | null
}) => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()
	const { navigate } = useTeloRouter()

	const troubleShootingVideoConference = useTeloSelector(
		selectTroubleShootingVideoConference,
	)
	const troubleShootingLogin = useTeloSelector(selectTroubleShootingLogin)
	const troubleShootingDataTransfer = useTeloSelector(
		selectTroubleShootingDataTransfer,
	)

	useFetchTroubleShooting()
	const logoUrl =
		config.region === 'GEMINI'
			? IMAGES.VISIONX_LOGO_URL
			: IMAGES.CONNECT_LOGO_URL
	return (
		<Container>
			<Logo alt="Luxottica Optometrist" src={logoUrl} />

			<Error>
				<div>{t('app.serverErrorMessage')}</div>
				<div>{error?.message || t('app.unknownError')}</div>
				<details>
					<summary>{t('app.details')}</summary>
					<div>{errorInfo?.componentStack}</div>
				</details>
			</Error>

			<Buttons>
				<Button onClick={() => window.location.reload()}>
					{t('app.reload')}
				</Button>

				<Button
					onClick={() => {
						console.log('calling logoutAction from ErrorBoundaryPage')
						dispatch(authActions.logoutAction())
						deleteIntersessionInactivityModalShown()
						navigate('/login')
						window.location.reload()
					}}
				>
					{t('app.backToLogin')}
				</Button>
			</Buttons>
			{isGeminiRegion === true ? (
				<></>
			) : (
				<HelpersWrapper>
					<TroubleShooting
						dataset={[
							troubleShootingVideoConference,
							troubleShootingDataTransfer,
						]}
						fullDataset={[
							troubleShootingLogin,
							troubleShootingVideoConference,
							troubleShootingDataTransfer,
						]}
					/>
				</HelpersWrapper>
			)}
		</Container>
	)
}

export default ErrorBoundaryPage
