const PX_TO_REM_RATIO = 16

export const pxToRem = (px: number | string): number => {
	const pixels = typeof px === 'string' ? parseInt(px) : px

	return pixels / PX_TO_REM_RATIO
}

export const firstLetterCapital = (str: string): string => {
	if (!str.length) {
		return str
	}
	return str.charAt(0).toUpperCase() + str.slice(1)
}

export const hexToRgba = (hex: string, alpha: number) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
	const rgb = result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: null
	return rgb && `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`
}
