import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TabDifForm } from '../../components/DigitalIntakeForm/types'

import {
	CheckInData,
	LockedSlot,
	TeloAppointment,
	TimeSlotData,
} from '../../model/appointment'
import { AppointmentState, Id } from '../../model/model'
import { TeloPatient } from '../../model/patient'

const initialState: AppointmentState = {
	today: [],
	availableSlots: [],
	lockedSlotId: '',
	scheduleId: '',
	digitalIntakaForm: {},
	forceRefresh: true,
}

export const slice = createSlice({
	name: 'appointments',
	initialState,
	reducers: {
		_setTodayAppointments: (
			state,
			{ payload }: PayloadAction<TeloAppointment[]>,
		) => {
			state.today = payload.map(appointment => ({
				...appointment,
			}))
		},
		_setAppointment: (state, { payload }: PayloadAction<CheckInData>) => {
			const isInState = !!state.today.find(b => b._id === payload._id)
			if (isInState) {
				state.today = state.today.map(appointment =>
					appointment._id === payload._id
						? {
								...payload,
								isTest: appointment.isTest,
								privacyPolicyDocuments: [],
						  }
						: appointment,
				)
			} else {
				state.today.push(payload)
			}
		},
		_deleteAppointment: (state, { payload }: PayloadAction<Id>) => {
			state.today = state.today.filter(({ _id }) => _id !== payload)
		},
		_setAvailableSlots: (state, { payload }: PayloadAction<TimeSlotData[]>) => {
			state.availableSlots = payload.map((slot, index) => ({
				...slot,
			}))
		},
		clearAvailableSlots: state => {
			state.availableSlots = []
		},
		_setLockedSlot: (state, { payload }: PayloadAction<LockedSlot>) => {
			state.lockedSlotId = payload.lockId
			state.scheduleId = payload.scheduleId
		},
		setSelectedSchedule: (state, { payload }: PayloadAction<string>) => {
			state.scheduleId = payload
		},
		clearAppointmentData: state => {
			state.today = []
			state.availableSlots = []
			state.lockedSlotId = ''
			state.scheduleId = ''
			state.digitalIntakaForm = {}
		},
		setIsTestAppointement: (
			state,
			{
				payload,
			}: PayloadAction<{
				appointmentId: string
				isTest: boolean
			}>,
		) => {
			state.today = state.today.map(appointment =>
				payload.appointmentId === appointment._id
					? {
							...appointment,
							isTest: payload.isTest,
					  }
					: appointment,
			)
		},
		setDigitalIntakeForm: (
			state,
			{
				payload,
			}: PayloadAction<{
				appointmentId: string
				dif: TabDifForm
			}>,
		) => {
			state.digitalIntakaForm = {
				...(state.digitalIntakaForm || {}),
				[payload.appointmentId]: payload.dif,
			}
		},
		_setPatientData: (
			state,
			{
				payload,
			}: PayloadAction<{ patient: TeloPatient; appointmentId: string }>,
		) => {
			let appointment = state.today.find(b => b._id === payload.appointmentId)

			if (!!appointment) {
				state.today = state.today.map(app => ({
					...app,
					patient:
						app._id === payload.appointmentId ? payload.patient : app.patient,
				}))
			}
		},
		_setForceRefresh: (state, { payload }: PayloadAction<boolean>) => {
			state.forceRefresh = payload
		},
	},
})

export default slice.reducer
