import React from 'react'

import { Grid as MGrid, GridProps } from '@mui/material'

type StyleguideGridProps = GridProps & { isFlex?: boolean; justify?: string }

const Grid = ({ children, isFlex, justify, ...props }: StyleguideGridProps) => {
	const style = {
		...(isFlex ? { display: 'flex', justify: justify || 'flex-start' } : {}),
	}

	return (
		<MGrid spacing={props.container ? 3 : undefined} {...props} style={style}>
			{children}
		</MGrid>
	)
}

export default Grid
