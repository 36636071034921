import React, {
	DetailedHTMLProps,
	ForwardedRef,
	forwardRef,
	HTMLAttributes,
	MouseEvent,
	useCallback,
} from 'react'
import { ChildrenProps } from '../utils/utilityTypes'
import { TeloDestination, useTeloRouter } from './teloRouter'

type SpanProps = DetailedHTMLProps<
	HTMLAttributes<HTMLSpanElement>,
	HTMLSpanElement
>

type TeloLinkProps = SpanProps &
	ChildrenProps & {
		to: TeloDestination
		withStyle?: boolean
	}

export const TeloLink = forwardRef(
	(
		{
			to,
			children,
			onClick,
			withStyle,

			...htmlProps
		}: TeloLinkProps,
		ref: ForwardedRef<HTMLDivElement>,
	) => {
		const { navigate: teloNavigate } = useTeloRouter()
		const handleClick = useCallback(
			(e: MouseEvent<HTMLSpanElement>) => {
				teloNavigate(to)
				onClick?.(e)
			},
			[onClick, teloNavigate, to],
		)
		return (
			<span
				ref={ref}
				{...htmlProps}
				onClick={e => {
					handleClick(e)
				}}
				style={
					withStyle
						? { color: 'black', fontWeight: 'bold', cursor: 'pointer' }
						: undefined
				}
			>
				{children}
			</span>
		)
	},
)
