import { useCallback, useEffect } from 'react'

import { useSelector } from 'react-redux'

import config from '../config'
import { useTeloDispatch } from '../store'
import {
	Notification,
	RecordedHttpError,
	RecordedUiError,
} from '../model/model'
import { selectHttpErrors, selectUiErrors } from '../features/errors/selectors'
import { AddNotificationPayload } from '../features/notifications/types'
import notificationsActions from '../features/notifications/actions'

export const useNotificationErrors = () => {
	const dispatch = useTeloDispatch()
	const httpErrors = useSelector(selectHttpErrors)
	const uiErrors = useSelector(selectUiErrors)
	const addNotification = useCallback(
		<N extends Omit<Notification, '_id'>>(
			notification: AddNotificationPayload<N>,
		) => dispatch(notificationsActions.addNotification(notification)),
		[dispatch],
	)
	const lastHttpError = !!httpErrors.length
		? httpErrors[httpErrors.length - 1]
		: null

	useEffect(() => {
		const isTabError = isTabErrorFn(lastHttpError)
		if (lastHttpError && isTabError) {
			addNotification({
				type: 'error',
				message: 'TAB: ' + lastHttpError.message,
				autoClose: false,
			})
		}
	}, [lastHttpError, addNotification])

	useEffect(() => {
		const isWebexError = isWebexErrorFn(uiErrors)
		const webexErrorMessage = isWebexError
			? 'Webex: ' + uiErrors[uiErrors.length - 1].message
			: ''
		const webexErrorId = isWebexError
			? uiErrors[uiErrors.length - 1].id
			: undefined
		if (isWebexError) {
			addNotification({
				type: 'error',
				message: webexErrorMessage,
				autoClose: false,
				errorType: 'ui',
				errorId: webexErrorId,
			})
		}
	}, [uiErrors, addNotification])

	useEffect(() => {
		const isNoDoctorError = isNoDoctorErrorFn(lastHttpError)
		if (isNoDoctorError) {
			addNotification({
				type: 'error',
				message: 'doctor.noDoctorAvailable',
				autoClose: true,
				messageIsLabelKey: true,
			})
		}
	}, [lastHttpError, addNotification])

	useEffect(() => {
		const isWebexError = isWebexErrorFn(uiErrors)
		const isTabError = isTabErrorFn(lastHttpError)
		const isNoDoctorError = isNoDoctorErrorFn(lastHttpError)
		const isProfilePhotoNotFound = isProfilePhotoNotFoundFn(lastHttpError)
		const nonBlockingErrorKey =
			!isWebexError &&
			!isTabError &&
			!isNoDoctorError &&
			!isProfilePhotoNotFound &&
			lastHttpError &&
			lastHttpError.nonBlockingErrorKey
		if (nonBlockingErrorKey) {
			addNotification({
				type: 'error',
				message: nonBlockingErrorKey,
				autoClose: true,
				messageIsLabelKey: true,
			})
		}
	}, [lastHttpError, uiErrors, addNotification])
}

const isNoDoctorErrorFn = (lastHttpError: RecordedHttpError | null): boolean =>
	lastHttpError?.message.includes('no doctors available') ?? false

const isTabErrorFn = (lastHttpError: RecordedHttpError | null): boolean =>
	lastHttpError?.internalUrl.includes(`${config.apiUrl}/appointments`) ?? false

const isWebexErrorFn = (uiErrors: RecordedUiError[] | null): boolean =>
	((uiErrors?.length &&
		uiErrors[uiErrors.length - 1]?.message.includes(
			'User has excessive device registrations',
		)) as boolean) ?? false

const isProfilePhotoNotFoundFn = (
	lastHttpError: RecordedHttpError | null,
): boolean => lastHttpError?.message.includes('ProfilePhoto not found') ?? false
