import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ChatMessage, ChatState } from '../../model/chat'
import { Id } from '../../model/model'

const initialState: ChatState = {}

export const slice = createSlice({
	name: 'chat',
	initialState,
	reducers: {
		addMessage: (
			state,
			{ payload }: PayloadAction<{ examId: Id; message: ChatMessage }>,
		) => {
			const { examId, message } = payload
			state[examId] = (state[examId] || []).concat(message)
		},
		clearHistory: (state, { payload }: PayloadAction<Id>) => {
			delete state[payload]
		},
		markAllAsRead: (state, { payload }: PayloadAction<Id>) => {
			state[payload] = (state[payload] || []).map(m => ({ ...m, read: true }))
		},
	},
})

export default slice.reducer
