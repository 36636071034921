import React from 'react'

import { Box, Typography as MTypography, TypographyProps } from '@mui/material'

import { MarginProps } from '../../model/types'

type Props = TypographyProps & MarginProps

const Subtitle: React.FC<Props> = ({ children, mb, mr, ml, mt, ...props }) => {
	return (
		<Box mb={mb} mr={mr} ml={ml} mt={mt}>
			<MTypography variant="h2" {...props}>
				{children}
			</MTypography>
		</Box>
	)
}

export default Subtitle
