import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from '../services/common'
import { SurgeryCondition } from '../model/surgeryCondition'
import { Id } from '../model/model'

const TAG = 'surgeryConditionsApi'
const BASE_PATH = (practiceId = '0') =>
	`/v3/practices/${practiceId}/surgeryconditions`

export const surgeryConditionsApi = createApi({
	reducerPath: 'surgeryConditionsApi',
	tagTypes: [TAG],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getSurgeryConditions: builder.query<SurgeryCondition[], Id>({
			query: practiceId => ({
				url: `${BASE_PATH(practiceId)}`,
			}),
			providesTags: (result, error, practiceId) =>
				result
					? [
							...result.map(({ _id }) => ({ type: TAG, _id } as const)),
							{ type: TAG, id: practiceId + '_LIST' },
					  ]
					: [{ type: TAG, id: practiceId + '_LIST' }],
		}),

		getSurgeryCondition: builder.query<
			SurgeryCondition,
			{ practiceId: Id; _id: Id }
		>({
			query: ({ practiceId, _id }) => ({
				url: `${BASE_PATH(practiceId)}/${_id}`,
			}),
			providesTags: result => (result ? [{ type: TAG, id: result._id }] : []),
		}),

		createSurgeryCondition: builder.mutation<
			SurgeryCondition,
			{ practiceId: Id; body: Partial<SurgeryCondition> }
		>({
			query: ({ practiceId, body }) => ({
				url: `${BASE_PATH(practiceId)}`,
				method: 'POST',
				body: JSON.stringify(body),
			}),
			invalidatesTags: (result, error, { practiceId }) => [
				{ type: TAG, id: practiceId + '_LIST' },
			],
		}),

		deleteSurgeryCondition: builder.mutation<void, { practiceId: Id; _id: Id }>(
			{
				query: ({ practiceId, _id }) => ({
					url: `${BASE_PATH(practiceId)}/${_id}`,
					method: 'DELETE',
				}),
				invalidatesTags: (result, error, { practiceId }) => [
					{ type: TAG, id: practiceId + '_LIST' },
				],
			},
		),

		updateSurgeryCondition: builder.mutation<
			SurgeryCondition,
			{ practiceId: Id; _id: Id; body: Partial<SurgeryCondition> }
		>({
			query: ({ practiceId, _id, body }) => ({
				url: `${BASE_PATH(practiceId)}/${_id}`,
				method: 'PUT',
				body: JSON.stringify(body),
			}),
			invalidatesTags: (result, error, practiceId) =>
				result
					? [
							{ type: TAG, id: practiceId + '_LIST' },
							{ type: TAG, id: result._id },
					  ]
					: [],
		}),
	}),
})

export const {
	useGetSurgeryConditionsQuery,
	useDeleteSurgeryConditionMutation,
	useCreateSurgeryConditionMutation,
	useGetSurgeryConditionQuery,
	useUpdateSurgeryConditionMutation,
} = surgeryConditionsApi
