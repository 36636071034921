/*
	Reading directly variables from process.env so that webpack can erase them
	and inject real env values at runtime.

	Don't read env dynamically as webpack can not make its erasure

	Example:
	
	```
	// ❌ WRONG:
	function read(myFrogKey) {
		const myFrogValue = process.env[myFrogKey]
	}

	// ✅ OK:
	function readMyFrogValue {
		const myFrogValue = process.env.MY_FROG_KEY
	}
	```
*/

export function getServingURL(): string | null {
	return process.env.SERVING_URL ?? null
}
