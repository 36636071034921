import { createApi } from '@reduxjs/toolkit/query/react'
import qs from 'qs'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { Id, YyyyMmDm } from '../model/model'
import { PatientApi, InternalPatient } from '../model/patient'
import { unversionedApiUrl } from './common'

export const patientsApi = createApi({
	reducerPath: 'patientsApi',
	baseQuery: staggeredBaseQueryWithBailOut(`${unversionedApiUrl}`),
	tagTypes: [],
	endpoints: builder => ({
		getMatchingCdaPatients: builder.query<
			{ patients: { patient: PatientApi; score: number }[] },
			{
				storeId: Id
				name: string
				surname: string
				dateOfBirth: YyyyMmDm
				emailAddress: string
			}
		>({
			query: query => ({
				url: `/v1/internal-patients/match-cda?${qs.stringify(query)}`,
			}),
		}),
		updatePatient: builder.mutation<InternalPatient, InternalPatient>({
			query: body => ({
				url: `/v1/internal-patients/${body._id}`,
				method: 'PUT',
				body,
			}),
		}),
	}),
})

export const { useGetMatchingCdaPatientsQuery, useUpdatePatientMutation } =
	patientsApi
