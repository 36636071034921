import React from 'react'
import styled from 'styled-components'

import { pxToRem } from '../libs/style'
import theme from '../styleguide/theme'
import Button from '../styleguide/buttons/Button'
import ChevronLeftIcon from '../styleguide/icons/ChevronLeftIcon'

const ButtonStyled = styled(Button)`
	svg {
		width: ${pxToRem(24)}rem;
		height: ${pxToRem(24)}rem;
		padding: ${theme.spacing(0.5)};
	}
`

type BackIconProps = Omit<
	React.ComponentProps<typeof Button>,
	'startIcon' | 'ref'
> & {
	bold?: boolean
}

const BackIconButton = ({
	children,
	bold = true,
	...buttonProps
}: BackIconProps) => {
	return (
		<ButtonStyled
			textAsIs
			bold={bold}
			underlined
			startIcon={<ChevronLeftIcon />}
			variant={'text'}
			{...buttonProps}
		>
			{children}
		</ButtonStyled>
	)
}

export default BackIconButton
