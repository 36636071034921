import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Reason, ReasonsUpdatePartial } from '../../model/reasons'

const initialState: Reason[] = []

export const slice = createSlice({
	name: 'reasons',
	initialState,
	reducers: {
		_loadReasons: (state, { payload }: PayloadAction<Reason[]>) => {
			state = payload
		},
		setReasonsEnabled: (
			state,
			{ payload: { _id, enabled } }: PayloadAction<ReasonsUpdatePartial>,
		) => {
			if (enabled !== undefined) {
				const index = state.findIndex(
					(item: ReasonsUpdatePartial) => item._id === _id,
				)
				state[index].enabled = enabled
			}
		},
	},
})

export const { setReasonsEnabled } = slice.actions

export default slice.reducer
