import * as Yup from 'yup'

import { isPopulated } from './libs/prescriptions'

Yup.setLocale({
	mixed: {
		default: 'validation.fieldInvalid',
		notType: ({ type }: { type: string }) => {
			return `validation.notType-${type}`
		},
		required: 'validation.mandatory',
	},
	string: {
		max: ({ max }: { max: number }) => ({
			key: `validation.stringTooLong`,
			max,
		}),
	},
})

Yup.addMethod(Yup.object, 'isPopulated', function (errorMsg: string) {
	return this.test('isPopulated', errorMsg, (value: object) => {
		return Object.values(value).some(isPopulated)
	})
})

export default Yup
