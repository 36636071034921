import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { HealthDescriptionsCatalogData } from '../../model/exam'
import { HealthDescriptionsState } from '../../model/model'

const initialState: HealthDescriptionsState = {
	catalog: [],
}

export const slice = createSlice({
	name: 'healthDescriptions',
	initialState,
	reducers: {
		_loadHealthDescriptionsCatalog: (
			state,
			{ payload }: PayloadAction<HealthDescriptionsCatalogData[]>,
		) => {
			state.catalog = payload
		},
	},
})

export default slice.reducer
