import { getFilteredAlerts } from '../../libs/alerts'
import { AlertCategory } from '../../model/alerts'
import { ActiveAlerts } from '../../model/model'
import { PatientAlert, SeverityKeys } from '../../model/patient'

export const getAreAlertsByTypeSame = (
	type: SeverityKeys,
	alerts: PatientAlert[],
	activeAlerts: ActiveAlerts,
): boolean => {
	const prevAlertsByType = activeAlerts[type].list

	const alertsByType = alerts.filter(
		({ severity, cancelled }) => !cancelled && severity === type,
	)

	return prevAlertsByType.length === alertsByType.length
}

export const getFilteredActiveAlerts = (
	type: SeverityKeys,
	alerts: PatientAlert[],
	activeAlerts: ActiveAlerts,
	availableCategories: AlertCategory[],
): PatientAlert[] => {
	const filteredAlerts = getFilteredAlerts(alerts, availableCategories, type)

	const cdsAlerts = activeAlerts[type].list.filter(({ type }) => type === 'CDS')

	const patientAlerts = [...filteredAlerts, ...cdsAlerts]

	return patientAlerts
}

export const getCDSActiveAlertsByType = (
	alert: PatientAlert,
	activeAlertsByType: PatientAlert[],
) =>
	activeAlertsByType.every(({ category: { id } }) => id === alert.category.id)
		? activeAlertsByType
		: [...activeAlertsByType, alert]
