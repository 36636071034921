import React from 'react'

import { SvgProps } from '../../model/types'

const FilledIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Filled Indicator">
				<path
					id="Icon"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M17.66 4.55379C17.9854 4.87922 17.9854 5.40686 17.66 5.7323L8.29081 15.1015C7.93283 15.4594 7.35243 15.4594 6.99445 15.1015L2.33933 10.4463C2.0139 10.1209 2.0139 9.59325 2.33933 9.26783C2.66476 8.94241 3.1924 8.94241 3.51784 9.26783L7.64263 13.3926L16.4815 4.55379C16.8069 4.22835 17.3346 4.22835 17.66 4.55379Z"
					fill="#20233D"
				/>
			</g>
		</svg>
	)
}

export default FilledIcon
