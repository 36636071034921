import i18n from 'i18next'
import {
	createNewAppointmentApi,
	createTabPatientApi,
	deleteAppointmentApi,
	findDif,
	freeLockedSlotApi,
	getAppointmentApi,
	getAvailableSlotsApi,
	lockSlotApi,
	refreshPatientDataApi,
	updateAppointmentApi,
} from '../../apiCalls'
import { formatName } from '../../libs/localization'
import { capitalizeFirstLetter } from '../../libs/utils'
import {
	AppointmentForm,
	AppointmentWithStatus,
	CheckInData,
	TimeSlot,
	TimeSlotData,
} from '../../model/appointment'
import { DifProductFindRequest } from '../../model/difProduct'
import { Id } from '../../model/model'
import { TeloPatient } from '../../model/patient'
import { TeloNavigationFn } from '../../routing/teloRouter'
import {
	AppThunk,
	AppThunkPromise,
	TeloDispatch,
	TeloGetState,
} from '../../store'
import { selectStoreId } from '../app/selectors'
import examsActions from '../exams/actions'
import notificationsActions from '../notifications/actions'
import { selectAppointment } from './selectors'
import { slice } from './slice'

const fetchAppointment =
	(
		appointmentId: Id,
		isOttMode?: boolean,
		ottMockAppointments?: AppointmentWithStatus[],
	): AppThunkPromise<void | {
		payload: CheckInData
		type: string
	}> =>
	(dispatch: TeloDispatch) => {
		if (isOttMode && ottMockAppointments) {
			const ottAppointment = ottMockAppointments.find(
				a => a._id === appointmentId,
			)
			ottAppointment &&
				dispatch(appointmentsActions._setTodayAppointments([ottAppointment]))
			ottAppointment &&
				appointmentsActions._setAppointment({
					...ottAppointment,
					isTest: false,
				})
			return Promise.resolve()
		} else {
			return getAppointmentApi(appointmentId).then(
				appointment =>
					appointment &&
					dispatch(
						appointmentsActions._setAppointment({
							...appointment,
							isTest: false,
						}),
					),
			)
		}
	}

const encodePatient = (clinicId: string, firstData: AppointmentForm) => {
	const tabPatient: TeloPatient = {
		email: firstData.email,
		address: firstData.address,
		birthDate: firstData.birthDate,
		city: firstData.city,
		country: firstData.country,
		id: firstData.id,
		language: firstData.language,
		middleName: firstData.middleName,
		name: firstData.name,
		phoneNumber: firstData.phoneNumber,
		postalCode: firstData.postalCode,
		secondaryPhone: firstData.secondaryPhone,
		secondaryPhoneType: firstData.secondaryPhoneType,
		clinicId: clinicId,
		surname: firstData.surname,
		typePhoneNumber: firstData.typePhoneNumber,
		source: 'TAB',
		externalIds: [{ source: 'TAB', code: firstData.id || '' }],
	}
	return tabPatient
}

export const createAppointmentAction =
	(
		clinicId: string,
		appointmentData: AppointmentForm,
		navigate: TeloNavigationFn,
	): AppThunk =>
	(dispatch: TeloDispatch) => {
		const tabPatient = encodePatient(clinicId, appointmentData)
		createTabPatientApi(tabPatient).then(patient => {
			if (patient && patient.id) {
				const appointmentApiData = {
					patientId: patient.id,
					lockId: appointmentData.lockedId,
					scheduleId: appointmentData.scheduleId,
				}
				createNewAppointmentApi(appointmentApiData).then(appointment => {
					dispatch(slice.actions.clearAppointmentData())
					navigate('/')
					appointment &&
						dispatch(
							notificationsActions.addNotification({
								message: i18n.t('checkin.appointmentConfirmed').replace(
									'%%1',
									formatName({
										name: capitalizeFirstLetter(patient.name),
										surname: capitalizeFirstLetter(patient.surname),
									}),
								),
								autoClose: true,
								type: 'success',
							}),
						)
				})
			}
		})
	}

const clearAvailableSlots = (): AppThunk => (dispatch: TeloDispatch) =>
	dispatch(slice.actions.clearAvailableSlots())

const getAvailableTimeSlotsAction =
	(data: TimeSlot): AppThunkPromise =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const state = getState()
		const selectedStoreId = selectStoreId(state)

		return getAvailableSlotsApi(
			data.appointmentType,
			data.appointmentDate,
			selectedStoreId,
		).then(slots => {
			if (slots) {
				dispatch(slice.actions._setAvailableSlots(slots))
			}
			return
		})
	}

const lockAppointmentAction =
	(slot: TimeSlotData): AppThunk =>
	(dispatch: TeloDispatch) => {
		slot &&
			lockSlotApi(slot.scheduleId).then(lock => {
				return (
					lock &&
					dispatch(
						slice.actions._setLockedSlot({
							...lock,
							scheduleId: slot.scheduleId,
						}),
					)
				)
			})
	}

const freeLockedAppointmentAction =
	(id: string): AppThunk =>
	(dispatch: TeloDispatch) => {
		freeLockedSlotApi(id).then(lock => {
			return lock && dispatch(slice.actions.clearAppointmentData())
		})
	}

const deleteAppointmentAction =
	(id: Id, navigate: TeloNavigationFn): AppThunk =>
	(dispatch: TeloDispatch, getState: TeloGetState) =>
		deleteAppointmentApi(id).then(res => {
			if (res !== undefined) {
				navigate('/')
				const state = getState()
				const appointment = selectAppointment(id)(state)

				dispatch(
					notificationsActions.addNotification({
						message: appointment
							? i18n.t('appointment.appointmentDeleted').replace(
									'%%1',
									formatName({
										name: appointment.patient.name,
										surname: appointment.patient.surname,
									}),
							  )
							: i18n.t('appointment.appointmentDeletedNoName'),
						autoClose: true,
						type: 'success',
					}),
				)
			}
		})

const updatePatientAction =
	(tabPatient: TeloPatient): AppThunkPromise<void | TeloPatient> =>
	(dispatch: TeloDispatch) =>
		createTabPatientApi(tabPatient)

const rescheduleAppointmentAction =
	({
		appointmentId,
		patientId,
		scheduleId,
		navigate,
	}: {
		appointmentId: string
		patientId: string
		scheduleId: string
		navigate: TeloNavigationFn
	}): AppThunk =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		lockSlotApi(scheduleId)
			.then(lock => {
				const { lockId } = lock
				const updateAppointmentBody = {
					patientId,
					scheduleId,
					lockId,
				}
				return updateAppointmentApi(appointmentId, updateAppointmentBody)
			})
			.then(() => {
				navigate('/')
				const state = getState()
				const appointment = selectAppointment(appointmentId)(state)
				dispatch(
					notificationsActions.addNotification({
						message: appointment
							? i18n.t('appointment.appointmentRescheduled').replace(
									'%%1',
									formatName({
										name: appointment.patient.name,
										surname: appointment.patient.surname,
									}),
							  )
							: i18n.t('appointment.appointmentRescheduledNoName'),
						autoClose: true,
						type: 'success',
					}),
				)
			})
	}

const getAppointmentDIF =
	({
		appointmentId,
		storeId,
	}: Omit<DifProductFindRequest, 'brand'>): AppThunk =>
	async (dispatch: TeloDispatch) => {
		const dif = await findDif({ appointmentId, brand: 'LC', storeId })
		dif &&
			dispatch(
				appointmentsActions.setDigitalIntakeForm({
					appointmentId,
					dif,
				}),
			)
	}

const reloadUserDataAction =
	(patientId: Id, examId: Id): AppThunk =>
	async (dispatch: TeloDispatch) => {
		const exam = await refreshPatientDataApi(patientId, examId)
		if (exam) {
			dispatch(examsActions._loadExam(exam))
		}
	}

const appointmentsActions = {
	...slice.actions,
	clearAvailableSlots,
	createAppointmentAction,
	deleteAppointmentAction,
	fetchAppointment,
	freeLockedAppointmentAction,
	getAppointmentDIF,
	getAvailableTimeSlotsAction,
	lockAppointmentAction,
	reloadUserDataAction,
	rescheduleAppointmentAction,
	updatePatientAction,
}

export default appointmentsActions
