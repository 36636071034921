import { useEffect } from 'react'

import { selectSessionId, selectStoreId } from '../features/app/selectors'
import { selectUsername } from '../features/auth/selectors'
import notificationsActions from '../features/notifications/actions'
import { AddNotificationPayload } from '../features/notifications/types'
import {
	selectDoctorPanelsStoresIds,
	selectUserQueuePanelsIds,
} from '../features/panels/selectors'
import { connectToSocketDoctorStore } from '../features/socket/doctorExamsInStore/doctorExamsInStoreSocket'
import { connectToSocketPanels } from '../features/socket/panels/panelsSocket'
import { connectToRoom } from '../features/socket/room/roomSocketActions'
import { RoomSocketType } from '../features/socket/room/roomSocketTypes'
import { AddNotificationFn } from '../features/socket/teloSocketTypes'
import { InstrumentType } from '../model/instrumentsApi'
import { Id } from '../model/model'
import { useTeloRouter } from '../routing/teloRouter'
import { WORKLIST_CACHE_TAG, worklistApi } from '../services/worklist'
import { useTeloDispatch, useTeloSelector } from '../store'

const useRoomSocket = (roomType: RoomSocketType, roomName?: string) => {
	const dispatch = useTeloDispatch()
	const { navigate } = useTeloRouter()
	useEffect(() => {
		if (!roomName) {
			return
		}
		dispatch(connectToRoom(roomType, roomName, navigate))
	}, [dispatch, navigate, roomName, roomType])
}

export const useSocketUser = (username: string) =>
	useRoomSocket('user-id', username ? `user-id-${username}` : undefined)

export const useSocketStore = () => {
	const storeId = useTeloSelector(selectStoreId)
	return useRoomSocket('store-id', storeId ? `store-id-${storeId}` : undefined)
}

export const useSocketAssessments = (isAppInsideEclips?: boolean) =>
	useRoomSocket('assessments', isAppInsideEclips ? undefined : 'assessments')

export const useSocketExamById = (examId?: Id) =>
	useRoomSocket('exam-id', examId ? `exam-id-${examId}` : undefined)

export const useSocketInstrument = (
	examId: Id,
	examLoaded: boolean,
	autoUpdate: boolean,
	instrumentType: InstrumentType,
) =>
	useRoomSocket(
		`instrument-type-${instrumentType}`,
		examLoaded && autoUpdate
			? `instrument-type-${instrumentType}_exam-id-${examId}`
			: undefined,
	)

export const useSocketInstrumentsInRoom = (
	storeId: string,
	stageId: Id,
	roomId: Id,
) =>
	useRoomSocket(
		'instruments-in-room',
		storeId && stageId && roomId
			? `store-id-${storeId}_stage-id-${stageId}_room-id-${roomId}`
			: undefined,
	)

export const useSocketStoreExams = (storeId?: Id) =>
	useRoomSocket('exams-in-store', storeId ? `exams-in-store-id-${storeId}` : '')

export const useSocketGetWarnings = (examId?: Id) =>
	useRoomSocket('warnings', examId ? `warnings_exam-id-${examId}` : undefined)

export const useSocketExamsInPanelStores = () => {
	const dispatch = useTeloDispatch()

	const username = useTeloSelector(selectUsername)
	const sessionId = useTeloSelector(selectSessionId)

	const panelStoresIds = useTeloSelector(selectDoctorPanelsStoresIds)
	const remoteStoresIdsToFetch = panelStoresIds.length ? panelStoresIds : []
	const stores = [...new Set(remoteStoresIdsToFetch)]
	// stores id are passed as a string because stores array can be recreated even if it holds the same data
	// (for instance whenever the Worklist is rerendered)
	// and this would cause the hook to be executed again even if stores didn't actually change
	const storesIdAsString = stores.sort().join(',')

	useEffect(() => {
		if (!storesIdAsString || !username) {
			return
		}

		const addNotification: AddNotificationFn = (
			notification: AddNotificationPayload<any>,
		) => {
			dispatch(notificationsActions.addNotification(notification))
		}

		connectToSocketDoctorStore({
			doctorUsername: username,
			updateWorklist: () => {
				// No need to clear the worklist cache if not in the worklist page
				// since when the user will go to the worklist page the cache is invalidated
				if (window.location.pathname.includes('/worklist')) {
					dispatch(worklistApi.util.invalidateTags([WORKLIST_CACHE_TAG]))
				}
			},
			sessionId,
			storeId: storesIdAsString,
			addNotification,
		})
	}, [dispatch, username, storesIdAsString, sessionId])
}

export const useSocketPanel = () => {
	const dispatch = useTeloDispatch()

	const userQueuePanels = useTeloSelector(selectUserQueuePanelsIds)

	// stores id are passed as a string because stores array can be recreated even if it holds the same data
	// (for instance whenever the Worklist is rerendered)
	// and this would cause the hook to be executed again even if stores didn't actually change
	const panelsIdAsString = userQueuePanels.join(',')

	useEffect(() => {
		if (!panelsIdAsString) {
			return
		}

		const addNotification: AddNotificationFn = (
			notification: AddNotificationPayload<any>,
		) => {
			dispatch(notificationsActions.addNotification(notification))
		}

		connectToSocketPanels({
			panelsId: panelsIdAsString,
			addNotification,
		})
	}, [dispatch, panelsIdAsString])
}

export const useSocketFieldUpdates = (examId?: Id) =>
	useRoomSocket('field-updates', examId ? `field-updates-${examId}` : undefined)

export const useSocketFeatureToggle = () =>
	useRoomSocket('features', 'features')
