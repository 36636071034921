import React from 'react'

import { SvgProps } from '../../model/types'

const CloseIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="currentColor"
			{...props}
		>
			<path d="M9.99996 11.1787L14.714 15.8927C15.0394 16.2182 15.567 16.2182 15.8925 15.8927C16.2179 15.5673 16.2179 15.0397 15.8925 14.7142L11.1785 10.0002L15.8925 5.28613C16.2179 4.96069 16.2179 4.43305 15.8925 4.10762C15.567 3.78218 15.0394 3.78218 14.714 4.10762L9.99996 8.82167L5.28586 4.1076C4.96043 3.78217 4.43279 3.78217 4.10736 4.1076C3.78192 4.43304 3.78192 4.96068 4.10736 5.28612L8.82137 10.0002L4.10736 14.7143C3.78192 15.0397 3.78192 15.5673 4.10736 15.8927C4.43279 16.2182 4.96043 16.2182 5.28586 15.8927L9.99996 11.1787Z" />
		</svg>
	)
}

export default CloseIcon
