import io, { Socket } from 'socket.io-client'
import { getNamesFromUsername } from '../../../apiCalls'
import appConfig from '../../../config'
import { formatName } from '../../../libs/localization'
import {
	socketCommonOptions,
	socketReconnectionFailedNotification,
} from '../../../libs/socket'
import {
	QueueNoDoctorAvailablePayload,
	QueuerStartedPayload,
} from '../../../model/queue'
import { SocketMessagesMap } from '../../../model/socket'
import { queueApi, QUEUE_CACHE_TAG } from '../../../services/queue'
import { worklistApi, WORKLIST_CACHE_TAG } from '../../../services/worklist'
import store from '../../../store'
import appointmentsActions from '../../appointments/actions'
import { selectUsername } from '../../auth/selectors'
import queueActions from '../../queue/actions'
import { newTeloSocketConnector } from '../teloSocketConnector'
import { isSocketConnected } from '../teloSocketRegistry'
import { AddNotificationFn, TeloSocket } from '../teloSocketTypes'

type PanelsSocketArgs = {
	panelsId: string
	addNotification: AddNotificationFn
}

export type QueuerAssignationTimedOutPayload = {
	examId: string
	storeId: string
	patient: {
		name: string
		surname: string
	}
	doctors: string[]
	examPutInQueueBy: string
}

const showQueuerAssignationTimedOutMessage = ({
	examId,
	patient,
	doctors,
}: QueuerAssignationTimedOutPayload) => {
	getNamesFromUsername(doctors)
		.then(doctorInfo => {
			store.dispatch(
				queueActions.addExamTimedOut({
					examId,
					patientName: formatName(patient),
					doctors: doctorInfo.map(formatName),
				}),
			)
		})
		.catch(() => {
			store.dispatch(
				queueActions.addExamTimedOut({
					examId,
					patientName: formatName(patient),
					doctors,
				}),
			)
		})
}

const invalidateQueueCompositionCache = () => {
	store.dispatch(queueApi.util.invalidateTags([QUEUE_CACHE_TAG]))
}

const showQueuerStartedMessage = ({
	examId,
	patient,
	doctor,
	examPutInQueueBy,
}: QueuerStartedPayload) => {
	const state = store.getState()
	const username = selectUsername(state)

	const examWasPutInQueueByThisUser = examPutInQueueBy === username

	if (examWasPutInQueueByThisUser) {
		store.dispatch(
			queueActions.addExamAssigned({
				examId,
				patientName: formatName(patient),
				doctorName: formatName(doctor),
			}),
		)
	}
}

const invalidateWorklistCache = () => {
	// No need to clear the worklist cache if not in the worklist page
	// since when the user will go to the worklist page the cache is invalidated
	if (window.location.pathname.includes('/worklist')) {
		// when existing exams updates there is no need to force the refresh of the appointments
		store.dispatch(appointmentsActions._setForceRefresh(false))
		store.dispatch(worklistApi.util.invalidateTags([WORKLIST_CACHE_TAG]))
		store.dispatch(appointmentsActions._setForceRefresh(true))
	}
}

const showNoDoctorAvailableMessage = ({
	examId,
	patient,
	examPutInQueueBy,
	assignationStartedBy,
}: {
	examId: string
	patient: {
		name: string
		surname: string
	}
	examPutInQueueBy: string
	assignationStartedBy: string
}) => {
	const state = store.getState()

	const username = selectUsername(state)

	if ([examPutInQueueBy, assignationStartedBy].includes(username)) {
		store.dispatch(
			queueActions.setNoDoctorAvailableModalInfo({
				show: true,
				examId,
				patient,
			}),
		)
	}
}

const newSocket = ({
	panelsId,
	addNotification,
}: PanelsSocketArgs): TeloSocket => {
	let socket: Socket | null = null

	const connect = (): void => {
		if (isSocketConnected(socket)) {
			return
		}

		socket = io(appConfig.socketUrl, {
			...socketCommonOptions,
			query: { panelsId },
		})

		socket.io.on('reconnect_failed', () => {
			addNotification(socketReconnectionFailedNotification)
		})

		socket.once('socket-messages-map', (messagesMap: SocketMessagesMap) => {
			const {
				queueUpdates,
				queueNoDoctorAvailable,
				queuerStarted,
				queuerAssignationTimedOut,
			} = messagesMap

			socket!.on(queueUpdates.msgToFe, (data: { storeId: string }) => {
				invalidateQueueCompositionCache()
			})
			socket!.on(
				queueNoDoctorAvailable.msgToFe,
				(data: QueueNoDoctorAvailablePayload) => {
					showNoDoctorAvailableMessage(data)
					invalidateQueueCompositionCache()
				},
			)

			socket!.on(queuerStarted.msgToFe, (data: QueuerStartedPayload) => {
				showQueuerStartedMessage(data)
			})

			socket!.on(
				queuerAssignationTimedOut.msgToFe,
				(data: QueuerAssignationTimedOutPayload) => {
					const state = store.getState()

					const username = selectUsername(state)

					const examWasPutInQueueByThisUser = data.examPutInQueueBy === username

					if (examWasPutInQueueByThisUser) {
						showQueuerAssignationTimedOutMessage(data)
					}
					invalidateQueueCompositionCache()
					invalidateWorklistCache()
				},
			)
		})
	}

	const disconnect = () => {
		if (!isSocketConnected(socket)) {
			return
		}
		socket!.disconnect()
		socket = null
	}

	return { connect, disconnect }
}

const socketConnector = newTeloSocketConnector()

export function connectToSocketPanels(args: PanelsSocketArgs): void {
	socketConnector.connectSocket({
		socketKey: args.panelsId,
		newSocket: () => newSocket(args),
	})
}
