import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { pxToRem } from '../../libs/style'
import theme from '../../styleguide/theme'
import Button from '../../styleguide/buttons/Button'
import DialogActions from '../../styleguide/dialog/DialogActions'
import DialogContent from '../../styleguide/dialog/DialogContent'
import DialogContentText from '../../styleguide/dialog/DialogContentText'
import Uppercase from '../../styleguide/typography/Uppercase'
import { useTeloDispatch, useTeloSelector } from '../../store'
import { selectDialog } from '../../features/dialog/selectors'
import dialogActions from '../../features/dialog/actions'
import { SessionExpirationDialogType } from '../../model/dialog'
import DialogTitle from '../../styleguide/dialog/DialogTitle'

const DialogWrapper = styled.div`
	max-width: ${pxToRem(546)}rem;
`

const DialogContentStyled = styled(DialogContent)`
	text-align: center;
	font-size: ${pxToRem(14)}rem;
	line-height: ${pxToRem(20)}rem;
	color: ${theme.palette.greyCustom.main};
`

const DialogContentTextStyled = styled(DialogContentText)`
	padding: ${theme.spacing(3)} 0;
	font-size: ${pxToRem(24)}rem;
	line-height: ${pxToRem(28)}rem;
`

const isSessionExpirationDialog = (
	dialog: any,
): dialog is SessionExpirationDialogType => dialog.type === 'sessionExpiration'

const SessionExpirationDialog = () => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()
	const dialog = useTeloSelector(selectDialog)

	return !isSessionExpirationDialog(dialog) ? null : (
		<DialogWrapper>
			<DialogTitle onClose={() => dispatch(dialogActions.closeDialog())}>
				{t('sessionExpiration.dialog.title')}
			</DialogTitle>
			<DialogContentStyled>
				<DialogContentTextStyled>
					{t('sessionExpiration.dialog.subtitle')}
				</DialogContentTextStyled>
				{t('sessionExpiration.dialog.message')}
			</DialogContentStyled>

			<DialogActions justifyContent="flex-end">
				<Button
					variant="contained"
					onClick={() => dispatch(dialogActions.closeDialog())}
				>
					<Uppercase>{t('app.okay')}</Uppercase>
				</Button>
			</DialogActions>
		</DialogWrapper>
	)
}

export default SessionExpirationDialog
