import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'

import { unversionedApiUrl } from './common'
import { SystemEquipments } from '../model/equipments'
import { PaginatedResponse } from '../model/types'
import { buildQueryString } from '../libs/url'

const TAG = 'sys-equipments'

type EquipmentsQuery = {
	enabled?: boolean
	q?: string
	page?: number
	limit?: number
}

export const equipmentsApi = createApi({
	reducerPath: 'equipmentsApi',
	tagTypes: [TAG],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getEquipments: builder.query<
			PaginatedResponse<SystemEquipments>,
			EquipmentsQuery
		>({
			query: qs => ({
				url: `/v1/equipments${buildQueryString(qs)}`,
			}),
			providesTags: [TAG],
		}),

		toogleEquipment: builder.mutation<void, { id: string; value: boolean }>({
			query: ({ id, value }) => ({
				url: `/v1/equipments/${id}`,
				method: 'PATCH',
				body: JSON.stringify({
					enabled: value,
				}),
			}),
			invalidatesTags: [TAG],
		}),

		createEquipment: builder.mutation<void, SystemEquipments>({
			query: body => ({
				url: `/v1/equipments`,
				method: 'POST',
				body: JSON.stringify(body),
			}),
			invalidatesTags: [TAG],
		}),

		updateEquipment: builder.mutation<
			void,
			{ id: string; value: SystemEquipments }
		>({
			query: ({ id, value }) => ({
				url: `/v1/equipments/${id}`,
				method: 'PUT',
				body: JSON.stringify(value),
			}),
			invalidatesTags: [TAG],
		}),
	}),
})

export const {
	useGetEquipmentsQuery,
	useToogleEquipmentMutation,
	useCreateEquipmentMutation,
	useUpdateEquipmentMutation,
} = equipmentsApi
