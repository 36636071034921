import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OcularHealthConditionState, OcularHealthCondition } from '../../model/ocularHealthCondition';


const initialState: OcularHealthConditionState = {
    conditions: []
}

export const slice = createSlice({
    name: 'conditions',
    initialState,
    reducers: {
        _setOcularHealthConditions: (state, { payload }: PayloadAction<OcularHealthCondition[]>) => {
            state.conditions = payload
        }
    }
})

export default slice.reducer