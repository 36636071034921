import { createApi } from '@reduxjs/toolkit/query/react'

import config from '../config'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { getSystemHeaders } from '../libs/system'
import { ICD10 } from '../model/icd10'
import { FilterParams, HeaderParams } from './common'

const FAVOURITE_TAG = 'ic10-favourites'

type Ic10Code = string

export const icd10Api = createApi({
	reducerPath: 'ic10Api',
	tagTypes: [FAVOURITE_TAG],
	baseQuery: staggeredBaseQueryWithBailOut(config.apiUrl),
	endpoints: builder => ({
		getIcd10ByCode: builder.query<ICD10, Ic10Code>({
			query: code => ({
				url: `/icd10/code/${code}`,
				headers: getSystemHeaders(),
			}),
			providesTags: [FAVOURITE_TAG],
		}),
		getIcd10Favourites: builder.query<ICD10[], HeaderParams>({
			query: ({ upriseOrgId }) => ({
				url: '/icd10/favourties',
				headers: getSystemHeaders(upriseOrgId),
			}),
			providesTags: [FAVOURITE_TAG],
		}),

		getFilteredIcd10: builder.query<ICD10[], FilterParams>({
			query: ({ filter, upriseOrgId }) => ({
				url: `/icd10/paginated?q=${encodeURIComponent(filter)}`,
				headers: getSystemHeaders(upriseOrgId),
			}),
			providesTags: [FAVOURITE_TAG],
		}),

		setIcd10Favourite: builder.mutation<
			ICD10,
			{ code: string; favourite: boolean }
		>({
			query: ({ code, favourite }) => ({
				url: `/users/icd10/${code}/favourite`,
				method: 'PUT',
				headers: getSystemHeaders(),
				body: {
					favourite,
				},
			}),
			invalidatesTags: [FAVOURITE_TAG],
		}),
	}),
})

export const {
	useGetIcd10ByCodeQuery,
	useGetIcd10FavouritesQuery,
	useSetIcd10FavouriteMutation,
	useGetFilteredIcd10Query,
} = icd10Api
