import jwtDecode from 'jwt-decode'

import { decodeRole } from '../../decoders/role'
import { AuthState, LoginToken } from '../../model/model'

export const getDecodedTokenData = (
	token: string,
	eclipsUsername?: string,
): Omit<AuthState, 'loginStatus'> => {
	const { exp, role } = jwtDecode<LoginToken>(token)

	const expirationDate = new Date(exp * 1000).toString()

	const decodedRole = decodeRole(role)

	const appLoginData: Omit<AuthState, 'loginStatus'> = {
		token,
		expirationDate,
		role: decodedRole,
		eclipsUserVerified: eclipsUsername ? true : false,
		authenticationError: false,
		missingUserError: '',
	}

	return appLoginData
}
