/*
	Uncomment following line to activate Why Did You Render utility.
	See https://github.com/welldone-software/why-did-you-render

	DO THIS ONLY IN DEVELOPMENT AS IT WILL DRASTICALLY DECREASE PERFORMANCE
	*/
// import './wdyr'

import React from 'react'
import { createRoot } from 'react-dom/client'
import * as serviceWorker from './serviceWorker'
import { TeloApp } from './TeloApp'
import { I18nLoader } from './utils/I18nLoader'

const root = createRoot(document.getElementById('root')!)

root.render(
	<I18nLoader>
		<TeloApp />
	</I18nLoader>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

console.log(`version: ${process.env.REACT_APP_VERSION}`)
