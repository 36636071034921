import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import dialogActions from '../features/dialog/actions'
import { selectDialog } from '../features/dialog/selectors'
import storeActions from '../features/stores/actions'
import { pxToRem } from '../libs/style'
import { DeleteStoreType } from '../model/dialog'
import { useTeloDispatch, useTeloSelector } from '../store'
import Button from '../styleguide/buttons/Button'
import DialogActions from '../styleguide/dialog/DialogActions'
import DialogContent from '../styleguide/dialog/DialogContent'
import DialogContentText from '../styleguide/dialog/DialogContentText'

const DialogContentTextStyled = styled(DialogContentText)`
	min-width: ${pxToRem(299)}rem;
`

const isDeleteStoreDialog = (dialog: any): dialog is DeleteStoreType =>
	dialog.type === 'deleteStore'

const DeleteStoreDialog = () => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()
	const dialog = useTeloSelector(selectDialog)

	return !isDeleteStoreDialog(dialog) ? null : (
		<>
			<DialogContent>
				<DialogContentTextStyled>
					{t('admin.deleteStoreQuestion').replace('%%1', dialog.store.name)}
				</DialogContentTextStyled>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => dispatch(dialogActions.closeDialog())}
					variant="outlined"
				>
					{t('app.cancel')}
				</Button>
				<Button
					onClick={() => {
						dispatch(storeActions.deleteStore(dialog.store)).finally(() =>
							dispatch(dialogActions.closeDialog()),
						)
					}}
					autoFocus
					variant="contained"
				>
					{t('app.yesDelete')}
				</Button>
			</DialogActions>
		</>
	)
}

export default DeleteStoreDialog
