import {
	addDoctorInPanelApi,
	createPanelApi,
	deletePanelApi,
	getDoctorPanelsApi,
	getPanelDoctorsTimingApi,
	getPanelsApi,
	removeDoctorInPanelApi,
	setDoctorInPanelActiveApi,
	updatePanelApi,
} from '../../apiCalls'
import { Id } from '../../model/model'
import {
	CreatePanelPayload,
	MapDoctorExamTypeAverageTime,
	Panel,
	UpdatePanelPayload,
} from '../../model/panel'
import { AppThunk, AppThunkPromise, TeloDispatch } from '../../store'
import notificationsActions from '../notifications/actions'
import uiActions from '../ui/actions'
import { slice } from './slice'

const getPanels =
	(doctorUsernames?: string[]): AppThunk =>
	(dispatch: TeloDispatch) => {
		getPanelsApi({ doctorUsernames }).then(
			panels => panels && dispatch(slice.actions._setPanels(panels)),
		)
	}

const getPanel =
	(panelId: Id): AppThunk =>
	(dispatch: TeloDispatch) => {
		getPanelsApi({ id: panelId }).then(
			panels =>
				panels && panels[0] && dispatch(slice.actions._setPanel(panels[0])),
		)
	}

const getDoctorPanels = (): AppThunk => (dispatch: TeloDispatch) => {
	getDoctorPanelsApi().then(
		panels => panels && dispatch(slice.actions._setPanels(panels)),
	)
}

const updatePanel =
	(panel: UpdatePanelPayload): AppThunkPromise<Panel | void> =>
	(dispatch: TeloDispatch) =>
		updatePanelApi(panel).then(panel => {
			if (panel) {
				dispatch(slice.actions._setPanel(panel))
				dispatch(
					notificationsActions.addNotification({
						type: 'success',
						message: 'panels.updateSuccess',
						autoClose: true,
						messageIsLabelKey: true,
					}),
				)
			} else {
				dispatch(
					notificationsActions.addNotification({
						type: 'error',
						message: 'panels.updateFailed',
						autoClose: true,
						messageIsLabelKey: true,
					}),
				)
			}
		})

const deletePanel =
	(panelId: Id): AppThunk =>
	(dispatch: TeloDispatch) => {
		deletePanelApi(panelId).then(
			deletedCount =>
				deletedCount && dispatch(slice.actions._removePanel(panelId)),
		)
	}

const createPanel =
	(panelData: CreatePanelPayload): AppThunkPromise =>
	(dispatch: TeloDispatch) =>
		createPanelApi(panelData).then(panel => {
			if (panel) {
				dispatch(slice.actions._setPanel(panel))
				dispatch(uiActions.setControlPanelActiveStep(5))
				dispatch(
					notificationsActions.addNotification({
						type: 'success',
						message: 'panels.creationSuccess',
						autoClose: true,
						messageIsLabelKey: true,
					}),
				)
			} else {
				dispatch(
					notificationsActions.addNotification({
						type: 'error',
						message: 'panels.createFailed',
						autoClose: true,
						messageIsLabelKey: true,
					}),
				)
			}
		})

const setDoctorActive =
	(panelId: string, active: boolean): AppThunkPromise<Panel | void> =>
	(dispatch: TeloDispatch) =>
		setDoctorInPanelActiveApi(panelId, active).then(panel => {
			panel && dispatch(slice.actions._setPanel(panel))
		})

const addDoctorInPanel =
	(panelId: string, username: string): AppThunkPromise<Panel | void> =>
	(dispatch: TeloDispatch) =>
		addDoctorInPanelApi(panelId, username).then(panel => {
			panel && dispatch(slice.actions._setPanel(panel))
		})

const removeDoctorInPanel =
	(panelId: string, username: string): AppThunkPromise<Panel | void> =>
	(dispatch: TeloDispatch) =>
		removeDoctorInPanelApi(panelId, username).then(panel => {
			panel && dispatch(slice.actions._setPanel(panel))
		})

const getPanelDoctorsTiming =
	(panelId: Id): AppThunkPromise<MapDoctorExamTypeAverageTime | void> =>
	async () => {
		return getPanelDoctorsTimingApi(panelId)
	}

const panelsActions = {
	getPanels,
	getPanel,
	updatePanel,
	createPanel,
	deletePanel,
	setDoctorActive,
	getDoctorPanels,
	addDoctorInPanel,
	removeDoctorInPanel,
	getPanelDoctorsTiming,
}

export default panelsActions
