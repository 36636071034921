import React from 'react'

import CloseIcon from '../../styleguide/icons/CloseIcon'
import { BannerWrapper, CloseButton } from './style'

type QueueNotificationsBannerProps = {
	message: string
	onClose: () => void
}

const QueueNotificationsBanner = (
	{ message, onClose }: QueueNotificationsBannerProps,
	ref: React.ForwardedRef<HTMLDivElement>,
) => {
	return (
		<BannerWrapper ref={ref}>
			{message}
			<CloseButton onClick={onClose}>
				<CloseIcon />
			</CloseButton>
		</BannerWrapper>
	)
}

export default React.forwardRef(QueueNotificationsBanner)
