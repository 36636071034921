import { useEffect } from 'react'
import jwtDecode from 'jwt-decode'
import { LoginToken } from '../model/model'
import { useExamStatusUpdate } from './useExamStatusUpdate'

// threshold to trigger exam status update in seconds
const EXAM_STATUS_UPDATE_THRESHOLD_SECONDS = 4
const INTERVAL_DELAY_MILLISECONDS = 3000

export const useOnTokenExpiration = (loginToken: string): void => {
	const { exp } = jwtDecode<LoginToken>(loginToken)
	const { setStartedExamToWaiting } = useExamStatusUpdate()

	useEffect(() => {
		const interval = setInterval(() => {
			if (
				Math.round(exp) - Math.round(Date.now() / 1000) <=
				EXAM_STATUS_UPDATE_THRESHOLD_SECONDS - 1
			) {
				setStartedExamToWaiting()
				clearInterval(interval)
				return
			}
		}, INTERVAL_DELAY_MILLISECONDS)

		return function cleanUp() {
			clearInterval(interval)
		}
	}, [exp, setStartedExamToWaiting])
}
