import { createApi } from '@reduxjs/toolkit/query/react'

import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { CDAResponse } from '../model/model'
import {
	CCDValidator,
	CDAModel,
} from '../components/ExamSummary/ExamSummaryCDA/model'
import { Exam } from '../model/exam'

import { unversionedApiUrl } from './common'
import { ServiceBaseUrl } from './serviceApi'
import { ServicesApiTag, ServicesReducerPath } from './constants'
import { CDADownloadQueryArg, CDAFilterParams } from './model'

export const cdaApi = createApi({
	reducerPath: ServicesReducerPath.CdaApi,
	tagTypes: [ServicesApiTag.Cda],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getExamData: builder.query<{}, CDAFilterParams>({
			query: ({ examId, type, sections }: CDAFilterParams) => ({
				url: `${ServiceBaseUrl.ExamsV3}/${examId}/cda?sections=${sections.join(
					',',
				)}&type=${type}`,
			}),
			extraOptions: { maxRetries: 0 },
		}),

		getAllCDAs: builder.query<CDAResponse, string>({
			query: examId => ({
				url: `${ServiceBaseUrl.CDAV3}/${examId}`,
			}),
			extraOptions: { maxRetries: 0 },
			providesTags: [ServicesApiTag.Cda],
		}),

		addCDA: builder.mutation<CDAModel | CCDValidator, CDAModel>({
			query: body => ({
				url: `${ServiceBaseUrl.CDAV3}`,
				method: 'POST',
				body,
			}),
			invalidatesTags: result => {
				if (result && 'resultsMetaData' in result) {
					return []
				}
				return [ServicesApiTag.Cda]
			},
		}),

		deleteCDAs: builder.mutation<string, string[]>({
			query: ids => ({
				url: `${ServiceBaseUrl.CDAV3}/delete/${ids.join(',')}`,
				method: 'DELETE',
			}),
			invalidatesTags: [ServicesApiTag.Cda],
		}),
		downloadCda: builder.query<string, CDADownloadQueryArg>({
			query: ({ cdaId }: CDADownloadQueryArg) => ({
				url: `${ServiceBaseUrl.CDAV3}/download/${cdaId}`,
				responseHandler: async (res: Response): Promise<string> =>
					window.URL.createObjectURL(await res.blob()),
			}),
			extraOptions: { maxRetries: 0 },
		}),
		downloadCdaDocument: builder.query<string, CDADownloadQueryArg>({
			query: ({ cdaId }: CDADownloadQueryArg) => ({
				url: `${ServiceBaseUrl.CDAV3}/${cdaId}/document/download`,
				responseHandler: async (res: Response): Promise<string> =>
					await res.text(),
			}),
			extraOptions: { maxRetries: 0 },
		}),
		importCda: builder.mutation<
			CDAModel | CCDValidator,
			{
				xml: string
				fileName: string
				storeId: string
				appointmentId: string
				examId: string
				examType: string
			}
		>({
			query: body => ({
				url: `${ServiceBaseUrl.CDAV3}/import`,
				method: 'POST',
				body: JSON.stringify(body),
			}),
			invalidatesTags: result => {
				if (result && 'resultsMetaData' in result) {
					return []
				}
				return [ServicesApiTag.Cda]
			},
		}),

		testImportCda: builder.mutation<
			CDAModel | CCDValidator,
			{
				xml: string
				fileName: string
				storeId: string
			}
		>({
			query: body => ({
				url: `${ServiceBaseUrl.CDAV3}/test-import`,
				method: 'POST',
				body: JSON.stringify(body),
			}),
			invalidatesTags: result => {
				if (result && 'resultsMetaData' in result) {
					return []
				}
				return [ServicesApiTag.Cda]
			},
		}),

		reconcile: builder.mutation<
			CDAModel,
			{
				cdaId: string
				patientId: string
				examId: string
			}
		>({
			query: ({ cdaId, patientId, examId }) => ({
				url: `${ServiceBaseUrl.CDAV3}/${cdaId}/reconcile`,
				method: 'PATCH',
				body: JSON.stringify({ patientId, examId }),
			}),
			invalidatesTags: [ServicesApiTag.Cda],
		}),

		importAndreconcile: builder.mutation<
			Exam,
			{
				patientId: string
				xml: string
				fileName: string
				storeId: string
			}
		>({
			query: body => ({
				url: `${ServiceBaseUrl.CDAV3}/import-and-reconcile`,
				method: 'POST',
				body: JSON.stringify(body),
			}),
			invalidatesTags: [ServicesApiTag.Cda],
		}),
	}),
})

export const {
	useLazyGetExamDataQuery,
	useGetAllCDAsQuery,
	useAddCDAMutation,
	useLazyDownloadCdaQuery,
	useLazyDownloadCdaDocumentQuery,
	useDeleteCDAsMutation,
	useImportCdaMutation,
	useTestImportCdaMutation,
	useReconcileMutation,
	useImportAndreconcileMutation,
} = cdaApi
