import { getTroubleShootingDataAPI } from '../../apiCalls'
import { AppThunk, TeloDispatch } from '../../store'
import { slice } from './slice'

const fetchTroubleShootingData = (): AppThunk => (dispatch: TeloDispatch) =>
	getTroubleShootingDataAPI().then(content => {
		content && dispatch(slice.actions.setContent(content))
	})

const troubleShootingActions = {
	...slice.actions,
	fetchTroubleShootingData,
}

export default troubleShootingActions
