import { Privilege } from '../../model/users'
import Yup from '../../yup'
import { AdminDocumentsFormValues } from './types'

export const adminDocumentsEditPrivileges: Privilege[] = ['StoreManager']

export const adminDocumentsFormValidationSchema = Yup.object().shape({
	name: Yup.string().required(),
	text: Yup.string().when('type', {
		is: 'text',
		then: () => Yup.string().required(),
	}),
	duration: Yup.number().positive().nullable().integer(),
})

export const defaultDocumentDuration = 1

export const fieldsToCheck = [
	'duration',
	'durationUnit',
	'active',
] as (keyof AdminDocumentsFormValues)[]
