import {
	compose,
	cond,
	flatMap,
	isArray,
	isEmpty,
	isUndefined,
	keys,
	negate,
	prop,
	some,
	stubFalse,
	stubTrue,
	union,
	update,
	values,
} from 'lodash/fp'

import {
	RetinoscopyEye,
	RetinoscopyFull,
} from '../../components/Retinoscopy/models'
import { AmslerGrid, AuxillaryTestingType } from '../../model/auxillaryTesting'
import { DiagnosticPharmaceuticalType } from '../../model/diagnosticPharmaceutical'
import { AuxiliaryData, CLOverRefractionValue } from '../../model/exam'
import {
	coverTestAlternating,
	coverTestUnilateral,
	CycloplegicEye,
	CycloplegicFull,
	ExamConditionSections,
} from '../../model/examCondition'
import { FundusExamMethodsType } from '../../model/fundusExamMethods'
import { GonioscopyType } from '../../model/gonioscopy'
import {
	NctTonometerData,
	PhoropterDataDayOrNight,
	PhoropterDataEyes,
	PhoropterDataPart,
	PhoropterDataWithAccuracy,
	PhoropterSingleEye,
	TonometerData,
} from '../../model/instruments'
import {
	BinocularAssessmentData,
	BloodPressureData,
	ColorTestData,
	CovertTestData,
	GoldmannTonometry,
	OcularHealthData,
	PachymetryManualData,
	PupilTestingData,
	PupilTestingNote,
	StereoTestData,
	VitalsData,
} from '../../model/manual'
import { OctType } from '../../model/oct'
import {
	OcularHealthAnteriorSegment,
	OcularHealthCondition,
	OcularHealthFundus,
	OcularHealthOpticNerve,
} from '../../model/ocularHealthCondition'
import { PediatricsVitalsType } from '../../model/pediatricsVitals'
import { TactileTonometryType } from '../../model/tactileTonometry'
import { flattenObject } from '../flattenObject'

import { getReplacedEmptyFormValues } from './commonConditions'
import {
	CheckboxFormKey,
	ConditionsValues,
	ConditionsFormValues,
} from './types'

export const formatConditionsDropdownOptions = (
	conditions?: OcularHealthCondition[],
) => {
	const conditionsFormatted = conditions
		? conditions
				.filter(c => c.enabled)
				.map(condition => ({
					label: condition.name,
					value: condition._id!,
				}))
		: []

	return conditionsFormatted
}

export const formatOcularHealthConditionValues = (
	auxillaryTesting: AuxillaryTestingType | undefined,
	diagnosticPharmaceutical: DiagnosticPharmaceuticalType | undefined,
	fundusExamMethods: FundusExamMethodsType | undefined,
	goldmanTonometry: GoldmannTonometry | undefined,
	oct: OctType | undefined,
	tactileTonometry: TactileTonometryType | undefined,
	gonioscopy: GonioscopyType | undefined,
	fundus: OcularHealthFundus | undefined,
	opticalNerv: OcularHealthOpticNerve | undefined,
	videoSliltLamp: OcularHealthAnteriorSegment | undefined,
	replace: boolean,
	formValues: OcularHealthData & { replaceValues: string },
) => {
	const filledValues = {
		...formValues,
		auxillaryTesting: fillValuesAuxillaryTesting(
			formValues['auxillaryTesting'],
			auxillaryTesting,
			replace,
		) as OcularHealthData['auxillaryTesting'],
		diagnosticPharmaceutical: fillValuesDiagnosticPharmaceutical(
			formValues['diagnosticPharmaceutical'],
			diagnosticPharmaceutical,
			replace,
		) as OcularHealthData['diagnosticPharmaceutical'],
		fundusExamMethods: fillValuesFundusExamMethods(
			formValues['fundusExamMethods'],
			fundusExamMethods,
			replace,
		) as OcularHealthData['fundusExamMethods'],
		goldmanTonometry: fillOcularHealthValues(
			formValues['goldmanTonometry'],
			goldmanTonometry,
			'goldmanTonometry',
			replace,
		) as OcularHealthData['goldmanTonometry'],
		oct: fillValuesOct(
			formValues['oct'],
			oct,
			replace,
		) as OcularHealthData['oct'],
		tactileTonometry: fillOcularHealthValues(
			formValues['tactileTonometry'],
			tactileTonometry,
			'tactileTonometry',
			replace,
		) as OcularHealthData['tactileTonometry'],
		gonioscopy: fillOcularHealthValues(
			formValues['gonioscopy'],
			gonioscopy,
			'gonioscopy',
			replace,
		) as OcularHealthData['gonioscopy'],
		opticalNerv: fillOcularHealthValues(
			formValues['opticalNerv'],
			opticalNerv,
			'opticalNerv',
			replace,
		) as OcularHealthData['opticalNerv'],
		fundus: fillOcularHealthValues(
			formValues['fundus'],
			fundus,
			'fundus',
			replace,
		) as OcularHealthData['fundus'],
		videoSliltLamp: fillOcularHealthValues(
			formValues['videoSliltLamp'],
			videoSliltLamp,
			'videoSliltLamp',
			replace,
		) as OcularHealthData['videoSliltLamp'],
	}
	return filledValues
}

export const shouldShowDialogOcularHealth = (
	auxillaryTesting: OcularHealthData['auxillaryTesting'],
	diagnosticPharmaceutical: OcularHealthData['diagnosticPharmaceutical'],
	fundusExamMethods: OcularHealthData['fundusExamMethods'],
	goldmanTonometry: OcularHealthData['goldmanTonometry'],
	oct: OcularHealthData['oct'],
	tactileTonometry: OcularHealthData['tactileTonometry'],
	gonioscopy: OcularHealthData['gonioscopy'],
	fundus: OcularHealthData['fundus'],
	opticalNerv: OcularHealthData['opticalNerv'],
	videoSlitLamp: OcularHealthData['videoSliltLamp'],
) => {
	const filledAuxillaryTesting = checkFilledAuxillaryTesting(auxillaryTesting)
	const filledDiagnosticPharmaceutical =
		!!diagnosticPharmaceutical?.diagnostics.length
	const filledFundusExamMethods =
		fundusExamMethods && checkFilledFundusExamMethods(fundusExamMethods)
	const filledGoldmanTonometry =
		goldmanTonometry && checkFilledFields(goldmanTonometry)
	const filledOct = checkFilledFieldsOct(oct)
	const filledTactileTonometry =
		tactileTonometry && checkFilledFields(tactileTonometry)
	const filledGonioscopy = gonioscopy && checkFilledFields(gonioscopy)
	const filledFundus = checkFilledFields(fundus)
	const filledOpticalNerve = checkFilledFields(opticalNerv)
	const filledSlitLamp = checkFilledFields(videoSlitLamp)

	return (
		filledAuxillaryTesting ||
		filledDiagnosticPharmaceutical ||
		filledFundusExamMethods ||
		filledGoldmanTonometry ||
		filledOct ||
		filledTactileTonometry ||
		filledGonioscopy ||
		filledFundus ||
		filledOpticalNerve ||
		filledSlitLamp
	)
}

export const checkFilledFields = (ocularHealthValues: {
	OD?: { [key: string]: string | undefined } | undefined
	OS?: { [key: string]: string | undefined } | undefined
}) => {
	const filledOD =
		ocularHealthValues.OD &&
		Object.values(ocularHealthValues.OD).some(value => !!value)
	const filledOS =
		ocularHealthValues.OS &&
		Object.values(ocularHealthValues.OS).some(value => !!value)

	return filledOD || filledOS
}

const checkFilledFieldsOct = (octValues: OctType) => {
	const filledAnteriorSegment = Object.values(octValues.anteriorSegment).some(
		value => !!value,
	)
	const filledOpticalNerve = Object.values(octValues.opticalNerve).some(
		value => !!value,
	)
	const filledRetina = Object.values(octValues.retina).some(value => !!value)

	return filledAnteriorSegment || filledOpticalNerve || filledRetina
}

const checkFilledFundusExamMethods = (
	fundusExamMethodsValues: FundusExamMethodsType,
) =>
	!!fundusExamMethodsValues?.dilated ||
	!!fundusExamMethodsValues?.reason?.length ||
	!!fundusExamMethodsValues?.methods?.length

const checkFilledAuxillaryTesting = (
	auxillaryTestingValues: AuxillaryTestingType,
) => {
	const filledOD = checkFilledAmslerGrid(auxillaryTestingValues.amslerGrid.OD)
	const filledOS = checkFilledAmslerGrid(auxillaryTestingValues.amslerGrid.OS)

	const filledAdditionalTesting =
		auxillaryTestingValues.additionalTesting.none ||
		!!auxillaryTestingValues.additionalTesting.note

	return filledOD || filledOS || filledAdditionalTesting
}

const checkFilledAmslerGrid = (amslerGridValues: AmslerGrid) =>
	!!amslerGridValues.note ||
	amslerGridValues.performed ||
	!amslerGridValues.notIndicated

const fillOcularHealthValues = (
	formValues: OcularHealthData[
		| 'goldmanTonometry'
		| 'tactileTonometry'
		| 'gonioscopy'
		| 'fundus'
		| 'opticalNerv'
		| 'videoSliltLamp'],
	conditionValues:
		| GoldmannTonometry
		| TactileTonometryType
		| GonioscopyType
		| OcularHealthOpticNerve
		| OcularHealthFundus
		| OcularHealthAnteriorSegment
		| undefined,
	name:
		| 'goldmanTonometry'
		| 'tactileTonometry'
		| 'gonioscopy'
		| 'fundus'
		| 'opticalNerv'
		| 'videoSliltLamp',
	replace: boolean,
) => {
	if (!conditionValues?.OD || !conditionValues?.OS || !formValues) {
		return { ...formValues }
	}

	const keysOD = [
		...new Set(
			Object.keys(formValues.OD || {}).concat(Object.keys(conditionValues?.OD)),
		),
	]

	const keysOS = [
		...new Set(
			Object.keys(formValues.OS || {}).concat(Object.keys(conditionValues?.OS)),
		),
	]

	const filledOD = keysOD.reduce((prev, key) => {
		const conditionValue = conditionValues.OD
			? conditionValues.OD?.[key as keyof typeof conditionValues.OD]
			: ''
		const formValue = formValues?.OD?.[key as keyof typeof formValues.OD]

		if ((replace || !formValue) && key !== 'note') {
			return { ...prev, [key]: conditionValue ?? '' }
		}

		return { ...prev, [key]: formValue }
	}, {})

	const filledOS = keysOS.reduce((prev, key) => {
		const conditionValue = conditionValues?.OS
			? conditionValues.OS?.[key as keyof typeof conditionValues.OD]
			: ''
		const formValue = formValues?.OS?.[key as keyof typeof formValues.OS]

		if ((replace || !formValue) && key !== 'note') {
			return { ...prev, [key]: conditionValue ?? '' }
		}

		return { ...prev, [key]: formValue }
	}, {})

	return {
		...formValues,
		OD: filledOD,
		OS: filledOS,
	} as OcularHealthData[typeof name]
}

const fillValuesDiagnosticPharmaceutical = (
	formValues: OcularHealthData['diagnosticPharmaceutical'],
	conditionValues: DiagnosticPharmaceuticalType | undefined,
	replace: boolean,
) => {
	if (!conditionValues || !formValues) {
		return formValues
	}

	if (replace || formValues.diagnostics) {
		return {
			...formValues,
			...conditionValues,
		} as OcularHealthData['diagnosticPharmaceutical']
	}

	return formValues
}

const fillValuesFundusExamMethods = (
	formValues: OcularHealthData['fundusExamMethods'],
	conditionValues: FundusExamMethodsType | undefined,
	replace: boolean,
) => {
	if (!conditionValues || !formValues) {
		return formValues
	}

	const filledValues = getFilledValues(formValues, conditionValues, replace)

	return {
		...formValues,
		...filledValues,
	} as OcularHealthData['fundusExamMethods']
}

const fillValuesOct = (
	formValues: OcularHealthData['oct'],
	conditionValues: OctType | undefined,
	replace: boolean,
) => {
	if (!conditionValues || !formValues) {
		return formValues
	}

	const DEFAULT_VAL = {
		OD: '',
		OS: '',
	}

	const filledAnteriorSegment = fillValuesNestedOct(
		formValues.anteriorSegment || DEFAULT_VAL,
		conditionValues.anteriorSegment,
		replace,
	)
	const filledOpticalNerve = fillValuesNestedOct(
		formValues.opticalNerve || DEFAULT_VAL,
		conditionValues.opticalNerve,
		replace,
	)
	const filledRetina = fillValuesNestedOct(
		formValues.retina || DEFAULT_VAL,
		conditionValues.retina,
		replace,
	)

	return {
		...formValues,
		anteriorSegment: filledAnteriorSegment,
		opticalNerve: filledOpticalNerve,
		retina: filledRetina,
	} as OcularHealthData['oct']
}

const fillValuesNestedOct = (
	formValues: OctType['anteriorSegment' | 'opticalNerve' | 'retina'],
	conditionValues: { OD: string; OS: string },
	replace: boolean,
) => {
	const OD =
		formValues?.OD && !replace ? formValues?.OD : conditionValues?.OD || ''
	const OS =
		formValues?.OS && !replace ? formValues?.OS : conditionValues?.OS || ''

	return {
		OD,
		OS,
	}
}

const mergeObjectConditionally = <T extends Record<string, any>>(
	obj1: T,
	obj2: T,
	replace: boolean,
) => {
	return Object.keys(obj1).reduce((result, key) => {
		const typedKey = key as keyof T
		result[typedKey] = replace
			? obj2[typedKey]
			: obj1[typedKey] || obj2[typedKey]
		return result
	}, {} as T)
}

const fillValuesAuxillaryTesting = (
	formValues: OcularHealthData['auxillaryTesting'],
	conditionValues: AuxillaryTestingType | undefined,
	replace: boolean,
) => {
	if (!conditionValues || !formValues) {
		return formValues
	}

	const filledAmslerOD = Object.keys(formValues.amslerGrid.OD).reduce(
		(prev, key) => {
			const conditionValue =
				conditionValues.amslerGrid.OD[
					key as keyof typeof conditionValues.amslerGrid.OD
				]
			const formValue =
				formValues.amslerGrid.OD[key as keyof typeof formValues.amslerGrid.OD]

			if (replace || !formValue) {
				return { ...prev, [key]: conditionValue }
			}

			return { ...prev, [key]: formValue }
		},
		{},
	)

	const filledAmslerOS = Object.keys(formValues.amslerGrid.OS).reduce(
		(prev, key) => {
			const conditionValue =
				conditionValues.amslerGrid.OS[
					key as keyof typeof conditionValues.amslerGrid.OS
				]
			const formValue =
				formValues.amslerGrid.OS[key as keyof typeof formValues.amslerGrid.OS]

			if (replace || !formValue) {
				return { ...prev, [key]: conditionValue }
			}

			return { ...prev, [key]: formValue }
		},
		{},
	)

	const filledAdditionalTesting = mergeObjectConditionally(
		formValues.additionalTesting,
		conditionValues.additionalTesting,
		replace,
	)

	return {
		additionalTesting: filledAdditionalTesting,
		amslerGrid: {
			OD: filledAmslerOD,
			OS: filledAmslerOS,
		},
	} as OcularHealthData['auxillaryTesting']
}

export const shouldShowDialogBinocularAssessment = (
	acaRatio: BinocularAssessmentData['acaRatio'],
	npa: BinocularAssessmentData['npa'],
	aniseikonia: BinocularAssessmentData['aniseikonia'],
	coverTestDistance: BinocularAssessmentData['coverTestDistance'],
	coverTestNear: BinocularAssessmentData['coverTestNear'],
	extraocular: BinocularAssessmentData['extraocular'],
	fusion: BinocularAssessmentData['fusion'],
	npc: BinocularAssessmentData['npc'],
	nraPra: BinocularAssessmentData['nraPra'],
	phoria: BinocularAssessmentData['phoria'],
	stereopsys: BinocularAssessmentData['stereopsys'],
) => {
	const filledAcaRatio = !!acaRatio.ratio
	const filledNpa = checkSomeFieldsFilled(npa)
	const filledAniseikonia = checkSomeFieldsFilled(aniseikonia)
	const filledCoverTestDistance = checkCoverTestFilled(coverTestDistance)
	const filledCoverTestNear = checkCoverTestFilled(coverTestNear)
	const filledExtraocular = checkExtraocularFilled(extraocular)
	const filledFusion = checkSomeFieldsFilled(fusion)
	const filledNpc = checkSomeFieldsFilled(npc)
	const filledNraPra = checkSomeFieldsFilled(nraPra)
	const filledPhoria = checkPhoriaFilled(phoria)
	const filledStereopsys = checkStereopsysFilled(stereopsys)

	return (
		filledAcaRatio ||
		filledNpa ||
		filledAniseikonia ||
		filledCoverTestDistance ||
		filledCoverTestNear ||
		filledExtraocular ||
		filledFusion ||
		filledNpc ||
		filledNraPra ||
		filledPhoria ||
		filledStereopsys
	)
}

export const checkSomeFieldsFilled = (
	binocularAssessmentValues: BinocularAssessmentData[
		| 'npa'
		| 'aniseikonia'
		| 'fusion'
		| 'npc'
		| 'nraPra'],
) => Object.values(binocularAssessmentValues).some(val => !!val)

export const checkCoverTestFilled = (covertTestValues: CovertTestData) =>
	Object.entries(covertTestValues).some(([key, val]) => !!val && key !== 'note')

export const checkExtraocularFilled = (
	extraocularValues: BinocularAssessmentData['extraocular'],
) => {
	const filledOD =
		extraocularValues.OD &&
		Object.values(extraocularValues.OD).some(value => !!value)
	const filledOS =
		extraocularValues.OS &&
		Object.values(extraocularValues.OS).some(value => !!value)

	return filledOD || filledOS
}

export const checkPhoriaFilled = (
	phoriaValues: BinocularAssessmentData['phoria'],
) => {
	const filledHorizontalDistance = Object.values(
		phoriaValues.horizontalDistance,
	).some(val => !!val)
	const filledVerticalDistance = Object.values(
		phoriaValues.verticalDistance,
	).some(val => !!val)
	const filledVerticalNear = Object.values(phoriaValues.verticalNear).some(
		val => !!val,
	)
	const filledHorizontalNear = Object.values(phoriaValues.horizontalNear).some(
		val => !!val,
	)

	return (
		filledHorizontalDistance ||
		filledVerticalDistance ||
		filledVerticalNear ||
		filledHorizontalNear
	)
}

export const checkStereopsysFilled = (
	stereopsysValues: BinocularAssessmentData['stereopsys'],
) => {
	const { suppressionOD, suppressionOS, note, ...rest } = stereopsysValues
	const stereopsysValuesClone = { ...rest }

	const filledValues = Object.values(stereopsysValuesClone).some(
		value => !!value,
	)

	return (
		filledValues ||
		!!stereopsysValues.suppressionOD ||
		!!stereopsysValues.suppressionOS
	)
}

export const formatBinocularAssessmentConditionValues = (
	acaRatio: BinocularAssessmentData['acaRatio'] | undefined,
	npa: BinocularAssessmentData['npa'] | undefined,
	aniseikonia: Omit<BinocularAssessmentData['aniseikonia'], 'note'> | undefined,
	coverTestNearAlternating: coverTestAlternating | undefined,
	coverTestNearUnilateral: coverTestUnilateral | undefined,
	coverTestDistanceAlternating: coverTestAlternating | undefined,
	coverTestDistanceUnilateral: coverTestUnilateral | undefined,
	extraocular:
		| Omit<BinocularAssessmentData['extraocular'], 'notes'>
		| undefined,
	fusion: Omit<BinocularAssessmentData['fusion'], 'note'> | undefined,
	npc: Omit<BinocularAssessmentData['npc'], 'note'> | undefined,
	nraPra: Omit<BinocularAssessmentData['nraPra'], 'note'> | undefined,
	phoria: BinocularAssessmentData['phoria'] | undefined,
	stereopsys: Omit<BinocularAssessmentData['stereopsys'], 'note'> | undefined,
	replace: boolean,
	formValues: BinocularAssessmentData,
) => {
	const filledValues = {
		...formValues,
		acaRatio: fillValuesAcaRatio(
			formValues['acaRatio'],
			acaRatio,
			replace,
		) as BinocularAssessmentData['acaRatio'],
		npa: fillBinocularAssessmentValues(
			formValues['npa'],
			npa,
			'npa',
			replace,
		) as BinocularAssessmentData['npa'],
		aniseikonia: fillBinocularAssessmentValues(
			formValues['aniseikonia'],
			aniseikonia,
			'aniseikonia',
			replace,
		) as BinocularAssessmentData['aniseikonia'],
		coverTestDistance: fillBinocularAssessmentValues(
			formValues['coverTestDistance'],
			coverTestDistanceAlternating && coverTestDistanceUnilateral
				? {
						...coverTestDistanceAlternating,
						...coverTestDistanceUnilateral,
						note: formValues['coverTestDistance'].note,
				  }
				: undefined,
			'coverTestDistance',
			replace,
		) as BinocularAssessmentData['coverTestDistance'],
		coverTestNear: fillBinocularAssessmentValues(
			formValues['coverTestNear'],
			coverTestNearAlternating && coverTestNearUnilateral
				? {
						...coverTestNearAlternating,
						...coverTestNearUnilateral,
						note: formValues['coverTestNear'].note,
				  }
				: undefined,
			'coverTestNear',
			replace,
		) as BinocularAssessmentData['coverTestNear'],
		fusion: fillBinocularAssessmentValues(
			formValues['fusion'],
			fusion,
			'fusion',
			replace,
		) as BinocularAssessmentData['fusion'],
		extraocular: fillExtraocularValues(
			formValues['extraocular'],
			extraocular,
			replace,
		) as BinocularAssessmentData['extraocular'],
		npc: fillBinocularAssessmentValues(
			formValues['npc'],
			npc,
			'npc',
			replace,
		) as BinocularAssessmentData['npc'],
		nraPra: fillBinocularAssessmentValues(
			formValues['nraPra'],
			nraPra,
			'nraPra',
			replace,
		) as BinocularAssessmentData['nraPra'],
		phoria: fillPhoriaValues(
			formValues['phoria'],
			phoria,
			replace,
		) as BinocularAssessmentData['phoria'],
		stereopsys: fillStereopsysValues(
			formValues['stereopsys'],
			stereopsys,
			replace,
		) as BinocularAssessmentData['stereopsys'],
	}

	return filledValues
}

const fillValuesAcaRatio = (
	formValues: BinocularAssessmentData['acaRatio'],
	conditionValues: BinocularAssessmentData['acaRatio'] | undefined,
	replace: boolean,
) => {
	if (!conditionValues || !formValues) {
		return formValues
	}

	if (replace || !formValues.ratio) {
		return {
			...formValues,
			...conditionValues,
		} as BinocularAssessmentData['acaRatio']
	}

	return formValues
}

const fillBinocularAssessmentValues = (
	formValues: BinocularAssessmentData[
		| 'npa'
		| 'aniseikonia'
		| 'npc'
		| 'nraPra'
		| 'fusion'
		| 'coverTestDistance'
		| 'coverTestNear'],
	conditionValues:
		| BinocularAssessmentData['npa' | 'coverTestDistance' | 'coverTestNear']
		| Omit<BinocularAssessmentData['nraPra'], 'note'>
		| Omit<BinocularAssessmentData['npc'], 'note'>
		| Omit<BinocularAssessmentData['fusion'], 'note'>
		| Omit<BinocularAssessmentData['aniseikonia'], 'note'>
		| undefined,
	name:
		| 'npa'
		| 'aniseikonia'
		| 'npc'
		| 'nraPra'
		| 'fusion'
		| 'coverTestDistance'
		| 'coverTestNear',
	replace: boolean,
) => {
	if (!conditionValues || !formValues) {
		return { ...formValues }
	}

	const filledValues = getFilledValues(formValues, conditionValues, replace)

	return {
		...formValues,
		...filledValues,
	} as BinocularAssessmentData[typeof name]
}

export const getConditionalFormKeys = <FV, CV>(
	formValues: FV,
	conditionValues: CV,
) =>
	compose<[FV], string[], string[]>(
		union(keys(conditionValues)),
		keys,
	)(formValues)

const getFilledValues = (
	formValues: ConditionsFormValues,
	conditionValues: ConditionsValues,
	shouldReplace: boolean,
) => {
	if (!formValues) {
		return formValues
	}

	const formKeys = getConditionalFormKeys(formValues, conditionValues)

	return formKeys.reduce((prev, key) => {
		const formValue = formValues[key as keyof ConditionsFormValues]

		const isFormValueAbsentOrIsCheckbox =
			isEmpty(formValue) || key in CheckboxFormKey

		if ((shouldReplace || isFormValueAbsentOrIsCheckbox) && key !== 'note') {
			const conditionValueByKey = conditionValues[key as keyof ConditionsValues]

			const conditionValue =
				!shouldReplace && key in CheckboxFormKey && isArray(formValue)
					? [...conditionValueByKey, ...formValue]
					: conditionValueByKey

			return { ...prev, [key]: conditionValue ?? '' }
		}

		return { ...prev, [key]: formValue }
	}, {})
}

const fillExtraocularValues = (
	formValues: BinocularAssessmentData['extraocular'],
	conditionValues:
		| Omit<BinocularAssessmentData['extraocular'], 'notes'>
		| undefined,
	replace: boolean,
) => {
	if (!conditionValues || !formValues) {
		return { ...formValues }
	}

	const filledOD = replace ? conditionValues.OD : formValues.OD
	const filledOS = replace ? conditionValues.OS : formValues.OS

	return {
		...formValues,
		OD: filledOD,
		OS: filledOS,
	} as BinocularAssessmentData['extraocular']
}

const fillPhoriaValues = (
	formValues: BinocularAssessmentData['phoria'],
	conditionValues: BinocularAssessmentData['phoria'] | undefined,
	replace: boolean,
) => {
	if (!conditionValues || !formValues) {
		return { ...formValues }
	}

	const filledHorizontalDistance = getFilledValues(
		formValues.horizontalDistance,
		conditionValues.horizontalDistance,
		replace,
	)

	const filledVerticalDistance = getFilledValues(
		formValues.verticalDistance,
		conditionValues.verticalDistance,
		replace,
	)

	const filledVerticalNear = getFilledValues(
		formValues.verticalNear,
		conditionValues.verticalNear,
		replace,
	)

	const filledHorizontalNear = getFilledValues(
		formValues.horizontalNear,
		conditionValues.horizontalNear,
		replace,
	)

	return {
		...formValues,
		horizontalDistance: filledHorizontalDistance,
		verticalDistance: filledVerticalDistance,
		verticalNear: filledVerticalNear,
		horizontalNear: filledHorizontalNear,
	} as BinocularAssessmentData['phoria']
}

const fillStereopsysValues = (
	formValues: BinocularAssessmentData['stereopsys'],
	conditionValues:
		| Omit<BinocularAssessmentData['stereopsys'], 'note'>
		| undefined,
	replace: boolean,
) => {
	if (!conditionValues || !formValues) {
		return { ...formValues }
	}

	const keys = [
		...new Set(Object.keys(formValues).concat(Object.keys(conditionValues))),
	]

	return keys.reduce((prev, key) => {
		const conditionValue = conditionValues[key as keyof typeof conditionValues]
		const formValue = formValues![key as keyof typeof formValues]

		if (replace || !formValue) {
			return { ...prev, [key]: conditionValue }
		}

		return { ...prev, [key]: formValue }
	}, {} as BinocularAssessmentData['stereopsys'])
}

export const shouldShowDialogBloodPressure = (
	bloodPressure: BloodPressureData,
) => {
	const { bp, arm, wrist, leftArmSitting, rightArmSitting } = bloodPressure
	const filledBp = Object.values(bp).some(val => !!val)

	return filledBp || arm || wrist || leftArmSitting || rightArmSitting
}

export const formatBloodPressureConditionValues = (
	conditionValues: Omit<BloodPressureData, 'note'> | undefined,
	replace: boolean,
	formValues: BloodPressureData,
) => {
	if (!conditionValues) {
		return { ...formValues }
	}

	const filledBp = Object.keys(conditionValues.bp).reduce((prev, key) => {
		const conditionValue =
			conditionValues.bp[key as keyof typeof conditionValues.bp]
		const formValue = formValues.bp[key as keyof typeof formValues.bp]

		if (replace || !formValue) {
			return { ...prev, [key]: conditionValue ?? '' }
		}

		return { ...prev, [key]: formValue }
	}, {})

	const filledValues = {
		...formValues,
		bp: filledBp,
		arm: conditionValues.arm,
		wrist: conditionValues.wrist,
		leftArmSitting: conditionValues.leftArmSitting,
		rightArmSitting: conditionValues.rightArmSitting,
	}

	return filledValues as BloodPressureData
}

export const shouldShowDialogColorVision = (colorTest: ColorTestData) => {
	const filledOD = Object.values(colorTest.OD).some(val => !!val)
	const filledOS = Object.values(colorTest.OS).some(val => !!val)

	return filledOD || filledOS
}

export const formatColorVisionConditionValues = (
	conditionValues = {} as ColorTestData,
	replace: boolean,
	formValues: ColorTestData,
) => {
	if (isEmpty(conditionValues)) {
		return { ...formValues }
	}

	const filledOD = getFilledEye(replace, formValues.OD, conditionValues.OD)
	const filledOS = getFilledEye(replace, formValues.OS, conditionValues.OS)

	const filledValues = {
		...formValues,
		OD: filledOD,
		OS: filledOS,
	}

	return filledValues as ColorTestData
}

export const shouldShowDialogStereo = (stereopsys: StereoTestData) =>
	!!stereopsys.randot || !!stereopsys.secArc || stereopsys.randomDotPatternsSeen

export const formatStereoConditionValues = (
	conditionValues: StereoTestData | undefined,
	replace: boolean,
	formValues: StereoTestData,
) => {
	if (!conditionValues) {
		return { ...formValues }
	}

	const filledValues = {
		...formValues,
		randot: getReplacedValue(
			conditionValues.randot,
			replace,
			formValues.randot,
		),
		secArc: getReplacedValue(
			conditionValues.secArc,
			replace,
			formValues.secArc,
		),
		randomDotPatternsSeen: conditionValues.randomDotPatternsSeen,
	}

	return filledValues as StereoTestData
}

export const shouldShowDialogPupil = (pupil: PupilTestingData) => {
	const { OD, OS, perrla, reactiveAccomodation } = pupil
	const filledOD = OD && Object.values(OD).some(val => !!val)
	const filledOS = OS && Object.values(OS).some(val => !!val)

	return filledOD || filledOS || perrla || reactiveAccomodation
}

export const formatPupilConditionValues = (
	conditionValues = {} as PupilTestingData,
	replace: boolean,
	formValues: PupilTestingNote,
) => {
	if (isEmpty(conditionValues)) {
		return { ...formValues }
	}

	const filledOD = getFilledEye(replace, formValues.OD, conditionValues.OD)
	const filledOS = getFilledEye(replace, formValues.OS, conditionValues.OS)

	const filledValues = {
		...formValues,
		OD: filledOD,
		OS: filledOS,
		perrla: conditionValues.perrla,
		reactiveAccomodation: conditionValues.reactiveAccomodation,
	}

	return filledValues as PupilTestingNote
}

export type ConditionalEyeValues =
	| PupilTestingData['OD' | 'OS']
	| ColorTestData['OD' | 'OS']
	| PachymetryManualData['OD' | 'OS']
	| RetinoscopyEye
	| CLOverRefractionValue
	| CycloplegicEye

export type ConditionalEyeFormValues =
	| PupilTestingData['OD' | 'OS']
	| ColorTestData['OD' | 'OS']
	| PachymetryManualData['OD' | 'OS']
	| RetinoscopyEye
	| PhoropterSingleEye
	| CycloplegicEye

const getFilledEye = (
	shouldReplace: boolean,
	formValues = {} as ConditionalEyeFormValues,
	conditionValues = {} as ConditionalEyeValues,
): ConditionalEyeFormValues | ConditionalEyeFormValues => {
	const formKeys = getConditionalFormKeys(formValues, conditionValues)

	return formKeys.reduce((prev, key) => {
		const formValue = formValues[key as keyof ConditionalEyeFormValues]

		if (shouldReplace || isEmpty(formValue)) {
			const conditionValue = conditionValues[key as keyof ConditionalEyeValues]

			return { ...prev, [key]: conditionValue ?? '' }
		}

		return { ...prev, [key]: formValue }
	}, {})
}

export const getShouldShowNctConditionsDialog = compose<
	[TonometerData],
	Record<string, any>,
	boolean
>(some<string | null>(negate(isEmpty)), nct => flattenObject(nct, '', true))

export const formatNctConditionValues = (
	conditionValues = {} as PachymetryManualData,
	replace: boolean,
	formValues: NctTonometerData,
) => {
	if (isEmpty(conditionValues)) {
		return { ...formValues }
	}

	const filledOD = getFilledEye(
		replace,
		formValues.manual?.OD,
		conditionValues.OD,
	)

	const filledOS = getFilledEye(
		replace,
		formValues.manual?.OS,
		conditionValues.OS,
	)

	const filledValues = {
		...formValues,
		manual: {
			OD: filledOD,
			OS: filledOS,
		},
	}

	return filledValues as NctTonometerData
}

export const shouldShowDialogPediatricVitals = (
	pediatricsVitals: PediatricsVitalsType,
) => {
	const { bmiPercentile, headOccipitalFrontalCircumference, weightForLength } =
		pediatricsVitals

	return (
		!!bmiPercentile || !!headOccipitalFrontalCircumference || !!weightForLength
	)
}

export const formatPediatricVitalsConditionValues = (
	conditionValues: Omit<VitalsData, 'notes'> | undefined,
	replace: boolean,
	formValues: VitalsData,
) => {
	if (!conditionValues) {
		return { ...formValues }
	}

	const keys = [
		...new Set(Object.keys(formValues).concat(Object.keys(conditionValues))),
	]

	return keys.reduce((prev, key) => {
		const conditionValue = conditionValues[key as keyof typeof conditionValues]
		const formValue = formValues![key as keyof typeof formValues]

		if (replace || !formValue) {
			return { ...prev, [key]: conditionValue }
		}

		return { ...prev, [key]: formValue }
	}, {} as VitalsData)
}

const getReplacedValue = (
	conditionValue: string | number | undefined,
	replace: boolean,
	formValue: string | number | undefined,
) => {
	return replace || !formValue ? conditionValue : formValue
}

export const getIsAnyCycloplegicRefractionFieldExistDialog = compose<
	[PhoropterDataPart],
	PhoropterDataDayOrNight,
	PhoropterDataWithAccuracy,
	PhoropterDataEyes | undefined | any,
	(string | null)[],
	boolean
>(
	some<string | null>(negate(isEmpty)),
	flatMap<unknown, string | null>(values),
	prop<PhoropterDataWithAccuracy, 'eyes'>('eyes'),
	prop<PhoropterDataDayOrNight, 'default'>('default'),
	prop<PhoropterDataPart, 'day'>('day'),
)

export const getShouldShowCycloplegicRefractionDialog = cond<
	PhoropterDataPart,
	boolean
>([
	[isEmpty, stubFalse],
	[stubTrue, getIsAnyCycloplegicRefractionFieldExistDialog],
])

export const getRefractionEmptyValues = compose<
	[PhoropterDataPart],
	PhoropterDataPart,
	PhoropterDataPart
>(
	update('day.default.eyes.OS', getReplacedEmptyFormValues),
	update('day.default.eyes.OD', getReplacedEmptyFormValues),
)

export const formatRefractionConditionValues = (
	replace: boolean,
	formValues: PhoropterDataPart,
	conditionValues?: CycloplegicFull,
) => {
	if (isUndefined(conditionValues)) {
		return { ...formValues }
	}

	// * is empty object
	if (isEmpty(conditionValues)) {
		return replace ? getRefractionEmptyValues(formValues) : formValues
	}

	const filledOD = getFilledEye(
		replace,
		formValues.day.default.eyes?.OD,
		conditionValues.OD,
	)

	const filledOS = getFilledEye(
		replace,
		formValues.day.default.eyes?.OS,
		conditionValues.OS,
	)

	const filledValues: PhoropterDataPart = {
		...formValues,
		day: {
			...formValues.day,
			default: {
				...formValues.day.default,
				eyes: {
					...formValues.day.default.eyes,
					OD: filledOD as PhoropterSingleEye,
					OS: filledOS as PhoropterSingleEye,
				},
			},
		},
	}

	return filledValues
}

export const isSupportedRefractionTest = (
	type: PropertyKey,
	conditions: ExamConditionSections,
): type is keyof ExamConditionSections => {
	return type in conditions
}

export const shouldShowDialogRetinoscopy = (
	values?: RetinoscopyFull,
): boolean => {
	if (isEmpty(values)) {
		return false
	}

	const filledOD = values.OD
		? Object.values(values.OD).some(val => !!val)
		: false

	const filledOS = values.OS
		? Object.values(values.OS).some(val => !!val)
		: false

	return filledOD || filledOS
}

export const getRetinoscopyEmptyValues = compose<
	[RetinoscopyFull],
	RetinoscopyFull,
	RetinoscopyFull
>(
	update('OS', getReplacedEmptyFormValues),
	update('OD', getReplacedEmptyFormValues),
)

export const formatRetinoscopyConditionValues = (
	replace: boolean,
	formValues: RetinoscopyFull,
	conditionValues?: RetinoscopyFull,
): RetinoscopyFull => {
	if (isUndefined(conditionValues)) {
		return { ...formValues }
	}

	// * is empty object
	if (isEmpty(conditionValues)) {
		return replace ? getRetinoscopyEmptyValues(formValues) : formValues
	}

	const filledOD = getFilledEye(replace, formValues.OD, conditionValues.OD)
	const filledOS = getFilledEye(replace, formValues.OS, conditionValues.OS)

	const filledValues: RetinoscopyFull = {
		...formValues,
		OD: filledOD as RetinoscopyEye,
		OS: filledOS as RetinoscopyEye,
	}

	return filledValues
}

export const getShouldShowRefractionDialog = (
	dryRetinoscopyFormValues = {} as RetinoscopyFull,
	wetRetinoscopyFormValues = {} as RetinoscopyFull,
	cycloplegicRefractionFormValues = {} as PhoropterDataPart,
) => {
	const shouldShowDryRetinoscopyDialog = shouldShowDialogRetinoscopy(
		dryRetinoscopyFormValues,
	)

	const shouldShowWetRetinoscopyDialog = shouldShowDialogRetinoscopy(
		wetRetinoscopyFormValues,
	)

	const shouldShowCycloplegicRefractionDialog =
		getShouldShowCycloplegicRefractionDialog(cycloplegicRefractionFormValues)

	return (
		shouldShowDryRetinoscopyDialog ||
		shouldShowWetRetinoscopyDialog ||
		shouldShowCycloplegicRefractionDialog
	)
}

export const formatDocAuxiliaryConditionValues = (
	conditionValues: AuxiliaryData | undefined,
	replace: boolean,
	formValues: AuxiliaryData,
) => {
	if (!conditionValues) {
		return { ...formValues }
	}

	const vergeancesHorizontal = fillDocAuxiliaryVergeancesHorizontal(
		formValues.vergeancesHorizontal,
		conditionValues.vergeancesHorizontal,
		replace,
	)

	const vergeancesVertical = fillDocAuxiliaryVergeancesVertical(
		formValues.vergeancesVertical,
		conditionValues.vergeancesVertical,
		replace,
	)

	const pursuitsAndSaccades = fillDocAuxiliaryPursuitsAndSaccades(
		formValues.pursuitsAndSaccades,
		conditionValues.pursuitsAndSaccades,
		replace,
	)

	return {
		vergeancesHorizontal,
		vergeancesVertical,
		pursuitsAndSaccades,
	} as AuxiliaryData
}

const fillDocAuxiliaryVergeancesHorizontal = (
	formValues: AuxiliaryData['vergeancesHorizontal'],
	conditionValues: AuxiliaryData['vergeancesHorizontal'],
	replace: boolean,
) => {
	if ((!conditionValues?.bi && !conditionValues?.bo) || !formValues) {
		return { ...formValues }
	}

	const { dist: formDistBI, near: formNearBI } = formValues.bi
	const { dist: formDistBO, near: formNearBO } = formValues.bo
	const { dist: conditionalDistBI, near: conditionalNearBI } =
		conditionValues.bi
	const { dist: conditionalDistBO, near: conditionalNearBO } =
		conditionValues.bo

	const filledBI = {
		dist: mergeObjectConditionally(formDistBI, conditionalDistBI, replace),
		near: mergeObjectConditionally(formNearBI, conditionalNearBI, replace),
	}

	const filledBO = {
		dist: mergeObjectConditionally(formDistBO, conditionalDistBO, replace),
		near: mergeObjectConditionally(formNearBO, conditionalNearBO, replace),
	}

	return { bi: filledBI, bo: filledBO } as AuxiliaryData['vergeancesHorizontal']
}

const fillDocAuxiliaryVergeancesVertical = (
	formValues: AuxiliaryData['vergeancesVertical'],
	conditionValues: AuxiliaryData['vergeancesVertical'],
	replace: boolean,
) => {
	if (
		(!conditionValues?.buOS &&
			!conditionValues?.buOD &&
			!conditionValues?.bdOS &&
			!conditionValues?.bdOD) ||
		!formValues
	) {
		return { ...formValues }
	}

	const {
		buOS: formBuOS,
		buOD: formBuOD,
		bdOS: formBdOS,
		bdOD: formBdOD,
	} = formValues
	const {
		buOS: conditionalnBuOS,
		buOD: conditionalnBuOD,
		bdOS: conditionalnBdOS,
		bdOD: conditionalnBdOD,
	} = conditionValues

	const filledBuOS = mergeObjectConditionally(
		formBuOS,
		conditionalnBuOS,
		replace,
	)

	const filledBuOD = mergeObjectConditionally(
		formBuOD,
		conditionalnBuOD,
		replace,
	)

	const filledBdOS = mergeObjectConditionally(
		formBdOS,
		conditionalnBdOS,
		replace,
	)

	const filledBdOD = mergeObjectConditionally(
		formBdOD,
		conditionalnBdOD,
		replace,
	)

	return {
		buOS: filledBuOS,
		buOD: filledBuOD,
		bdOS: filledBdOS,
		bdOD: filledBdOD,
	} as AuxiliaryData['vergeancesVertical']
}

const fillDocAuxiliaryPursuitsAndSaccades = (
	formValues: AuxiliaryData['pursuitsAndSaccades'],
	conditionValues: AuxiliaryData['pursuitsAndSaccades'],
	replace: boolean,
) => {
	if (!conditionValues || !formValues) {
		return formValues
	}

	const filledOD = mergeObjectConditionally(
		formValues.OD,
		conditionValues.OD,
		replace,
	)

	const filledOS = mergeObjectConditionally(
		formValues.OS,
		conditionValues.OS,
		replace,
	)

	return {
		OD: filledOD,
		OS: filledOS,
	} as AuxiliaryData['pursuitsAndSaccades']
}
