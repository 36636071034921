import { createApi } from '@reduxjs/toolkit/query/react'

import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { SystemCodeCVX } from '../model/cvx'
import { unversionedApiUrl } from './common'

const CVXType_TAG = 'cvx'

export const cvxApi = createApi({
	reducerPath: 'cvxApi',
	tagTypes: [CVXType_TAG],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getCvxCodes: builder.query<SystemCodeCVX[], void>({
			query: () => ({
				url: `/system/v1/cvx`,
			}),
			providesTags: [CVXType_TAG],
		}),
	}),
})

export const { useGetCvxCodesQuery } = cvxApi
