import { Optional } from '../utils/utilityTypes'
import {
	ReadTeloRouteParamsFn,
	TeloDestination,
	TeloPath,
	useTeloRouter,
} from './teloRouter'

/**
 * List of know Telo params, either from route or search
 */
type KnownTeloParam =
	| 'appointmentId'
	| 'currentExamId'
	| 'examId'
	| 'fhirAppointmentId'
	| 'fhirLocationId'
	| 'internalPatientId'
	| 'panelId'
	| 'patientId'
	| 'practiceId'
	| 'redirect-url'
	| 'referralDoctorId'
	| 'roomId'
	| 'stageId'
	| 'storeId'
	| 'username'
	| 'appType'
	| 'slot'
	| 'step'
	| 'as'
	| 'fu'
	| 'date'

/**
 * A Telo param can be decorated with options
 */
export type TeloParamWithOptions = {
	name: KnownTeloParam
	required: boolean
}

/**
 * Input Telo param can either be a bare KnownParam or a param decorated with options
 */
export type TeloParam = KnownTeloParam | TeloParamWithOptions

/*
	The result is an object which fields are only the ones listed as input.
	
	Examples with no compile error:
	- const { examId } = readTeloParams("examId")
	- const { examId, currentExamId, storeId } = readTeloParams("examId",
		{name: "currentExamId", required: true},
		{name: "storeId", required: false})

	Examples with compile error:
	- const { examId, currentExamId } = readTeloParams("examId") --> currentExamId not found!
*/
type AllowedResultField<K extends TeloParam> = K extends TeloParamWithOptions
	? Extract<KnownTeloParam, K['name']>
	: Extract<KnownTeloParam, K>

/*
	Determines whether the field is required or not.
	If T is a WithOptions object, then is required if T["required"] is true.
	Otherweise, it is required by default
*/
type IsRequiredField<T extends TeloParam> = T extends TeloParamWithOptions
	? T['required']
	: true

/*
	Determines field value according to its being required or not.

	Examples:
	- const { examId } = readTeloParams("examId") -> type of examId is "string"
	- const { examId } = readTeloParams({name: "examId", required: true}) -> type of examId is "string"
	- const { examId } = readTeloParams({name: "examId", required: false}) -> type of examId is "string | null"
*/
type FieldValue<T extends TeloParam> = IsRequiredField<T> extends true
	? string
	: string | null

/*
	The result of readTeloParams function.
	Contains only the fields listed in input and their value is:
	- string if the field is mandatory
	- string | null if the field is not mandatory
*/
type ReadTeloParamResult<T extends TeloParam[]> = {
	[K in T[number] as AllowedResultField<K>]: FieldValue<K>
}

/**
 * Reads provided telo params, either from URL (search params) or matched route
 */
export type ReadTeloParamsFn = <T extends TeloParam[]>(
	...teloParams: T
) => ReadTeloParamResult<T>

/**
 * Set the params on the searchParams objects and write it to the URL, with the provided options
 */
export type SetTeloParamsFn = (
	params: Partial<Record<KnownTeloParam, string>>,
	options?: { replace: boolean },
) => void

/**
 * Reads the specified query params from the browser URL,
 * that is window.localtion.search
 * @see readTeloSearchParamsFromURL
 */
export const readTeloSearchParamsFromBrowserURL: ReadTeloParamsFn = (
	...teloParams
) => readTeloSearchParamsFromURL(window.location.search, ...teloParams)

/**
 * Reads the specified query params from the URL or dynamic route part from a route
 *
 * A param can be specified directly and is considered required.
 *
 * ```
 * // `examId` is of type `string`, throws error if `examId` param is not found in URL
 * const { examId } = readTeloSearchParamsFromURL(myUrl, "examId")
 * ```
 *
 * A param can also be specified as object with options, to dictate if it is required or not
 *
 * ```
 * // `examId` is of type `string`, throws error if `examId` param is not found in URL
 * const { examId } = readTeloSearchParamsFromURL(myUrl, { name: "examId", required: true })
 *
 * // `examId` is of type `string | null`, if `examId` param is not found in URL then null is returned
 * const { examId } = readTeloSearchParamsFromURL(myUrl, { name: "examId", required: false })
 * ```
 *
 * Input can be mixed, for each field the behaviors described above apply
 *
 * ```
 * const { examId, currentExamId, storeId } =
 *  readTeloSearchParamsFromURL(myUrl,
 *    "examId",
 *    { name: "currentExamId", required: true },
 *    { name: "storeId", required: false }
 *  )
 * ```
 *
 * @param url the url to be parsed
 * @param teloParams the list of input fields, with or without options
 * @returns an object with only the fields listed in input with their value being "string" if required or "string | null" if not required
 */
export const readTeloSearchParamsFromURL = <T extends TeloParam[]>(
	url: string,
	...teloParams: T
): ReadTeloParamResult<T> => {
	if (!teloParams || teloParams.length === 0) {
		throw new Error('Specify at least one param to read from URL')
	}

	const urlParamsRaw = new URLSearchParams(url)
	const urlParams = toLowerCaseParamNames(urlParamsRaw)
	const res = readTeloParamsFromFunc(
		pName => urlParams.get(pName.toLowerCase()),
		...teloParams,
	)
	return res
}

/**
 * Helper to read params from what is returned by the `readParamByName` function.
 *
 * @param readParamByName a parameters provider function
 * @param teloParams the list of input fields, with or without options
 * @see readTeloSearchParamsFromURL
 */
export const readTeloParamsFromFunc = <T extends TeloParam[]>(
	readParamByName: (pName: string) => Optional<string>,
	...teloParams: T
): ReadTeloParamResult<T> => {
	return (
		[...teloParams]
			// normalize input creating all WithOptions objects
			.map(tp =>
				typeof tp === 'string'
					? { name: tp, required: true }
					: (tp as TeloParamWithOptions),
			)
			// read param values and create proper output object
			.map(({ name: tpName, required }) => {
				const paramValue = readParamByName(tpName)
				const validParamValue = paramValue != null && paramValue !== ''
				if (required && !validParamValue) {
					throw new Error(`Parameter '${tpName}' not found`)
				}
				return !validParamValue
					? { tpName, value: null }
					: { tpName, value: paramValue }
			})
			// create proper result object
			.reduce((prev, { tpName, value }) => {
				prev[tpName] = value
				return prev
			}, {} as any)
	)
}

function toLowerCaseParamNames(params: URLSearchParams) {
	const newParams = new URLSearchParams()
	for (const [name, value] of params) {
		newParams.append(name.toLowerCase(), value)
	}
	return newParams
}

export const isPathMatchingRoute = (
	route: string,
	currentPath: string,
): boolean => {
	if (route === currentPath) {
		return true
	}

	const routeCrumbs = route.split('/').filter(Boolean)
	const pathCrumbs = currentPath.split('/').filter(Boolean)

	if (routeCrumbs.length !== pathCrumbs.length) {
		return false
	}
	let match = false
	for (let i = 0; i < routeCrumbs.length; i++) {
		const rCrumb = routeCrumbs[i]
		if (!rCrumb.startsWith(':')) {
			match = rCrumb === pathCrumbs[i]
		}
	}
	return match
}

type ReadRouteParamsFromPathFn = (
	routeAndPath: { route: string; currentPath: string },
	...searchedParams: TeloParam[]
) => ReturnType<ReadTeloRouteParamsFn>

/**
 * Read route params from path
 * @param routeAndPath to check if they match and to parse params
 * @param searchedParams the params to read
 * @returns the value of read params
 */
export const readRouteParamsFromPath: ReadRouteParamsFromPathFn = (
	{ route, currentPath },
	...searchedParams
) => {
	if (searchedParams.length === 0) {
		throw new Error(`Provide at least one route param to search for`)
	}
	if (!isPathMatchingRoute(route, currentPath)) {
		throw new Error(
			`Route '${route}' and path '${currentPath}' are not matching`,
		)
	}
	const routeCrumbs = route.split('/')
	const pathCrumbs = currentPath.split('/')
	const routeParams = new Map<string, string>()
	for (let i = 0; i < routeCrumbs.length; i++) {
		const rCrumb = routeCrumbs[i]
		const pCrumb = pathCrumbs[i]
		if (rCrumb.startsWith(':')) {
			routeParams.set(rCrumb.substring(1), pCrumb)
		}
	}
	return readTeloParamsFromFunc(
		pName => routeParams.get(pName),
		...searchedParams,
	)
}

export const isPath = (to: TeloDestination): to is TeloPath => {
	return !!(to as TeloPath)?.pathname
}

export const useCheckCurrentPage = () => {
	const { getLocation } = useTeloRouter()
	const location = getLocation()
	const currentPath = location.pathname
	const isCurrentPage = (page: string) => currentPath.includes(page)

	const isWorklistPage = isCurrentPage('worklist')
	const isExamPage = isCurrentPage('/exam') || isCurrentPage('/call')
	const isAdminPage = isCurrentPage('/admin')
	const isProfilePage = isCurrentPage('/profile')
	const isRenewalApp = isCurrentPage('/renewal')
	const isStoreSelectionPage = isCurrentPage('/store-selection')
	const isStoreSettingsPage =
		isCurrentPage('/store') && isCurrentPage('/settings')

	return {
		isWorklistPage,
		isExamPage,
		isAdminPage,
		isProfilePage,
		isRenewalApp,
		isStoreSelectionPage,
		isStoreSettingsPage,
	}
}
