import { isSameDay } from 'date-fns'
import { toDate, utcToZonedTime } from 'date-fns-tz'
import { calculateAppointmentStatus } from '../../libs/appointments'
import { AppointmentWithStatus } from '../../model/appointment'
import { Id, RootState } from '../../model/model'

export const selectAvailableSlots = (state: RootState) =>
	state.appointments.availableSlots
export const lockedSlotId = (state: RootState) =>
	state.appointments.lockedSlotId
export const selectedScheduleId = (state: RootState) =>
	state.appointments.scheduleId

export const selectAppointment =
	(id: Id, isOttMode?: boolean, ottAppointment?: AppointmentWithStatus[]) =>
	(state: RootState): AppointmentWithStatus | undefined => {
		const appointment = (
			isOttMode && ottAppointment ? ottAppointment : state.appointments.today
		).find(({ _id }) => _id === id)

		if (!appointment) {
			return undefined
		}

		const exams = Object.values(state.exams)
		const examWithAppId = exams
			.filter(exam => exam.appointmentId === appointment._id)
			.map(exam => {
				const timeZone = exam.store.timezone
				const dateTimezoned = utcToZonedTime(
					exam.checkedInAt || exam.externalAppointment.date,
					timeZone,
				)

				return {
					...exam,
					dateTimezoned,
				}
			})

		const exam =
			examWithAppId && examWithAppId.length > 1
				? examWithAppId.find(exam => {
						const exists = isSameDay(
							exam.dateTimezoned,
							utcToZonedTime(toDate(appointment.date), appointment.timezone),
						)
						return exists
				  })
				: examWithAppId[0]
		return {
			...appointment,
			status: calculateAppointmentStatus(appointment, exam),
			history: exam ? exam.history : [],
		}
	}

export const selectDIFByAppointmentId =
	(appointmentId: Id) => (state: RootState) => {
		const dif =
			state.appointments.digitalIntakaForm &&
			state.appointments.digitalIntakaForm[appointmentId]

		return dif || undefined
	}

export const forceRefreshSelector = (state: RootState) =>
	state.appointments.forceRefresh
