import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { TimeoutsState } from '../../model/model'
import { TimeoutConfigWithId } from '../../model/timeoutConfig'

const initialState: TimeoutsState = {
	sessionTimeoutConfig: { hours: 12, minutes: 0 },
	inactivityTimeoutConfig: { hours: 0, minutes: 15 },
}

export const slice = createSlice({
	name: 'timeouts',
	initialState,
	reducers: {
		_setTimeout: (state, { payload }: PayloadAction<TimeoutConfigWithId>) =>
			payload,
	},
})

export default slice.reducer
