import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ContactLenses, ContactLensesCatalogData } from '../../model/exam'
import { Symptom, SymptomsUpdatePartial } from '../../model/symptoms'
import { ContactLensesState } from '../../model/model'

const initialState: Symptom[] = []

export const slice = createSlice({
	name: 'symptoms',
	initialState,
	reducers: {
		_loadSymptoms: (state, { payload }: PayloadAction<Symptom[]>) => {
			state = payload
		},
		setSymptomsEnabled: (
			state,
			{ payload: { _id, enabled } }: PayloadAction<SymptomsUpdatePartial>,
		) => {
			if (enabled !== undefined) {
				const index = state.findIndex(
					(item: SymptomsUpdatePartial) => item._id === _id,
				)
				state[index].enabled = enabled
			}
		},
	},
})

export const { setSymptomsEnabled } = slice.actions

export default slice.reducer
