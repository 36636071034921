import React from 'react'

import { selectStoreId } from '../features/app/selectors'
import { selectUsername, selectUserRole } from '../features/auth/selectors'
import dialogActions from '../features/dialog/actions'
import { selectDialog } from '../features/dialog/selectors'
import examsActions from '../features/exams/actions'
import prescriptionsActions from '../features/prescriptions/actions'
import { selectPrescriptionByExamId } from '../features/prescriptions/selectors'
import { selectContactLensesSignature } from '../features/signRequests/selectors'
import { getUserDashboard } from '../libs/auth'
import { Dialog, QuitWithTrialType } from '../model/dialog'
import { RootState } from '../model/model'
import { useTeloRouter } from '../routing/teloRouter'
import { useTeloDispatch, useTeloSelector } from '../store'

import QuitExamDialog from './Prescription/RX/QuitExamDialog'

const isQuitWithTrialDialog = (dialog: Dialog): dialog is QuitWithTrialType =>
	typeof dialog === 'object' && 'examId' in dialog

const QuitWithTrialDialog = () => {
	const dispatch = useTeloDispatch()

	const dialog = useTeloSelector(selectDialog)
	const storeId = useTeloSelector(selectStoreId)
	const username = useTeloSelector(selectUsername)
	const userRole = useTeloSelector(selectUserRole)

	const examId = (dialog as QuitWithTrialType).examId
	const open = isQuitWithTrialDialog(dialog)

	const prescription = useTeloSelector((state: RootState) =>
		selectPrescriptionByExamId(state, examId),
	)

	const contactLensesSignature = useTeloSelector(
		selectContactLensesSignature(examId),
	)
	const { navigate } = useTeloRouter()

	const handleCloseExamWithTrial = async (generateChart?: boolean) => {
		if (!isQuitWithTrialDialog(dialog)) {
			return
		}

		await dispatch(
			examsActions.setExamStatus({
				id: dialog.examId,
				status: 'LensTrialQuit',
				username,
			}),
		)

		// if there is prescription when closing with exam trial, we should sign and send it
		// https://abstractsrl.atlassian.net/browse/TEL-7623
		prescription &&
			(await dispatch(
				prescriptionsActions.updatePrescription({
					examId,
					prescriptionId: prescription._id,
					prescriptionData: prescription?.data,
					contactLensesSignature: contactLensesSignature?.data,
					send: true,
				}),
			))

		generateChart && (await dispatch(examsActions.generateChart(examId)))

		dispatch(dialogActions.closeDialog())
		navigate(getUserDashboard(userRole, storeId))
	}

	return !isQuitWithTrialDialog(dialog) ? null : (
		<QuitExamDialog
			examId={dialog.examId}
			open={open}
			confirmDialog={handleCloseExamWithTrial}
			closeDialog={() => dispatch(dialogActions.closeDialog())}
		/>
	)
}

export default QuitWithTrialDialog
