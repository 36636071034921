import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Id } from "../../model/model"

import { InternalPatient } from '../../model/patient'
import { PatientMatch, PendingDataToCreateExam } from '../../model/patientMatch'

const initialState: PatientMatch = {}

export const slice = createSlice({
	name: 'patientMatch',
	initialState,
	reducers: {
		_setCandidates: (state, { payload }: PayloadAction<InternalPatient[]>) => {
			state.candidates = payload
		},
		setPendingData: (
			state,
			{ payload }: PayloadAction<PendingDataToCreateExam | undefined>,
		) => {
			state.pendingDataToCreateExam = payload
		},
		_clearPatientMatchData: state => {
			return (state = initialState)
		},
		setInternalPatientId: (
			state,
			{ payload }: PayloadAction<Id | undefined>,
		) => {
			state.internalPatientId = payload
		},
	},
})

export default slice.reducer
