import React from 'react'
import styled from 'styled-components'

import {
	Step,
	StepLabel,
	Stepper as MStepper,
	StepperProps,
} from '@mui/material'
import { StepIconProps } from '@mui/material/StepIcon'

import ThickIcon from './icons/ThickIcon'
import theme from './theme'

interface StepIconPropsWithIndex extends StepIconProps {
	index: number
}

const StepIcon: React.FC<StepIconPropsWithIndex> = props => {
	const { active, completed, index, className } = props

	const classes = `${completed ? 'completed' : 'not-completed'} ${
		active ? ' active' : ''
	} ${className}`

	return (
		<div className={classes}>
			{completed ? (
				<ThickIcon />
			) : (
				<div className={`point${completed ? ' completed' : ''}`}>
					<span className={`number${completed ? ' completed' : ''}`}>
						{index}
					</span>
				</div>
			)}
		</div>
	)
}

const StyledStepIcon = styled(StepIcon)`
  border-radius: 10rem;
  border: solid 1px ${theme.palette.grey[500]};
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 70%;
  }

  &.completed {
    background-color: #ffffff;
  }

  &.completed svg {
    color: #000000;
  }

  .point {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .number {
    font-size: 14px;
    color: #000000;

    &.active {
      color: #ffffff;
    }
  }
}

.number {
  font-size: 14px;
  color: #000000;

  &.active {
    color: #ffffff;
  }
}
`

const StyledStepper = styled(MStepper)<{ alternativeColors: boolean }>`
	position: relative;
	z-index: ${theme.zIndex.pageElements};
	padding: ${theme.spacing(3)} 0 ${theme.spacing(4)};

	.MuiStep-alternativeLabel {
		height: 100%;
	}

	.MuiStepLabel-root.MuiStepLabel-alternativeLabel {
		height: 100%;
		flex-direction: column-reverse;
	}

	.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
		margin-top: 0;
		margin-bottom: 16px;
		text-decoration: none;
	}

	.MuiStepConnector-alternativeLabel {
		top: auto;
		bottom: 12px;
	}

	.MuiStepLabel-labelContainer {
		margin-bottom: auto;
	}

	${props =>
		props.alternativeColors
			? `
		.active {
			background-color: ${theme.palette.aquamarine[500]};
			color: ${theme.palette.aquamarine[500]};
			border: none;

			.point{
				background-color: transparent;
			}
		}
	`
			: ''}
	.MuiStepLabel-label::first-letter {
		text-transform: uppercase;
	}

	.MuiStepLabel-label {
		text-decoration: ${props =>
			props.alternativeColors ? 'none' : 'underline'};
		color: ${props =>
			props.alternativeColors
				? theme.palette.grey[600]
				: theme.palette.grey[900]};
	}

	.Mui-disabled .MuiStepLabel-label {
		color: ${theme.palette.grey[500]};
	}

	.active-step,
	.MuiStepLabel-active,
	.MuiStepIcon-root.MuiStepIcon-active {
		text-decoration: none;
		font-weight: 900;

		span {
			text-decoration: none;
		}
	}

	.MuiStepLabel-labelContainer .Mui-active {
		font-weight: 900;

		span {
			text-decoration: none;
		}
	}
`

const Underlined = styled.span<{ alternativeColors: boolean }>`
	text-decoration: ${props => (props.alternativeColors ? 'none' : 'underline')};
	text-underline-offset: 0.2em;
`

interface Props extends Omit<StepperProps, 'children'> {
	steps: string[]
	setActiveStep?: (stepIndex: number) => void
	enableClick?: boolean
	disabledStepsIndexes?: number[]
	completedStepsIndexes?: number[]
	onTopLabels?: boolean
	alternativeColors?: boolean
	StepIconComponent?: React.ElementType
}

const Stepper: React.FC<Props> = ({
	activeStep,
	setActiveStep,
	steps,
	enableClick,
	disabledStepsIndexes = [],
	completedStepsIndexes = [],
	onTopLabels = true,
	alternativeColors = false,
	...props
}) => {
	const ottClassName = (index: number) => {
		if (index === 1) {
			return 'ott-preTest-Lensometry'
		} else if (index === 2) {
			return 'ott-preTest-Autorefraction'
		} else if (index === 6) {
			return 'ott-preTest-ManualInputs'
		}
	}

	return (
		<StyledStepper
			activeStep={activeStep}
			{...props}
			alternativeLabel={onTopLabels}
			alternativeColors={alternativeColors}
		>
			{steps.map((label, i) => {
				const disabledStep = disabledStepsIndexes.includes(i)
				const completed = props.nonLinear
					? completedStepsIndexes.includes(i)
					: undefined
				return (
					<Step
						key={label}
						color="secondary"
						disabled={disabledStep}
						completed={completed}
						onClick={
							(enableClick && disabledStep) || !disabledStep
								? () => setActiveStep && setActiveStep(i)
								: undefined
						}
						className={ottClassName(i) && activeStep === i ? 'active-step' : ''}
					>
						<StepLabel
							StepIconComponent={props => (
								<StyledStepIcon {...props} index={i + 1} />
							)}
						>
							<Underlined alternativeColors={alternativeColors}>
								{label}
							</Underlined>
						</StepLabel>
					</Step>
				)
			})}
		</StyledStepper>
	)
}

export default Stepper
