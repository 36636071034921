import React from 'react'

import { SvgProps } from '../../model/types'

const ThickIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="currentColor"
			{...props}
		>
			<path d="M17.6599 4.55379C17.9854 4.87922 17.9854 5.40686 17.6599 5.7323L8.29077 15.1015C7.93279 15.4594 7.35239 15.4594 6.99441 15.1015L2.33929 10.4463C2.01386 10.1209 2.01386 9.59325 2.33929 9.26783C2.66472 8.94241 3.19236 8.94241 3.5178 9.26783L7.64259 13.3926L16.4814 4.55379C16.8069 4.22835 17.3345 4.22835 17.6599 4.55379Z" />
		</svg>
	)
}

export default ThickIcon
