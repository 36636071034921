import {
	AuxiliaryData,
	PursuitSaccadesData,
	VergeancesData,
	VergeancesVerticalData,
} from '../model/exam'
import { StandardNotification } from '../model/model'
import { OcularHealthData } from '../model/manual'
import { AddNotificationPayload } from '../features/notifications/types'

import { checkFilledFields, shouldShowDialogOcularHealth } from './conditions'

//As all values can be saved as "" we have to check if they really have a value

export const checkIfExamHasAuxiliaryValues = (auxiliary: AuxiliaryData) => {
	const { pursuitsAndSaccades, vergeancesHorizontal, vergeancesVertical } =
		auxiliary

	const pursuitSaccades =
		pursuitsAndSaccades && checkPursuitSaccadesEyes(pursuitsAndSaccades)
	const vergencesV =
		vergeancesVertical && checkVerticalVergencesEyes(vergeancesVertical)
	const vergencesH =
		vergeancesHorizontal && checkHorizontalVergencesEyes(vergeancesHorizontal)

	return pursuitSaccades || vergencesV || vergencesH
}

const checkPursuitSaccadesEyes = (pursuitSaccadesData: PursuitSaccadesData) => {
	const { OD, OS } = pursuitSaccadesData

	return hasValues(OD) || hasValues(OS)
}

const checkVerticalVergencesEyes = (
	vergencesVerticalData: VergeancesVerticalData,
) => {
	const { buOS, buOD, bdOD, bdOS } = vergencesVerticalData

	return (
		hasValues(buOD) || hasValues(buOS) || hasValues(bdOD) || hasValues(bdOS)
	)
}

const checkHorizontalVergencesEyes = (vergencesHorizontal: VergeancesData) => {
	const {
		bi: { dist: distBI, near: nearBI },
		bo: { dist: distBO, near: nearBO },
	} = vergencesHorizontal

	const bi = hasValues(distBI) || hasValues(nearBI)
	const bo = hasValues(distBO) || hasValues(nearBO)

	return bi || bo
}

export const checkIfExamHasOcularHealthValues = (
	ocularHealthData?: OcularHealthData,
) => {
	if (!ocularHealthData) {
		return false
	}

	const {
		videoSliltLamp,
		opticalNerv,
		fundus,
		goldmanTonometry,
		oct,
		tactileTonometry,
		fundusExamMethods,
		auxillaryTesting,
		diagnosticPharmaceutical,
		gonioscopy,
	} = ocularHealthData

	const hasVideoOpticalFundus = shouldShowDialogOcularHealth(
		auxillaryTesting,
		diagnosticPharmaceutical,
		fundusExamMethods,
		goldmanTonometry,
		oct,
		tactileTonometry,
		gonioscopy,
		fundus,
		opticalNerv,
		videoSliltLamp,
	)
	const hasGoldmanTonometry =
		goldmanTonometry && checkFilledFields(goldmanTonometry)
	const hasTactileTonometry =
		tactileTonometry && checkFilledFields(tactileTonometry)
	const hasFundusExamMethods =
		fundusExamMethods && hasValues(fundusExamMethods, true)
	const hasDiagnosticPharmaceutical =
		!!diagnosticPharmaceutical?.diagnostics.length ||
		!!diagnosticPharmaceutical?.timestamp

	return (
		hasVideoOpticalFundus ||
		hasGoldmanTonometry ||
		hasTactileTonometry ||
		hasFundusExamMethods ||
		hasDiagnosticPharmaceutical ||
		hasAuxiliaryTesting(auxillaryTesting)
	)
}

const hasAuxiliaryTesting = (
	auxiliaryTesting: OcularHealthData['auxillaryTesting'],
) => {
	if (!auxiliaryTesting) {
		return false
	}
	const { amslerGrid, additionalTesting } = auxiliaryTesting

	const amslerGridPerformed = amslerGrid.OD.performed || amslerGrid.OS.performed
	const amslerGridNotes = !!amslerGrid.OD.note || !!amslerGrid.OS.note
	const hasAdditionalTesting =
		additionalTesting.none || !!additionalTesting.note

	return amslerGridNotes || amslerGridPerformed || hasAdditionalTesting
}

const hasValues = (
	obj: { [key: string]: string | boolean | string[] | object },
	fundusExamMethods?: boolean,
) => {
	if (fundusExamMethods) {
		return Object.values(obj).some(
			value => !!value && (value as string | string[]).length,
		)
	}

	return Object.values(obj).some(value => !!value)
}

export const exportSucceededNotification: AddNotificationPayload<StandardNotification> =
	{
		type: 'success',
		message: 'doctor.dataImportSuccess',
		autoClose: true,
		messageIsLabelKey: true,
	}
