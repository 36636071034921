import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import appConfig from '../config'
import { ExamEmergencyAccess } from '../model/examEmergencyAccess'

export const emergencyAccessApi = createApi({
	reducerPath: 'emergencyAccess',
	baseQuery: staggeredBaseQueryWithBailOut(appConfig.apiUrl),
	tagTypes: ['emergencyAccessUser', 'emergencyAccessExam'],
	endpoints: builder => ({
		requireEmergencyAccess: builder.mutation<
			ExamEmergencyAccess,
			{
				examId: string
				expiredAt: number
				reason: string
			}
		>({
			query: ({ examId, ...payload }) => ({
				url: `/exams/${examId}/emergency-accesses`,
				method: 'POST',
				body: JSON.stringify(payload),
			}),
			invalidatesTags: ['emergencyAccessUser', 'emergencyAccessExam'],
		}),
		userEmergencyAccess: builder.query<
			ExamEmergencyAccess[],
			{
				onlyActive?: boolean
			}
		>({
			query: ({ onlyActive }) => ({
				url: `/users/emergency-accesses?onlyActive=${!!onlyActive}`,
				method: 'GET',
			}),
			providesTags: ['emergencyAccessUser'],
		}),

		examEmergencyAccess: builder.query<
			ExamEmergencyAccess[],
			{
				examId: string
				onlyActive?: boolean
				storeId?: string
			}
		>({
			query: ({ examId, onlyActive, storeId }) => ({
				url: `/exams/${examId}/emergency-accesses?onlyActive=${!!onlyActive}`,
				method: 'GET',
				headers: {
					'x-store': storeId,
				},
			}),
			providesTags: ['emergencyAccessExam'],
		}),
	}),
})

export const {
	useRequireEmergencyAccessMutation,
	useUserEmergencyAccessQuery,
	useExamEmergencyAccessQuery,
} = emergencyAccessApi
