import {
	FieldsData,
	Path,
	FieldDataMutationPayload,
	FieldDataQueryPayload,
} from './model'
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers'
import {
	BaseQueryFn,
	QueryReturnValue,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import config from '../config'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import store from '../store'

const QUERY_TIMEOUT = 100
const MUTATION_TIMEOUT = 1500

let timerQuery: number | null
let timerMutation: number | null

type Entry = {
	cacheKey: string
	examId: string
	path: string
	index?: number
	status: 'to_be_called' | 'done' | 'in_progress'
	res: (okResponse: { data: any }) => void
	rej: (koResponse: { error: unknown }) => void
}

type MutationEntry = Entry & {
	value: unknown
}

type QueryEntry = Entry

const queriesMap: Record<string, QueryEntry> = {}
const mutationsMap: Record<string, MutationEntry> = {}

const queryFn = staggeredBaseQueryWithBailOut(config.apiUrl) as BaseQueryFn<
	{ url: string; method?: string; body?: unknown },
	{ path: string; value: unknown; index?: number }[],
	FetchBaseQueryError
>

const getCacheKey = (payload: {
	examId: string
	index?: number
	path: string
}) => {
	const { examId, index, path } = payload

	return `${examId}_${index}_${path}`
}

const executeQueries = () => {
	const queriesToPlace = Object.entries(queriesMap).filter(
		([, { status }]) => status === 'to_be_called',
	)

	queriesToPlace.forEach(([, query]) => {
		query.status = 'in_progress'
	})

	const queriesByExam = queriesToPlace.reduce((result, [, query]) => {
		if (!result[query.examId]) {
			result[query.examId] = []
		}
		result[query.examId].push(query)
		return result
	}, {} as Record<string, QueryEntry[]>)

	Object.values(queriesByExam).forEach(async queries => {
		const examId = queries[0].examId

		const controller = new AbortController()
		const signal = controller.signal

		const result = await queryFn(
			{
				url: `/exams/${examId}/get-fields-data`,
				// We use a POST instead of a GET because we are using the body
				method: 'POST',
				body: JSON.stringify(
					queries.map(({ path, index }) => ({ path, index })),
				),
			},
			{
				signal,
				abort: () => {},
				dispatch: store.dispatch,
				getState: store.getState,
				endpoint: 'getFieldsData',
				type: 'query',
				extra: {},
			},
			{},
		)

		if (result.data) {
			result.data.forEach(result => {
				const query = queries.find(
					query => query.path === result.path && query.index === result.index,
				)
				if (!query) {
					throw new Error(
						`Response ${JSON.stringify(result)} does not match any query`,
					)
				}
				query.status = 'done'
				query.res({ data: result })
			})
		} else if (result.error) {
			queries.forEach(query => {
				query.status = 'done'
				query.rej({ error: result.error })
			})
		}

		queries
			.filter(({ status }) => status !== 'done')
			.forEach(query => {
				query.res({ data: null })
			})

		queries.forEach(query => {
			delete queriesMap[query.cacheKey]
		})
	})
}

const executeMutations = () => {
	const mutationsToPlace = Object.entries(mutationsMap).filter(
		([, { status }]) => status === 'to_be_called',
	) as [Path, MutationEntry][]

	mutationsToPlace.forEach(([, item]) => {
		item.status = 'in_progress'
	})

	const mutationsByExam = mutationsToPlace.reduce((result, [, mutation]) => {
		if (!result[mutation.examId]) {
			result[mutation.examId] = []
		}
		result[mutation.examId].push(mutation)
		return result
	}, {} as Record<string, MutationEntry[]>)

	Object.values(mutationsByExam).forEach(async mutations => {
		const examId = mutations[0].examId

		const controller = new AbortController()
		const signal = controller.signal

		const result = await queryFn(
			{
				url: `/exams/${examId}/set-fields-data`,
				method: 'POST',
				body: JSON.stringify(
					mutations.map(({ path, index, value }) => ({
						path,
						index,
						value,
					})),
				),
			},
			{
				signal,
				abort: () => {},
				dispatch: store.dispatch,
				getState: store.getState,
				endpoint: 'setFieldsData',
				type: 'query',
				extra: {},
			},
			{},
		)

		if (result.data) {
			result.data.forEach(result => {
				const mutation = mutations.find(
					mutation =>
						mutation.path === result.path && mutation.index === result.index,
				)
				if (!mutation) {
					throw new Error(
						`Response ${JSON.stringify(result)} does not match any mutation`,
					)
				}
				mutation.status = 'done'
				mutation.res({ data: result })
			})
		} else if (result.error) {
			mutations.forEach(mutation => {
				mutation.status = 'done'
				mutation.rej({ error: result.error })
			})
		}

		mutations
			.filter(({ status }) => status !== 'done')
			.forEach(mutation => {
				mutation.res({
					data: null,
				})
			})

		mutations.forEach(({ cacheKey }) => {
			delete queriesMap[cacheKey]
		})
	})
}

const enqueueQueryCall = <P extends Path>(
	payload: FieldDataQueryPayload<P>,
): MaybePromise<QueryReturnValue<FieldsData[P], unknown, {}>> => {
	if (timerQuery) {
		window.clearTimeout(timerQuery)
	}

	timerQuery = window.setTimeout(() => {
		executeQueries()
		timerQuery = null
	}, QUERY_TIMEOUT)

	return new Promise((res, rej) => {
		const { examId, index, path } = payload

		const cacheKey = getCacheKey(payload)

		queriesMap[cacheKey] = {
			cacheKey,
			examId,
			path,
			index,
			status: 'to_be_called',
			res,
			rej,
		}
	})
}

const enqueueMutationCall = <P extends Path>(
	payload: FieldDataMutationPayload<P>,
): MaybePromise<QueryReturnValue<FieldsData[P], unknown, {}>> => {
	if (timerMutation) {
		window.clearTimeout(timerMutation)
	}

	timerMutation = window.setTimeout(() => {
		executeMutations()
		timerMutation = null
	}, MUTATION_TIMEOUT)

	return new Promise((res, rej) => {
		const { examId, path, index, value } = payload

		const cacheKey = getCacheKey(payload)

		mutationsMap[cacheKey] = {
			cacheKey,
			examId,
			index,
			path,
			value,
			status: 'to_be_called',
			res,
			rej,
		}
	})
}

const getPendingMutationValue = <K extends Path>(
	examId: string,
	path: K,
	index: number | undefined,
): FieldsData[Path]['value'] | undefined => {
	const cacheKey = getCacheKey({ examId, path, index })
	return mutationsMap[cacheKey]?.value as FieldsData[Path]['value'] | undefined
}

const enqueueCall = <P extends Path>(
	payload: FieldDataMutationPayload<P> | FieldDataQueryPayload<P>,
): MaybePromise<QueryReturnValue<FieldsData[P], unknown, {}>> => {
	return payload.type === 'query'
		? enqueueQueryCall(payload)
		: enqueueMutationCall(payload)
}

const callQueue = {
	enqueueCall,
	getPendingMutationValue,
}

export default callQueue
