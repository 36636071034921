import { createSelector } from 'reselect'
import { RootState } from '../../model/model'
import { VAScale } from '../../model/vaScales'

const getVaScales = (state: RootState) => state.vaScales.scales

export const selectVaScaleCatalog = (scale: VAScale) =>
	createSelector([getVaScales], scales => {
		return [
			...new Set(
				scales
					.map(s => s[scale])
					.filter(s => s !== undefined || s !== null)
					.map(val =>
						typeof val === 'string' && val.includes(',')
							? val.replace(',', '.')
							: val,
					)
					.sort((a: string, b: string) => {
						const _a = Number(a)
						const _b = Number(b)

						if (isNaN(_a) && isNaN(_b)) return _a - _b
						else if (isNaN(_a) && !isNaN(_b)) return 1
						else if (!isNaN(_a) && isNaN(_b)) return -1
						else return _a - _b
					}) || [],
			),
		]
	})

export const selectVaScaleCatalogAsOptions = (scale: VAScale) =>
	createSelector([selectVaScaleCatalog(scale)], opts =>
		opts.map(e => ({ label: e, value: e })),
	)
