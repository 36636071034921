import React, { useState } from 'react'
import styled from 'styled-components'

import { FormControl, InputAdornment, OutlinedInputProps } from '@mui/material'

import { pxToRem } from '../libs/style'
import IconButton from './buttons/IconButton'
import CloseIcon from './icons/CloseIcon'
import SearchIcon from './icons/SearchIcon'
import Input from './Input'
import theme from './theme'

const Wrapper = styled.div`
	padding-top: ${theme.spacing(1)};
`

const IconButtonNoPadding = styled(IconButton)`
	padding: 0;
`

const Outer = styled(FormControl)`
	.MuiOutlinedInput-input {
		padding: ${theme.spacing(1.5)} ${theme.spacing(2)};

		&.MuiOutlinedInput-inputAdornedStart {
			padding-left: 0;
		}
	}

	.MuiInputLabel-outlined {
		&::first-letter {
			text-transform: uppercase;
		}

		&:not(.MuiInputLabel-shrink) {
			color: #5c6b82;
		}

		&:not(.MuiInputLabel-shrink) {
			margin-left: ${theme.spacing(3)};
			top: 50%;

			transform: scale(0.75) translateY(-50%) translateX(${theme.spacing(1.5)});
			width: 100%;
		}
	}
`
const CloseIconSmall = styled(CloseIcon)`
	color: ${theme.palette.secondary.main};
	height: ${pxToRem(12)}rem;
`
interface Props extends OutlinedInputProps {
	onReset: () => void
	onEnterKey?: (e: React.KeyboardEvent) => void
	setValue?: (term: string) => void
	placeholder?: string
	setValueOnBlur?: boolean
}

const SearchBox: React.FC<Props> = ({
	className,
	id = 'searchInput',
	label,
	onChange,
	onEnterKey,
	onReset,
	placeholder,
	setValue,
	value,
	setValueOnBlur = false,
}) => {
	const [shrink, setShrink] = useState(false)
	return (
		<Wrapper className="wrapper-box">
			<Outer className={className}>
				<Input
					className={className}
					variant="outlined"
					label={label}
					value={value}
					id={id}
					data-testid={'search-username'}
					placeholder={placeholder}
					onChange={onChange}
					onFocus={e => setShrink(true)}
					onBlur={e => {
						setShrink(false)
						const term = (e.target as HTMLInputElement).value
						setValueOnBlur && setValue && setValue(term)
					}}
					onKeyUp={e => {
						const term = (e.target as HTMLInputElement).value
						const shouldTriggerCallbacks = e.keyCode === 13
						if (shouldTriggerCallbacks) {
							if (onEnterKey !== undefined) {
								onEnterKey(e)
							}
							if (setValue !== undefined) {
								setValue(term)
							}
						}
					}}
					InputLabelProps={{ shrink: !!value || shrink }}
					InputProps={{
						endAdornment: (
							<InputAdornment position="start">
								{value ? (
									<IconButtonNoPadding onClick={onReset}>
										<CloseIconSmall />
									</IconButtonNoPadding>
								) : (
									''
								)}
							</InputAdornment>
						),
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
			</Outer>
		</Wrapper>
	)
}

export default SearchBox
