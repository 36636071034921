import React from 'react'

import { SvgProps } from '../../model/types'

const UserIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 20 20"
			height="18"
			width="18"
			fill="currentColor"
			{...props}
		>
			<path d="M10 0C4.476 0 0 4.476 0 10s4.476 10 10 10 10-4.476 10-10S15.524 0 10 0zm5.161 17A8.664 8.664 0 0110 18.71 8.664 8.664 0 014.839 17v-.548a2.583 2.583 0 012.58-2.581c.448 0 1.11.46 2.581.46 1.476 0 2.129-.46 2.58-.46a2.583 2.583 0 012.581 2.58zm1.234-1.109a3.858 3.858 0 00-3.814-3.31c-.827 0-1.226.46-2.581.46s-1.75-.46-2.58-.46a3.858 3.858 0 00-3.815 3.31A8.663 8.663 0 011.29 10c0-4.802 3.908-8.71 8.71-8.71 4.802 0 8.71 3.908 8.71 8.71a8.663 8.663 0 01-2.315 5.891zM10 4.516a3.549 3.549 0 100 7.097 3.549 3.549 0 000-7.097zm0 5.807a2.26 2.26 0 01-2.258-2.258A2.26 2.26 0 0110 5.806a2.26 2.26 0 012.258 2.259A2.26 2.26 0 0110 10.323z" />
		</svg>
	)
}

export default UserIcon
