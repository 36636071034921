import React from 'react'

import { Typography as MTypography, TypographyProps } from '@mui/material'

interface Props extends TypographyProps {}

const PageTitle: React.FC<Props> = ({ children, ...props }) => {
	return (
		<MTypography variant="h1" {...props}>
			{children}
		</MTypography>
	)
}

export default PageTitle
