import qs from 'qs'

import { ExamQuery } from '../model/exam'

export enum ServiceBaseUrl {
	ExamsV1 = `v1/exams`,
	PatientCare = `v1/patient-care`,
	ExamsV3 = `v3/exams`,
	CheckCredentials = `v1/check-credentials`,
	CDAV3 = `v3/cda`,
	AIV3 = `v3`,
	ContactLenses = 'v1/contactLenses',
	Symptoms = 'v1/symptoms',
	Reasons = 'v1/reasons',
	Coding = 'v2/coding',
	SignedPrivacyPolicyDocuments = 'v3/signed-privacy-policy-documents',
}

export const serviceApi = {
	getExams: (query: ExamQuery) =>
		`${ServiceBaseUrl.ExamsV1}?${qs.stringify(query)}`,
	updateExams: () => `${ServiceBaseUrl.ExamsV1}/list`,
	checkInExam: (examId: string) =>
		`${ServiceBaseUrl.ExamsV3}/${examId}/checkin`,
	lockExam: (examId: string) => `${ServiceBaseUrl.ExamsV3}/${examId}/lock`,
	getExamCodes: (examId: string) => `${ServiceBaseUrl.ExamsV3}/${examId}/codes`,
	updateGoals: (examId: string) => `${ServiceBaseUrl.ExamsV3}/${examId}/goals`,
}
