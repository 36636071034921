import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDateFormatter } from '../../hooks/useDateFormatter'
import { FhirPatient } from '../../model/patient'
import ChevronDownIcon from '../../styleguide/icons/ChevronDownIcon'
import Radio from '../../styleguide/Radio'
import theme from '../../styleguide/theme'

const Tr = styled.tr`
	td {
		background-color: ${theme.palette.grey[50]};
		padding: 1rem;
		border: none;
		text-align: left;

		&:first-of-type {
			border-radius: 5px 0 0 5px;
		}

		&:last-of-type {
			border-radius: 0 5px 5px 0;
		}
	}
`

const Caret = styled(ChevronDownIcon)`
	transition: transform ${theme.transitions.duration.standard}ms;

	&.open {
		transform: rotate(180deg);
	}
`

const Details = styled.div`
	display: grid;
	grid-template-rows: 0fr;
	transition: 0.25s linear;

	&.open {
		grid-template-rows: 1fr;
	}

	> div {
		overflow: hidden;

		> div {
			margin-top: 0.5rem;
			background-color: rgba(64, 114, 238, 0.04);
			border-radius: 5px;
			display: grid;
			padding: 1rem;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(2, 1fr);
			gap: 0.5rem;
		}
	}
`

const Field = styled.div`
	display: flex;
	gap: 1rem;
`

const Label = styled.div`
	color: ${theme.palette.grey[500]};
	font-size: 0.85rem;
`

const Value = styled.div`
	font-size: 1rem;
`

export const PatientRow = ({
	onClick,
	patient,
	selected,
}: {
	onClick: () => void
	patient: FhirPatient
	selected: boolean
}) => {
	const { t } = useTranslation()

	const { formatDate } = useDateFormatter()

	const [showDetails, setShowDetails] = useState(false)

	return (
		<>
			<Tr onClick={onClick}>
				<td>
					<Radio checked={selected} label="" />
				</td>
				<td>{patient.name}</td>
				<td>{patient.surname}</td>
				<td>{patient.birthDate ? formatDate(patient.birthDate) : ''}</td>
				<td>{patient.email}</td>
				<td>
					<button onClick={() => setShowDetails(!showDetails)}>
						<Caret className={showDetails ? 'open' : ''} />
					</button>
				</td>
			</Tr>
			<tr>
				<td colSpan={6}>
					<Details className={showDetails ? 'open' : ''}>
						<div>
							<div>
								<Field>
									<Label>{t('forms.phoneNumber')}</Label>
									<Value>{patient.phone}</Value>
								</Field>
								<Field>
									<Label>{t('forms.tabAddress')}</Label>
									<Value>{patient.address}</Value>
								</Field>
								<Field>
									<Label>{t('forms.tabZip')}</Label>
									<Value>{patient.postalCode}</Value>
								</Field>
								<Field>
									<Label>{t('forms.tabState')}</Label>
									<Value>{patient.state}</Value>
								</Field>
								<Field>
									<Label>{t('forms.tabCity')}</Label>
									<Value>{patient.city}</Value>
								</Field>
							</div>
						</div>
					</Details>
				</td>
			</tr>
		</>
	)
}
