import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { SystemReferralType } from '../model/referralType'
import { FilterParams, unversionedApiUrl } from './common'

const ReferralType_TAG = 'referralType'

type ReferralTypeCode = string

export const referralTypeApi = createApi({
	reducerPath: 'referralTypeApi',
	tagTypes: [ReferralType_TAG],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getReferralTypeByCode: builder.query<SystemReferralType, ReferralTypeCode>({
			query: code => ({
				url: `/system/v2/referraltype/find/code/${code}`,
			}),
			providesTags: [ReferralType_TAG],
		}),

		getFilteredReferralType: builder.query<SystemReferralType[], FilterParams>({
			query: ({
				filter,
				propNames = ['code', 'description'],
			}: FilterParams) => ({
				url: `/system/v2/referraltype/paginated?q=${encodeURIComponent(
					filter,
				)}&propNames=${propNames.join(',')}`,
			}),
			providesTags: [ReferralType_TAG],
		}),
	}),
})

export const {
	useGetReferralTypeByCodeQuery,
	useGetFilteredReferralTypeQuery,
} = referralTypeApi
