import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getAssetSrc } from '../../apiCalls'
import dialogActions from '../../features/dialog/actions'
import { selectDialog } from '../../features/dialog/selectors'
import visualSimulationActions from '../../features/visualSimulation/actions'
import useVisualSimulation from '../../hooks/useVisualSimulation'
import { VisualSimulationShareDialogType } from '../../model/dialog'
import { useTeloDispatch, useTeloSelector } from '../../store'
import Button from '../../styleguide/buttons/Button'
import DialogActions from '../../styleguide/dialog/DialogActions'
import DialogContent from '../../styleguide/dialog/DialogContent'
import DialogContentText from '../../styleguide/dialog/DialogContentText'

const ImageContent = styled.div`
	img {
		max-width: 100%;
	}
`

const VisualSimulationShareDialog = () => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()
	const dialog = useTeloSelector(
		selectDialog,
	) as VisualSimulationShareDialogType
	const shareMedia = useVisualSimulation(dialog.examId)

	return (
		<>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{t('doctor.visualSimulationDialogTitle')}
				</DialogContentText>
				<ImageContent>
					<img src={getAssetSrc(dialog.media.path, dialog.media.data)} alt="" />
				</ImageContent>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => dispatch(dialogActions.closeDialog())}
					variant="outlined"
				>
					{t('app.cancel')}
				</Button>
				<Button
					onClick={() => {
						shareMedia(true)
						dispatch(
							visualSimulationActions.share({
								examId: dialog.examId,
								active: true,
							}),
						)
						dispatch(dialogActions.closeDialog())
					}}
					autoFocus
					variant="contained"
				>
					{t('app.confirm')}
				</Button>
			</DialogActions>
		</>
	)
}

export default VisualSimulationShareDialog
