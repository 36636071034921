import { useCallback } from 'react'
import { createApi, TagDescription } from '@reduxjs/toolkit/query/react'
import qs from 'qs'
import { useSelector } from 'react-redux'

import { selectSelectedStoreId } from '../features/stores/selectors'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import {
	GetSesContactsApiQuery,
	GetSesContactsQuery,
	GetSesSecureEmailOrganizationQuery,
	GetSesSecureEmailProviderQuery,
	GetSesSecureEmailQuery,
	SecureMailContact,
	SendNewSesMessageBody,
	SesContact,
	SesContact_db,
	SesMessage,
	SesMessageHeader,
	MESSAGE_TYPE,
	SesMessageOutboxHeader,
} from '../model/ses'
import { unversionedApiUrl } from './common'
import { Id } from '../model/model'

const SES_MESSAGES = 'ses-messages'

const SES_MESSAGE = 'ses-message'

const SES_CONTACT = 'ses-contact'

const SES_CONTACTS = 'ses-contacts'

const SES_SECURE_EMAILS = 'ses-secure-emails'

const SES_OUTBOX_URL = 'v1/ses-outbound-messages'

export const SES_EMAIL_ALREADY_EXISTS = 'SES_EMAIL_ALREADY_EXISTS'

type SesTagDescription = TagDescription<
	| typeof SES_MESSAGES
	| typeof SES_MESSAGE
	| typeof SES_CONTACT
	| typeof SES_CONTACTS
	| typeof SES_SECURE_EMAILS
>[]

const getSecureEmailsQuery = (query: GetSesSecureEmailQuery) => {
	switch (query.registrationType) {
		case 'Provider':
			return {
				registrationType: query.registrationType,
				firstName: query.firstName,
				lastName: query.lastName,
				secureEmail: query.secureEmail,
				npi: query.npi,
			} as GetSesSecureEmailProviderQuery
		case 'Organization':
			return {
				registrationType: query.registrationType,
				organizationName: query.organizationName,
				secureEmail: query.secureEmail,
				npi: query.npi,
			} as GetSesSecureEmailOrganizationQuery
	}
}

// TODO: better handling of cache invalidation for all messages

export const sesApi = createApi({
	reducerPath: 'sesApi',
	tagTypes: [
		SES_MESSAGES,
		SES_MESSAGE,
		SES_CONTACTS,
		SES_SECURE_EMAILS,
		SES_CONTACT,
	],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getMessageHeaders: builder.query<
			{
				messages: SesMessageHeader[]
				pagination: {
					page: number
					pageLength: number
					recordsCount: number
				}
			},
			{
				type: MESSAGE_TYPE.SENT | MESSAGE_TYPE.RECEIVED
				page: number
				pageLength?: number
			}
		>({
			query: pagination => ({
				url: `/v1/ses/messages?${qs.stringify(pagination)}`,
			}),
			providesTags: [SES_MESSAGES],
		}),

		getMessage: builder.query<SesMessage, { messageId: string }>({
			query: ({ messageId }) => ({
				url: `/v1/ses/message/${messageId}`,
			}),
			providesTags: message =>
				message ? [{ type: SES_MESSAGE, messageId: message.messageId }] : [],
			onQueryStarted: async (messageId, { dispatch, queryFulfilled }) => {
				await queryFulfilled
				dispatch(sesApi.util?.invalidateTags([SES_MESSAGES]))
			},
		}),

		setReadStatus: builder.mutation<
			{ success: boolean },
			{ messageId: string; read: boolean }
		>({
			query: ({ messageId, read }) => ({
				url: `/v1/ses/message/${messageId}`,
				method: 'PUT',
				body: { read },
			}),
			invalidatesTags: (data, _, params) => [SES_MESSAGES],
		}),

		sendMessage: builder.mutation<
			{ messageId: string },
			{ body: SendNewSesMessageBody; storeId: Id }
		>({
			query: ({ body, storeId }) => ({
				url: `/v1/ses/message`,
				method: 'POST',
				body,
				headers: {
					'x-store': storeId,
				},
			}),
			invalidatesTags: data => [
				SES_MESSAGES,
				{ type: SES_MESSAGE, messageId: data?.messageId },
			],
		}),

		deleteMessages: builder.mutation<
			{ success: boolean },
			{ messageIds: string[] }
		>({
			query: ({ messageIds }) => ({
				url: `/v1/ses/messages?ids=${messageIds.join(',')}`,
				method: 'DELETE',
			}),
			invalidatesTags: (data, _, params) =>
				data?.success ? [SES_MESSAGES] : [],
		}),

		getContacts: builder.query<
			{
				contacts: SesContact_db[]
				pagination: {
					page: number
					pageLength: number
					recordsCount: number
				}
			},
			{
				query: GetSesContactsQuery
				pagination: {
					page: number
					pageLength: number
				}
			}
		>({
			query: ({ query, pagination }) => ({
				url: `/v1/ses/contacts?${qs.stringify({ ...query, ...pagination })}`,
			}),
			providesTags: [SES_CONTACTS],
		}),

		getSecureEmails: builder.query<SecureMailContact[], GetSesSecureEmailQuery>(
			{
				query: query => ({
					url: `/v1/ses/secure-emails?${qs.stringify(
						getSecureEmailsQuery(query),
					)}`,
				}),
				providesTags: [SES_SECURE_EMAILS],
			},
		),

		createContact: builder.mutation<
			{ _id: string; username: string; message?: string },
			Omit<SesContact, '_id'>
		>({
			query: body => ({
				url: `/v1/ses/contact`,
				method: 'POST',
				body,
				validateStatus: (response, result) =>
					response.ok || result.message === SES_EMAIL_ALREADY_EXISTS,
			}),
			invalidatesTags: (result, _error) => {
				if (result && result.username) {
					return [SES_CONTACTS, { type: SES_CONTACT, id: result.username }]
				}
				return [SES_CONTACTS]
			},
		}),

		updateContact: builder.mutation<
			{ _id: string; message?: string },
			{
				contactId: string
				update: Partial<SesContact>
			}
		>({
			query: ({ contactId, update }) => ({
				url: `/v1/ses/contact/${contactId}`,
				method: 'PUT',
				body: {
					...update,
					address: update.address || undefined,
					fax: update.fax || undefined,
					title: update.title || undefined,
					organizationName: update.organizationName || undefined,
				},
				validateStatus: (response, result) =>
					response.ok || result.message === SES_EMAIL_ALREADY_EXISTS,
			}),
			invalidatesTags: (_result, _error, { contactId }) => {
				return [SES_CONTACTS, { type: SES_CONTACT, id: contactId }]
			},
		}),

		setFavorite: builder.mutation<
			{ _id: string },
			{ contactId: string; favorite: boolean }
		>({
			query: ({ contactId, favorite }) => ({
				url: `/v1/ses/contact/${contactId}/favorite`,
				method: 'PUT',
				body: { favorite },
			}),
			invalidatesTags: (_result, _error, { contactId }) => {
				return [SES_CONTACTS, { type: SES_CONTACT, id: contactId }]
			},
		}),

		deleteContact: builder.mutation<void, { contactId: string }>({
			query: ({ contactId }) => ({
				url: `/v1/ses/contacts?contactIds=${contactId}`,
				method: 'DELETE',
			}),
			invalidatesTags: (_result, _error, { contactId }) => {
				return [SES_CONTACTS, { type: SES_CONTACT, id: contactId }]
			},
		}),

		deleteContacts: builder.mutation<void, { contactIds: string[] }>({
			query: ({ contactIds }) => ({
				url: `/v1/ses/contacts?contactIds=${contactIds.join(',')}`,
				method: 'DELETE',
			}),
			invalidatesTags: (_result, _error, { contactIds }) => {
				const contactsIdsTags = contactIds.map(id => ({
					type: SES_CONTACT as typeof SES_CONTACT,
					id,
				}))
				return [SES_CONTACTS, ...contactsIdsTags]
			},
		}),

		getContactFromUsername: builder.query<
			SesContact | null,
			{ username: string }
		>({
			query: ({ username }) => `/v1/ses/contacts/username/${username}`,
			providesTags: (contact, _error, { username }) => {
				if (contact) {
					return [{ type: SES_CONTACT, id: contact._id }]
				}
				return [{ type: SES_CONTACT, id: username }]
			},
		}),

		getContactFromToken: builder.query<SesContact, void>({
			query: () => `/v1/ses/contacts/from-token`,
		}),

		getOutboxListMessages: builder.query<
			{
				data: []
				page: number
				limit: number
				recordsCount: number
			},
			{ page: number; limit: number }
		>({
			query: pagination => ({
				url: `${SES_OUTBOX_URL}?${qs.stringify(pagination)}`,
			}),
			providesTags: [SES_MESSAGES],
		}),

		deleteOutboxMessages: builder.mutation<
			{ success: boolean },
			{ _ids: string[] }
		>({
			query: ({ _ids }) => ({
				url: `${SES_OUTBOX_URL}?ids=${_ids.join(',')}`,
				method: 'DELETE',
			}),
			invalidatesTags: (data, _) => (data?.success ? [SES_MESSAGES] : []),
		}),

		getSyncStatus: builder.mutation<
			{
				status: string
				id: string
				mdnConfirmationDate?: string
			},
			{
				ids: string[]
			}
		>({
			query: body => ({
				url: `/${SES_OUTBOX_URL}/status/sync`,
				method: 'POST',
				body,
			}),
		}),

		getOutboxMessageById: builder.query<
			SesMessageOutboxHeader,
			{ messageId: string }
		>({
			query: ({ messageId }) => ({
				url: `${SES_OUTBOX_URL}/${messageId}`,
			}),
			providesTags: message =>
				message ? [{ type: SES_MESSAGE, messageId: message._id }] : [],
		}),
	}),
})

export const {
	useGetMessageHeadersQuery,
	useGetMessageQuery,
	useSetReadStatusMutation,
	useSendMessageMutation,
	useDeleteMessagesMutation,
	useCreateContactMutation,
	useUpdateContactMutation,
	useSetFavoriteMutation,
	useDeleteContactsMutation,
	useLazyGetSecureEmailsQuery,
	useGetContactFromTokenQuery,
	useGetOutboxListMessagesQuery,
	useGetOutboxMessageByIdQuery,
	useDeleteOutboxMessagesMutation,
	useGetSyncStatusMutation,
} = sesApi

export const useGetContactsQuery = ({
	query,
	pagination,
}: {
	query: Omit<GetSesContactsQuery, 'storeId'>
	pagination: {
		page: number
		pageLength: number
	}
}) => {
	const storeId = useSelector(selectSelectedStoreId)
	return sesApi.useGetContactsQuery({
		query: { ...query, storeId },
		pagination,
	})
}

export const useLazyGetContactsQuery = () => {
	const [_getContacts, result] = sesApi.useLazyGetContactsQuery()
	const storeId = useSelector(selectSelectedStoreId)

	const getContacts = useCallback(
		({ query, pagination }: GetSesContactsApiQuery) => {
			return _getContacts({ query: { ...query, storeId }, pagination })
		},
		[_getContacts, storeId],
	)

	return [getContacts, result] as [typeof getContacts, typeof result]
}

export const useGetContactFromUsernameQuery = ({
	username,
}: {
	username: string
}) => {
	return sesApi.useGetContactFromUsernameQuery(
		{ username },
		{ skip: !username },
	)
}
