import { AzureCommunicationData } from './azureCommunication'
import { PageKey } from './exam'
import { ExternalSource, Id } from './model'
import { VAScale } from './vaScales'

export type AutoInstrumentType =
	| 'AR'
	| 'KM'
	| 'LM'
	| 'NT'
	| 'OCT'
	| 'PH'
	| 'RI'
	| 'SL'
	| 'VF'

export type ManualInstrumentType =
	| 'RX'
	| 'VA'
	| 'ST'
	| 'CT'
	| 'BP'
	| 'OH'
	| 'BA'

export interface Instrument {
	_id: Id
	serial: string
	model: string
	brand: string
	type: AutoInstrumentType
}

export interface Room {
	_id: Id
	stageId: Id
	autorefraction?: Instrument
	biometer?: Instrument
	keratometer?: Instrument
	lensmeter?: Instrument
	listeningForConfiguration: boolean
	lockedByExamId: Id
	doctorRoomLockedByExamId?: Id
	manualInstruments: ManualInstrumentType[]
	name: string
	oct?: Instrument
	online: boolean
	phoropter?: PhoroptersCluster
	phoropterControlLink?: string
	digitalSlitLampLink?: string
	retinalImaging?: Instrument
	slitLamp?: Instrument
	tonometer?: Instrument
	visualFields?: Instrument
	webexId?: string
	azureCommunication?: AzureCommunicationData
	doctorRoom?: boolean
}

export type ExtendedRoom = Room & {
	combineName: string
	usedByAnotherExam: boolean
}

export type PhoropterMode = 'remote' | 'store'

export interface PhoroptersCluster extends Instrument {
	mode: PhoropterMode
}

export interface Stage {
	_id: Id
	name: string
	rooms: Room[]
}

export interface Store {
	_id: Id
	address: string
	availableDoctors?: number
	blobDocumentUrl: string
	brand?: BrandTypes
	busyDoctors?: number
	city?: string
	code?: string
	country: string
	dateFormat?: string
	defaultCommunicationProvider: CommunicationProviderTypes
	difEnabled?: boolean
	directBooking?: boolean
	email: string
	enableChat: boolean
	enableCockpit: boolean
	enableRefractionist: boolean
	externalIds: ExternalSource[]
	fax?: string
	fhirLocationUpriseId?: string
	fhirOrganizationUpriseId?: string
	name: string
	ottMode?: boolean
	pharmacyContact?: string
	phone?: string
	privacyPolicyDocuments: PrivacyPolicyDocument[]
	stages: Stage[]
	state: string
	storeNumber: string
	storeType?: StoreTypes
	timezone: string
	vaScale: VAScale
	zipCode?: string
	isCreateManually?: boolean
	orgIds?: string[]
}

export type StoresState = {
	[id: string]: Store
}

export type CreateStorePayload = Omit<
	Store,
	| '_id'
	| 'privacyPolicyDocuments'
	| 'blobDocumentUrl'
	| 'defaultCommunicationProvider'
	| 'brand'
	| 'storeType'
>

export interface PrivacyDocument {
	_id: string
	name: string
	text?: string
	path?: string
	active: boolean
	createdAt: string
	updatedAt: string
}

interface PrivacyDocumentPayload {
	name: string
	active: boolean
	duration: number
	durationUnit: PrivacyPolicyDocumentDurationUnit
}

interface CreateTextDocumentPayload extends PrivacyDocumentPayload {
	text: string
}

interface CreatePdfDocumentPayload extends PrivacyDocumentPayload {
	pdf: string
}

export interface UpdateDocumentPayload extends PrivacyDocumentPayload {
	text?: string
}

export type CreateDocumentPayload =
	| CreateTextDocumentPayload
	| CreatePdfDocumentPayload

export enum PrivacyPolicyDocumentDurationUnit {
	years = 'years',
	months = 'months',
	weeks = 'weeks',
	days = 'days',
}

export interface PrivacyPolicyDocument {
	_id: Id
	name: string
	path: string
	text: string
	ref: string
	enabled: boolean
	duration?: number
	durationUnit?: PrivacyPolicyDocumentDurationUnit
	active?: boolean
	createdAt?: Date
	updatedAt: Date
}

export type CommunicationProviderTypes = 'webex' | 'azure'
export type BrandTypes = 'L' | 'T' | 'P' | 'C' | 'V' | 'F'
export type StoreTypes =
	| 'CAN'
	| 'EMP'
	| 'FRAN'
	| 'FRANEMP'
	| 'FRANSUB'
	| 'MOD'
	| 'SUB'

export const videoCallProviders = [{ value: 'azure', label: 'Microsoft Azure' }]

export const brands = [
	{ value: 'L', label: 'LensCrafters' },
	{ value: 'T', label: 'TargetOptical' },
	{ value: 'P', label: 'Pearle Vision' },
	{ value: 'C', label: 'CVS' },
	{ value: 'V', label: 'Team Vision' },
	{ value: 'F', label: 'ForEyes' },
	{ value: 'A', label: 'Antoine Laoun' },
	{ value: 'R', label: 'Ray-Ban' },
]

export const brandsAPAC = [{ value: 'OPSM', label: 'OPSM' }]

export const storeTypes = [
	{ value: 'CAN', label: 'CAN' },
	{ value: 'EMP', label: 'EMP' },
	{ value: 'FRAN', label: 'FRAN' },
	{ value: 'FRANEMP', label: 'FRANEMP' },
	{ value: 'FRANSUB', label: 'FRANSUB' },
	{ value: 'MOD', label: 'MOD' },
	{ value: 'SUB', label: 'SUB' },
]

export const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy'

export const dateFormats = [
	{ value: 'MM/dd/yyyy', label: 'dateFormatDefaultLabel' },
	{ value: 'dd/MM/yyyy', label: 'dateFormatOptionLabel' },
]

export interface UpdateStoreStaffBody {
	deletedUsers: string[]
	staffPractices: {
		[userId in string]: string
	}
}

export interface RouteGetPreexamAverageTimeInStoreResponse {
	todayPreexamAverageTime: number | null
}

export type AverageExamType = {
	[examType: string]: number
}

export type AverageExamTypeByDoctor = {
	panel: string
	fullname: string
	username: string
	average: AverageExamType
}

export type MissionControlStatGlobalAdmin = {
	storeId: string
	total: number
	busy: number
	averagePreTest?: number
	averageDoctor?: AverageExamType
	averageDoctorDetail?: AverageExamTypeByDoctor[]
}

export type ExamModeAvg = {
	inStore?: number
	remote?: number
}
export type AverageExamDurationByTypeAndMode = {
	[examType: string]: ExamModeAvg
}

export type MissionControlTechnicianStat = {
	storeId: string
	total: number
	busy: number
	averagePreTest: ExamModeAvg
	averageDoctorDetail: AverageExamDurationByTypeAndMode
}

export type MissionControlDoctorStat = {
	stores: {
		name: string
		panel: string
	}[]
	preTestPatients: number
	globalAverageTime: AverageExamType
	personalAverageTime: AverageExamType
}

export type MissionControlFilteredStoreIds = {
	storeId: string
}

export type RoomSelectionPage = {
	[key in PageKey]?: boolean
}
