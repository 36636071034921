import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TroubleShooting, TroubleShootingData } from '../../model/troubleShooting';

const initialState: TroubleShooting = {
    login: {},
    dataTransfer: {},
    videoConference: {}
}

export const slice = createSlice({
    name: 'troubleShooting',
    initialState,
    reducers: {
        setContent: (state, { payload }: PayloadAction<TroubleShootingData>) => {
            state.login = payload.troubleShooting.login
            state.dataTransfer = payload.troubleShooting.dataTransfer
            state.videoConference = payload.troubleShooting.videoConference
        },
    },
})

export default slice.reducer
