import { SnackbarProps } from '@mui/material/Snackbar'

import styled from 'styled-components'

import Snackbar from '../../styleguide/Snackbar'
import { NotificationSeverity } from '../../model/model'
import theme from '../../styleguide/theme'
import { pxToRem } from '../../libs/style'

export const NotificationAlertSnackbar = styled(Snackbar)<SnackbarProps>`
	&.MuiSnackbar-anchorOriginTopRight {
		top: 140px;
		right: ${theme.spacing(2)};
	}
`

export const NotificationAlertTitle = styled.span<{
	severity: NotificationSeverity
}>`
	color: ${({ severity }) => {
		switch (severity) {
			case NotificationSeverity.Neutral:
				return theme.palette.primary.main
			case NotificationSeverity.Info:
				return theme.palette.secondary.dark
			case NotificationSeverity.Success:
				return theme.palette.success.dark
			case NotificationSeverity.Warning:
				return theme.palette.orange.dark
			case NotificationSeverity.Error:
				return theme.palette.error.dark
			default:
				return
		}
	}};
`

export const NotificationAlertContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${pxToRem(theme.spacing(0.5))}rem;
`
