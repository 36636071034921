import { ExamStatus } from '../model/exam'
import {
	AutorefractionData,
	AutorefractionDataDayOrNight,
	AutorefractionDataWithAccuracy,
	AutorefractionSingleEye,
	Eye,
	HistoricalRXData,
	HOrient,
	InstrumentRemoteMedia,
	KeratometerData,
	KeratometerDataDayOrNight,
	KeratometerDataWithAccuracy,
	KeratormeterDataEye,
	LensmeterData,
	LensmeterDataWithAccuracy,
	LensmeterSingleEye,
	LensUse,
	OCTData,
	PhoropterData,
	PhoropterDataDayOrNight,
	PhoropterDataPart,
	PhoropterDataWithAccuracy,
	PhoropterSingleEye,
	RetinalImageData,
	SlitLampData,
	SlitLampMedia,
	TonometerData,
	TonometerDataEye,
	VisualAcuityBothEye,
	VisualAcuityData,
	VisualAcuitySingleEye,
	VisualFieldsData,
	VOrient,
} from '../model/instruments'
import {
	AutorefractionDataApi,
	AutorefractionDataDayOrNightApi,
	AutorefractionDataWithAccuracyApi,
	AutorefractionSingleEyeApi,
	BaseValue,
	EyeApi,
	GetInstrumentDataResponseApi,
	HistoricalRXDataApi,
	InstrumentType,
	KeratometerDataApi,
	KeratometerDataDayOrNightApi,
	KeratometerDataWithAccuracyApi,
	KeratometerSingleEyeApi,
	LensmeterDataApi,
	LensmeterDataWithAccuracyApi,
	LensmeterSingleEyeApi,
	OCTDataApi,
	PhoropterDataApi,
	PhoropterDataDayOrNightApi,
	PhoropterDataPartApi,
	PhoropterDataWithAccuracyApi,
	PhoropterSingleEyeApi,
	RemoteMediaApi,
	RetinalImagingDataApi,
	SlitLampDataApi,
	TonometerDataApi,
	TonometerMeasureApi,
	VisualAcuityDataApi,
	VisualFieldsDataApi,
} from '../model/instrumentsApi'

const isLensmeterDataApi = (data: any): data is LensmeterDataApi => {
	const eye = data?.default?.L || data?.default?.R
	const lensmeterKeys: (keyof LensmeterSingleEyeApi)[] = [
		'sphere',
		'cylinder',
		'axis',
		'add',
		'prism',
	]
	return (
		eye && Object.keys(eye).every((key: any) => lensmeterKeys.includes(key))
	)
}

const isAutorefractionDataApi = (data: any): data is AutorefractionDataApi => {
	const eye = data?.day?.default?.L || data?.day?.default?.R
	const autorefractionKeys: (keyof AutorefractionSingleEyeApi)[] = [
		'sphere',
		'cylinder',
		'axis',
		'pupils',
	]

	return (
		eye &&
		Object.keys(eye).every((key: any) => autorefractionKeys.includes(key))
	)
}

const isVisualFieldsDataApi = (data: any): data is VisualFieldsDataApi =>
	typeof data === 'object' && ('L' in data || 'R' in data)

const isKeratometerData = (data: any): data is KeratometerDataApi => {
	const eye = data?.day?.default?.L || data?.day?.default?.R
	const keratoemterKeys: (keyof KeratometerSingleEyeApi)[] = ['R1', 'R2']
	return (
		eye && Object.keys(eye).every((key: any) => keratoemterKeys.includes(key))
	)
}

const isTonometerData = (data: unknown): data is TonometerDataApi =>
	typeof data === 'object' && data !== null && ('L' in data || 'R' in data)

const isPhoropterData = (data: unknown): data is PhoropterDataApi =>
	typeof data === 'object' &&
	data !== null &&
	('subjectiveRefraction' in data ||
		'finalRx' in data ||
		'cycloplegicRefraction' in data)

const isSlitLampDataApi = (data: any): data is SlitLampDataApi =>
	typeof data === 'object' && ('L' in data || 'R' in data)

const isRetinalImagingDataApi = (data: any): data is RetinalImagingDataApi =>
	typeof data === 'object' && ('L' in data || 'R' in data)

const isOCTDataApi = (data: any): data is OCTDataApi =>
	typeof data === 'object' && ('L' in data || 'R' in data)

export const decodeInstrumentData = (
	instrumentData: GetInstrumentDataResponseApi,
	instrumentType: InstrumentType,
):
	| LensmeterData
	| AutorefractionData
	| VisualFieldsData
	| KeratometerData
	| TonometerData
	| PhoropterData
	| SlitLampData
	| RetinalImageData
	| OCTData => {
	const { examData, examStatus, updatedAt } = instrumentData

	switch (instrumentType) {
		case 'LM':
			if (!isLensmeterDataApi(examData)) {
				throw new Error(
					`wrong instrument payload: expected lensmeter data, received ${JSON.stringify(
						instrumentData,
					)}`,
				)
			}
			return decodeLensmeterData(examData, examStatus)
		case 'AR':
			if (!isAutorefractionDataApi(examData)) {
				throw new Error(
					`wrong instrument payload: expected autorefraction data, received ${JSON.stringify(
						instrumentData,
					)}`,
				)
			}
			return decodeAutorefractionData(examData, examStatus)
		case 'KM':
			if (!isKeratometerData(examData)) {
				throw new Error(
					`wrong instrument payload: expected keratometer data, received ${JSON.stringify(
						instrumentData,
					)}`,
				)
			}
			return decodeKeratometerData(examData, examStatus)
		case 'NT':
			if (!isTonometerData(examData)) {
				throw new Error(
					`wrong instrument payload: expected tonometer data, received ${JSON.stringify(
						instrumentData,
					)}`,
				)
			}
			return decodeTonometerData(examData, examStatus)
		case 'PH':
			if (!isPhoropterData(examData)) {
				throw new Error(
					`wrong instrument payload: expected phoropter data, received ${JSON.stringify(
						instrumentData,
					)}`,
				)
			}
			return decodePhoropterData(examData, examStatus, updatedAt)
		case 'VF':
			if (!isVisualFieldsDataApi(examData)) {
				throw new Error(
					`wrong instrument payload: expected visual fields data, received ${JSON.stringify(
						instrumentData,
					)}`,
				)
			}
			return decodeVisualFieldData(examData)
		case 'SL':
			if (!isSlitLampDataApi(examData)) {
				throw new Error(
					`wrong instrument payload: expected visual fields data, received ${JSON.stringify(
						instrumentData,
					)}`,
				)
			}
			return decodeSlitLampData(examData)
		case 'RI':
			if (!isRetinalImagingDataApi(examData)) {
				throw new Error(
					`wrong instrument payload: expected retinal image data, received ${JSON.stringify(
						instrumentData,
					)}`,
				)
			}
			return decodeRetinalImagingData(examData)
		case 'OCT':
			if (!isOCTDataApi(examData)) {
				throw new Error(
					`wrong instrument payload: expected retinal image data, received ${JSON.stringify(
						instrumentData,
					)}`,
				)
			}
			return decodeOCTData(examData)
		default:
			throw new Error('Unknown instrument type')
	}
}

const isBaseValue = (data: string): data is BaseValue =>
	['BI', 'BO', 'BU', 'BD', 'in', 'out', 'up', 'down', ''].includes(data)

const decodeBaseValue = (data: BaseValue): HOrient | VOrient | '' => {
	switch (data) {
		case 'in':
			return 'BI'
		case 'out':
			return 'BO'
		case 'up':
			return 'BU'
		case 'down':
			return 'BD'
		default:
			return data
	}
}

const emptyLensmeterEye: LensmeterSingleEye = {
	sphere: null,
	cyl: null,
	axis: '',
	add: '',
	hPrism: '',
	hOrient: '',
	vPrism: '',
	vOrient: '',
}

const emptyVisualAcuityBothEye: VisualAcuityBothEye = {
	distance: '',
	distanceAdditional: '',
	near: '',
	nearAdditional: '',
}

const emptyVisualAcuitySingleEye: VisualAcuitySingleEye = {
	distance: '',
	distanceAdditional: '',
	distancePH: '',
	near: '',
	nearAdditional: '',
	nearPH: '',
}

const decodeLensmeterMeasureApi = (
	data: LensmeterSingleEyeApi,
): LensmeterSingleEye => {
	if (!isBaseValue(data.prism?.x?.base || '')) {
		throw new Error('bad hOrient')
	}
	if (!isBaseValue(data.prism?.y?.base || '')) {
		throw new Error('bad vOrient')
	}

	return {
		sphere: data?.sphere || '',
		cyl: data?.cylinder || '',
		axis: data?.axis || '',
		add: data?.add || '',
		hPrism: data?.prism?.x?.value || '',
		hOrient: (data?.prism?.x?.base
			? decodeBaseValue(data?.prism.x?.base)
			: '') as HOrient,
		vPrism: data?.prism?.y?.value || '',
		vOrient: (data?.prism?.y?.base
			? decodeBaseValue(data?.prism.y?.base)
			: '') as VOrient,
	}
}

const isLensUse = (data: string): data is LensUse =>
	!data ||
	[
		'',
		'fullTime',
		'distanceOnly',
		'near',
		'intermediate',
		'computer',
		'occupational',
		'sun',
		'other',
	].includes(data)

const decodeLensmeterDataWithAccuracyApi = (
	data: LensmeterDataWithAccuracyApi,
): LensmeterDataWithAccuracy => {
	return {
		accuracy: data?.accuracy,
		OD: data?.R ? decodeLensmeterMeasureApi(data.R) : emptyLensmeterEye,
		OS: data?.L ? decodeLensmeterMeasureApi(data.L) : emptyLensmeterEye,
	}
}

const decodeVisualAcuityData = (
	data: VisualAcuityDataApi,
): VisualAcuityData => {
	return {
		OD: data?.OD ? data.OD : emptyVisualAcuitySingleEye,
		OS: data?.OS ? data.OS : emptyVisualAcuitySingleEye,
		BOTH: data?.BOTH ? data.BOTH : emptyVisualAcuityBothEye,
	}
}

export const decodeLensmeterData = (
	data: LensmeterDataApi,
	examStatus?: ExamStatus,
): LensmeterData => {
	if (!isLensUse(data.use)) {
		throw new Error('bad lens use')
	}

	const _default = decodeLensmeterDataWithAccuracyApi(data.default)
	const other = data.other
		? decodeLensmeterDataWithAccuracyApi(data.other)
		: undefined
	const _visualAcuity = decodeVisualAcuityData(data?.visualAcuity)

	return {
		default: _default,
		visualAcuity: _visualAcuity,
		other,
		examStatus,
		use: data?.use || '',
		useNote: data?.note || '',
		lensType: data?.lensType || '',
	}
}

export const decodeHistoricalRxData = (
	data: HistoricalRXDataApi,
): HistoricalRXData => {
	return {
		...decodeLensmeterData(data),
		lensAddOns: data.lensAddOns || [],
		lensMaterial: data.lensMaterial || '',
		lensDesign: data.lensDesign || '',
	}
}

const decodeAutorefractionSingleEyeApi = (
	data?: AutorefractionSingleEyeApi,
): AutorefractionSingleEye => {
	if (!data) {
		return {
			sphere: null,
			cyl: null,
			axis: '',
			pupils: '',
		}
	}

	return {
		sphere: data.sphere,
		cyl: data.cylinder,
		axis: data.axis,
		pupils: data.pupils || '',
	}
}

const decodeAutorefractionDataWithAccuracy = (
	data: AutorefractionDataWithAccuracyApi,
): AutorefractionDataWithAccuracy => {
	if (!data.R && !data.L) {
		throw new Error('both eyes are missing in phoropter data')
	}
	return {
		accuracy: data.accuracy,
		eyes: {
			OD: decodeAutorefractionSingleEyeApi(data.R),
			OS: decodeAutorefractionSingleEyeApi(data.L),
		},
	}
}

const decodeAutorefractionDataDayOrNight = (
	data: AutorefractionDataDayOrNightApi,
): AutorefractionDataDayOrNight => {
	return {
		default: decodeAutorefractionDataWithAccuracy(data.default),
		other: data.other
			? decodeAutorefractionDataWithAccuracy(data.other)
			: undefined,
	}
}

export const decodeAutorefractionData = (
	data: AutorefractionDataApi,
	examStatus?: ExamStatus,
): AutorefractionData => {
	return {
		day: decodeAutorefractionDataDayOrNight(data.day),
		night: data.night
			? decodeAutorefractionDataDayOrNight(data.night)
			: undefined,
		topography: data.topography
			? data.topography.map(decodeRemoteMediaApi)
			: [],
		visualSimulation: data.visionSimulation
			? data.visionSimulation.map(decodeRemoteMediaApi)
			: [],
		examStatus,
		summary: data.summary?.map(decodeRemoteMediaApi),
		crystalline: data.crystalline?.map(decodeRemoteMediaApi),
		pupillaryDistance: data.pupillaryDistance,
		accommodation: data.accommodation,
	}
}

const decodeKeratometerDataEye = (
	data: KeratometerSingleEyeApi,
): KeratormeterDataEye => {
	return {
		power: data?.R1?.power || '',
		hMeridian: data?.R1?.axis || '',
		vPower: data?.R2?.power || '',
		vMeridian: data?.R2?.axis || '',
	}
}

const decodeKeratometerDataWithAccuracy = (
	data: KeratometerDataWithAccuracyApi,
): KeratometerDataWithAccuracy => {
	return {
		accuracy: data.accuracy,
		OS: decodeKeratometerDataEye(data.L),
		OD: decodeKeratometerDataEye(data.R),
	}
}

const decodeKeratometerDataDayOrNight = (
	data: KeratometerDataDayOrNightApi,
): KeratometerDataDayOrNight => {
	return {
		default: decodeKeratometerDataWithAccuracy(data.default),
		other: data.other
			? decodeKeratometerDataWithAccuracy(data.other)
			: undefined,
	}
}

const decodeKeratometerData = (
	data: KeratometerDataApi,
	examStatus?: ExamStatus,
): KeratometerData => {
	return {
		day: decodeKeratometerDataDayOrNight(data.day),
		night: data.night ? decodeKeratometerDataDayOrNight(data.night) : undefined,
		examStatus,
	}
}

const decodeTonometerDataEye = (
	data: TonometerMeasureApi,
	time?: number,
): TonometerDataEye => {
	return {
		iop1: data?.iop1 || '',
		iop2: data?.iop2 || '',
		iopc: data?.iopc || '',
		time: time,
	}
}

const decodeTonometerData = (
	data: TonometerDataApi,
	examStatus?: ExamStatus,
): TonometerData => {
	return {
		OS: decodeTonometerDataEye(data.L, data.acquisitionTime),
		OD: decodeTonometerDataEye(data.R, data.acquisitionTime),
		examStatus,
		method: data.method,
		notes: data.notes,
		pachymetry: {
			OD: {
				data: data.pachymetry?.R.media.map(decodeRemoteMediaApi) || [],
				note: data.pachymetry?.R.note || '',
			},
			OS: {
				data: data.pachymetry?.L.media.map(decodeRemoteMediaApi) || [],
				note: data.pachymetry?.L.note || '',
			},
		},
	}
}

const decodePhoropterMeasureApi = (
	data?: PhoropterSingleEyeApi,
): PhoropterSingleEye => {
	if (!data) {
		return {
			sphere: null,
			cyl: null,
			axis: '',
			add: '',
			hPrism: '',
			hOrient: '',
			vPrism: '',
			vOrient: '',
			description: '',
		}
	}

	if (!isBaseValue(data.prism.x.base)) {
		throw new Error('bad hOrient')
	}
	if (!isBaseValue(data.prism.y.base)) {
		throw new Error('bad vOrient')
	}

	return {
		sphere: data.sphere,
		cyl: data.cylinder,
		axis: data.axis,
		add: data.add,
		hPrism: data.prism.x.value,
		hOrient: decodeBaseValue(data.prism.x.base) as HOrient,
		vPrism: data.prism.y.value,
		vOrient: decodeBaseValue(data.prism.y.base) as VOrient,
		description: data.description,
	}
}

const decodePhoropterDataWithAccuracy = (
	data: PhoropterDataWithAccuracyApi,
): PhoropterDataWithAccuracy => {
	if (!data.R && !data.L) {
		throw new Error('both eyes are missing in phoropter data')
	}

	return {
		accuracy: data.accuracy,
		eyes: {
			OD: decodePhoropterMeasureApi(data.R),
			OS: decodePhoropterMeasureApi(data.L),
		},
		both: {
			distance: data.B?.va.distance || '',
			distanceAdditional:
				data.B?.va.distanceAdditional !== undefined
					? data?.B?.va.distanceAdditional.toString()
					: '',
			near: data.B?.va.near || '',
			nearAdditional:
				data.B?.va.nearAdditional !== undefined
					? data.B?.va.nearAdditional.toString()
					: '',
		},
		visualAcuity: {
			OD: data?.R?.va || '',
			ODAdditional:
				data?.R?.vaAdditional !== undefined
					? data?.R?.vaAdditional.toString()
					: '',
			OS: data?.L?.va || '',
			OSAdditional:
				data?.L?.vaAdditional !== undefined
					? data?.L?.vaAdditional.toString()
					: '',
		},
	}
}

const decodePhoropterDataDayOrNight = (
	data: PhoropterDataDayOrNightApi,
): PhoropterDataDayOrNight => {
	return {
		default: decodePhoropterDataWithAccuracy(data.default),
		other: data.other ? decodePhoropterDataWithAccuracy(data.other) : undefined,
	}
}

const decodePhoropterDataPart = (
	data: PhoropterDataPartApi,
	updatedAt?: string,
): PhoropterDataPart => {
	return {
		day: decodePhoropterDataDayOrNight(data.day),
		night: data.night ? decodePhoropterDataDayOrNight(data.night) : undefined,
		updatedAt: updatedAt ? new Date(updatedAt).getTime() : undefined,
		note: data.note,
		ph: data.ph ? data.ph : undefined,
		glare: data.glare ? data.glare : undefined,
	}
}

const decodePhoropterData = (
	data: PhoropterDataApi,
	examStatus?: ExamStatus,
	updatedAt?: string,
): PhoropterData => {
	return {
		subjectiveRefraction: data.subjectiveRefraction
			? decodePhoropterDataPart(data.subjectiveRefraction, updatedAt)
			: undefined,
		cycloplegicRefraction: data.cycloplegicRefraction
			? decodePhoropterDataPart(data.cycloplegicRefraction, updatedAt)
			: undefined,
		finalRefraction: data.finalRx
			? decodePhoropterDataPart(data.finalRx, updatedAt)
			: undefined,
		contactLenses: data.contactLenses
			? decodePhoropterDataPart(data.contactLenses, updatedAt)
			: undefined,
		pupillaryDistance: data.pupillaryDistance,
		sensitivity: data.sensitivity,
		examStatus,
	}
}

const decodeEye = (eye: EyeApi): Eye => (eye === 'L' ? 'OS' : 'OD')

export const decodeRemoteMediaApi = (
	data: RemoteMediaApi,
): InstrumentRemoteMedia => {
	if (!data.data && !data.path) {
		throw new Error('Both data and path are missing in external image')
	}
	return {
		name: data.name || '',
		eye: decodeEye(data.eye),
		mode: data.mode,
		format: data.format || '',
		data: data.data,
		path: data.path,
		examStatus: data.status,
		createdAt: data.createdAt,
		note: data.note,
	}
}

const decodeSlitLampMediaApi = (data: RemoteMediaApi): SlitLampMedia => {
	return {
		...decodeRemoteMediaApi(data),
		contactLensId: data.contactLensesCode || '',
	}
}

const decodeSlitLampData = ({ L, R }: SlitLampDataApi): SlitLampData => {
	return {
		OS: {
			data: L.media?.map(decodeSlitLampMediaApi) || [],
			note: L.note || '',
		},
		OD: {
			data: R.media?.map(decodeSlitLampMediaApi) || [],
			note: R.note || '',
		},
	}
}

export const decodeRetinalImagingData = ({
	L,
	R,
}: RetinalImagingDataApi): RetinalImageData => {
	return {
		OS: {
			data: L.media?.map(decodeRemoteMediaApi) || [],
			note: L.note || '',
		},
		OD: {
			data: R.media?.map(decodeRemoteMediaApi) || [],
			note: R.note || '',
		},
	}
}

const decodeVisualFieldData = ({
	L,
	R,
}: VisualFieldsDataApi): VisualFieldsData => {
	return {
		OS: {
			data: L.media?.map(decodeRemoteMediaApi) || [],
			note: L.note || '',
		},
		OD: {
			data: R.media?.map(decodeRemoteMediaApi) || [],
			note: R.note || '',
		},
	}
}

export const decodeOCTData = ({
	L,
	R,
	examStatus,
	unableToPerformExam,
	unableToPerformExamReason,
}: OCTDataApi): OCTData => {
	return {
		OS: {
			rnfl: L?.rnfl?.toString() || '',
			gcl: L?.gcl?.toString() || '',
			macThickness: L?.macThickness?.toString() || '',
			axialLength: L?.axialLength?.toString() || '',
			media: L.media?.map(decodeRemoteMediaApi) || [],
			note: L.note || '',
		},
		OD: {
			rnfl: R?.rnfl?.toString() || '',
			gcl: R?.gcl?.toString() || '',
			macThickness: R?.macThickness?.toString() || '',
			axialLength: R?.axialLength?.toString() || '',
			media: R.media?.map(decodeRemoteMediaApi) || [],
			note: R.note || '',
		},
		examStatus: examStatus,
		unableToPerformExam,
		unableToPerformExamReason,
	}
}
