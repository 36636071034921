import { createApi } from '@reduxjs/toolkit/query/react'

import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { SystemCodeNDC } from '../model/ndc'
import { unversionedApiUrl } from './common'

const NDCType_TAG = 'ndc'

export const ndcApi = createApi({
	reducerPath: 'ndcApi',
	tagTypes: [NDCType_TAG],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getNdcCodes: builder.query<SystemCodeNDC[], void>({
			query: () => ({
				url: `/system/v1/ndc`,
			}),
			providesTags: [NDCType_TAG],
		}),
	}),
})

export const { useGetNdcCodesQuery } = ndcApi
