import { createGlobalStyle } from 'styled-components'
import { FONTS } from '../utils/assetRegistry'
import theme from './theme'

const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'Ivar Display';
    src: url('${FONTS.IVAR_DISPLAY_ITALIC_WOFF2}') format('woff2'),
        url('${FONTS.IVAR_DISPLAY_ITALIC_TTF}') format('truetype'),
        url('${FONTS.IVAR_DISPLAY_ITALIC_SVG}') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'OpticianSans';
    src: url('${FONTS.OPTIKER_K_WOFF2}') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Grotesk Text Std';
    src: url('${FONTS.NHAAS_GROTESK_TXSTD_RG}') format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Grotesk Text Std';
    src: url('${FONTS.NHAAS_GROTESK_TXSTD_IT}') format('opentype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Grotesk Text Std';
    src: url('${FONTS.NHAAS_GROTESK_TXSTD_MD}') format('opentype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Grotesk Text Std';
    src: url('${FONTS.NHAAS_GROTESK_TXSTD_MDIT}') format('opentype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Haas Grotesk Text Std';
    src: url('${FONTS.NHAAS_GROTESK_TXSTD_BD}') format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Neue Haas Grotesk Text Std';
    src: url('${FONTS.NHAAS_GROTESK_TXSTD_BDIT}') format('opentype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('${FONTS.POPPINS_BOLD_TTF}') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('${FONTS.POPPINS_BOLDITALIC_TTF}') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('${FONTS.POPPINS_ITALIC_TTF}') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('${FONTS.POPPINS_MEDIUM_TTF}') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Poppins';
    src: url('${FONTS.POPPINS_MEDIUM_ITALIC_TTF}') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('${FONTS.POPPINS_REGULAR_TTF}') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html,
body,
#root {
    height: 100%;
    font-family: Neue Haas Grotesk Text Std, Arial, sans-serif;
}

body {
    background-color: ${theme.palette.grey[100]};
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

fieldset {
  border: 0;
}

button {
  background-color: transparent;
  border: none;
  appearance: none;
  font: inherit;
}

strong {
  font-weight: 900;
}

.pswp img {
    max-width: none;
    object-fit: contain;
}

.title {
    font-family: Poppins, Arial, sans-serif;
}

ul.MuiList-root.MuiList-padding.MuiMenu-list {
    max-height: 360px;
}
`

export default GlobalStyle
