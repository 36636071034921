import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'
import { handle401Error } from '../libs/auth'

import { ServicesEndpointName } from './constants'

export const rtkQueryErrorManager: Middleware = () => next => action => {
	// RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
	if (isRejectedWithValue(action) /* && action.payload.status !== 401 */) {
		if (
			action.payload.status === 401 &&
			action.meta.arg.endpointName !== ServicesEndpointName.CheckInactivityLogin
		) {
			handle401Error({ status: Number(action.payload.status) })
		}
	}
	return next(action)
}
