import React, { FC, ForwardedRef, forwardRef } from 'react'

import {
	CheckCircle as CheckCircleIcon,
	Info as InfoIcon,
	Report as ReportIcon,
	Warning as WarningIcon,
} from '@mui/icons-material'
import { Color, Link } from '@mui/material'
import MAlert, { AlertProps } from '@mui/material/Alert'

import styled from 'styled-components'

import Button from './buttons/Button'
import theme from './theme'
import { useTranslation } from 'react-i18next'
import { pxToRem } from '../libs/style'

type AlertAction = {
	title: string
	onAction?: () => void
}

export type TeloAlertColor =
	| 'neutral'
	| 'info'
	| 'success'
	| 'warning'
	| 'error'

export type TeloAlertActions = {
	primary?: AlertAction
	secondary?: AlertAction
}

export type TeloAlertProps = Omit<AlertProps, 'severity'> & {
	severity: TeloAlertColor
	actions?: TeloAlertActions
	isIconVisible?: boolean
}

const TeloAlert = forwardRef(
	(
		{ children, severity, ...props }: TeloAlertProps,
		ref: ForwardedRef<HTMLDivElement>,
	) => {
		return (
			<MAlert
				{...props}
				severity={severity === 'neutral' ? 'info' : severity}
				ref={ref}
			>
				{children}
			</MAlert>
		)
	},
)

const AlertActions = styled.div`
	display: flex;
	gap: ${theme.spacing(1)};
	margin-top: ${theme.spacing(2)};
`

const StyledAlert = styled(TeloAlert)`
	background-color: ${props => {
		switch (props.severity as TeloAlertColor) {
			case 'neutral':
				return theme.palette.grey[200]
			case 'info':
				return (theme.palette.secondary as unknown as Color)[100]
			case 'success':
				return (theme.palette.success as unknown as Color)[100]
			case 'warning':
				return (theme.palette.warning as unknown as Color)[100]
			case 'error':
				return (theme.palette.error as unknown as Color)[100]
			default:
				return '-'
		}
	}};
	color: ${theme.palette.greyCustom.main};
	border-color: ${props => {
		switch (props.severity as TeloAlertColor) {
			case 'neutral':
				return theme.palette.grey[500]
			case 'info':
				return (theme.palette.secondary as unknown as Color)[500]
			case 'success':
				return (theme.palette.success as unknown as Color)[500]
			case 'warning':
				return (theme.palette.warning as unknown as Color)[500]
			case 'error':
				return (theme.palette.error as unknown as Color)[500]
			default:
				return '-'
		}
	}};

	.MuiAlertTitle-root {
		color: ${props => {
			switch (props.severity as TeloAlertColor) {
				case 'neutral':
				case 'info':
					return theme.palette.primary.main
				case 'success':
					return theme.palette.success.dark
				case 'warning':
					return theme.palette.warning.dark
				case 'error':
					return theme.palette.error.dark
				default:
					return '-'
			}
		}};
	}

	.MuiAlert-icon {
		color: ${props => {
			switch (props.severity as TeloAlertColor) {
				case 'neutral':
					return theme.palette.greyCustom.main
				case 'info':
					return theme.palette.secondary.main
				case 'success':
					return theme.palette.success.main
				case 'warning':
					return theme.palette.warning.main
				case 'error':
					return theme.palette.error.main
				default:
					return '-'
			}
		}};
	}

	.MuiAlert-message {
		padding: ${theme.spacing(1)} ${theme.spacing(0.5)};
		&::first-letter {
			text-transform: uppercase;
		}
	}
`
const Alert: FC<TeloAlertProps> = forwardRef(
	({ isIconVisible = true, children, actions, severity, ...props }, ref) => {
		const icons = {
			info: <InfoIcon />,
			error: <ReportIcon />,
			warning: <WarningIcon />,
			success: <CheckCircleIcon />,
		}

		const { t } = useTranslation()

		return (
			<StyledAlert
				variant="outlined"
				severity={severity}
				{...(!isIconVisible && { icon: false })}
				{...(isIconVisible && { iconMapping: icons })}
				{...props}
				ref={ref}
			>
				{children}
				{actions && (
					<AlertActions>
						{actions.primary && (
							<Link
								component="button"
								onClick={actions.primary.onAction}
								sx={{ fontSize: `${pxToRem(14)}rem` }}
							>
								{t(actions.primary.title)}
							</Link>
						)}
						{actions.secondary && (
							<Link
								component="button"
								onClick={actions.secondary.onAction}
								sx={{ fontSize: `${pxToRem(14)}rem` }}
							>
								{t(actions.secondary.title)}
							</Link>
						)}
					</AlertActions>
				)}
			</StyledAlert>
		)
	},
)

export default Alert
