import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReportsState } from '../../model/model'
import { ReportResponse } from '../../model/report'

const initialState: ReportsState = []

export const slice = createSlice({
	name: 'reports',
	initialState,
	reducers: {
		_loadReports: (_, { payload }: PayloadAction<ReportResponse>) =>
			payload &&
			payload.items &&
			payload.items.reduce((result, report) => {
				result.push(report)
				return result
			}, [] as ReportsState),
	},
})

export default slice.reducer
