import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { getSystemHeaders } from '../libs/system'
import { SystemCodeLoinc } from '../model/loinc'
import { FilterParams, unversionedApiUrl } from './common'

const LOINC_TAG = 'loinc'
const LOINC_FAV_TAG = 'loinc-favourite'

type LoincCode = string

export const loincApi = createApi({
	reducerPath: 'loincApi',
	tagTypes: [LOINC_TAG, LOINC_FAV_TAG],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getLoincByCode: builder.query<SystemCodeLoinc, LoincCode>({
			query: code => ({
				url: `/system/v2/loinc/find/code/${code}`,
			}),
			providesTags: [LOINC_TAG],
		}),
		getMultipleLoincByCode: builder.query<SystemCodeLoinc[], LoincCode>({
			query: codes => ({
				url: `/system/v2/loinc?codes=${codes}`,
			}),
			providesTags: [LOINC_TAG],
		}),
		getFilteredLoinc: builder.query<SystemCodeLoinc[], FilterParams>({
			query: ({
				filter,
				propNames = ['code', 'description'],
				favourites,
			}: FilterParams) => ({
				url: !favourites
					? `/system/v2/loinc/paginated?q=${encodeURIComponent(
							filter,
					  )}&propNames=${propNames.join(',')}`
					: '/system/v2/loinc/favourites',
				headers: getSystemHeaders(),
			}),
			providesTags: (result, error, query) => [
				query.favourites ? LOINC_FAV_TAG : LOINC_TAG,
			],
		}),
		setLoincFavourite: builder.mutation<
			SystemCodeLoinc,
			{ code: string; favourite: boolean }
		>({
			query: ({ code, favourite }) => ({
				url: `/v1/users/loinc/${code}/favourite`,
				method: 'PUT',
				body: {
					favourite,
				},
			}),
			invalidatesTags: [LOINC_TAG, LOINC_FAV_TAG],
		}),
	}),
})

export const {
	useGetLoincByCodeQuery,
	useGetFilteredLoincQuery,
	useSetLoincFavouriteMutation,
	useGetMultipleLoincByCodeQuery,
} = loincApi
