import React, { ErrorInfo, ReactNode, Suspense } from 'react'
import ErrorBoundaryPage from '../../pages/ErrorBoundaryPage'
import CircularProgress from '../../styleguide/CircularProgress'

interface ErrorBoundaryProps {
	children?: ReactNode
}

type ErrorBoundaryState = {
	error: Error | null
	errorInfo: ErrorInfo | null
}

class ErrorBoundary extends React.Component<
	ErrorBoundaryProps,
	ErrorBoundaryState
> {
	constructor(props: ErrorBoundaryProps) {
		super(props)
		this.state = { error: null, errorInfo: null }
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		this.setState({
			error,
			errorInfo,
		})
	}

	render() {
		if (this.state.error) {
			return (
				<Suspense fallback={<CircularProgress />}>
					<ErrorBoundaryPage
						error={this.state.error}
						errorInfo={this.state.errorInfo}
					/>
				</Suspense>
			)
		}

		return this.props.children
	}
}

export default ErrorBoundary
