import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from './common'
import { TimeoutConfig, TimeoutConfigWithId } from '../model/timeoutConfig'

const TimeoutsType_TAG = 'timeouts'

export const timeoutsApi = createApi({
	reducerPath: 'timeoutsApi',
	tagTypes: [TimeoutsType_TAG],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	refetchOnMountOrArgChange: true,
	endpoints: builder => ({
		getTimeouts: builder.query<TimeoutConfigWithId[], void>({
			query: () => ({
				url: `/v1/timeouts`,
			}),
			providesTags: [TimeoutsType_TAG],
		}),
		updateTimeout: builder.mutation<
			TimeoutConfigWithId,
			{
				id: string
				timeouts: TimeoutConfig
			}
		>({
			query: ({ id, timeouts }) => {
				return {
					url: `/v1/timeouts/${id}`,
					method: 'PATCH',
					body: JSON.stringify(timeouts),
				}
			},
		}),
	}),
})

export const { useGetTimeoutsQuery, useUpdateTimeoutMutation } = timeoutsApi
