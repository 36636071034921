import { TFunction } from 'i18next'
import { AlertCategory } from '../model/alerts'
import { CDS } from '../model/cds'
import { PatientAlert } from '../model/patient'
import { SEVERITY } from '../components/PatientAlerts/constant'

export const getFilteredAlerts = (
	alerts: PatientAlert[],
	availableCategories: AlertCategory[],
	severity: string,
): PatientAlert[] =>
	alerts.filter(
		a =>
			a.severity === severity &&
			!a.cancelled &&
			(availableCategories.map(({ _id }) => _id).includes(a.category.id) ||
				a.category.name === 'PMS'),
	)

export const generateCDSAlert = (
	t: TFunction,
	rule: CDS,
	userName: string = '',
): PatientAlert => {
	const { id, area } = rule

	return {
		createdAt: new Date().toISOString(),
		category: {
			id,
			name: t(`legalEntity.CDS.areas.${area}`),
		},
		severity: SEVERITY.MEDIUM,
		focus: 'patient',
		description: t(`legalEntity.CDS.rules.${id}.topic`),
		createdBy: { name: userName },
		type: 'CDS',
	}
}
