import React from 'react'

import { SvgProps } from '../../model/types'

const AlertFilled: React.FC<SvgProps> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="currentColor"
			{...props}
		>
			<path
				clipRule="evenodd"
				d="M12.7613 1.6665C13.2033 1.6665 13.6273 1.8421 13.9398 2.15466L17.845 6.0599C18.1576 6.37246 18.3332 6.79639 18.3332 7.23841V12.7613C18.3332 13.2033 18.1576 13.6273 17.845 13.9398L13.9398 17.845C13.6272 18.1576 13.2033 18.3332 12.7613 18.3332H7.23841C6.79639 18.3332 6.37246 18.1576 6.0599 17.845L2.15466 13.9398C1.8421 13.6272 1.6665 13.2033 1.6665 12.7613V7.23841C1.6665 6.79639 1.8421 6.37246 2.15466 6.0599L6.0599 2.15466C6.37246 1.8421 6.79639 1.6665 7.23841 1.6665H12.7613ZM9.99984 12.4998C9.53959 12.4998 9.1665 12.8729 9.1665 13.3332C9.1665 13.7934 9.53959 14.1665 9.99984 14.1665C10.4601 14.1665 10.8332 13.7934 10.8332 13.3332C10.8332 12.8729 10.4601 12.4998 9.99984 12.4998ZM9.99984 4.99984C9.57246 4.99984 9.22025 5.32154 9.17211 5.73599L9.1665 5.83317V10.8332C9.1665 11.2934 9.53959 11.6665 9.99984 11.6665C10.4272 11.6665 10.7794 11.3448 10.8276 10.9304L10.8332 10.8332V5.83317C10.8332 5.37294 10.4601 4.99984 9.99984 4.99984Z"
			/>
		</svg>
	)
}

export default AlertFilled
