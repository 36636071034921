import examsActions from '../features/exams/actions'
import { ExamStatus } from '../model/exam'
import { selectUsername } from '../features/auth/selectors'
import { selectExam } from '../features/exams/selectors'
import { selectPendingExamId } from '../features/users/selectors'
import { useTeloDispatch, useTeloSelector } from '../store'

const startedExamStatuses: ExamStatus[] = [
	'DoctorModeSelected',
	'DoctorStarted',
]

export const useExamStatusUpdate = () => {
	const dispatch = useTeloDispatch()
	const username = useTeloSelector(selectUsername)
	const doctorPendingExamId = useTeloSelector(selectPendingExamId(username))
	const exam = useTeloSelector(selectExam(doctorPendingExamId || ''))

	const setStartedExamToWaiting = () => {
		if (
			doctorPendingExamId &&
			exam?.status &&
			startedExamStatuses.includes(exam?.status as ExamStatus)
		) {
			dispatch(
				examsActions.setExamStatus({
					id: doctorPendingExamId,
					status: 'Waiting' as ExamStatus,
					username,
				}),
			)
		}
	}

	return { setStartedExamToWaiting }
}
