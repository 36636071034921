import React from 'react'

import { SvgProps } from '../../model/types'

const ErrorIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="currentColor"
			{...props}
		>
			<path d="M9.99996 1.66666C14.6023 1.66666 18.3333 5.39761 18.3333 9.99999C18.3333 14.6023 14.6023 18.3333 9.99996 18.3333C5.39758 18.3333 1.66663 14.6023 1.66663 9.99999C1.66663 5.39761 5.39758 1.66666 9.99996 1.66666ZM9.99996 3.33332C6.31806 3.33332 3.33329 6.31809 3.33329 9.99999C3.33329 13.6819 6.31806 16.6667 9.99996 16.6667C13.6819 16.6667 16.6666 13.6819 16.6666 9.99999C16.6666 6.31809 13.6819 3.33332 9.99996 3.33332ZM8.23224 7.05368L9.99996 8.82141L11.7677 7.05366C12.0931 6.72822 12.6208 6.72822 12.9462 7.05366C13.2716 7.3791 13.2716 7.90674 12.9462 8.23217L11.1785 9.99991L12.9463 11.7677C13.2717 12.0932 13.2717 12.6208 12.9463 12.9462C12.6209 13.2717 12.0932 13.2717 11.7678 12.9462L9.99996 11.1784L8.23216 12.9462C7.90673 13.2717 7.37909 13.2717 7.05365 12.9462C6.72822 12.6208 6.72822 12.0932 7.05365 11.7677L8.82146 9.99991L7.05373 8.23219C6.72829 7.90676 6.72829 7.37912 7.05373 7.05368C7.37917 6.72825 7.9068 6.72825 8.23224 7.05368Z" />
		</svg>
	)
}

export default ErrorIcon
