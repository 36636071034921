import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
	Id,
	PatientGuarantor,
	SignDocumentsState,
	SignedDocuments,
} from '../../model/model'
import { SetSignDocumentByExamPayload } from './model'
import { signDocumentsSliceName } from './constants'
import { PrivacyPolicyDocumentGuarantor } from '../../model/appointment'

export const signDocumentsInitialState: SignDocumentsState = {
	signedDocuments: {} as SignedDocuments,
	patientGuarantor: {} as PatientGuarantor,
}

export const signDocumentsSlice = createSlice({
	name: signDocumentsSliceName,
	initialState: signDocumentsInitialState,
	reducers: {
		clearSignedDocumentsByExam: (
			state,
			{ payload }: PayloadAction<{ examId: Id }>,
		) => {
			const { examId } = payload
			if (state.signedDocuments[examId]) {
				state.signedDocuments[examId] = []
			}
		},
		setSignDocumentByExam: (
			{ signedDocuments },
			{
				payload: { examId, document },
			}: PayloadAction<SetSignDocumentByExamPayload>,
		) => {
			if (!signedDocuments[examId]) {
				signedDocuments[examId] = [document]
			}

			const isExistingExamAndNewDoc = !signedDocuments[examId].find(
				oldDoc => oldDoc.documentId === document.documentId,
			)

			if (isExistingExamAndNewDoc) {
				signedDocuments[examId] = [...signedDocuments[examId], document]
			}
		},
		setPatientGuarantor: (
			{ patientGuarantor },
			{
				payload,
			}: PayloadAction<{ patientId: Id; pg: PrivacyPolicyDocumentGuarantor }>,
		) => {
			patientGuarantor[payload.patientId] = payload.pg
		},
	},
})

export default signDocumentsSlice.reducer
