import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../model/model'

export const selectPatientExport = createSelector(
	(state: RootState) => state.patientExport.exportState,
	exportState => {
		return exportState
	},
)

export const selectPatientExportById = (patientId: string) =>
	createSelector(selectPatientExport, exports => {
		return exports.find(_export => _export.patientIds[0] === patientId)
	})

export const selectPatientFullName = createSelector(
	(state: RootState) => state.patientExport.patientFullName,
	patientFullName => patientFullName,
)
