import { Role } from '../model/users'

export const decodeRole = (role: string): Role => {
	switch (role) {
		case 'TechnicalAdmin':
			return 'TechnicalAdmin'
		case 'GlobalAdmin':
			return 'GlobalAdmin'
		case 'Technician':
			return 'Technician'
		case 'Refractionist':
			return 'Refractionist'
		case 'Doctor':
			return 'Doctor'
		case 'FrontDesk':
			return 'FrontDesk'
		default:
			return 'Guest'
	}
}
