import { createApi } from '@reduxjs/toolkit/query/react'

import { DifListItem } from '../components/DigitalIntakeForm/types'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from './common'
import { ServiceBaseUrl } from './serviceApi'
import { ServicesApiTag, ServicesReducerPath } from './constants'
import { Id } from '../model/model'
import {
	PatientCare,
	PatientCareCreateBody,
	PatientCareSelectiveUpdateBody,
} from '../model/patientCare'

export const patientCareApi = createApi({
	reducerPath: ServicesReducerPath.PatientCare,
	tagTypes: [ServicesApiTag.PatientCare],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	refetchOnMountOrArgChange: true,
	endpoints: builder => ({
		getPatientCare: builder.query<PatientCare[], void>({
			query: () => ({
				url: ServiceBaseUrl.PatientCare,
			}),
			providesTags: [ServicesApiTag.PatientCare],
		}),
		getPatientCareEnabledOptions: builder.query<DifListItem[], void>({
			query: () => ({
				url: ServiceBaseUrl.PatientCare,
			}),
			transformResponse: (response: PatientCare[]): DifListItem[] =>
				response
					? response
							.filter(item => item.isActive)
							.map(status => ({
								label: status.description,
								value: status.description,
								code: status.code,
							}))
					: [],
			providesTags: [ServicesApiTag.PatientCare],
		}),
		createPatientCare: builder.mutation<PatientCare, PatientCareCreateBody>({
			query: payload => ({
				url: ServiceBaseUrl.PatientCare,
				method: 'POST',
				body: JSON.stringify(payload),
			}),
			invalidatesTags: [ServicesApiTag.PatientCare],
		}),
		updatePatientCare: builder.mutation<
			boolean,
			{ id: Id; payload: PatientCareSelectiveUpdateBody }
		>({
			query: ({ id, payload }) => ({
				url: `${ServiceBaseUrl.PatientCare}/${id}`,
				method: 'PATCH',
				body: JSON.stringify(payload),
			}),
			invalidatesTags: [ServicesApiTag.PatientCare],
		}),
	}),
})

export const {
	useGetPatientCareQuery,
	useCreatePatientCareMutation,
	useUpdatePatientCareMutation,
	useGetPatientCareEnabledOptionsQuery,
} = patientCareApi
