import React, { FC } from 'react'
import { DialogProps as MDialogProps } from '@mui/material/Dialog'

import { MuiDialog } from './Dialog.styled'

export type DialogProps = MDialogProps & {
	isBorderTopVisible?: boolean
}

const Dialog: FC<DialogProps> = ({ children, ...props }) => (
	<MuiDialog
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description"
		maxWidth="md"
		{...props}
	>
		{children}
	</MuiDialog>
)
export default Dialog
