import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { decodeInstrumentData } from '../../decoders/instrumentData'
import {
	initPreTestData,
	isAutorefractionData,
	isKeratometerData,
	isLensmeterData,
	isPhoropterData,
	isRetinalImageData,
	isSlitLampData,
	isStrippedExam,
	isTonometerData,
	isVisualFieldsData,
	isExam,
	isOctData,
} from '../../libs/exams'
import { Exam, ExamApi, ExamStatus, StrippedExam } from '../../model/exam'
import { InstrumentInRoom } from '../../model/instruments'
import { InstrumentDataApi, InstrumentType } from '../../model/instrumentsApi'
import { ExamsState, Id } from '../../model/model'

const mapInstrumentTypeToKeys: {
	[k in InstrumentType]: InstrumentInRoom
} = {
	AR: InstrumentInRoom.Autorefraction,
	KM: InstrumentInRoom.Keratometer,
	LM: InstrumentInRoom.Lensmeter,
	NT: InstrumentInRoom.Tonometer,
	OCT: InstrumentInRoom.Oct,
	PH: InstrumentInRoom.Phoropter,
	RI: InstrumentInRoom.RetinalImaging,
	SL: InstrumentInRoom.SlitLamp,
	VF: InstrumentInRoom.VisualFields,
}

const initialState: ExamsState = {}

export const slice = createSlice({
	name: 'exams',
	initialState,
	reducers: {
		_setNewExam: (state, { payload }: PayloadAction<ExamApi>) => {
			state[payload._id] = initPreTestData(payload, state[payload._id])
		},
		_loadExams: (
			state,
			{ payload }: PayloadAction<(ExamApi | StrippedExam)[]>,
		) => {
			const currentDisplayedExam = state.currentDisplayedExam as
				| Exam
				| StrippedExam
				| undefined
			const examsList = payload.reduce((result, exam) => {
				result[exam._id] = isStrippedExam(exam)
					? exam
					: initPreTestData(exam, state[exam._id])
				return result
			}, {} as ExamsState)

			return currentDisplayedExam
				? {
						...examsList,
						currentDisplayedExam: currentDisplayedExam,
				  }
				: { ...examsList }
		},
		_loadExam: (state, { payload }: PayloadAction<ExamApi | StrippedExam>) => {
			const exam = isStrippedExam(payload)
				? payload
				: initPreTestData(payload, state[payload._id])
			state[payload._id] = exam
			state.currentDisplayedExam = exam
		},
		_removeExam: (state, { payload }: PayloadAction<Id>) => {
			delete state[payload]
		},
		_setPhoropterIsResetting: (state, { payload }: PayloadAction<Id>) => {
			if (state[payload] && isExam(state[payload])) {
				;(state[payload] as Exam).phoropterIsResetting = true
			}
		},
		_setInstrumentData: (
			state,
			{
				payload,
			}: PayloadAction<{
				examId: string
				instrumentData: InstrumentDataApi
				instrumentType: InstrumentType
				updatedAt?: string
				examStatus?: ExamStatus
			}>,
		) => {
			const { examId, instrumentData, instrumentType, examStatus } = payload
			if (!state[examId] || !isExam(state[examId])) {
				return
			}
			const key = mapInstrumentTypeToKeys[instrumentType]
			let decodedData
			try {
				decodedData = decodeInstrumentData(
					{
						examData: instrumentData,
						examStatus,
						updatedAt: payload.updatedAt,
					},
					instrumentType,
				)
			} catch (error) {
				return
			}

			if (key === 'autorefraction' && isAutorefractionData(decodedData)) {
				;(state[examId] as Exam).preTest.instruments[key] = decodedData
			} else if (key === 'lensmeter' && isLensmeterData(decodedData)) {
				;(state[examId] as Exam).preTest.instruments[key] = decodedData
			} else if (key === 'keratometer' && isKeratometerData(decodedData)) {
				;(state[examId] as Exam).preTest.instruments[key] = decodedData
			} else if (key === 'tonometer' && isTonometerData(decodedData)) {
				;(state[examId] as Exam).preTest.instruments[key] = decodedData
			} else if (key === 'phoropter' && isPhoropterData(decodedData)) {
				const updatedAt =
					decodedData.subjectiveRefraction?.updatedAt ||
					decodedData.cycloplegicRefraction?.updatedAt ||
					decodedData.finalRefraction?.updatedAt ||
					0
				if ((state[examId] as Exam).phoropterIsResetting) {
					;(state[examId] as Exam).discardPhoropterReadingsBefore = updatedAt
					;(state[examId] as Exam).phoropterIsResetting = false
				} else if (
					!(state[examId] as Exam).discardPhoropterReadingsBefore ||
					(updatedAt &&
						updatedAt > (state[examId] as Exam).discardPhoropterReadingsBefore)
				) {
					;(state[examId] as Exam).preTest.instruments[key] = decodedData
				}
			} else if (key === 'visualFields' && isVisualFieldsData(decodedData)) {
				;(state[examId] as Exam).preTest.instruments[key] = decodedData
			} else if (key === 'slitLamp' && isSlitLampData(decodedData)) {
				;(state[examId] as Exam).preTest.instruments[key] = decodedData
			} else if (key === 'retinalImaging' && isRetinalImageData(decodedData)) {
				;(state[examId] as Exam).preTest.instruments[key] = decodedData
			} else if (key === 'oct' && isOctData(decodedData)) {
				;(state[examId] as Exam).preTest.instruments[key] = decodedData
			} else {
				throw new Error(`Unknown instrument type: ${instrumentType}`)
			}
		},
		_isDifOpen: (
			state,
			{ payload }: PayloadAction<{ Id: Id; isOpen: boolean }>,
		) => {
			if (state[payload.Id]) {
				;(state[payload.Id] as Exam).isDifOpen = payload.isOpen
			}
		},
		resetExamsList: () => ({}),
	},
})

export default slice.reducer
