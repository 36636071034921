import styled from 'styled-components'

import { FormControlLabel as MFormControlLabel } from '@mui/material'

import theme from './theme'

const FormControlLabel = styled(MFormControlLabel)`
	&.MuiFormControlLabel-labelPlacementStart {
		.MuiInputBase-root {
			margin-left: ${theme.spacing(3)};
		}
	}

	.MuiFormControlLabel-label::first-letter {
		text-transform: uppercase;
	}
`

export default FormControlLabel
