import { createApi } from '@reduxjs/toolkit/query/react'

import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from './common'
import { ServiceBaseUrl } from './serviceApi'
import { ServicesApiTag, ServicesReducerPath } from './constants'
import {
	CreatePPDV2,
	DOCUMENT_STATUS,
	PrivacyPolicyDocumentSignedV2,
} from '../model/signedPrivacyPolicyDocuments'
import { Id } from '../model/model'

export const signedPrivacyPolicyDocumentsApi = createApi({
	reducerPath: ServicesReducerPath.SignedPrivacyPolicyDocuments,
	tagTypes: [ServicesApiTag.SignedPrivacyPolicyDocuments],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	refetchOnMountOrArgChange: true,
	endpoints: builder => ({
		getSignedPrivacyPolicyDocuments: builder.query<
			PrivacyPolicyDocumentSignedV2[],
			{ patientId: Id; isAllHistory?: boolean }
		>({
			query: ({ patientId, isAllHistory = false }) => ({
				url: `${ServiceBaseUrl.SignedPrivacyPolicyDocuments}/${patientId}?isAllHistory=${isAllHistory}`,
			}),
			providesTags: [ServicesApiTag.SignedPrivacyPolicyDocuments],
		}),
		updateSignedPrivacyPolicyDocumentsStatus: builder.mutation<
			PrivacyPolicyDocumentSignedV2[],
			{ patientId: Id; status: DOCUMENT_STATUS; note: string }
		>({
			query: ({ patientId, status, note }) => ({
				url: `${ServiceBaseUrl.SignedPrivacyPolicyDocuments}/${patientId}/update-patient-documents-status`,
				method: 'PATCH',
				body: JSON.stringify({ status, note }),
			}),
			invalidatesTags: [ServicesApiTag.SignedPrivacyPolicyDocuments],
		}),
		updateStoreSignedPrivacyPolicyDocumentsStatus: builder.mutation<
			PrivacyPolicyDocumentSignedV2[],
			{ storeDocumentId: Id; status: DOCUMENT_STATUS }
		>({
			query: ({ storeDocumentId, status }) => ({
				url: `${ServiceBaseUrl.SignedPrivacyPolicyDocuments}/${storeDocumentId}/update-documents-status`,
				method: 'PATCH',
				body: JSON.stringify({ status }),
			}),
			invalidatesTags: [ServicesApiTag.SignedPrivacyPolicyDocuments],
		}),
		addSignedPrivacyPolicyDocuments: builder.mutation<
			PrivacyPolicyDocumentSignedV2,
			CreatePPDV2
		>({
			query: payload => ({
				url: `${ServiceBaseUrl.SignedPrivacyPolicyDocuments}/add-document`,
				method: 'POST',
				body: JSON.stringify(payload),
			}),
			invalidatesTags: [ServicesApiTag.SignedPrivacyPolicyDocuments],
		}),
	}),
})

export const {
	useGetSignedPrivacyPolicyDocumentsQuery,
	useUpdateSignedPrivacyPolicyDocumentsStatusMutation,
	useUpdateStoreSignedPrivacyPolicyDocumentsStatusMutation,
	useAddSignedPrivacyPolicyDocumentsMutation,
} = signedPrivacyPolicyDocumentsApi
