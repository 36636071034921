import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConnectCoreMediaContent } from '../../model/contentCoreMedia'

const initialState: ConnectCoreMediaContent = {
    clinicalData: {},
    rxAddOns: {},
    diseaseAndTreatment: {}
}

export const slice = createSlice({
    name: 'coremedia',
    initialState,
    reducers: {
        setContent: (state, { payload }: PayloadAction<ConnectCoreMediaContent>) => {
            state.clinicalData = payload.clinicalData
            state.rxAddOns = payload.rxAddOns
            state.diseaseAndTreatment = payload.diseaseAndTreatment
        },
    },
})

export default slice.reducer
