import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ReferralCommonsState } from '../../model/model'
import { ReferralReasonsCatalogData, ReferralSpecialtiesCatalogData } from "../../model/referralDoctors"

const initialState: ReferralCommonsState = {
	reasons: [],
	specialties: []
}

export const slice = createSlice({
	name: 'referralCommons',
	initialState,
	reducers: {
		_loadReasons: (
			state,
			{ payload }: PayloadAction<ReferralReasonsCatalogData[]>,
		) => {
			state.reasons = payload
		},
		_loadSpecialties: (
			state,
			{ payload }: PayloadAction<ReferralSpecialtiesCatalogData[]>,
		) => {
			state.specialties = payload
		},
	},
})

export default slice.reducer
