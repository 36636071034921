import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type MFEState = {
	examPageRedirectURL: string | null
	examId: string | null
	currentExamId: string | null
	internalPatientId: string | null
}

const initialState: MFEState = {
	examPageRedirectURL: null,
	examId: null,
	currentExamId: null,
	internalPatientId: null,
}

const mfeSlice = createSlice({
	name: 'mfe',
	initialState,
	reducers: {
		setExamId: (state, { payload: examId }: PayloadAction<string>) => {
			state.examId = examId
		},
		setCurrentExamId: (
			state,
			{ payload: currentExamId }: PayloadAction<string | null>,
		) => {
			state.currentExamId = currentExamId
		},
		setInternalPatientId: (
			state,
			{ payload: internalPatientId }: PayloadAction<string | null>,
		) => {
			state.internalPatientId = internalPatientId
		},
		setExamPageRedirectURL: (
			state,
			{ payload: url }: PayloadAction<string>,
		) => {
			const relativeUrl = url.replace(window.location.origin, '')
			state.examPageRedirectURL = relativeUrl
		},
	},
})

export const mfeReducer = mfeSlice.reducer
export const mfeActions = mfeSlice.actions
