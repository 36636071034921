import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import dialogActions from '../features/dialog/actions'
import { selectDialog } from '../features/dialog/selectors'
import storesActions from '../features/stores/actions'
import { pxToRem } from '../libs/style'
import { RemoveInstrumentType } from '../model/dialog'
import { useTeloDispatch, useTeloSelector } from '../store'
import Button from '../styleguide/buttons/Button'
import DialogActions from '../styleguide/dialog/DialogActions'
import DialogContent from '../styleguide/dialog/DialogContent'
import DialogContentText from '../styleguide/dialog/DialogContentText'

const DialogContentTextStyled = styled(DialogContentText)`
	min-width: ${pxToRem(299)}rem;
`

const isRemoveInstrumentDialog = (
	dialog: any,
): dialog is RemoveInstrumentType =>
	dialog.type === 'removeInstrument' && 'storeId' in dialog

const RemoveInstrumentDialog = () => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()
	const dialog = useTeloSelector(selectDialog)

	return !isRemoveInstrumentDialog(dialog) ? null : (
		<>
			<DialogContent>
				<DialogContentTextStyled>
					{t('admin.removeInstrumentQuestion')
						.replace('%%1', t(`instruments.${dialog.instrumentType}`))
						.replace('%%2', dialog.room.name)}
				</DialogContentTextStyled>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => dispatch(dialogActions.closeDialog())}
					variant="outlined"
				>
					{t('app.cancel')}
				</Button>
				<Button
					onClick={() => {
						const { room, instrumentType } = dialog
						dispatch(
							storesActions.removeInstrumentFromRoomAction({
								storeId: dialog.storeId,
								room,
								instrumentsToRemove: [instrumentType],
							}),
						).finally(() => dispatch(dialogActions.closeDialog()))
					}}
					autoFocus
					variant="contained"
				>
					{t('app.yesRemove')}
				</Button>
			</DialogActions>
		</>
	)
}

export default RemoveInstrumentDialog
