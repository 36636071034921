import { Badge } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import appConfig from '../config'
import {
	selectNewCropStatusCount,
	selectStoreId,
} from '../features/app/selectors'
import { selectUsername } from '../features/auth/selectors'
import { useTeloSelector } from '../store'
import { getNewCropStatusInput } from './Prescription/NewCrop/api'
import { NewCropModal } from './Prescription/NewCrop/newCrop.modal'

type EPrescribingBadgeProps = {
	storeId: string
	username: string
	counter: number
	onItemClick: (storeId: string, username: string) => void
}

const EPrescribingBadge = ({
	storeId,
	username,
	counter,
	onItemClick,
}: EPrescribingBadgeProps) => {
	const { t } = useTranslation()

	return (
		<Badge
			badgeContent={counter}
			color="error"
			onClick={() => onItemClick(storeId, username)}
		>
			{t('app.ePrescribingMenuItem')}
		</Badge>
	)
}

export const EPrescribingMenuItem = () => {
	const storeId = useTeloSelector(selectStoreId)
	const username = useTeloSelector(selectUsername)
	const newCropCounter = useTeloSelector(selectNewCropStatusCount)

	const [modalContent, setModalContent] = useState<string | undefined>()

	const handleBadgeClicked = async () => {
		const content = await getNewCropStatusInput(storeId, username)
		if (content) {
			setModalContent(content)
		}
	}

	return (
		<>
			<EPrescribingBadge
				storeId={storeId}
				username={username}
				counter={newCropCounter}
				onItemClick={handleBadgeClicked}
			/>
			{/* Seems like modalContent is not rerendered once injected */}
			{modalContent ? (
				<NewCropModal
					baseUrl={appConfig.newCrop.url}
					rxInput={modalContent}
					show={true}
					onClose={() => {
						setModalContent(undefined)
					}}
				/>
			) : null}
		</>
	)
}
