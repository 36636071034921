import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from './common'
import { Ethnicity } from '../model/ethnicity'

export const ethnicitiesApi = createApi({
	reducerPath: 'ethnicitiesApi',
	tagTypes: ['Ethnicities'],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getEthnicitiesForDropdown: builder.query<Ethnicity[], void>({
			query: () => ({
				url: 'v1/ethnicities',
			}),
			transformResponse: (response: Ethnicity[]): Ethnicity[] => response,
			providesTags: ['Ethnicities'],
		}),
	}),
})

export const { useGetEthnicitiesForDropdownQuery } = ethnicitiesApi
