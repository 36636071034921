import { RootState } from '../../model/model'

export const selectNoDoctorAvailableModalInfo = (state: RootState) =>
	state.queue.noDoctorAvailableModalInfo

export const selectStartedExams = (state: RootState) =>
	state.queue.assignedExams

export const selectTimedoutExams = (state: RootState) =>
	state.queue.timedoutExams
