import { isToday } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { selectExams } from '../features/exams/selectors'
import notificationsActions from '../features/notifications/actions'
import {
	selectIsDoctor,
	selectIsRemoteDoctor,
	selectUser,
} from '../features/users/selectors'
import { convertExamStatusToNumber, examEndedStatus } from '../libs/exams'
import { ExamHistory, ExamStatus } from '../model/exam'
import { useTeloDispatch, useTeloSelector } from '../store'

const examTimerHistoryStorageKey = 'examTimerHistoryExamIds'

const findStartTimeForExamStatus = (
	history: ExamHistory,
	status: ExamStatus,
): Date | undefined => {
	return history.find(i => i.currentStatus === status)?.statusUpdatedAt
}

export const useExamTimerDoctor = (loginToken: string): void => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()

	// Select all exams
	const exams = useTeloSelector(selectExams)
	const isDoctor = useTeloSelector(selectIsDoctor)
	const [examTimerHistory, setExamTimerHistory] = useState<string[]>([])
	const loggedInUser = useTeloSelector(selectUser)

	const persistExamTimerHistory = (examTimerHistory: string[]) => {
		localStorage.setItem(
			examTimerHistoryStorageKey,
			JSON.stringify(examTimerHistory),
		)
		setExamTimerHistory(examTimerHistory)
	}

	useEffect(() => {
		const examTimerHistoryFromStorage = localStorage.getItem(
			examTimerHistoryStorageKey,
		)

		if (examTimerHistoryFromStorage !== null) {
			setExamTimerHistory(JSON.parse(examTimerHistoryFromStorage))
		}
	}, [])

	useEffect(() => {
		if (!loginToken || !isDoctor) {
			return
		}

		const examsToCheck = exams.filter(({ status, doctor, checkedInAt }) => {
			const isTodayExam = !checkedInAt ? false : isToday(new Date(checkedInAt))
			const examStatusNumber = convertExamStatusToNumber(status)
			const isEnded = examEndedStatus.includes(status)
			const shouldTrackTiming =
				isTodayExam === true &&
				examStatusNumber > 6 &&
				isEnded === false &&
				doctor === loggedInUser?.username

			return shouldTrackTiming
		})

		const interval = setInterval(() => {
			examsToCheck.forEach(exam => {
				const examStatusNumber = convertExamStatusToNumber(exam.status)

				const examHistory = exam.history

				const isContactLensExam = exam.examType?.match('CL') !== null
				const notificationIntervalInMins = isContactLensExam ? 18 : 15
				const notificationIntervalInMS = 1000 * 60 * notificationIntervalInMins
				if (
					!Array.isArray(examHistory) ||
					['Paused', 'Waiting'].includes(exam.status)
				) {
					return
				}

				const [start, end] = [7, 8]
				if (![start, end].includes(examStatusNumber)) {
					return
				}

				const statusUpdatedAt = findStartTimeForExamStatus(
					examHistory,
					'DoctorStarted',
				)

				if (statusUpdatedAt === undefined) {
					return
				}

				// Check for doctor notifications
				// 16 > 15 send a notification, send >= 30
				const examTimeSpentMS =
					new Date().getTime() - new Date(statusUpdatedAt).getTime()
				const isNotificationSent = examTimerHistory.find(
					notification => exam._id === notification,
				)

				if (!isNotificationSent && examTimeSpentMS > notificationIntervalInMS) {
					dispatch(
						notificationsActions.addNotification({
							autoClose: true,
							message: t('exam.timeSpentNotification', {
								minutes: notificationIntervalInMins,
							}),
							type: 'info',
							colored: true,
						}),
					)

					const updatedExamTimerHistory = examTimerHistory.filter(
						e => e !== exam._id,
					)

					persistExamTimerHistory([...updatedExamTimerHistory, exam._id])
				}
			})
		}, 1000)

		return function cleanUp() {
			clearInterval(interval)
		}
	}, [exams, examTimerHistory, isDoctor, loginToken, t, dispatch, loggedInUser])
}

export const useExamTimerPretest = (loginToken: string) => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()

	// Select all exams
	const exams = useTeloSelector(selectExams)
	const isRemoteDoctor = useTeloSelector(selectIsRemoteDoctor)
	const [examTimerHistory, setExamTimerHistory] = useState<string[]>([])
	const loggedInUser = useTeloSelector(selectUser)
	const username = loggedInUser?.username

	const persistExamTimerHistory = (examTimerHistory: string[]) => {
		localStorage.setItem(
			examTimerHistoryStorageKey,
			JSON.stringify(examTimerHistory),
		)
		setExamTimerHistory(examTimerHistory)
	}

	useEffect(() => {
		const examTimerHistoryFromStorage = localStorage.getItem(
			examTimerHistoryStorageKey,
		)

		if (examTimerHistoryFromStorage !== null) {
			setExamTimerHistory(JSON.parse(examTimerHistoryFromStorage))
		}
	}, [])

	useEffect(() => {
		if (!loginToken || isRemoteDoctor === true) {
			return
		}

		const examsToCheck = exams.filter(
			({ status, checkedInAt, localTechnician }) => {
				const isTodayExam = !checkedInAt
					? false
					: isToday(new Date(checkedInAt))
				const examStatusNumber = convertExamStatusToNumber(status)
				const isEnded = examEndedStatus.includes(status)
				const shouldTrackTiming =
					isTodayExam === true &&
					examStatusNumber === 1 &&
					isEnded === false &&
					localTechnician === loggedInUser?.username

				return shouldTrackTiming
			},
		)

		const interval = setInterval(() => {
			examsToCheck.forEach(exam => {
				const examHistory = exam.history
				const notificationIntervalInMins = 15
				const notificationIntervalInMS = 1000 * 60 * notificationIntervalInMins

				const statusUpdatedAt = findStartTimeForExamStatus(
					examHistory,
					'PreTestStarted',
				)

				if (statusUpdatedAt === undefined) {
					return
				}

				// Check for doctor notifications
				// 16 > 15 send a notification, send >= 30
				const examTimeSpentMS =
					new Date().getTime() - new Date(statusUpdatedAt).getTime()

				const isNotificationSent = examTimerHistory.find(
					notification => exam._id === notification,
				)

				if (!isNotificationSent && examTimeSpentMS > notificationIntervalInMS) {
					dispatch(
						notificationsActions.addNotification({
							autoClose: true,
							message: t('exam.timeSpentNotification', { minutes: 15 }),
							type: 'info',
							colored: true,
						}),
					)

					const updatedExamTimerHistory = examTimerHistory.filter(
						e => e !== exam._id,
					)

					persistExamTimerHistory([...updatedExamTimerHistory, exam._id])
				}
			})
		}, 1000)

		return function cleanUp() {
			clearInterval(interval)
		}
	}, [
		exams,
		examTimerHistory,
		t,
		dispatch,
		username,
		isRemoteDoctor,
		loggedInUser,
		loginToken,
	])
}
