import React from 'react'
import { useTranslation } from 'react-i18next'

import dialogActions from '../features/dialog/actions'
import { selectDialog } from '../features/dialog/selectors'
import uiActions from '../features/ui/actions'
import { UncompletedPrescriptionDialogType } from '../model/dialog'
import { ExamStep } from '../model/exam'
import { useTeloDispatch, useTeloSelector } from '../store'
import Button from '../styleguide/buttons/Button'
import DialogActions from '../styleguide/dialog/DialogActions'
import DialogContent from '../styleguide/dialog/DialogContent'
import DialogContentText from '../styleguide/dialog/DialogContentText'

const isUncompletePrescriptionDialog = (
	dialog: any,
): dialog is UncompletedPrescriptionDialogType => typeof dialog === 'object'

const UncompletePrescriptionDialog = () => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()
	const dialog = useTeloSelector(selectDialog)

	return !isUncompletePrescriptionDialog(dialog) ? null : (
		<>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{t('doctor.uncompletedPrescriptionDialogTitle')}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						dispatch(uiActions.setDoctorExamPageActiveStep(ExamStep.PreTest))
						dispatch(dialogActions.closeDialog())
					}}
					variant="contained"
				>
					{t('doctor.uncompletedPrescriptionDialogButtonText')}
				</Button>
			</DialogActions>
		</>
	)
}

export default UncompletePrescriptionDialog
