/* This is the main app entry point, it depends on react-router and uses it to create the telo wrapper */
/* eslint-disable telo/no-react-router-import */
import React, { Suspense } from 'react'

import { StyledEngineProvider, ThemeProvider } from '@mui/material'

import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import styled, { ThemeProvider as StyledThemeProvider } from 'styled-components'

import { SnackbarProvider } from 'notistack'
import TeloRoutes from './Routes'
import { TeloLocalization } from './TeloLocalization'

import { queueSnackbarClasses } from './features/notifications/constants'
import NotificationArea from './features/notifications/NotificationArea'
import store from './store'
import CircularProgress from './styleguide/CircularProgress'
import Container from './styleguide/Container'
import GlobalStyle from './styleguide/globalStyle'
import theme from './styleguide/theme'

const FullHeightContainer = styled(Container)`
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: ${theme.palette.grey[100]};

	~ .${queueSnackbarClasses.containerAnchorOriginTopRight} {
		top: 100px;
	}

	> div {
		&:first-child {
			height: 100%;
			padding: 0;
		}
	}
`

const queryClient = new QueryClient()

export const TeloApp = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<StyledEngineProvider injectFirst>
					<StyledThemeProvider theme={theme}>
						<ThemeProvider theme={theme}>
							<SnackbarProvider
								classes={{
									containerAnchorOriginTopRight:
										queueSnackbarClasses.containerAnchorOriginTopRight,
								}}
							>
								<GlobalStyle />
								<Suspense fallback={<CircularProgress />}>
									<FullHeightContainer maxWidth={false} disableGutters={true}>
										<TeloLocalization>
											<BrowserRouter>
												<TeloRoutes />
											</BrowserRouter>
											<NotificationArea />
										</TeloLocalization>
									</FullHeightContainer>
								</Suspense>
							</SnackbarProvider>
						</ThemeProvider>
					</StyledThemeProvider>
				</StyledEngineProvider>
			</Provider>
		</QueryClientProvider>
	)
}
