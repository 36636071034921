import { CLEvaluation, CLOverRefraction } from '../../model/exam'
import { FundusExamMethodsType } from '../../model/fundusExamMethods'
import {
	BinocularAssessmentData,
	OcularHealthData,
	PhoriaHorizontalAssessmentData,
	PhoriaVerticalAssessmentData,
} from '../../model/manual'

export enum ContactLenseConditionSection {
	OverRefraction = 'overRefraction',
	Evaluation = 'evaluation',
}

export type ContactLensesConditionValues = {
	overRefraction?: CLOverRefraction
	evaluation?: CLEvaluation
}

export enum CheckboxFormKey {
	reason = 'reason',
}

export type ConditionsFormValues =
	| BinocularAssessmentData[
			| 'npa'
			| 'aniseikonia'
			| 'npc'
			| 'nraPra'
			| 'fusion'
			| 'coverTestDistance'
			| 'coverTestNear']
	| OcularHealthData['fundusExamMethods']
	| PhoriaHorizontalAssessmentData
	| PhoriaVerticalAssessmentData
	| PhoriaVerticalAssessmentData
	| PhoriaHorizontalAssessmentData

export type ConditionsValues =
	| BinocularAssessmentData['npa' | 'coverTestDistance' | 'coverTestNear']
	| Omit<BinocularAssessmentData['nraPra'], 'note'>
	| Omit<BinocularAssessmentData['npc'], 'note'>
	| Omit<BinocularAssessmentData['fusion'], 'note'>
	| Omit<BinocularAssessmentData['aniseikonia'], 'note'>
	| FundusExamMethodsType
	| PhoriaHorizontalAssessmentData
	| PhoriaVerticalAssessmentData
	| PhoriaVerticalAssessmentData
	| PhoriaHorizontalAssessmentData
