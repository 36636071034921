import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from './common'
import { Queue } from '../model/queue'

export const QUEUE_CACHE_TAG = 'worklist'

export const queueApi = createApi({
	reducerPath: 'queueApi',
	tagTypes: [QUEUE_CACHE_TAG],
	baseQuery: staggeredBaseQueryWithBailOut(`${unversionedApiUrl}`),
	refetchOnMountOrArgChange: true,
	endpoints: builder => ({
		getQueue: builder.query<Record<string, Queue>, { storeId?: string }>({
			query: ({ storeId }) => ({
				url: `/v1/queue${storeId ? `?storeId=${storeId}` : ''}`,
			}),
			providesTags: [QUEUE_CACHE_TAG],
		}),

		retryAssignation: builder.mutation<void, { examId: string }>({
			query: ({ examId }) => ({
				url: `/v1/queue/retry/${examId}`,
				method: 'POST',
				body: JSON.stringify({}),
			}),
		}),
	}),
})

export const { useGetQueueQuery, useRetryAssignationMutation } = queueApi
