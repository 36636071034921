import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Id } from '../../model/model'
import { Panel, PanelsState } from '../../model/panel'

const initialState = null as PanelsState

export const slice = createSlice({
	name: 'panels',
	initialState,
	reducers: {
		_setPanel: (state, { payload }: PayloadAction<Panel>) => {
			const panelIndex = state?.findIndex(({ _id }) => _id === payload._id)
			if (state && panelIndex !== undefined) {
				state[panelIndex] = payload
			} else {
				return (state || []).concat(payload)
			}
		},
		_removePanel: (state, { payload }: PayloadAction<Id>) => state?.filter(({ _id }) => _id !== payload),
		_setPanels: (_, { payload }: PayloadAction<Panel[]>) => payload,
	}
})

export default slice.reducer
