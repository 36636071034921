import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from './common'
import config from '../config'
import {
	Practice,
	PracticeRemoveVisDocumentAttachments,
	PracticeVisDocumentAttachments,
	UpdatePracticePayload,
	VisFile,
} from '../model/practice'
import practicesActions from '../features/practices/actions'

const TAG = 'vis-documents'

export const visDocumentsApi = createApi({
	reducerPath: 'visDocumentsApi',
	tagTypes: [TAG],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		updatePracticeVisDocuments: builder.mutation<
			Practice,
			UpdatePracticePayload
		>({
			query: payload => ({
				url: `${config.apiUrl}/practices/${payload._id}/visdocuments`,
				method: 'PUT',
				body: JSON.stringify(payload),
			}),
			async onQueryStarted(_, { dispatch, queryFulfilled }) {
				try {
					const { data: practice } = await queryFulfilled

					dispatch(practicesActions.getPractice(practice._id))
				} catch {}
			},
		}),
		uploadPracticeVisDocumentAttachments: builder.mutation<
			VisFile[],
			{
				practiceId: string
				body: PracticeVisDocumentAttachments
			}
		>({
			query: ({ practiceId, body }) => ({
				url: `${config.apiUrl}/practices/${practiceId}/visdocuments/attachments`,
				method: 'PUT',
				body: JSON.stringify(body),
			}),
		}),
		removePracticeVisDocumentAttachments: builder.mutation<
			{},
			PracticeRemoveVisDocumentAttachments
		>({
			query: payload => ({
				url: `${config.apiUrl}/practices/visdocuments/attachments/remove`,
				method: 'DELETE',
				body: JSON.stringify(payload),
			}),
			extraOptions: { maxRetries: 1 },
		}),
	}),
})

export const {
	useUpdatePracticeVisDocumentsMutation,
	useUploadPracticeVisDocumentAttachmentsMutation,
	useRemovePracticeVisDocumentAttachmentsMutation,
} = visDocumentsApi
