import { Field, Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import azureCommunicationActions from '../../../features/azureCommunication/actions'
import uiActions from '../../../features/ui/actions'
import { Id } from '../../../model/model'
import Button from '../../../styleguide/buttons/Button'
import { StyledForm } from '../../../styleguide/CommonPageComponents'
import Dialog from '../../../styleguide/dialog/Dialog'
import DialogActions from '../../../styleguide/dialog/DialogActions'
import DialogContent from '../../../styleguide/dialog/DialogContent'
import DialogContentText from '../../../styleguide/dialog/DialogContentText'
import Checkbox from '../../../styleguide/forms/Checkbox'
import QuitWithPrescribedCL from '../../QuitWithPrescribedCL'
import CircularProgress from '../../../styleguide/CircularProgress'
import { selectExam } from '../../../features/exams/selectors'
import { selectIsDoctor } from '../../../features/users/selectors'
import { useTeloDispatch, useTeloSelector } from '../../../store'
import { selectPracticeByExamId } from '../../../features/practices/selectors'
import { convertSingleUnit } from '../../../libs/time'
import FirstLetterCapital from '../../../styleguide/typography/FirstLetterCapital'
import DialogTitle from '../../../styleguide/dialog/DialogTitle'

const StyledCheckbox = styled(Checkbox)`
	text-transform: uppercase;
	padding-top: 16px;
	margin-left: 0;
`

type QuitExamDialogProps = {
	open: boolean
	examId: Id
	closeDialog: () => void
	confirmDialog: (generateChart?: boolean) => Promise<void>
	isPrescribedCl?: boolean
	isAbort?: boolean
	abortReason?: JSX.Element
}

const QuitExamDialog = ({
	examId,
	open,
	closeDialog,
	confirmDialog,
	isPrescribedCl = false,
	isAbort = false,
	abortReason,
}: QuitExamDialogProps) => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const initialeValues = {
		generateChart: false,
	}
	const exam = useTeloSelector(selectExam(examId))
	const isDoctor = useTeloSelector(selectIsDoctor)
	const isNoGenerateChart = [
		'IntakeFormEnded',
		'PreTestStarted',
		'PreTestFinished',
	].includes(exam?.status ?? '')

	const products = (exam?.contactLensesTrial || [])
		.filter(p => p.status === 'PRESCRIBED')
		.map(({ catalogueId }) => catalogueId)

	const practice = useTeloSelector(selectPracticeByExamId(examId))
	const { setTo: chartCreationTime, unit: chartCreationUnit } =
		practice?.chartCreation || {}

	return (
		<Dialog open={open}>
			<Formik
				initialValues={initialeValues}
				onSubmit={async values => {
					setIsSubmitting(true)
					dispatch(azureCommunicationActions.setTeloCallStatus('FORCE_ENDED'))
					dispatch(uiActions.setShowCallWidget(false))
					dispatch(uiActions.clearCurrentChatInfo())
					await confirmDialog(values.generateChart)
					setIsSubmitting(false)
					closeDialog()
				}}
			>
				{() => (
					<StyledForm data-testid="quit-exam-dialog">
						<DialogTitle onClose={() => closeDialog()}>
							<FirstLetterCapital>
								{isNoGenerateChart
									? t('doctor.signPrescriptionNoChart')
									: t('doctor.signPrescription')}
							</FirstLetterCapital>
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								{isNoGenerateChart
									? t('doctor.EndExamDialogWithoutChart')
									: practice?.autoChartCreation
									? t('doctor.EndExamDialogWithAutoChart', {
											time: chartCreationTime,
											unit: convertSingleUnit(
												chartCreationUnit,
												chartCreationTime,
											),
									  })
									: t('doctor.EndExamDialogWithoutAutoChart')}
							</DialogContentText>
							{isPrescribedCl && <QuitWithPrescribedCL products={products} />}
							{isAbort && abortReason}
							{isDoctor && !isNoGenerateChart && (
								<div>
									<Field
										component={StyledCheckbox}
										disabled={isSubmitting}
										label={t('doctor.EndExamDialogCheckbox')}
										name={`generateChart`}
									/>
								</div>
							)}
						</DialogContent>
						<DialogActions>
							{isSubmitting ? (
								<CircularProgress />
							) : (
								<>
									<Button
										data-testid="close-exam-cancel"
										onClick={closeDialog}
										variant="outlined"
									>
										{t('app.cancel')}
									</Button>
									<Button
										data-testid="close-exam-confirm"
										type="submit"
										variant="contained"
									>
										{t('app.confirm')}
									</Button>
								</>
							)}
						</DialogActions>
					</StyledForm>
				)}
			</Formik>
		</Dialog>
	)
}

export default QuitExamDialog
