import {
	compose,
	isObject,
	isUndefined,
	omitBy,
	reduce,
	toPairs,
} from 'lodash/fp'

export const flattenObject = <O extends Record<string, any>>(
	obj: O,
	parent?: string,
	usePrefix = false,
): Record<string, any> =>
	compose<
		[O],
		Array<[string, O[keyof O]]>,
		Record<string, any>,
		Record<string, any>
	>(
		omitBy<Record<string, any>>(isUndefined),
		reduce((acc, [key, value]) => {
			let propName = usePrefix && parent ? parent + '.' + key : key

			if (isObject(value)) {
				return { ...acc, ...flattenObject(value, propName, usePrefix) }
			}

			return { ...acc, [propName]: value }
		}, {}),
		toPairs,
	)(obj)
