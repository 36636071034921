import { io, Socket } from 'socket.io-client'
import appConfig from '../../../config'
import { Id } from '../../../model/model'
import { newTeloSocketConnector } from '../teloSocketConnector'
import { AddNotificationFn, TeloSocket } from '../teloSocketTypes'
import { basicDisconnectSocket, isSocketConnected } from '../teloSocketUtils'
import { ShareFn } from './visualSimulationTypes'
import {
	socketCommonOptions,
	socketReconnectionFailedNotification,
} from '../../../libs/socket'

const SHARE_ON = 'new_share'
const SHARE_OFF = 'close_share'

type VisualSimulationShareEmitter = {
	emitShare: ShareFn
}

type VisualSimulationSocket = TeloSocket & VisualSimulationShareEmitter

type VisualSimulationSocketArgs = {
	examId: Id
	share: ShareFn
	addNotification: AddNotificationFn
}

const newSocket = ({
	examId,
	share,
	addNotification,
}: VisualSimulationSocketArgs): VisualSimulationSocket => {
	let socket: Socket | null = null

	const connect = () => {
		if (isSocketConnected(socket)) {
			return
		}
		socket = io(appConfig.socketUrl, {
			...socketCommonOptions,
			query: { roomName: getSocketKey(examId) },
		})

		socket.io.on('reconnect_failed', () => {
			addNotification(socketReconnectionFailedNotification)
		})

		socket.on(SHARE_ON, () => {
			share(true)
		})

		socket.on(SHARE_OFF, () => {
			share(false)
		})
	}
	const disconnect = () => {
		basicDisconnectSocket(socket)
		socket = null
	}

	const emitShare = (active: boolean) => {
		if (!isSocketConnected(socket)) {
			addNotification({
				type: 'error',
				message: 'no connection',
				autoClose: true,
			})
			return
		}

		const msg = active ? SHARE_ON : SHARE_OFF
		socket!.emit(msg, {
			senderId: socket!.id,
		})
	}

	return { connect, disconnect, emitShare }
}

function getSocketKey(examId: string) {
	return `${examId}-visualSimulation`
}

const socketConnector = newTeloSocketConnector()
export function connectToSocketVisualSimulation(
	args: VisualSimulationSocketArgs,
): void {
	const { examId } = args

	socketConnector.connectSocket({
		socketKey: getSocketKey(examId),
		newSocket: () => newSocket(args),
	})
}

export function getVisualSimulationEmitter(): VisualSimulationShareEmitter | null {
	return socketConnector.getSocket<VisualSimulationSocket>()
}
