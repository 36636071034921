import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import dialogActions from '../features/dialog/actions'
import selectedUsersActions from '../features/selectedUsers/actions'
import { selectDialog } from '../features/dialog/selectors'
import usersActions from '../features/users/actions'
import { formatName } from '../libs/localization'
import { pxToRem } from '../libs/style'
import { DeleteUserType } from '../model/dialog'
import { useTeloDispatch, useTeloSelector } from '../store'
import Button from '../styleguide/buttons/Button'
import DialogActions from '../styleguide/dialog/DialogActions'
import DialogContent from '../styleguide/dialog/DialogContent'
import DialogContentText from '../styleguide/dialog/DialogContentText'
import { User } from '../model/users'

const DialogContentTextStyled = styled(DialogContentText)`
	min-width: ${pxToRem(299)}rem;
`

const isDeleteUserDialog = (dialog: any): dialog is DeleteUserType =>
	dialog.type === 'deleteUser'

const DeleteUserDialog = () => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()
	const dialog = useTeloSelector(selectDialog)
	const displayedUserList = (users: User[]): string => {
		let formattedName: string[] = []
		users.map(user => formatName(user))
		return formattedName.join(', ')
	}

	const deleteUserQuestion =
		isDeleteUserDialog(dialog) && dialog.users.length > 1
			? t('admin.deleteUsersQuestion')
			: t('admin.deleteUserQuestion')

	return !isDeleteUserDialog(dialog) ? null : (
		<>
			<DialogContent>
				<DialogContentTextStyled>
					<>
						{deleteUserQuestion.replace('%%1', displayedUserList(dialog.users))}
					</>
				</DialogContentTextStyled>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => dispatch(dialogActions.closeDialog())}
					variant="outlined"
				>
					{t('app.cancel')}
				</Button>
				<Button
					className={'confirm-delete-user-button'}
					onClick={() => {
						dispatch(usersActions.deleteUsers(dialog.users)).finally(() =>
							dispatch(dialogActions.closeDialog()),
						)
						dispatch(selectedUsersActions._removeSelectedUsers())
					}}
					autoFocus
					variant="contained"
				>
					{t('app.yesDelete')}
				</Button>
			</DialogActions>
		</>
	)
}

export default DeleteUserDialog
