import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ExamApi, StrippedExam } from '../../model/exam'
import { InternalPatient } from "../../model/patient"
import { SearchState } from '../../model/search'

const initialState: SearchState = {
	searchTerm: '',
	results: null,
	patientResults: null
}

export const slice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		setSearchTerm: (
			state,
			{ payload }: PayloadAction<string>,
		) => {
			state.searchTerm = payload
		},
		_setResults: (state, { payload }: PayloadAction<(ExamApi | StrippedExam)[]>) => {
			state.results = payload
		},
		_setPatientsResults: (state, { payload }: PayloadAction<InternalPatient[]>) => {
			state.patientResults = payload
		},
		resetResults: (state) => {
			state.results = null
		},
		clearSearch: (state) => {
			state.results = null
			state.searchTerm = ''
		}
	}
})

export default slice.reducer
