import { TFunction } from 'i18next'
import {
	getDoctorHistoryPatient,
	searchExamsApi,
	searchPatientsApi,
} from '../../apiCalls'
import store, { AppThunk, TeloDispatch, TeloGetState } from '../../store'
import { selectUsername } from '../auth/selectors'
import notificationsActions from '../notifications/actions'
import { selectIsDoctor } from '../users/selectors'
import { slice } from './slice'

const throwExamsSearchError = (dispatch: TeloDispatch, message: string) => {
	dispatch(slice.actions._setResults([]))
	store.dispatch<any>(
		notificationsActions.addNotification({
			type: 'error',
			message: message,
			autoClose: true,
			errorType: 'http',
			messageIsLabelKey: true,
			autoCloseDelay: undefined,
		}),
	)
}

const searchExams =
	(searchTerm: string, t: TFunction): AppThunk =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		dispatch(slice.actions.setSearchTerm(searchTerm))
		const state = getState()
		const isDoctor = selectIsDoctor(state)
		const username = selectUsername(state)

		const apiCall = isDoctor
			? getDoctorHistoryPatient(username, searchTerm)
			: searchExamsApi(searchTerm)

		return apiCall
			.then(results => {
				if (results) {
					dispatch(slice.actions._setResults(results))
				} else {
					throwExamsSearchError(dispatch, t('app.searchError'))
				}
			})
			.catch(_ => {
				throwExamsSearchError(dispatch, t('app.searchError'))
			})
	}

const searchPatients =
	(searchTerm: string, storeId?: string): AppThunk =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		dispatch(slice.actions.setSearchTerm(searchTerm))

		const apiCall = searchPatientsApi(searchTerm, storeId)

		return apiCall.then(results => {
			results && dispatch(slice.actions._setPatientsResults(results))
		})
	}

const clearSearch =
	(): AppThunk => (dispatch: TeloDispatch, getState: TeloGetState) =>
		dispatch(slice.actions.clearSearch())

const searchActions = {
	...slice.actions,
	searchExams,
	searchPatients,
	clearSearch,
}

export default searchActions
