import React, { useEffect, useState } from 'react'
import { i18nLoadCompleted } from '../i18n'
import { Waiter } from './Waiter'
import { ChildrenProps, WaiterConfigProps } from './utilityTypes'

type I18nLoaderProps = ChildrenProps & WaiterConfigProps

export const I18nLoader = ({
	children,
	hideWaiter,
	waiterSize,
}: I18nLoaderProps) => {
	const [i18nLoaded, setI18nLoaded] = useState(false)
	useEffect(() => {
		if (i18nLoaded) {
			return
		}
		const waitForI18n = async () => {
			await i18nLoadCompleted
			setI18nLoaded(true)
		}
		waitForI18n()
	}, [i18nLoaded])

	return (
		<Waiter
			renderChildrenWhen={i18nLoaded}
			hideWaiter={hideWaiter}
			waiterSize={waiterSize}
		>
			{children}
		</Waiter>
	)
}
