import { SnackbarOrigin } from '@mui/material/Snackbar'

import { NotificationType } from '../../model/model'

export const notificationType: Record<NotificationType, NotificationType> = {
	error: 'error',
	success: 'success',
	info: 'info',
} as const

export const offlineNotification = 'offline-notification'

export const notificationAutoCloseDelay = 5000

export const defaultNotificationAlertAnchorOrigin: SnackbarOrigin = {
	vertical: 'top',
	horizontal: 'right',
}

export const queueSnackbarClasses = {
	containerAnchorOriginTopRight: `queue-containerAnchorOriginTopRight`,
}
