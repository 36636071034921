import { FieldArray } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { FormHelperText } from '@mui/material'

import Button from '../buttons/Button'
import CloseIcon from '../icons/CloseIcon'
import AddIcon from '../icons/AddIcon'
import theme from '../theme'
import FilledIcon from '../icons/FilledIcon'

interface Props {
	totalEntries: { value: string; label: string; disabled?: boolean }[]
	selectedEntries: { value: string; label: string }[]
	name: string
	multiSelectEntries?: boolean
	className?: string
	useStartIcon?: boolean
	onSelectValue?: (e: { value: string; label: string }) => void
}

const Group = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
`

const Entry = styled.div`
	margin-right: ${theme.spacing(1)};
	margin-left: ${theme.spacing(0.5)};
	margin-bottom: ${theme.spacing(1)};
`

const CheckboxGroup: React.FC<Props> = ({
	name,
	totalEntries,
	selectedEntries,
	multiSelectEntries = true,
	className,
	useStartIcon = false,
	onSelectValue,
}) => {
	const { t } = useTranslation()
	return (
		<FieldArray
			name={name}
			render={arrayHelpers => {
				const form = arrayHelpers.form
				const fieldNameParts = name.split('.')
				const fieldError = fieldNameParts.reduce((result, part) => {
					return (
						result &&
						// dont't know why at first round result[part] is equal to [null] TEL-534
						!Array.isArray(result[part]) &&
						result[part]
					)
				}, form.errors as { [k: string]: any })
				const fieldTouched = !!fieldNameParts.reduce((result, part) => {
					return result && result[part]
				}, form.touched as { [k: string]: any })
				return (
					<div>
						<Group className={className}>
							{totalEntries.map((entry, index) => {
								const isSelectedIndex = selectedEntries.findIndex(
									({ value }) => value === entry.value,
								)
								const isSelected = isSelectedIndex > -1
								return (
									<Entry key={index}>
										<Button
											data-testid="checkbox-item"
											type="button"
											variant={isSelected ? 'contained' : 'outlined'}
											startIcon={
												useStartIcon ? isSelected ? <FilledIcon /> : null : null
											}
											endIcon={
												useStartIcon ? null : isSelected ? (
													<CloseIcon />
												) : (
													<AddIcon />
												)
											}
											disabled={!!entry.disabled}
											onClick={e => {
												if (!multiSelectEntries) {
													arrayHelpers.replace(0, entry)
												} else {
													if (isSelected) {
														arrayHelpers.remove(isSelectedIndex)
													} else {
														arrayHelpers.push(entry)
													}
												}
												onSelectValue && onSelectValue(entry)
											}}
										>
											{entry.label}
										</Button>
									</Entry>
								)
							})}
						</Group>
						{!!fieldError && !!fieldTouched && (
							<FormHelperText error={true}>
								{t('errors.fieldRequired')}
							</FormHelperText>
						)}
					</div>
				)
			}}
		/>
	)
}

export default CheckboxGroup
