import styled from 'styled-components'

import { Accordion as MExpansionPanel } from '@mui/material'

import theme from '../theme'

const MuiAccordionStyled = styled(MExpansionPanel).attrs({
	elevation: 0,
	variant: 'outlined',
})`
	margin-bottom: ${theme.spacing(1)};
	//TODO: important needed for first-of-type styles
	border-radius: 12px !important;

	&:before {
		content: none;
	}
`

export default MuiAccordionStyled
