import { debounce } from 'lodash'
import { useEffect } from 'react'

export const useEclipsKeepAlive = (eclipsEnabled: boolean) => {
	const handleDocumentMouseUp = debounce((event: { button: number }) => {
		if (event.button !== 2) {
			const payload = {
				event: 'Connect_SessionKeepAlive',
				payload: {},
			}
			window.parent.postMessage(payload, '*')
		}
	}, 500)

	useEffect(() => {
		if (eclipsEnabled === true) {
			document.addEventListener('mouseup', handleDocumentMouseUp)
		}
		return () => {
			if (eclipsEnabled === true) {
				document.removeEventListener('mouseup', handleDocumentMouseUp)
			}
		}
	}, [eclipsEnabled, handleDocumentMouseUp])

	return
}
