import { Field } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDateFormatter } from '../../hooks/useDateFormatter'
import { firstLetterCapital } from '../../libs/style'
import { Languages, TypePhoneNumber } from '../../model/model'
import { pageWrapperPadding } from '../../styleguide/CommonPageComponents'
import DatePicker from '../../styleguide/forms/DatePicker'
import Input from '../../styleguide/forms/Input'
import Select from '../../styleguide/forms/Select'
import theme from '../../styleguide/theme'

const Wrapper = styled.div`
	${pageWrapperPadding}
	flex: 1;
	overflow: auto;
`

const Fields = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 2rem;
	margin-bottom: 1.25rem;
`

const SectionHeader = styled.div`
	width: 100%;
	color: ${theme.palette.grey[500]};
	margin-bottom: 1rem;
`

const StyledField = styled(Field)`
	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: ${theme.palette.secondary.main};
	}
`

const SearchButtonWrapper = styled.div`
	grid-column-start: 1;
	grid-column-end: 4;
	display: flex;
	justify-content: end;
`

const LastFields = styled(Fields)`
	grid-template-columns: repeat(6, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-template-areas:
		'address address address address zip zip'
		'state state city city country country';

	> :nth-child(1) {
		grid-area: address;
	}

	> :nth-child(2) {
		grid-area: zip;
	}

	> :nth-child(3) {
		grid-area: state;
	}

	> :nth-child(4) {
		grid-area: city;
	}

	> :nth-child(5) {
		grid-area: country;
	}
`

export const TAB_TYPE_PHONE_NUMBER = [
	{ value: TypePhoneNumber.HOME, labelKey: 'forms.homeTypePhone' },
	{ value: TypePhoneNumber.OFFICE, labelKey: 'forms.officeTypePhone' },
	{ value: TypePhoneNumber.PERSONAL, labelKey: 'forms.personalTypePhone' },
]

export const TAB_LANGUAGES = [
	{ value: Languages.ENGLISH, labelKey: 'forms.english' },
	{ value: Languages.SPANISH, labelKey: 'forms.spanish' },
	{ value: Languages.FRENCH, labelKey: 'forms.french' },
	{ value: Languages.OTHER, labelKey: 'forms.other' },
]

export const AppointmentPatientFormBody = ({
	SearchPatientButton,
	formLocked,
}: {
	SearchPatientButton: JSX.Element
	formLocked: boolean
}) => {
	const { t } = useTranslation()
	const { dateFormat } = useDateFormatter()

	return (
		<Wrapper>
			<SectionHeader>
				{t('forms.sectionHeaderPersonalInformation')}
			</SectionHeader>

			<Fields>
				<StyledField
					name="name"
					fullWidth
					component={Input}
					required
					label={t('forms.name')}
					placeholder={t('forms.namePlaceholder')}
				/>

				<StyledField
					name="surname"
					fullWidth
					component={Input}
					required
					label={t('forms.surname')}
					placeholder={t('forms.surnamePlaceholder')}
				/>

				<StyledField
					name="birthDate"
					component={DatePicker}
					fullWidth
					disableFuture={true}
					label={firstLetterCapital(t('forms.birthDate'))}
					helperText={dateFormat.toUpperCase()}
					required
				/>

				<SearchButtonWrapper>{SearchPatientButton}</SearchButtonWrapper>
			</Fields>

			<SectionHeader>{t('forms.sectionHeaderContacts')}</SectionHeader>

			<Fields>
				<StyledField
					name="email"
					fullWidth
					component={Input}
					required
					label={t('forms.email')}
					placeholder={t('forms.emailPlaceholder')}
					type="email"
					disabled={formLocked}
				/>

				<StyledField
					name="phone"
					fullWidth
					component={Input}
					label={t('forms.phoneNumber')}
					placeholder={t('forms.phoneNumberPlaceholder')}
					type="tel"
					disabled={formLocked}
				/>

				<StyledField
					name="phoneType"
					component={Select}
					fullWidth
					label={t('forms.primaryPhoneType')}
					options={TAB_TYPE_PHONE_NUMBER.map(item => ({
						value: item.value,
						label: t(item.labelKey),
					}))}
					anchorMenu={true}
					disabled={formLocked}
				/>

				<StyledField
					name="secondaryPhone"
					fullWidth
					component={Input}
					label={t('forms.altPhoneNumber')}
					placeholder={t('forms.altPhoneNumberPlaceholder')}
					type="tel"
					disabled={formLocked}
				/>

				<StyledField
					name="secondaryPhoneType"
					component={Select}
					fullWidth
					label={t('forms.altTypePhoneNumber')}
					options={TAB_TYPE_PHONE_NUMBER.map(item => ({
						value: item.value,
						label: t(item.labelKey),
					}))}
					anchorMenu={true}
					disabled={formLocked}
				/>

				<StyledField
					name="language"
					component={Select}
					fullWidth
					label={t('forms.language')}
					options={TAB_LANGUAGES.map(item => ({
						value: item.value,
						label: t(item.labelKey),
					}))}
					anchorMenu={true}
					disabled={formLocked}
				/>
			</Fields>

			<SectionHeader>{t('forms.sectionHeaderAddress')}</SectionHeader>

			<LastFields>
				<StyledField
					name="address"
					fullWidth
					component={Input}
					label={t('forms.tabAddress')}
					placeholder={t('forms.tabAddress')}
					disabled={formLocked}
				/>

				<StyledField
					name="postalCode"
					fullWidth
					component={Input}
					label={t('forms.tabZip')}
					placeholder={t('forms.tabZip')}
					type="tel"
					disabled={formLocked}
				/>

				<StyledField
					name="state"
					fullWidth
					component={Input}
					label={t('forms.tabState')}
					placeholder={t('forms.tabState')}
					disabled={formLocked}
				/>

				<StyledField
					name="city"
					fullWidth
					component={Input}
					label={t('forms.tabCity')}
					placeholder={t('forms.tabCity')}
					disabled={formLocked}
				/>

				<StyledField
					name="country"
					fullWidth
					component={Input}
					label={t('forms.tabCountry')}
					placeholder={t('forms.tabCountry')}
					disabled={formLocked}
				/>
			</LastFields>
		</Wrapper>
	)
}
