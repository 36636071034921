import { createApi } from '@reduxjs/toolkit/query/react'
import qs from 'qs'
import { decodeRole } from '../decoders/role'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { Id, UsersState } from '../model/model'
import { ApiUser, GetUsersQuery, Role } from '../model/users'
import { unversionedApiUrl } from './common'

const STORE_USERS = 'storeStaff'
export const GET_USERS_TAG = 'getUsers'

export type StoreUsersQueryResult = {
	username: string
	fullName: string
}

export const usersApi = createApi({
	reducerPath: 'usersApi',
	baseQuery: staggeredBaseQueryWithBailOut(`${unversionedApiUrl}`),
	tagTypes: [STORE_USERS, GET_USERS_TAG],
	endpoints: builder => ({
		getUserByUsername: builder.query<
			{ name: string; surname: string; role: Role },
			{ username: string }
		>({
			query: ({ username }) => ({
				url: `/v1/users/find-light/${username}`,
			}),
		}),
		getUsersByStore: builder.query<
			StoreUsersQueryResult[],
			{ storeId: Id; roles: Role[] }
		>({
			query: ({ storeId, roles }) => ({
				url: `/v3/users/${storeId}?=roles=${roles.join(',')}`,
			}),
			providesTags: [STORE_USERS],
			transformResponse: (res: ApiUser[]) => {
				return res.map(u => ({
					username: u.username,
					fullName: `${u.name} ${u.surname}`,
				}))
			},
		}),
		getUsers: builder.query<UsersState, GetUsersQuery>({
			query: args => ({
				url: `/v1/users?${qs.stringify({ ...args })}`,
			}),
			providesTags: [GET_USERS_TAG],
			transformResponse: (res: ApiUser[]) => {
				return res.reduce((result, user) => {
					result[user.username] = { ...user, role: decodeRole(user.role) }
					return result
				}, {} as UsersState)
			},
		}),
	}),
})

export const {
	useGetUserByUsernameQuery,
	useGetUsersByStoreQuery,
	useLazyGetUsersQuery,
} = usersApi
