import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { pxToRem } from '../libs/style'
import theme from '../styleguide/theme'
import { opacity } from '../styleguide/CommonPageComponents'

const expand = keyframes`
  0% {
    max-width: ${pxToRem(36)}rem;
  }
  
  50% {
    max-width: ${pxToRem(140)}rem;
  }

  100% {
    max-width: ${pxToRem(36)}rem;
  }
`

export const StyledToggle = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	width: max-content;
	background-color: #4072ee;
	border-top-right-radius: ${pxToRem(6)}rem;
	border-top-left-radius: ${pxToRem(6)}rem;
	font-size: ${pxToRem(16)}rem;
	color: ${theme.palette.background.default};
	padding: ${theme.spacing(1)};
	white-space: nowrap;
	cursor: pointer;
	z-index: 0;

	span {
		&.icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: ${pxToRem(20)}rem;
			height: ${pxToRem(20)}rem;
		}

		&.text {
			animation-name: ${expand}, ${opacity};
			animation-duration: 2000ms, 1250ms;
			animation-iteration-count: 1;
			animation-fill-mode: none;
			animation-timing-function: ease, ease-in-out;
			max-width: 0;
			opacity: 0;
			margin-right: 0;
			font-size: ${pxToRem(12)}rem;
			transition: max-width 1000ms ease 125ms, opacity 250ms ease-in-out 250ms,
				margin-right 250ms ease-in-out 250ms;
		}
	}

	&.open,
	&.active {
		span.text {
			opacity: 1;
			max-width: ${pxToRem(140)}rem;
			margin-right: ${theme.spacing(1)};
		}
	}
`

type Props = {
	text: string
	isOpen: boolean
	onClick: () => void
	icon: JSX.Element
}

const ExpandableButton: React.FC<Props> = ({ text, isOpen, onClick, icon }) => {
	const [isHover, setIsHover] = useState<boolean>(false)

	const handleMouseEnter = () => {
		setIsHover(true)
	}
	const handleMouseLeave = () => {
		setIsHover(false)
	}

	return (
		<StyledToggle
			onClick={onClick}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			className={`${isHover ? 'active' : ''} ${isOpen ? 'open' : ''}`}
		>
			<span className="text">{text}</span>
			{icon}
		</StyledToggle>
	)
}

export default ExpandableButton
