import { createApi } from '@reduxjs/toolkit/query/react'

import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from './common'
import { ServiceBaseUrl } from './serviceApi'
import { ServicesApiTag, ServicesReducerPath } from './constants'
import {
	AIPredictionsResponse,
	AIFeedbackResponse,
	AIPredictionRequest,
	AIFeedbackRequest,
} from '../model/ai'

export const aiApi = createApi({
	reducerPath: ServicesReducerPath.AIApi,
	tagTypes: [ServicesApiTag.Ai],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		fetchAIPredictions: builder.mutation<
			AIPredictionsResponse[],
			AIPredictionRequest[]
		>({
			query: body => ({
				url: `${ServiceBaseUrl.AIV3}/ai/predict`,
				method: 'POST',
				body,
			}),
			invalidatesTags: [{ type: ServicesApiTag.Ai, id: 'LIST' }],
		}),

		sendFeedbackToAI: builder.mutation<AIFeedbackResponse, AIFeedbackRequest>({
			query: body => ({
				url: `${ServiceBaseUrl.AIV3}/ai/feedback`,
				method: 'POST',
				body,
			}),
			invalidatesTags: [{ type: ServicesApiTag.Ai, id: 'LIST' }],
		}),
	}),
})

export const { useFetchAIPredictionsMutation, useSendFeedbackToAIMutation } =
	aiApi
