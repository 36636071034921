import React from 'react'
import { SvgProps } from '../../model/types'

const HeartIcon = (props: SvgProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.4113 3.1675C17.1576 4.18492 18.3855 6.25053 18.3314 8.66071C18.264 11.6683 15.9226 14.322 11.9327 16.6921C11.3411 17.0435 10.7175 17.4998 9.99984 17.4998C9.29575 17.4998 8.64534 17.0357 8.06698 16.6921C4.07712 14.322 1.73567 11.6683 1.66822 8.66071C1.61417 6.25053 2.84205 4.18492 4.58829 3.1675C6.2216 2.2159 8.27329 2.21079 9.99984 3.61519C11.7264 2.21079 13.7781 2.2159 15.4113 3.1675ZM14.5723 4.60758C13.4109 3.9309 11.9593 3.95497 10.7021 5.22615C10.3166 5.61594 9.68309 5.61594 9.29759 5.22615C8.04043 3.95497 6.58874 3.9309 5.42733 4.60758C4.22364 5.30888 3.29352 6.79768 3.33447 8.6233C3.38141 10.7166 5.03412 12.952 8.91817 15.2592C9.25809 15.4611 9.61334 15.7207 9.99984 15.828C10.3863 15.7207 10.7416 15.4611 11.0815 15.2592C14.9656 12.952 16.6183 10.7166 16.6652 8.6233C16.7062 6.79768 15.776 5.30888 14.5723 4.60758Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export default HeartIcon
