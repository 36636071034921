import { SOUNDS } from '../../../utils/assetRegistry'

const audio = new Audio(SOUNDS.DING_URL)

// since audio.play implementation does not
// reset currentTime, no need to check if audio
// is currently playing or not
export function playDing() {
	if (audio.readyState >= 2) {
		audio.play()
	}
}
