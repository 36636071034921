import { ThemeOptions, createTheme } from '@mui/material/styles'
import { LicenseInfo } from '@mui/x-date-pickers-pro'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import appConfig from '../config'

import { themeComponents } from './themeComponents'
import { palette } from './themePalette'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LICENSE || '')

declare module '@mui/material' {
	interface Color {
		50: string
		100: string
		200: string
		300: string
		400: string
		500: string
		550: string
		600: string
		700: string
		800: string
		900: string
		A100: string
		A200: string
		A400: string
		A700: string
	}
}

declare module '@mui/material/styles' {
	interface PaletteColor {
		[key: number | string]: string
	}

	interface Palette {
		avatar: Palette['primary']
		spacer: Palette['primary']
		aquamarine: Palette['primary']
		greyCustom: Palette['primary']
		titles: Palette['primary']
	}

	interface PaletteOptions {
		avatar: PaletteOptions['primary']
		spacer: PaletteOptions['primary']
	}
}

// To add custom variables to theme with TS
// https://material-ui.com/guides/typescript/#customization-of-theme
const customTheme: ThemeOptions = {
	components: themeComponents,
	typography: {
		fontFamily: 'Neue Haas Grotesk Text Std, Arial, sans-serif',
		fontSizes: {
			XXS: '0.4rem',
			XS: '0.6rem',
			S: '0.8rem',
			M: '1rem',
			L: '1.2rem',
			XL: '1.4rem',
			XXL: '1.6rem',
		},
		h1: {
			fontSize: '1.6rem',
			fontWeight: 400,
			textTransform: 'capitalize',
		},
		h2: {
			fontSize: '1.3rem',
			fontWeight: 400,
			textTransform: 'capitalize',
		},
	},
	shape: {
		borderRadius: 5,
		borderThickness: 1,
	},
	palette,
	zIndex: {
		mobileStepper: 1000,
		speedDial: 1050,
		pageElements: 1068,
		header: 1101,
		appBar: 1100,
		drawer: 1200,
		autocompletePopper: 1250,
		snackbar: 1400,
		tooltip: 1450,
		call: 1470,
		modal: 1500,
		autocompleteModalPopper: 1501,
		lightbox: 1600,
	},
} as ThemeOptions

const theme = createTheme(customTheme)

export const sizings = {
	mdLarge: 850,
	mdSmall: 740,
	drawer: 240,
	drawerMenu: 216,
	drawerHandle: 24,
	scrollbar: 7,
}

if (process.env.REACT_APP_UI_REWORK) {
	theme.palette = {
		...theme.palette,
		primary: {
			...theme.palette.primary,
			main: '#20233D',
		},
		secondary: {
			...theme.palette.secondary,
			main: '#3E70D6',
			light: '#4F82E8',
		},
		success: {
			...theme.palette.success,
			main: '#23AE31',
			light: '#36D545',
		},
		error: {
			...theme.palette.error,
			main: '#BA1D1D',
			light: '#DF2D2D',
		},
		grey: {
			...theme.palette.grey,
			50: '#F9F9F9',
			100: '#F5F6F8',
			200: '#EDF3FD',
			300: '#E4EDFA',
			400: '#D9E4F5',
			500: '#C8D6EB',
			600: '#8396B4',
			700: '#3E4A5C',
			800: '#323C4B',
			900: '#101419',
		},
		severity: {
			LOW: '#79A3F7',
			MEDIUM: '#FFB63B',
			HIGH: '#F55959',
		},
	}
}

export default theme
