import React, { FC } from 'react'

import IconButton from '../../styleguide/buttons/IconButton'
import CloseIcon from '../../styleguide/icons/CloseIcon'

import { offlineNotification } from './constants'

export type NotificationCloseButtonProps = {
	id: string
	onClose: () => void
}

export const NotificationCloseButton: FC<NotificationCloseButtonProps> = ({
	id,
	onClose,
}) => {
	if (id === offlineNotification) {
		return null
	}

	return (
		<IconButton
			size="small"
			aria-label="close"
			data-testid="close-button"
			color="inherit"
			onClick={onClose}
		>
			<CloseIcon />
		</IconButton>
	)
}
