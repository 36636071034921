import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { selectIsOTTMode, selectStoreId } from '../features/app/selectors'
import { selectUsername } from '../features/auth/selectors'
import dialogActions from '../features/dialog/actions'
import examsActions from '../features/exams/actions'
import storesActions from '../features/stores/actions'
import { selectInstrumentsStages } from '../features/stores/selectors'
import { useSyncDataStore } from '../hooks/useSyncData'
import { pxToRem } from '../libs/style'
import { isString } from '../libs/utils'
import { ExamStatus } from '../model/exam'
import FormControl from '../styleguide/FormControl'
import LockIcon from '../styleguide/icons/LockIcon'
import InputLabel from '../styleguide/InputLabel'
import MenuItem from '../styleguide/MenuItem'
import Select from '../styleguide/Select'
import theme from '../styleguide/theme'
import InfoIcon from '../styleguide/icons/InfoIcon'
import SettingsIcon from '../styleguide/icons/SettingsIcon'
import { useTeloDispatch, useTeloSelector } from '../store'
import { SelectChangeEvent } from '@mui/material'

const SelectsWrapper = styled.div`
	display: grid;
	grid-template-columns: 15rem 15rem 1fr;
	gap: ${theme.spacing(3)};
`

const StyledFormControl = styled(FormControl)`
	.MuiFormLabel-root::first-letter {
		text-transform: uppercase;
	}
`

const StyledMenuItem = styled(MenuItem)`
	flex-direction: column;
`

const StyledSelect = styled(Select)`
	&.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: ${theme.palette.secondary.main};
	}
`

const MaintenanceLabel = styled.div`
	line-height: 1.3;
	align-self: flex-start;
	font-size: ${theme.typography.caption.fontSize};
	display: flex;

	div {
		margin-right: ${theme.spacing(1)};
	}

	svg {
		width: ${pxToRem(14)}rem;
		height: ${pxToRem(14)}rem;
	}
`

const SelectRoomItem = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`

const OTTRoomsShield = styled.div`
	position: absolute;
	height: ${theme.spacing(20)};
	width: ${theme.spacing(30)};
`

const OTTRoomsIconsShield = styled.div`
	position: absolute;
	height: ${theme.spacing(8)};
	width: ${theme.spacing(4)};
	bottom: ${theme.spacing(5.5)};
	right: ${theme.spacing(1.5)};
`

const StageAndRoomSelection: React.FC<{
	examId: string
	examCurrentStatus?: ExamStatus
	className?: string
	defaultOpenRoomDDM?: boolean
	setDefaultOpenRoomDDM?: (value: boolean) => void
}> = ({
	examId,
	className,
	examCurrentStatus,
	defaultOpenRoomDDM,
	setDefaultOpenRoomDDM,
}) => {
	const dispatch = useTeloDispatch()
	const { t } = useTranslation()
	const isOttMode = useTeloSelector(selectIsOTTMode)
	const stages = useTeloSelector(selectInstrumentsStages)
	const storeId = useTeloSelector(selectStoreId)
	const username = useTeloSelector(selectUsername)
	const [loading, setLoading] = useState(false)
	const [selectedStageId, setSelectedStageId] = useState(
		stages.length === 1
			? stages[0]._id
			: stages.find(
					({ rooms }) =>
						!!rooms.find(({ lockedByExamId }) => lockedByExamId === examId) ||
						isOttMode,
			  )?._id || '',
	)

	useSyncDataStore()

	const rooms =
		isOttMode && stages.length === 1
			? stages[0].rooms
			: stages
					.filter(({ _id }) => _id === selectedStageId)
					.flatMap(({ rooms }) => rooms)

	return (
		<SelectsWrapper className={className}>
			<StyledFormControl>
				<InputLabel htmlFor="select-stage">{t('exam.selectStage')}</InputLabel>
				<Select
					id="select-stage"
					data-testid="select-stage"
					value={selectedStageId}
					disabled={loading}
					label={t('exam.selectStage')}
					onChange={(ev: SelectChangeEvent<unknown>) => {
						isString(ev.target.value) && setSelectedStageId(ev.target.value)
					}}
				>
					{stages.map(({ _id, name }) => (
						<MenuItem key={_id} value={_id}>
							{name}
						</MenuItem>
					))}
				</Select>
			</StyledFormControl>
			<StyledFormControl variant="outlined">
				<InputLabel htmlFor="select-room">{t('exam.selectCluster')}</InputLabel>
				{isOttMode ? (
					<OTTRoomsShield className="ott-preTest-DDM">
						<OTTRoomsIconsShield className="ott-preTest-Icons" />
						<StyledSelect
							id="select-room"
							open={defaultOpenRoomDDM}
							onClick={() => {
								setDefaultOpenRoomDDM &&
									setDefaultOpenRoomDDM(!defaultOpenRoomDDM)
							}}
							data-testid="select-room"
							label={t('exam.selectCluster')}
							value={rooms[0]._id || ''}
							onChange={async ev => {
								if (!isString(ev.target.value)) {
									return
								}
								if (
									examCurrentStatus &&
									['Waiting'].includes(examCurrentStatus)
								) {
									return
								}
								const lockedRoom = rooms.find(
									r =>
										r._id === ev.target.value &&
										r.lockedByExamId &&
										r.lockedByExamId.length > 0,
								)
								if (lockedRoom) {
									dispatch(
										dialogActions.openDialog({
											type: 'unlockDialog',
											storeId,
											stageId: lockedRoom.stageId,
											roomId: lockedRoom._id,
										}),
									)
									return
								}
								setLoading(true)
								await dispatch(
									storesActions.lockInstrumentRoomAction({
										stageId: selectedStageId,
										roomId: ev.target.value,
										examId: examId,
										storeId,
									}),
								)
								if (examCurrentStatus === 'IntakeFormEnded') {
									await dispatch(
										examsActions.setExamStatus({
											id: examId,
											status: 'PreTestStarted',
											username,
										}),
									)
								}
								setLoading(false)
							}}
						>
							{rooms.map(
								({
									_id,
									name,
									lockedByExamId,
									online,
									listeningForConfiguration,
								}) => (
									<StyledMenuItem
										key={_id}
										value={_id}
										disabled={Boolean(!online || listeningForConfiguration)}
										onClick={async () => {
											isOttMode &&
												(await dispatch(
													storesActions.lockInstrumentRoomAction({
														stageId: selectedStageId,
														roomId: _id,
														examId: examId,
														storeId,
													}),
												))
										}}
									>
										<SelectRoomItem>
											<div>{name}</div>
											{Boolean(lockedByExamId) &&
												!listeningForConfiguration && (
													<div>
														<LockIcon />
													</div>
												)}
											{listeningForConfiguration && (
												<div>
													<SettingsIcon />
												</div>
											)}
										</SelectRoomItem>
										{listeningForConfiguration && (
											<MaintenanceLabel>
												<div>
													<InfoIcon />
												</div>
												<div>{t('app.underMaintenance')}</div>
											</MaintenanceLabel>
										)}
									</StyledMenuItem>
								),
							)}
						</StyledSelect>
					</OTTRoomsShield>
				) : (
					<StyledSelect
						id="select-room"
						data-testid="select-room"
						label={t('exam.selectCluster')}
						value={
							rooms.find(({ lockedByExamId }) => lockedByExamId === examId)
								?._id || ''
						}
						onChange={async ev => {
							if (!isString(ev.target.value)) {
								return
							}
							if (
								examCurrentStatus &&
								['Waiting'].includes(examCurrentStatus)
							) {
								return
							}
							const lockedRoom = rooms.find(
								r =>
									r._id === ev.target.value &&
									r.lockedByExamId &&
									r.lockedByExamId.length > 0,
							)
							if (lockedRoom) {
								dispatch(
									dialogActions.openDialog({
										type: 'unlockDialog',
										storeId,
										stageId: lockedRoom.stageId,
										roomId: lockedRoom._id,
									}),
								)
								return
							}
							setLoading(true)
							await dispatch(
								storesActions.lockInstrumentRoomAction({
									stageId: selectedStageId,
									roomId: ev.target.value,
									examId: examId,
									storeId,
								}),
							)
							if (examCurrentStatus === 'IntakeFormEnded') {
								await dispatch(
									examsActions.setExamStatus({
										id: examId,
										status: 'PreTestStarted',
										username,
									}),
								)
							}
							setLoading(false)
						}}
					>
						{rooms.map(
							({
								_id,
								name,
								lockedByExamId,
								online,
								listeningForConfiguration,
							}) => (
								<StyledMenuItem
									key={_id}
									value={_id}
									disabled={Boolean(!online || listeningForConfiguration)}
									onClick={async () => {
										isOttMode &&
											(await dispatch(
												storesActions.lockInstrumentRoomAction({
													stageId: selectedStageId,
													roomId: _id,
													examId: examId,
													storeId,
												}),
											))
									}}
								>
									<SelectRoomItem>
										<div>{name}</div>
										{Boolean(lockedByExamId) &&
											lockedByExamId !== examId &&
											!listeningForConfiguration && (
												<div>
													<LockIcon />
												</div>
											)}
										{listeningForConfiguration && (
											<div>
												<SettingsIcon />
											</div>
										)}
									</SelectRoomItem>
									{listeningForConfiguration && (
										<MaintenanceLabel>
											<div>
												<InfoIcon />
											</div>
											<div>{t('app.underMaintenance')}</div>
										</MaintenanceLabel>
									)}
								</StyledMenuItem>
							),
						)}
					</StyledSelect>
				)}
			</StyledFormControl>
		</SelectsWrapper>
	)
}

export default StageAndRoomSelection
