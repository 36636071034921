import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { selectGlobalEditingStatus } from '../features/ui/selectors'
import { useTeloSelector } from '../store'

import {
	ButtonContainerFullWidth,
	NextButtonFooter,
	PrevButtonFooter,
} from '../styleguide/CommonPageComponents'
import ThickIcon from '../styleguide/icons/ThickIcon'
import theme from '../styleguide/theme'
import ChevronLeftIcon from '../styleguide/icons/ChevronLeftIcon'

const StepCounter = styled.div`
	grid-column: 2;
	place-self: center center;
	color: ${theme.palette.grey[400]};
`

interface Props {
	activeStep: number
	disableBack?: boolean
	disableNext?: boolean
	FirstStepBackBtn?: JSX.Element
	nextBtn?: JSX.Element | null
	nextLabel?: string
	numberOfSteps: number
	oneColumnStepper?: boolean
	showBackButton?: boolean
	showStepCounter?: boolean
	StepBackInheritBtn?: JSX.Element
	isFixed?: boolean
	onClick?: (values: any) => void
	onBack?: (values: any) => void
	setActiveStep: (step: number) => void
}

const StepperNavigaton: React.FC<Props> = ({
	activeStep,
	numberOfSteps,
	disableNext,
	disableBack,
	setActiveStep,
	FirstStepBackBtn,
	StepBackInheritBtn,
	showBackButton = true,
	showStepCounter = true,
	oneColumnStepper = false,
	isFixed = false,
	onClick,
	onBack,
	nextBtn,
	nextLabel,
}) => {
	const isEditing = useTeloSelector(selectGlobalEditingStatus)
	const { t } = useTranslation()
	const isLastStep = activeStep === numberOfSteps - 1
	return (
		<ButtonContainerFullWidth
			oneColumnStepper={oneColumnStepper}
			isFixed={isFixed}
		>
			{showBackButton === true && (
				<>
					{activeStep === 0 ? (
						FirstStepBackBtn ? (
							FirstStepBackBtn
						) : null
					) : StepBackInheritBtn ? (
						StepBackInheritBtn
					) : (
						<PrevButtonFooter
							type="button"
							variant="text"
							disabled={disableBack}
							onClick={e => {
								setActiveStep(activeStep - 1)
								onBack && onBack(e)
							}}
							startIcon={<ChevronLeftIcon />}
						>
							{t('app.back')}
						</PrevButtonFooter>
					)}
				</>
			)}

			{showStepCounter && (
				<StepCounter>{`${t('app.step')} ${
					activeStep + 1
				}/${numberOfSteps}`}</StepCounter>
			)}

			{nextBtn !== undefined ? (
				nextBtn
			) : (
				<NextButtonFooter
					data-testid="next-button-footer"
					type="submit"
					disabled={disableNext || isEditing}
					onClick={onClick}
					endIcon={isLastStep && <ThickIcon />}
					className="ott-checkInPage-NextButton"
				>
					{nextLabel ? nextLabel : t(isLastStep ? 'app.confirm' : 'app.next')}
				</NextButtonFooter>
			)}
		</ButtonContainerFullWidth>
	)
}

export default StepperNavigaton
