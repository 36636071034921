import React from 'react'

import { SvgProps } from '../../model/types'

const HelpIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="currentColor"
			{...props}
		>
			<path d="M9.99996 1.66666C14.6023 1.66666 18.3333 5.39761 18.3333 9.99999C18.3333 14.6023 14.6023 18.3333 9.99996 18.3333C5.39758 18.3333 1.66663 14.6023 1.66663 9.99999C1.66663 5.39761 5.39758 1.66666 9.99996 1.66666ZM9.99996 3.33332C6.31806 3.33332 3.33329 6.31809 3.33329 9.99999C3.33329 13.6819 6.31806 16.6667 9.99996 16.6667C13.6819 16.6667 16.6666 13.6819 16.6666 9.99999C16.6666 6.31809 13.6819 3.33332 9.99996 3.33332ZM9.99996 13.3333C10.4602 13.3333 10.8333 13.7064 10.8333 14.1667C10.8333 14.6269 10.4602 15 9.99996 15C9.53971 15 9.16663 14.6269 9.16663 14.1667C9.16663 13.7064 9.53971 13.3333 9.99996 13.3333ZM9.99996 5.41666C11.6683 5.41666 13.0208 6.76913 13.0208 8.43749C13.0208 9.71016 12.2341 10.797 11.1236 11.2422C11.008 11.2886 10.9189 11.3532 10.869 11.4092C10.8321 11.4506 10.8266 11.5038 10.8282 11.5584L10.8333 11.6667C10.8333 12.1269 10.4602 12.5 9.99996 12.5C9.57258 12.5 9.22037 12.1783 9.17223 11.7638L9.16663 11.6667V11.4583C9.16663 10.4976 9.94104 9.92066 10.5035 9.69524C11.0035 9.49474 11.3541 9.00607 11.3541 8.43749C11.3541 7.68961 10.7479 7.08332 9.99996 7.08332C9.25204 7.08332 8.64579 7.68961 8.64579 8.43749C8.64579 8.89774 8.27269 9.27082 7.81246 9.27082C7.35223 9.27082 6.97913 8.89774 6.97913 8.43749C6.97913 6.76913 8.3316 5.41666 9.99996 5.41666Z" />
		</svg>
	)
}

export default HelpIcon
