import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../styleguide/buttons/Button'
import Dialog from '../../styleguide/dialog/Dialog'
import DialogContent from '../../styleguide/dialog/DialogContent'
import DialogActions from '../../styleguide/dialog/DialogActions'
import { FhirPatient } from '../../model/patient'
import { PatientRow } from './PatientRow'
import styled from 'styled-components'
import InfoIcon from '../../styleguide/icons/InfoIcon'
import theme from '../../styleguide/theme'
import CircularProgress from '../../styleguide/CircularProgress'
import CloseIcon from '../../styleguide/icons/CloseIcon'
import { pxToRem } from '../../libs/style'

const Header = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-bottom: 2rem;
`

const Title = styled.div`
	font-size: 1rem;
	width: 100%;
	display: flex;
	justify-content: space-between;

	svg {
		width: ${pxToRem(12)}rem;
		height: ${pxToRem(12)}rem;
		cursor: pointer;
	}
`

const Alert = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	font-size: 0.75rem;
	color: ${theme.palette.grey[500]};
`

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
`

const Thead = styled.thead`
	color: ${theme.palette.grey[500]};

	th {
		text-transform: capitalize;
		padding: 1rem;
		text-align: left;
	}
`

const Actions = styled(DialogActions)`
	display: flex;
	gap: 2rem;
	justify-content: flex-end;
	padding: 20px 24px;
`

export const PatientFoundModal = ({
	open,
	onClose,
	patients,
	selectPatient,
}: {
	open: boolean
	onClose: () => void
	patients: FhirPatient[]
	selectPatient: (patient: FhirPatient) => void
}) => {
	const { t } = useTranslation()

	const [selectedPatient, setSelectedPatient] = useState<
		FhirPatient | undefined
	>(patients.length === 1 ? patients[0] : undefined)

	const [creatingAppointment, setCreatingAppointment] = useState(false)

	return (
		<Dialog open={open} onClose={onClose} fullWidth>
			<DialogContent>
				<Header>
					<Title>
						<span>{t('patient.isThisAnExistingPatient')}</span>
						<CloseIcon onClick={onClose} fill="#4072EE" />
					</Title>
					<Alert>
						<InfoIcon /> {t('patient.selectExistingPatientAlert')}
					</Alert>
				</Header>

				<Table>
					<Thead>
						<tr>
							<th></th>
							<th>{t('patient.name')}</th>
							<th>{t('patient.surname')}</th>
							<th>{t('patient.dateOfBirth')}</th>
							<th>{t('patient.email')}</th>
							<th></th>
						</tr>
					</Thead>
					<tbody>
						{patients.map(patient => (
							<PatientRow
								key={patient.fhirId}
								onClick={() => setSelectedPatient(patient)}
								patient={patient}
								selected={patient.fhirId === selectedPatient?.fhirId}
							/>
						))}
					</tbody>
				</Table>
			</DialogContent>
			<Actions>
				<Button
					variant="text"
					color="secondary"
					onClick={onClose}
					disabled={creatingAppointment}
				>
					{t('patient.createNewPatient')}
				</Button>
				<Button
					variant="text"
					color="secondary"
					disabled={!selectedPatient || creatingAppointment}
					startIcon={creatingAppointment && <CircularProgress size={16} />}
					onClick={() => {
						if (selectedPatient) {
							selectPatient(selectedPatient)
							setCreatingAppointment(true)
						}
					}}
				>
					{t('patient.useThisPatient')}
				</Button>
			</Actions>
		</Dialog>
	)
}
