import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
	CarouselData,
	ClinicalDataSelectedContent,
	DiseaseAndTreatmentsDataCarousel,
	MyopiaContentKey,
	PrescriptionCLCarousel,
	PrescriptionDataCarousel,
} from '../../model/carousel'
import {
	ControlOptionsKeys,
	LifeStyleRecommendationsKeys,
} from '../../model/myopia'
import { CarouselState } from '../../model/model'
import { PrescriptionData } from '../../model/prescription'
import { instrumentType } from '../../model/cockpit'

const initialState: CarouselState = {
	showCarousel: false,
	shareCarousel: false,
	slidesQuantity: 0,
	activeSlide: 0,
	myopiaContent: {
		myopiaCharts: {
			refractionChartLeftEye: false,
			refractionChartRightEye: false,
			axialLengthChartLeftEye: false,
			axialLengthChartRightEye: false,
		},
		controlOptions: [],
		lifeStyleRecommendations: [],
		showSummary: false,
	},
	trendsOverTimeContent: {
		showSummary: false,
		totCharts: {
			sphere: false,
			cyl: false,
			spheq: false,
			add: false,
		},
	},
	clinicalDataContent: {
		showSummary: false,
		selectedContent: [],
	},
}

export const slice = createSlice({
	name: 'modalCarousel',
	initialState,
	reducers: {
		setShowCarousel: (state, { payload }: PayloadAction<boolean>) => {
			state.showCarousel = payload
		},
		setClinicalDataContent: (
			state,
			{ payload }: PayloadAction<ClinicalDataSelectedContent[] | undefined>,
		) => {
			state.clinicalDataContent.selectedContent = payload
		},
		setPrescriptionContent: (
			state,
			{ payload }: PayloadAction<PrescriptionDataCarousel[] | undefined>,
		) => {
			state.prescriptionContent = payload
		},
		setContactLensesData: (
			state,
			{ payload }: PayloadAction<PrescriptionCLCarousel[] | undefined>,
		) => {
			state.prescriptionClContent = payload
		},
		setPrescriptionForm: (
			state,
			{ payload }: PayloadAction<PrescriptionData>,
		) => {
			state.prescriptionForm = payload
		},
		setDiseasesAndTreatmentsContent: (
			state,
			{
				payload,
			}: PayloadAction<DiseaseAndTreatmentsDataCarousel[] | undefined>,
		) => {
			state.diseaseTreatmentContent = payload
		},
		setCarouselContent: (
			state,
			{ payload }: PayloadAction<CarouselData | undefined>,
		) => {
			state.carouselData = payload
		},
		setShareCarousel: (state, { payload }: PayloadAction<boolean>) => {
			state.shareCarousel = payload
		},
		setSlidesQuantity: (state, { payload }: PayloadAction<number>) => {
			state.slidesQuantity = payload
		},
		setActiveSlide: (state, { payload }: PayloadAction<number>) => {
			state.activeSlide = payload
		},
		setMyopiaSelectedContent: (
			state,
			{ payload }: PayloadAction<{ key: MyopiaContentKey; show: boolean }>,
		) => {
			state.myopiaContent.myopiaCharts[payload.key] = payload.show
		},
		setMyopiaControlOptions: (
			state,
			{ payload }: PayloadAction<ControlOptionsKeys[]>,
		) => {
			state.myopiaContent.controlOptions = payload
		},
		setMyopiaLifestyleRecommendations: (
			state,
			{ payload }: PayloadAction<LifeStyleRecommendationsKeys[]>,
		) => {
			state.myopiaContent.lifeStyleRecommendations = payload
		},
		setMyopiaShowSummary: state => {
			state.myopiaContent.showSummary = !state.myopiaContent.showSummary
		},
		setTotShowSummary: state => {
			state.trendsOverTimeContent.showSummary =
				!state.trendsOverTimeContent.showSummary
		},
		setTotSelectedContent: (
			state,
			{ payload }: PayloadAction<{ key: instrumentType; show: boolean }>,
		) => {
			state.trendsOverTimeContent.totCharts[payload.key] = payload.show
		},
		setClinicalDataShowSummary: state => {
			state.clinicalDataContent.showSummary =
				!state.clinicalDataContent.showSummary
		},
	},
})

export default slice.reducer
