/* This is the main app entry point, it depends on react-router and uses it to create the telo wrapper */
/* eslint-disable telo/no-react-router-import */
import React, { ReactNode, useCallback, useMemo } from 'react'

import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom'
import { useLogoutNavigation } from './hooks/navigation/useLogoutNavigation'
import { useSocketFeatureToggle } from './hooks/useSocket'
import {
	GetTeloLocationFn,
	TeloNavigationFn,
	TeloRouterContext,
	TeloRouterContextHandles,
} from './routing/teloRouter'
import { readTeloParamsFromFunc } from './routing/teloRouterUtils'
import store from './store'

const Setup = ({ children }: { children?: ReactNode }) => {
	useSocketFeatureToggle()
	useLogoutNavigation()

	return <>{children}</>
}

export const WithTeloNavigation = ({ children }: { children?: ReactNode }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const [searchParams, setSearchParams] = useSearchParams()
	const rrParams = useParams()

	const teloNavigate: TeloNavigationFn = useCallback(
		(to, opts) => {
			if (typeof to === 'number') {
				navigate(to)
			} else {
				navigate(to, opts as any)
			}
		},
		[navigate],
	)

	const getLocation: GetTeloLocationFn = useCallback(() => location, [location])

	const data: TeloRouterContextHandles = useMemo(
		() => ({
			getLocation,
			navigate: teloNavigate,
			readSearchParams: (...teloParams) => {
				const urlParams = new URLSearchParams(getLocation().search)
				const readSearchParams = (pName: string) => {
					return urlParams.get(pName)
				}
				return readTeloParamsFromFunc(readSearchParams, ...teloParams)
			},
			setSearchParams: (params, options) => {
				Object.entries(params).forEach(([key, value]) => {
					searchParams.set(key, value)
				})
				setSearchParams(searchParams, options)
			},
			getAllSearchParams: () => ({ searchParams }),
			readRouteParams: (...searched) => {
				return readTeloParamsFromFunc(pName => {
					return rrParams[pName]
				}, ...searched)
			},
		}),
		[getLocation, rrParams, searchParams, setSearchParams, teloNavigate],
	)

	return (
		<TeloRouterContext.Provider value={data}>
			<Setup>{children}</Setup>
		</TeloRouterContext.Provider>
	)
}
