import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import config from '../config'
import authActions from '../features/auth/actions'
import { selectHttpErrors, selectUiErrors } from '../features/errors/selectors'
import TroubleShooting from '../features/troubleShooting/TroubleShooting'
import troubleShootingActions from '../features/troubleShooting/actions'
import {
	selectTroubleShootingDataTransfer,
	selectTroubleShootingLogin,
	selectTroubleShootingVideoConference,
} from '../features/troubleShooting/selectors'
import { isGeminiRegion } from '../libs/region'
import { useTeloRouter } from '../routing/teloRouter'
import { useTeloDispatch, useTeloSelector } from '../store'
import { HelpersWrapper, PageWrapper } from '../styleguide/CommonPageComponents'
import Button from '../styleguide/buttons/Button'
import theme from '../styleguide/theme'
import { IMAGES } from '../utils/assetRegistry'
import { useFetchTroubleShooting } from '../hooks/useFetchTroubleShooting'
import { deleteIntersessionInactivityModalShown } from '../libs/intersession'

const Container = styled(PageWrapper)`
	background-color: ${theme.palette.background.secondary};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const Logo = styled.img<{ visionx: boolean }>`
	width: ${props => (props.visionx === true ? '17%' : '33%')};
	height: auto;
	display: block;
	margin-bottom: ${theme.spacing(4)};
`

const Error = styled.div`
	color: ${theme.palette.grey[500]};
	font-size: 0.8rem;
	width: 90%;
	margin: ${theme.spacing(5)} auto ${theme.spacing(4)};
	text-align: center;
	&:first-letter {
		text-transform: uppercase;
	}

	> * {
		margin-bottom: ${theme.spacing(2)};
	}

	details p {
		text-align: left;
	}
`

const Buttons = styled.div`
	display: flex;
	justify-content: center;
	> *:first-child {
		margin-right: ${theme.spacing(3)};
	}
`

const ErrorPage = () => {
	const { t } = useTranslation()
	const { navigate } = useTeloRouter()
	const httpErrors = useTeloSelector(selectHttpErrors)
	const uiErrors = useTeloSelector(selectUiErrors)
	const dispatch = useTeloDispatch()

	const troubleShootingLogin = useTeloSelector(selectTroubleShootingLogin)
	const troubleShootingVideoConference = useTeloSelector(
		selectTroubleShootingVideoConference,
	)
	const troubleShootingDataTransfer = useTeloSelector(
		selectTroubleShootingDataTransfer,
	)

	useFetchTroubleShooting()

	const logoUrl =
		config.region === 'GEMINI'
			? IMAGES.VISIONX_LOGO_URL
			: IMAGES.CONNECT_LOGO_URL

	return (
		<Container>
			<Logo
				alt="Luxottica Optometrist"
				src={logoUrl}
				visionx={config.region === 'GEMINI'}
			/>

			{httpErrors.map((error, i) => (
				<Error key={`http_${i}`}>
					<div>{t('app.serverErrorMessage')}</div>
					<div>{error?.message || t('app.unknownError')}</div>
					<details>
						<summary>{t('app.details')}</summary>
						<div>
							{error?.internalUrl && (
								<div>Internal url: {error.internalUrl}</div>
							)}
							{error?.externalUrl && (
								<div>External url: {error.externalUrl}</div>
							)}
							{error?.origin && <div>Origin: {error.origin}</div>}
						</div>
					</details>
				</Error>
			))}
			{uiErrors.map((error, i) => (
				<Error key={`ui_${i}`}>
					<div>{t('app.uiErrorMessage')}</div>
					<div>{error?.message || t('app.unknownError')}</div>
					<details>
						<summary>{t('app.details')}</summary>

						{error?.stack && <p>Stack: {error.stack}</p>}
					</details>
				</Error>
			))}

			<Buttons>
				<Button onClick={() => window.location.reload()}>
					{t('app.reload')}
				</Button>

				<Button
					onClick={() => {
						console.log('calling logoutAction from ErrorPage')
						dispatch(authActions.logoutAction())
						deleteIntersessionInactivityModalShown()
						navigate('/login')
						window.location.reload()
					}}
				>
					{t('app.backToLogin')}
				</Button>
			</Buttons>
			{isGeminiRegion === true ? (
				<></>
			) : (
				<HelpersWrapper>
					<TroubleShooting
						dataset={[
							troubleShootingVideoConference,
							troubleShootingDataTransfer,
						]}
						fullDataset={[
							troubleShootingLogin,
							troubleShootingVideoConference,
							troubleShootingDataTransfer,
						]}
					/>
				</HelpersWrapper>
			)}
		</Container>
	)
}

export default ErrorPage
