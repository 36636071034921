import jwt_decode from 'jwt-decode'

import { createSelector } from '@reduxjs/toolkit'

import { decodeRole } from '../../decoders/role'
import { LoginToken, RootState } from '../../model/model'
import { Role } from '../../model/users'

export const selectAuthToken = (state: RootState) => state.auth.token

export const selectAuthenticationError = (state: RootState) =>
	state.auth.authenticationError

export const selectMissingUserError = (state: RootState) =>
	state.auth.missingUserError

export const selectDecodedAuthToken = createSelector(
	selectAuthToken,
	authToken => {
		if (!authToken || authToken.length === 0) {
			return undefined
		}
		const decodedToken = jwt_decode(authToken) as LoginToken
		return decodedToken
	},
)

export const selectUserRole = createSelector(
	selectDecodedAuthToken,
	(decodedToken): Role => {
		return decodedToken?.role ? decodeRole(decodedToken.role) : 'Guest'
	},
)

export const selectAppLoginStatus = (state: RootState) => state.auth.loginStatus
export const selectAppEclipsUserVerified = (state: RootState) =>
	state.auth.eclipsUserVerified

export const selectUsername = createSelector(
	selectDecodedAuthToken,
	decodedToken => {
		return decodedToken?.username?.toLowerCase() || ''
	},
)
