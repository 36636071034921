import { useEffect } from 'react'
import jwtDecode from 'jwt-decode'

import { LoginToken } from '../model/model'
import { TeloDispatch } from '../store'
import dialogActions from '../features/dialog/actions'

export const TOKEN_EXP_TIME_IN_SECONDS = 300

export const useTokenExpirationNotification = (
	loginToken: string,
	skipFeature: boolean,
	dispatch: TeloDispatch,
): void => {
	useEffect(() => {
		if (skipFeature === true) {
			return
		}

		const { exp } = jwtDecode<LoginToken>(loginToken)

		const interval = setInterval(() => {
			if (
				Math.round(exp) - Math.round(Date.now() / 1000) <=
				TOKEN_EXP_TIME_IN_SECONDS
			) {
				dispatch(
					dialogActions.openDialog({
						type: 'sessionExpiration',
						customTitle: true,
					}),
				)
				clearInterval(interval)
			}
		}, 1000)

		return function cleanUp() {
			clearInterval(interval)
		}
	}, [dispatch, skipFeature, loginToken])
}
