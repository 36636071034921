import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { AlertCategory } from '../../model/alerts'
import { PatientAlert, SeverityKeys } from '../../model/patient'
import { AlertsState } from '../../model/model'
import { CDS, CDS_RULE } from '../../model/cds'

import {
	getFilteredActiveAlerts,
	getAreAlertsByTypeSame,
	getCDSActiveAlertsByType,
} from './helpers'
import {
	ActivateAlertPayload,
	ActivateCDSAlertPayload,
	DeactivateCDSAlertPayload,
} from './types'
import { alertsSliceName, initialActiveAlerts } from './constants'

export const alertsInitialState: AlertsState = {
	existingCategoryNames: [],
	list: [],
	availableCategories: [],
	activeAlerts: initialActiveAlerts,
	rules: [],
	uprise: [],
	cds: [],
	prevOpener: '',
	alertsPageIsOpened: false,
}

export const slice = createSlice({
	name: alertsSliceName,
	initialState: alertsInitialState,
	reducers: {
		setExistingCategoryNames: (state, { payload }: PayloadAction<string[]>) => {
			state.existingCategoryNames = payload
		},
		addNameToExisting: (state, { payload }: PayloadAction<string>) => {
			state.existingCategoryNames.push(payload)
		},
		setAlerts: (state, { payload }: PayloadAction<PatientAlert[]>) => {
			state.list = payload
		},
		setCDSRules: (state, { payload }: PayloadAction<CDS[]>) => {
			state.rules = payload
		},
		setUprisePatientAlerts: (
			state,
			{ payload }: PayloadAction<PatientAlert[]>,
		) => {
			state.uprise = payload
		},
		setCDSPatientAlert: (state, { payload }: PayloadAction<PatientAlert>) => {
			const index = state.cds.findIndex(
				({ category }) => category.id === payload.category.id,
			)
			if (index > -1) {
				state.cds[index] = payload
			} else {
				state.cds.push(payload)
			}
		},
		deleteCDSPatientAlert: (state, { payload }: PayloadAction<CDS_RULE>) => {
			const index = state.cds.findIndex(
				({ category }) => category.id === payload,
			)
			if (index > -1) {
				state.cds.splice(index, 1)
			}
		},
		setAvailableCategories: (
			state,
			{ payload }: PayloadAction<AlertCategory[]>,
		) => {
			state.availableCategories = payload
		},
		activateAlert: (
			state,
			{ payload: { type, alerts } }: PayloadAction<ActivateAlertPayload>,
		) => {
			const areSameAlerts = getAreAlertsByTypeSame(
				type,
				alerts,
				state.activeAlerts,
			)

			if (!areSameAlerts) {
				const filteredAlerts = getFilteredActiveAlerts(
					type,
					alerts,
					state.activeAlerts,
					state.availableCategories,
				)

				if (filteredAlerts.length) {
					state.activeAlerts[type].enabled = true
					state.activeAlerts[type].list = filteredAlerts
				}
			}
		},
		activateCDSAlert: (
			state,
			{ payload: { type, alert } }: PayloadAction<ActivateCDSAlertPayload>,
		) => {
			const idsInList = state.activeAlerts[type].list.map(
				({ category }) => category.id,
			)
			const alertIsAlreadyInList = idsInList.some(
				id => id === alert.category.id,
			)

			if (!alertIsAlreadyInList) {
				state.activeAlerts[type].enabled = true
				state.activeAlerts[type].list = [
					...state.activeAlerts[type].list,
					alert,
				]
			}
		},
		deactivateAlert: (state, { payload }: PayloadAction<SeverityKeys>) => {
			state.activeAlerts[payload].enabled = false
		},
		deactivateCDSAlert: (
			state,
			{ payload }: PayloadAction<DeactivateCDSAlertPayload>,
		) => {
			const { type, rule } = payload
			const list = state.activeAlerts[type].list.filter(
				a => a.category.id !== rule,
			)

			if (!list.length) {
				state.activeAlerts[type].enabled = false
			}
			state.activeAlerts[type].list = list
		},
		resetActiveAlerts: state => {
			state.activeAlerts = initialActiveAlerts
		},
		resetCDSAlerts: (state, { payload }: PayloadAction<SeverityKeys>) => {
			const list = state.activeAlerts[payload].list.filter(
				a => a.type !== 'CDS',
			)

			state.activeAlerts[payload].list = list
		},
		clearCDSAlerts: state => {
			state.cds = []
		},
		setPrevOpener: (state, { payload }: PayloadAction<string>) => {
			state.prevOpener = payload
		},
		setAlertsPageIsOpened: (state, { payload }: PayloadAction<boolean>) => {
			state.alertsPageIsOpened = payload
		},
	},
})

export default slice.reducer
