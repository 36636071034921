import React from 'react'

import { FormControl as MFormControl, FormControlProps } from '@mui/material'

const FormControl: React.FC<FormControlProps> = ({ children, ...props }) => {
	return (
		<MFormControl variant="outlined" {...props}>
			{children}
		</MFormControl>
	)
}

export default FormControl
