import { createApi } from '@reduxjs/toolkit/query/react'
import callQueue from '../formFields/callQueue'
import {
	FieldsData,
	Path,
	FieldDataMutationPayload,
	FieldDataQueryPayload,
	GetFieldDataQuery,
	BasePath,
} from '../formFields/model'
import { RootState } from '../model/model'
import { fieldsDataMetaApi } from './fieldsDataMeta'

export const updateFieldDataCacheEntryAction = ({
	examId,
	index,
	path,
	value,
}: {
	examId: string
	path: Path
	index: number | undefined
	value: FieldsData[Path]['value']
}) =>
	fieldsDataApi.util.updateQueryData(
		'getFieldData',
		{ examId, index, path },
		draft => {
			// in this case the return is needed since draft can be null
			return Object.assign(
				draft || {
					path,
					index,
					createdBy: '',
					createdAt: new Date().toISOString(),
				},
				{ value },
			)
		},
	)

export const fieldsDataApi = createApi({
	reducerPath: 'fieldsDataApi',
	baseQuery: <P extends Path>(
		payload: FieldDataMutationPayload<P> | FieldDataQueryPayload<P>,
	) => callQueue.enqueueCall(payload),
	endpoints: builder => ({
		getFieldData: builder.query<
			FieldsData[Path] | null,
			{ examId: string; path: Path; index: number | undefined }
		>({
			query: params => ({
				...params,
				type: 'query',
			}),
		}),

		setFieldData: builder.mutation<
			FieldsData[Path] | null,
			{
				examId: string
				path: Path
				index: number | undefined
				value: FieldsData[Path]['value']
			}
		>({
			query: params => ({
				...params,
				type: 'mutation',
			}),
			async onQueryStarted(
				{ examId, index, path, value },
				{ dispatch, getState, queryFulfilled },
			) {
				try {
					// Update immediately the cached value with the new one to let the update propagate
					// to all components that are using the data
					dispatch(
						updateFieldDataCacheEntryAction({ examId, index, path, value }),
					)

					// When the query is fulfilled update the value with the one returned by the server...
					const { data } = await queryFulfilled
					dispatch(
						fieldsDataApi.util.updateQueryData(
							'getFieldData',
							{ examId, index, path },
							draft => {
								// in this case the return is needed since draft can be null
								return Object.assign(
									draft || {
										path,
										index,
										createdBy: '',
										createdAt: new Date().toISOString(),
									},
									data,
								)
							},
						),
					)

					// ...and update the info query...
					const basePath = path.split('.')[0] as BasePath

					const state = getState() as RootState

					const hasNonEmptyValues = Object.values(
						state.fieldsDataApi.queries,
					).some(query => {
						if (query?.endpointName !== 'getFieldData') {
							return false
						}
						const args = (query as GetFieldDataQuery).originalArgs
						const data = (query as GetFieldDataQuery).data
						return (
							args.examId === examId &&
							args.path.includes(basePath) &&
							data &&
							data.value !== null
						)
					})

					dispatch(
						fieldsDataMetaApi.util.updateQueryData(
							'getBasePathInfo',
							{ examId, basePath },
							draft => {
								return {
									hasValues: true,
									hasNonEmptyValues,
									hasNight: draft?.hasNight || path.includes('.night.'),
									hasOtherAccuracy:
										draft?.hasOtherAccuracy || path.includes('.other.'),
									validIndexes:
										index !== undefined
											? [...new Set((draft?.validIndexes || []).concat(index))]
											: draft?.validIndexes || [],
								}
							},
						),
					)

					// ...and check if we need
					// to update the nextValidIndex
					const getArrayNextValidIndexQuery = Object.values(
						state.fieldsDataMetaApi.queries,
					).find(query => {
						const args = query?.originalArgs as {
							examId: string
							basePath: string
						}
						return (
							query?.endpointName === 'getArrayNextValidIndex' &&
							args.examId === examId &&
							args.basePath === basePath
						)
					})

					const currentNextValidIndex =
						(
							getArrayNextValidIndexQuery?.data as
								| undefined
								| { nextValidIndex: number }
						)?.nextValidIndex || 0

					// If the index we've written is the current nextValidIndex
					// it means that we've filled in the new tab, so we are ready
					// to add a new tab, so a new nextValidIndex must be fetched
					if (index === currentNextValidIndex) {
						dispatch(
							fieldsDataMetaApi.endpoints.getArrayNextValidIndex.initiate(
								{ examId, basePath },
								{ forceRefetch: true },
							),
						)
					}
				} catch {}
			},
		}),
	}),
})

export const { useSetFieldDataMutation } = fieldsDataApi

export const useBatchMutation = () => {
	const [mutateField] = useSetFieldDataMutation()

	const batchMutate = ({
		examId,
		values,
		pathPrefix,
		index,
	}: {
		examId: string
		pathPrefix: string
		values: any
		index?: number
	}) => {
		for (const key in values) {
			mutateField({
				examId,
				index,
				path: `${pathPrefix}.${key}` as Path,
				value: values[key],
			})
		}
	}

	return { batchMutate }
}

export const useGetFieldDataQuery = <P extends Path>(params: {
	examId: string
	path: P
	// Index is defined as mandatory, even if it can be undefined, because it is part of the composite key that
	// identify the data, so it is safer to explicit declare it, event if it is non existent
	index: number | undefined
}) => {
	const { currentData, isError, isFetching, isLoading, isSuccess, error } =
		fieldsDataApi.useGetFieldDataQuery(params)
	return {
		// always use currentData, since it it vital that the data
		// refers to the right query params: examId, path and index
		data: (currentData ?? null) as FieldsData[P] | null,
		isLoading,
		isError,
		isFetching,
		isSuccess,
		error,
	}
}
