import React from 'react'
import { useTranslation } from 'react-i18next'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import styled from 'styled-components'
import { getAssetSrc } from '../../apiCalls'
import dialogActions from '../../features/dialog/actions'
import { selectDialog } from '../../features/dialog/selectors'
import useVisualSimulation from '../../hooks/useVisualSimulation'
import { pxToRem } from '../../libs/style'
import { VisualSimulationViewerDialogType } from '../../model/dialog'
import { useTeloDispatch, useTeloSelector } from '../../store'
import Button from '../../styleguide/buttons/Button'
import DialogActions from '../../styleguide/dialog/DialogActions'
import DialogContent from '../../styleguide/dialog/DialogContent'
import DialogContentText from '../../styleguide/dialog/DialogContentText'
import theme from '../../styleguide/theme'

const CarouselContainer = styled.div`
	padding: ${theme.spacing(2)};
`

const CarouselStyled = styled(Carousel)`
	ul {
		list-style-type: none;
	}
	img {
		max-width: 100%;
	}
`

const VisualSimulationViewerDialog = () => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()
	const dialog = useTeloSelector(
		selectDialog,
	) as VisualSimulationViewerDialogType
	const shareMedia = useVisualSimulation(dialog.examId)
	const src = dialog.media.map(m => getAssetSrc(m.path, m.data))
	return (
		<>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{t('exam.visualSimulation')}
				</DialogContentText>
				<CarouselContainer>
					<CarouselStyled
						showThumbs={false}
						showStatus={false}
						useKeyboardArrows={true}
					>
						{src.map((s, index) => (
							<div key={s}>
								<img src={`${s}`} alt={`vs-${index}`} key={`vs-${index}`} />
							</div>
						))}
					</CarouselStyled>
				</CarouselContainer>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						shareMedia(false)
						dispatch(dialogActions.closeDialog())
					}}
					variant="outlined"
				>
					{t('app.cancel')}
				</Button>
			</DialogActions>
		</>
	)
}

export default VisualSimulationViewerDialog
