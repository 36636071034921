import i18next from 'i18next'
import { io, Socket } from 'socket.io-client'

import appConfig from '../../../config'
import { Store } from '../../../model/store'
import { StandardNotification } from '../../../model/model'
import { AddNotificationPayload } from '../../notifications/types'
import {
	connectTeloSocket,
	disconnectTeloSocket,
	getTeloSocket,
} from '../teloSocketRegistry'
import { AddNotificationFn, TeloSocket } from '../teloSocketTypes'
import { basicDisconnectSocket, isSocketConnected } from '../teloSocketUtils'
import {
	socketCommonOptions,
	socketReconnectionFailedNotification,
} from '../../../libs/socket'

const DOCTOR_STARTED = 'doctor_started'
const NEW_EXAM_REQUEST = 'new_exam_request'

const BASE_NOTIFICATION_DATA: Omit<
	AddNotificationPayload<StandardNotification>,
	'message'
> = {
	autoClose: true,
	playSound: true,
	colored: true,
	type: 'info',
}

type NotificationsSender = {
	sendNewExamNotification: (usename: string, isRemote: boolean) => void
	sendDoctorStartedNotification: (username: string) => void
}

type NotificationsSocket = TeloSocket & NotificationsSender

type NotificationsSocketArgs = {
	sessionUsername: string | null
	currentStore: Store | null
	isLocalTechnician: boolean
	isDoctor: boolean
	addNotification: AddNotificationFn
}

const newSocket = (args: NotificationsSocketArgs): NotificationsSocket => {
	let socket: Socket | null = null
	const connect = (): void => {
		if (isSocketConnected(socket)) {
			return
		}
		socket = io(appConfig.socketUrl, {
			...socketCommonOptions,
			query: { roomName: getKey() },
		})

		socket.io.on('reconnect_failed', () => {
			args.addNotification(socketReconnectionFailedNotification)
		})

		socket.on(DOCTOR_STARTED, (data: DoctorStartedMessageData) => {
			onDoctorStartedMessage({ ...args, data })
		})

		socket.on(NEW_EXAM_REQUEST, (data: NewExamRequestMessageData) => {
			onNewExamRequestMessage({ ...args, data })
		})
	}

	const disconnect = (): void => {
		basicDisconnectSocket(socket)
		socket = null
	}

	const sendNewExamNotification = (username: string, isRemote: boolean) => {
		if (!isSocketConnected(socket)) {
			return
		}
		socket!.emit(NEW_EXAM_REQUEST, {
			content: i18next.t('exam.notifications.newExamRequest'),
			username,
			isRemote,
			store: args.currentStore,
		})
	}

	const sendDoctorStartedNotification = (username: string) => {
		socket!.emit(DOCTOR_STARTED, {
			content: i18next.t('exam.notifications.doctorStarted'),
			username,
			store: args.currentStore,
		})
	}

	return {
		connect,
		disconnect,
		sendNewExamNotification,
		sendDoctorStartedNotification,
	}
}

type DoctorStartedMessageData = {
	content: string
	username: string
	store: Store
}

type DoctorStartedMessageArgs = NotificationsSocketArgs & {
	data: DoctorStartedMessageData
}

const onDoctorStartedMessage = ({
	data: { content, store, username: messageUsername },
	sessionUsername,
	currentStore,
	isLocalTechnician,
	addNotification,
}: DoctorStartedMessageArgs): void => {
	const sentByCurrentUser = messageUsername === sessionUsername
	const isSameStore = !!store && store._id === currentStore?._id

	if (!sentByCurrentUser && isLocalTechnician && isSameStore) {
		addNotification({
			...BASE_NOTIFICATION_DATA,
			message: content,
		})
	}
}

type NewExamRequestMessageData = {
	content: string
	username: string
	isRemote: boolean
	store: Store
}

type NewExamRequestMessageArgs = NotificationsSocketArgs & {
	data: NewExamRequestMessageData
}

const onNewExamRequestMessage = ({
	data: { content, isRemote, store, username },
	sessionUsername,
	currentStore,
	addNotification,
	isDoctor,
}: NewExamRequestMessageArgs): void => {
	const sentByCurrentUser = username === sessionUsername
	const isSameStore = store && store._id === currentStore?._id

	if (!sentByCurrentUser && isDoctor && !isRemote && isSameStore) {
		addNotification({
			...BASE_NOTIFICATION_DATA,
			message: content,
		})
	}
}

const SOCKET_KEY = `notifications`
const getKey = () => SOCKET_KEY

export function connectToSocketNotifications(
	args: NotificationsSocketArgs,
): void {
	connectTeloSocket({
		socketKey: SOCKET_KEY,
		newSocket: () => newSocket(args),
	})
}

export function disconnectFromSocketNotifications(): void {
	disconnectTeloSocket(SOCKET_KEY)
}

export function getNotificationsSender(): NotificationsSender | null {
	return getTeloSocket<NotificationsSocket>(SOCKET_KEY)
}
