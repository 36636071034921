import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { VAScaleConversion } from '../../model/vaScales'
import { RefractionClassicExamState } from '../../model/model'

const initialState: RefractionClassicExamState = {
	rerender: 0,
}

export const slice = createSlice({
	name: 'refractionClassicExam',
	initialState,
	reducers: {
		forceRerender: state => {
			state.rerender = state.rerender ? 0 : 1
		},
	},
})

export default slice.reducer
