import React from 'react'
import { useTranslation } from 'react-i18next'
import dialogActions from '../features/dialog/actions'
import { selectDialog } from '../features/dialog/selectors'
import { ChangeRoomDialogType } from '../model/dialog'
import { useTeloDispatch, useTeloSelector } from '../store'
import Button from '../styleguide/buttons/Button'
import DialogActions from '../styleguide/dialog/DialogActions'
import DialogContent from '../styleguide/dialog/DialogContent'
import StageAndRoomSelection from './StageAndRoomSelection'
import DialogTitle from '../styleguide/dialog/DialogTitle'
import FirstLetterCapital from '../styleguide/typography/FirstLetterCapital'
import IconButton from '../styleguide/buttons/IconButton'
import CloseIcon from '../styleguide/icons/CloseIcon'

const isChangeRoomDialog = (dialog: any): dialog is ChangeRoomDialogType =>
	typeof dialog === 'object'

const ChangeRoomDialog = () => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()
	const dialog = useTeloSelector(selectDialog)
	return !isChangeRoomDialog(dialog) ? null : (
		<>
			<DialogTitle onClose={() => dispatch(dialogActions.closeDialog())}>
				<FirstLetterCapital>{t('doctor.pleaseSelectRoom')}</FirstLetterCapital>
			</DialogTitle>
			<DialogContent>
				<StageAndRoomSelection examId={dialog.examId} />
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={() => dispatch(dialogActions.closeDialog())}
				>
					{t('app.close')}
				</Button>
			</DialogActions>
		</>
	)
}

export default ChangeRoomDialog
