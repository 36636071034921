import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from './common'
import { Race } from '../model/race'

export const racesApi = createApi({
	reducerPath: 'racesApi',
	tagTypes: ['Races'],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getRaces: builder.query<Race[], void>({
			query: () => ({
				url: 'v1/races',
			}),
			transformResponse: (response: Race[]) => response,
			providesTags: ['Races'],
		}),
	}),
})

export const { useGetRacesQuery } = racesApi
