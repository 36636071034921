import React from 'react'

import { SvgProps } from '../../model/types'

const ChevronDownIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			xmlns="http://www.w3.org/2000/svg"
			fill="currentColor"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.5898 13.0898C10.2643 13.4152 9.73667 13.4152 9.41126 13.0898L4.69721 8.37567C4.37176 8.05027 4.37176 7.52264 4.69721 7.1972C5.02264 6.87177 5.55028 6.87177 5.87571 7.1972L10.0005 11.322L14.1253 7.1972C14.4508 6.87177 14.9783 6.87177 15.3038 7.1972C15.6293 7.52264 15.6293 8.05027 15.3038 8.37567L10.5898 13.0898Z"
			/>
		</svg>
	)
}

export default ChevronDownIcon
