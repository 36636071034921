import { CDS_RULE } from '../../model/cds'
import { SeverityKeys } from '../../model/patient'

export const NOTISTAK_MED_KEY = 'patientMediumAlert'
export const NOTISTAK_LO_KEY = 'patientLowAlert'
export const FIELD_MAX_LENGTH = 255

export const PATIENT_ALERT_PAGE = 'PatientAlert'

export const SEVERITY: Record<string, SeverityKeys> = {
	LOW: 'LOW',
	MEDIUM: 'MEDIUM',
	HIGH: 'HIGH',
}

export const CDS_RULES: Record<string, CDS_RULE> = {
	HasDepression: 'rule_1',
	CheckPenicillinMedication: 'rule_2',
	CheckHasWarfarainAndAspirin: 'rule_3',
	CheckMammogramDue: 'rule_4',
	CheckFluVaccineHighRiskPatients: 'rule_5',
	CheckBMIHigh: 'rule_6',
	CheckHemographicA1cHigh: 'rule_7',
}

export const ACTUAL_MONTH = ['1', '2', '9', '10', '11', '12']

export const DEPRESSION_CODES = [
	'F32',
	'F32.0',
	'F32.1',
	'F32.2',
	'F32.3',
	'F32.4',
	'F32.5',
	'F32.8',
	'F32.81',
	'F32.89',
	'F32.9',
	'F32.A',
	'F33',
	'F33.0',
	'F33.1',
	'F33.2',
	'F33.3',
	'F33.4',
	'F33.40',
	'F33.41',
	'F33.42',
	'F33.8',
	'F33.9',
	'F43.21',
	'F34.1',
]

export const PENICILLIN_ALLERGY_CODES = [
	'10026',
	'10027',
	'10029',
	'11845',
	'245',
	'Z10026',
]

export const INFLUENZA_ALLERGY_CODES = [
	'14501',
	'15910',
	'15941',
	'16050',
	'16051',
	'182',
	'8815',
	'8819',
]

export const PENICILLIN_MEDICATION_CODES = [
	'207369',
	'2103018',
	'207370',
	'836307',
	'1438129',
	'1438136',
	'1050111',
	'731568',
	'731571',
	'731566',
	'731566',
	'995906',
	'1484873',
	'731541',
	'1549551',
	'836307',
	'1438129',
	'1438136',
	'1050111',
	'1050115',
	'1484873',
	'731541',
	'1549551',
	'897052',
	'1360637',
	'1360635',
	'1359005',
	'1359009',
	'1358974',
	'824186',
	'824190',
	'824194',
	'861689',
	'791949',
	'205730',
	'791942',
	'791944',
	'828246',
	'1291987',
	'757969',
	'94406',
	'2604802',
	'2604804',
	'617432',
	'617333',
	'617339',
	'618028',
	'2566445',
	'2566438',
	'2566441',
	'2566443',
	'2583730',
	'2108835',
	'2108828',
	'2108831',
	'2108833',
	'897048',
	'2105800',
	'2105791',
	'2105794',
	'2105797',
	'2262032',
	'2598556',
	'2598552',
	'2598546',
	'2598548',
	'2598550',
	'897049',
	'1358969',
	'151392',
	'2566433',
	'215687',
	'2108823',
	'897045',
	'828243',
	'2262027',
	'220466',
	'2598541',
	'1659134',
	'1659139',
	'1659151',
	'211974',
	'211979',
	'2110503',
	'2110505',
	'892782',
	'892784',
	'1726207',
	'1726216',
	'2184147',
	'1044196',
	'1659595',
	'1659600',
	'205843',
	'1659134',
	'1659139',
	'1659151',
]

export const ASPIRIN_MEDICATION_CODES = [
	'1001473',
	'1039496',
	'1052413',
	'1053324',
	'17315',
	'18385',
	'202546',
	'202554',
	'215256',
	'215431',
	'215568',
	'215770',
	'217020',
	'217127',
	'217481',
	'218783',
	'219010',
	'219779',
	'219980',
	'220011',
	'220112',
	'220143',
	'220751',
	'226718',
	'24292',
	'25027',
	'702317',
	'707765',
	'721973',
	'724441',
	'747233',
	'796658',
	'847088',
	'848763',
	'848769',
	'896890',
	'1358848',
	'1359078',
	'1361397',
	'1372693',
	'1438292',
	'1551555',
	'1593111',
	'1600986',
	'1665357',
	'1722690',
	'1811911',
	'1193079',
	'1247393',
	'1247398',
	'1303154',
	'1312950',
	'904668',
	'904664',
	'1730187',
	'1191',
	'1006995',
	'1007005',
	'1007112',
	'1007240',
	'1007337',
	'1007353',
	'1007407',
	'1007424',
	'1007472',
	'1007613',
	'1008006',
	'1008080',
	'1008684',
	'1008791',
	'1008832',
	'1008877',
	'1008988',
	'1009038',
	'1053102',
	'1053120',
	'1111420',
	'135095',
	'214160',
	'214249',
	'214250',
	'214251',
	'214253',
	'214254',
	'214255',
	'214256',
	'214257',
	'214258',
	'214259',
	'215451',
	'226716',
	'392499',
	'466424',
	'466549',
	'466584',
	'669119',
	'687078',
	'689502',
	'689507',
	'689509',
	'689511',
	'689512',
	'689513',
	'689515',
	'689516',
	'689518',
	'689519',
	'689522',
	'689524',
	'689529',
	'689540',
	'689541',
	'689551',
	'689552',
	'689553',
	'689554',
	'689555',
	'689556',
	'690996',
	'690997',
	'812525',
	'813961',
	'814664',
	'815052',
	'816174',
	'816812',
	'816818',
	'817275',
	'817405',
	'817455',
	'817778',
	'817958',
	'818496',
	'819659',
	'819817',
	'820701',
	'1534422',
	'1593109',
	'1811630',
	'314293',
	'1001476',
	'1039499',
	'1052416',
	'1053327',
	'1872088',
	'212086',
	'830525',
	'830530',
	'853501',
	'1536680',
	'1536835',
	'1189776',
	'1189781',
	'1925790',
	'211292',
	'211874',
	'211887',
	'211902',
	'260847',
	'387090',
	'608696',
	'794229',
	'825180',
	'825181',
	'848166',
	'979118',
	'1592700',
	'1792832',
	'1293665',
	'209468',
	'211835',
	'994277',
	'211881',
	'211891',
	'211297',
	'210864',
	'211832',
	'213290',
	'749795',
	'994228',
	'1234976',
	'1101754',
	'209823',
	'211310',
	'1362082',
	'702320',
	'723530',
	'723533',
	'724444',
	'747236',
	'797053',
	'1052678',
	'848772',
	'848928',
	'896876',
	'896893',
	'1358853',
	'1359083',
	'1361402',
	'994239',
	'1438297',
	'1551559',
	'1593116',
	'1600991',
	'1665362',
	'1722695',
	'1811916',
	'1811918',
	'1250909',
	'1247397',
	'1247402',
	'1537012',
	'1303159',
	'1001474',
	'1039497',
	'1052414',
	'1053325',
	'572393',
	'830523',
	'830529',
	'853500',
	'1536678',
	'1536834',
	'1189775',
	'1189780',
	'1925789',
	'571734',
	'572203',
	'572216',
	'572230',
	'574308',
	'608695',
	'794228',
	'848165',
	'979117',
	'1293661',
	'570079',
	'572166',
	'994276',
	'572210',
	'572220',
	'571739',
	'571342',
	'572163',
	'573200',
	'994227',
	'1234973',
	'1101753',
	'570399',
	'571752',
	'574548',
	'702318',
	'723528',
	'723531',
	'724442',
	'747234',
	'797051',
	'847089',
	'848770',
	'848927',
	'896874',
	'896891',
	'1358849',
	'1359079',
	'1361398',
	'994238',
	'1438293',
	'1551556',
	'1593112',
	'1600987',
	'1665358',
	'1722691',
	'1811912',
	'1811917',
	'1250908',
	'1247394',
	'1247399',
	'1537010',
	'1303155',
	'1001475',
	'1039498',
	'1052415',
	'1053326',
	'1872086',
	'1052677',
	'367149',
	'367758',
	'368457',
	'368653',
	'366384',
	'368468',
	'367752',
	'367856',
	'368281',
	'366382',
	'366390',
	'369814',
	'393300',
	'369063',
	'369817',
	'369693',
	'368986',
	'702319',
	'700585',
	'830524',
	'825179',
	'749794',
	'830544',
	'723529',
	'723532',
	'724443',
	'747235',
	'797052',
	'848765',
	'848771',
	'896875',
	'896892',
	'1536679',
	'94125',
	'1358850',
	'1359080',
	'1361399',
	'1438294',
	'1537011',
	'1592699',
	'1551557',
	'1593113',
	'1600988',
	'1665359',
	'1722692',
	'1293662',
	'1856540',
	'1811913',
	'1193081',
	'1247395',
	'1247400',
	'1303156',
	'1872085',
	'103863',
	'103954',
	'104474',
	'104475',
	'104899',
	'106809',
	'1092398',
	'197374',
	'198464',
	'198471',
	'198473',
	'198475',
	'198479',
	'198480',
	'199274',
	'199281',
	'243670',
	'247137',
	'308403',
	'308409',
	'308411',
	'308412',
	'308416',
	'308417',
	'308418',
	'318272',
	'333834',
	'349516',
	'391930',
	'433353',
	'605252',
	'692836',
	'702316',
	'747211',
	'763111',
	'797050',
	'806446',
	'857121',
	'876666',
	'876667',
	'900528',
	'996988',
	'1535484',
	'1536503',
	'1536815',
	'1536833',
	'1536993',
	'1537019',
	'1537200',
	'1722689',
	'1811631',
	'1234872',
	'1250907',
	'1312718',
	'1049691',
	'1050241',
	'197447',
	'197930',
	'197945',
	'198461',
	'198463',
	'198466',
	'198467',
	'198477',
	'212033',
	'238134',
	'238135',
	'308363',
	'359221',
	'637540',
	'724614',
	'848768',
	'994226',
	'994237',
	'996991',
	'996994',
	'1433630',
	'1536467',
	'1536498',
	'1536670',
	'1536675',
	'1536840',
	'1537029',
	'1811632',
	'1291868',
	'1312713',
	'243685',
	'246461',
	'259081',
	'853499',
	'763116',
	'205251',
	'308278',
	'308297',
	'432638',
	'827318',
	'828585',
	'828594',
	'896884',
	'994528',
	'994535',
	'1593110',
	'243694',
	'994810',
	'994811',
	'685589',
	'688214',
	'863184',
	'863186',
	'891134',
	'891136',
	'892160',
	'900470',
	'994430',
	'994435',
	'1665356',
	'1673788',
	'315413',
	'315414',
	'315418',
	'315420',
	'315422',
	'315424',
	'315425',
	'315426',
	'315428',
	'315429',
	'315430',
	'315431',
	'315432',
	'315433',
	'317297',
	'317298',
	'317299',
	'317300',
	'329292',
	'329294',
	'329295',
	'329547',
	'332336',
	'335933',
	'335953',
	'335990',
	'335993',
	'336010',
	'336012',
	'336028',
	'336430',
	'350459',
	'350460',
	'647977',
	'685588',
	'688213',
	'863185',
	'891133',
	'891135',
	'892159',
	'900469',
	'994429',
	'994434',
	'1665355',
	'1673786',
	'1872084',
	'238137',
	'238142',
	'370609',
	'370611',
	'370612',
	'370614',
	'370615',
	'370643',
	'370939',
	'370940',
	'370942',
	'370943',
	'370944',
	'370945',
	'372778',
	'372833',
	'373427',
	'374442',
	'374702',
	'374711',
	'374753',
	'374755',
	'374756',
	'374757',
	'374758',
	'374760',
	'374765',
	'375972',
	'375995',
	'375998',
	'376394',
	'379282',
	'379357',
	'379422',
	'379497',
	'393142',
	'692835',
	'763110',
	'863183',
	'900527',
	'1536466',
	'1536497',
	'1536502',
	'1536669',
	'1536814',
	'1536839',
	'1537028',
	'1537199',
	'1593108',
	'1722688',
	'1811629',
	'1856538',
	'1872083',
	'1153231',
	'1153232',
	'1153233',
	'1153234',
	'1153517',
	'1153518',
	'1153519',
	'1153520',
	'1153522',
	'1153523',
	'1153525',
	'1153526',
	'1153529',
	'1153530',
	'1153531',
	'1153532',
	'1153535',
	'1153540',
	'1153542',
	'1153551',
	'1153552',
	'1153719',
	'1153723',
	'1153725',
	'1153729',
	'1153730',
	'1153731',
	'1153732',
	'1153733',
	'1153734',
	'1153735',
	'1153736',
	'1153737',
	'1153738',
	'1153741',
	'1153742',
	'1153743',
	'1153744',
	'1153749',
	'1153750',
	'1153987',
	'1153988',
	'1154056',
	'1154057',
	'1154060',
	'1154061',
	'1154066',
	'1154067',
	'1154068',
	'1154069',
	'1154070',
	'1154071',
	'1154257',
	'1154258',
	'1154265',
	'1154266',
	'1593106',
	'1593107',
	'1722686',
	'1722687',
	'1811627',
	'1811628',
	'1294760',
	'1294849',
	'1294922',
	'1294937',
	'1295251',
	'1295296',
	'1295715',
	'1295740',
	'1872087',
	'1358851',
	'1358852',
	'1551558',
	'1166124',
	'1166125',
	'1168235',
	'1168236',
	'1168634',
	'1168635',
	'1172780',
	'1172781',
	'1174230',
	'1174233',
	'1175547',
	'1176591',
	'1176592',
	'1177642',
	'1177643',
	'1178412',
	'1178414',
	'1184781',
	'1184782',
	'1184849',
	'1185412',
	'1185791',
	'1185792',
	'1186049',
	'1186050',
	'1193082',
	'1193083',
	'1247396',
	'1247401',
	'1312954',
	'1600989',
	'1600990',
	'1665360',
	'1665361',
	'1168326',
	'1168327',
	'1168328',
	'1168329',
	'1168628',
	'1168631',
	'1168674',
	'1168675',
	'1171456',
	'1171457',
	'1172135',
	'1172136',
	'1172157',
	'1172517',
	'1172518',
	'1172519',
	'1172520',
	'1174228',
	'1174229',
	'1174692',
	'1174693',
	'1176688',
	'1176689',
	'1177581',
	'1177582',
	'1177786',
	'1177787',
	'1178700',
	'1178701',
	'1179769',
	'1179770',
	'1293663',
	'1293664',
	'1359081',
	'1359082',
	'1361400',
	'1361401',
	'1438295',
	'1438296',
	'1172555',
	'1172556',
	'1172559',
	'1172562',
	'1174226',
	'1174227',
	'1179446',
	'1179447',
	'1184309',
	'1184310',
	'1187555',
	'1187556',
	'1303157',
	'1303158',
	'1593114',
	'1593115',
	'1722693',
	'1722694',
	'1811914',
	'1811915',
	'1295785',
	'1295836',
	'1295926',
	'1295996',
	'1296328',
	'1296554',
	'1296562',
	'1297128',
	'1296052',
]

export const WARFARAIN_MEDICATION_CODES = [
	'202421',
	'405155',
	'11289',
	'114194',
	'82118',
	'855290',
	'855298',
	'855304',
	'855314',
	'855320',
	'855326',
	'855334',
	'855340',
	'855346',
	'855292',
	'855300',
	'855306',
	'855316',
	'855322',
	'855328',
	'855336',
	'855342',
	'855348',
	'855289',
	'855297',
	'855303',
	'855313',
	'855319',
	'855325',
	'855333',
	'855339',
	'855345',
	'855291',
	'855299',
	'855305',
	'855315',
	'855321',
	'855327',
	'855335',
	'855341',
	'855347',
	'368417',
	'406078',
	'855288',
	'855296',
	'855302',
	'855308',
	'855312',
	'855318',
	'855324',
	'855332',
	'855338',
	'855344',
	'855350',
	'855287',
	'855295',
	'855301',
	'855307',
	'855311',
	'855317',
	'855323',
	'855331',
	'855337',
	'855343',
	'855349',
	'374319',
	'374320',
	'1161789',
	'1161790',
	'1161791',
	'1167808',
	'1167809',
	'1171655',
	'1171656',
]

export const MAMMOGRAM_CODES = [
	'76083',
	'76082',
	'77052',
	'77051',
	'77056',
	'77057',
	'77055',
]

export const DIABETES_CODES = [
	'E10.10',
	'E10.11',
	'E10.21',
	'E10.22',
	'E10.29',
	'E10.311',
	'E10.319',
	'E10.3211',
	'E10.3212',
	'E10.3213',
	'E10.3219',
	'E10.3291',
	'E10.3292',
	'E10.3293',
	'E10.3299',
	'E10.3311',
	'E10.3312',
	'E10.3313',
	'E10.3319',
	'E10.3391',
	'E10.3392',
	'E10.3393',
	'E10.3399',
	'E10.3411',
	'E10.3412',
	'E10.3413',
	'E10.3419',
	'E10.3491',
	'E10.3492',
	'E10.3493',
	'E10.3499',
	'E10.3511',
	'E10.3512',
	'E10.3513',
	'E10.3519',
	'E10.3521',
	'E10.3522',
	'E10.3523',
	'E10.3529',
	'E10.3531',
	'E10.3532',
	'E10.3533',
	'E10.3539',
	'E10.3541',
	'E10.3542',
	'E10.3543',
	'E10.3549',
	'E10.3551',
	'E10.3552',
	'E10.3553',
	'E10.3559',
	'E10.3591',
	'E10.3592',
	'E10.3593',
	'E10.3599',
	'E10.36',
	'E10.37X1',
	'E10.37X2',
	'E10.37X3',
	'E10.37X9',
	'E10.39',
	'E10.40',
	'E10.41',
	'E10.42',
	'E10.43',
	'E10.44',
	'E10.49',
	'E10.51',
	'E10.52',
	'E10.59',
	'E10.610',
	'E10.618',
	'E10.620',
	'E10.621',
	'E10.622',
	'E10.628',
	'E10.630',
	'E10.638',
	'E10.641',
	'E10.649',
	'E10.65',
	'E10.69',
	'E10.8',
	'E10.9',
	'E11.00',
	'E11.01',
	'E11.10',
	'E11.11',
	'E11.21',
	'E11.22',
	'E11.29',
	'E11.311',
	'E11.319',
	'E11.3211',
	'E11.3212',
	'E11.3213',
	'E11.3219',
	'E11.3291',
	'E11.3292',
	'E11.3293',
	'E11.3299',
	'E11.3311',
	'E11.3312',
	'E11.3313',
	'E11.3319',
	'E11.3391',
	'E11.3392',
	'E11.3393',
	'E11.3399',
	'E11.3411',
	'E11.3412',
	'E11.3413',
	'E11.3419',
	'E11.3491',
	'E11.3492',
	'E11.3493',
	'E11.3499',
	'E11.3511',
	'E11.3512',
	'E11.3513',
	'E11.3519',
	'E11.3521',
	'E11.3522',
	'E11.3523',
	'E11.3529',
	'E11.3531',
	'E11.3532',
	'E11.3533',
	'E11.3539',
	'E11.3541',
	'E11.3542',
	'E11.3543',
	'E11.3549',
	'E11.3551',
	'E11.3552',
	'E11.3553',
	'E11.3559',
	'E11.3591',
	'E11.3592',
	'E11.3593',
	'E11.3599',
	'E11.36',
	'E11.37X1',
	'E11.37X2',
	'E11.37X3',
	'E11.37X9',
	'E11.39',
	'E11.40',
	'E11.41',
	'E11.42',
	'E11.43',
	'E11.44',
	'E11.49',
	'E11.51',
	'E11.52',
	'E11.59',
	'E11.610',
	'E11.618',
	'E11.620',
	'E11.621',
	'E11.622',
	'E11.628',
	'E11.630',
	'E11.638',
	'E11.641',
	'E11.649',
	'E11.65',
	'E11.69',
	'E11.8',
	'E11.9',
	'E13.00',
	'E13.01',
	'E13.10',
	'E13.11',
	'E13.21',
	'E13.22',
	'E13.29',
	'E13.311',
	'E13.319',
	'E13.3211',
	'E13.3212',
	'E13.3213',
	'E13.3219',
	'E13.3291',
	'E13.3292',
	'E13.3293',
	'E13.3299',
	'E13.3311',
	'E13.3312',
	'E13.3313',
	'E13.3319',
	'E13.3391',
	'E13.3392',
	'E13.3393',
	'E13.3399',
	'E13.3411',
	'E13.3412',
	'E13.3413',
	'E13.3419',
	'E13.3491',
	'E13.3492',
	'E13.3493',
	'E13.3499',
	'E13.3511',
	'E13.3512',
	'E13.3513',
	'E13.3519',
	'E13.3521',
	'E13.3522',
	'E13.3523',
	'E13.3529',
	'E13.3531',
	'E13.3532',
	'E13.3533',
	'E13.3539',
	'E13.3541',
	'E13.3542',
	'E13.3543',
	'E13.3549',
	'E13.3551',
	'E13.3552',
	'E13.3553',
	'E13.3559',
	'E13.3591',
	'E13.3592',
	'E13.3593',
	'E13.3599',
	'E13.36',
	'E13.37X1',
	'E13.37X2',
	'E13.37X3',
	'E13.37X9',
	'E13.39',
	'E13.40',
	'E13.41',
	'E13.42',
	'E13.43',
	'E13.44',
	'E13.49',
	'E13.51',
	'E13.52',
	'E13.59',
	'E13.610',
	'E13.618',
	'E13.620',
	'E13.621',
	'E13.622',
	'E13.628',
	'E13.630',
	'E13.638',
	'E13.641',
	'E13.649',
	'E13.65',
	'E13.69',
	'E13.8',
	'E13.9',
	'O24.011',
	'O24.012',
	'O24.013',
	'O24.019',
	'O24.02',
	'O24.03',
	'O24.111',
	'O24.112',
	'O24.113',
	'O24.119',
	'O24.12',
	'O24.13',
	'O24.311',
	'O24.312',
	'O24.313',
	'O24.319',
	'O24.32',
	'O24.33',
	'O24.811',
	'O24.812',
	'O24.813',
	'O24.819',
	'O24.82',
	'O24.8',
]

export const ASTHMA_CODES = [
	'J45',
	'J45.2',
	'J45.20',
	'J45.21',
	'J45.22',
	'J45.3',
	'J45.30',
	'J45.31',
	'J45.32',
	'J45.4',
	'J45.40',
	'J45.41',
	'J45.42',
	'J45.5',
	'J45.50',
	'J45.51',
	'J45.52',
	'J45.9',
	'J45.90',
	'J45.901',
	'J45.902',
	'J45.909',
	'J45.99',
	'J45.990',
	'J45.991',
	'J45.998',
]

export const IMMUNIZATION_DIABETES_CODES = [
	'E08.00',
	'E08.01',
	'E08.10',
	'E08.11',
	'E08.21',
	'E08.22',
	'E08.29',
	'E08.311',
	'E08.319',
	'E08.3211',
	'E08.3212',
	'E08.3213',
	'E08.3219',
	'E08.3291',
	'E08.3292',
	'E08.3293',
	'E08.3299',
	'E08.3311',
	'E08.3312',
	'E08.3313',
	'E08.3319',
	'E08.3391',
	'E08.3392',
	'E08.3393',
	'E08.3399',
	'E08.3411',
	'E08.3412',
	'E08.3413',
	'E08.3419',
	'E08.3491',
]

export const HIV_CODES = ['B20']

export const HODGKIN_CODES = [
	'C81',
	'C81.0',
	'C81.00',
	'C81.01',
	'C81.02',
	'C81.03',
	'C81.04',
	'C81.05',
	'C81.06',
	'C81.07',
	'C81.08',
	'C81.09',
	'C81.1',
	'C81.10',
	'C81.11',
	'C81.12',
	'C81.13',
	'C81.14',
	'C81.15',
	'C81.16',
	'C81.17',
	'C81.18',
	'C81.19',
	'C81.2',
	'C81.20',
	'C81.21',
	'C81.22',
	'C81.23',
	'C81.24',
	'C81.25',
	'C81.26',
	'C81.27',
	'C81.28',
	'C81.29',
	'C81.3',
	'C81.30',
	'C81.31',
	'C81.32',
	'C81.33',
	'C81.34',
	'C81.35',
	'C81.36',
	'C81.37',
	'C81.38',
	'C81.39',
	'C81.4',
	'C81.40',
	'C81.41',
	'C81.42',
	'C81.43',
	'C81.44',
	'C81.45',
	'C81.46',
	'C81.47',
	'C81.48',
	'C81.49',
	'C81.7',
	'C81.70',
	'C81.71',
	'C81.72',
	'C81.73',
	'C81.74',
	'C81.75',
	'C81.76',
	'C81.77',
	'C81.78',
	'C81.79',
	'C81.9',
	'C81.90',
	'C81.91',
	'C81.92',
	'C81.93',
	'C81.94',
	'C81.95',
	'C81.96',
	'C81.97',
	'C81.98',
	'C81.99',
]

export const MYELOMA_CODES = [
	'C90',
	'C90.0',
	'C90.00',
	'C90.01',
	'C90.02',
	'C90.1',
	'C90.10',
	'C90.11',
	'C90.12',
	'C90.2',
	'C90.20',
	'C90.21',
	'C90.22',
	'C90.3',
	'C90.30',
	'C90.31',
	'C90.32',
]

export const LYMPHOMA_CODES = [
	'C84.4',
	'C84.40',
	'C84.41',
	'C84.42',
	'C84.43',
	'C84.44',
	'C84.45',
	'C84.46',
	'C84.47',
	'C84.48',
	'C84.49',
	'C84.6',
	'C84.60',
	'C84.61',
	'C84.62',
	'C84.63',
	'C84.64',
	'C84.65',
	'C84.66',
	'C84.67',
	'C84.68',
	'C84.69',
	'C84.7',
	'C84.70',
	'C84.71',
	'C84.72',
	'C84.73',
	'C84.74',
	'C84.75',
	'C84.76',
	'C84.77',
	'C84.78',
	'C84.79',
	'C84.7A',
	'C84.9',
	'C84.90',
	'C84.91',
	'C84.92',
	'C84.93',
	'C84.94',
	'C84.95',
	'C84.96',
	'C84.97',
	'C84.98',
	'C84.99',
	'C84.Z',
	'C84.Z0',
	'C84.Z1',
	'C84.Z2',
	'C84.Z3',
	'C84.Z4',
	'C84.Z5',
	'C84.Z6',
	'C84.Z7',
	'C84.Z8',
	'C84.Z9',
	'C83.0',
	'C83.00',
	'C83.01',
	'C83.02',
	'C83.03',
	'C83.04',
	'C83.05',
	'C83.06',
	'C83.07',
	'C83.08',
	'C83.09',
	'C83.1',
	'C83.10',
	'C83.11',
	'C83.12',
	'C83.13',
	'C83.14',
	'C83.15',
	'C83.16',
	'C83.17',
	'C83.18',
	'C83.19',
	'C83.3',
	'C83.30',
	'C83.31',
	'C83.32',
	'C83.33',
	'C83.34',
	'C83.35',
	'C83.36',
	'C83.37',
	'C83.38',
	'C83.39',
	'C83.5',
	'C83.50',
	'C83.51',
	'C83.52',
	'C83.53',
	'C83.54',
	'C83.55',
	'C83.56',
	'C83.57',
	'C83.58',
	'C83.59',
	'C83.7',
	'C83.70',
	'C83.71',
	'C83.72',
	'C83.73',
	'C83.74',
	'C83.75',
	'C83.76',
	'C83.77',
	'C83.78',
	'C83.79',
	'C83.8',
	'C83.80',
	'C83.81',
	'C83.82',
	'C83.83',
	'C83.84',
	'C83.85',
	'C83.86',
	'C83.87',
	'C83.88',
	'C83.89',
	'C83.9',
	'C83.90',
	'C83.91',
	'C83.92',
	'C83.93',
	'C83.94',
	'C83.95',
	'C83.96',
	'C83.97',
	'C83.98',
	'C83.99',
	'C84.A',
	'C84.A0',
	'C84.A1',
	'C84.A2',
	'C84.A3',
	'C84.A4',
	'C84.A5',
	'C84.A6',
	'C84.A7',
	'C84.A8',
	'C84.A9',
	'C82.0',
	'C82.00',
	'C82.01',
	'C82.02',
	'C82.03',
	'C82.04',
	'C82.05',
	'C82.06',
	'C82.07',
	'C82.08',
	'C82.09',
	'C82.1',
	'C82.10',
	'C82.11',
	'C82.12',
	'C82.13',
	'C82.14',
	'C82.15',
	'C82.16',
	'C82.17',
	'C82.18',
	'C82.19',
	'C82.2',
	'C82.20',
	'C82.21',
	'C82.22',
	'C82.23',
	'C82.24',
	'C82.25',
	'C82.26',
	'C82.27',
	'C82.28',
	'C82.29',
	'C82.3',
	'C82.30',
	'C82.31',
	'C82.32',
	'C82.33',
	'C82.34',
	'C82.35',
	'C82.36',
	'C82.37',
	'C82.38',
	'C82.39',
	'C82.4',
	'C82.40',
	'C82.41',
	'C82.42',
	'C82.43',
	'C82.44',
	'C82.45',
	'C82.46',
	'C82.47',
	'C82.48',
	'C82.49',
	'C82.5',
	'C82.50',
	'C82.51',
	'C82.52',
	'C82.53',
	'C82.54',
	'C82.55',
	'C82.56',
	'C82.57',
	'C82.58',
	'C82.59',
	'C82.6',
	'C82.60',
	'C82.61',
	'C82.62',
	'C82.63',
	'C82.64',
	'C82.65',
	'C82.66',
	'C82.67',
	'C82.68',
	'C82.69',
	'C82.8',
	'C82.80',
	'C82.81',
	'C82.82',
	'C82.83',
	'C82.84',
	'C82.85',
	'C82.86',
	'C82.87',
	'C82.88',
	'C82.89',
	'C82.9',
	'C82.90',
	'C82.91',
	'C82.92',
	'C82.93',
	'C82.94',
	'C82.95',
	'C82.96',
	'C82.97',
	'C82.98',
	'C82.99',
	'C85.1',
	'C85.2',
	'C85.20',
	'C85.21',
	'C85.22',
	'C85.23',
	'C85.24',
	'C85.25',
	'C85.26',
	'C85.27',
	'C85.28',
	'C85.29',
	'C85.8',
	'C85.80',
	'C85.81',
	'C85.82',
	'C85.83',
	'C85.84',
	'C85.85',
	'C85.86',
	'C85.87',
	'C85.88',
	'C85.89',
	'C85.9',
	'C85.90',
	'C85.91',
	'C85.92',
	'C85.93',
	'C85.94',
	'C85.95',
	'C85.96',
	'C85.97',
	'C85.98',
	'C85.99',
	'C86.0',
	'C86.1',
	'C86.2',
	'C86.4',
	'C86.6',
	'C88.0',
	'C88.4',
	'C91.5',
	'C91.50',
	'C91.51',
	'C91.52',
	'C96.A',
].concat(HODGKIN_CODES)

export const LEUKEMIA_CODES = [
	'C90.1',
	'C90.10',
	'C90.11',
	'C90.12',
	'C91.0',
	'C91.00',
	'C91.01',
	'C91.02',
	'C91.1',
	'C91.10',
	'C91.11',
	'C91.12',
	'C91.3',
	'C91.30',
	'C91.31',
	'C91.32',
	'C91.4',
	'C91.40',
	'C91.41',
	'C91.42',
	'C91.5',
	'C91.50',
	'C91.51',
	'C91.52',
	'C91.6',
	'C91.60',
	'C91.61',
	'C91.62',
	'C91.9',
	'C91.90',
	'C91.91',
	'C91.92',
	'C91.A',
	'C91.A0',
	'C91.A1',
	'C91.A2',
	'C91.Z',
	'C91.Z0',
	'C91.Z1',
	'C91.Z2',
	'C92.0',
	'C92.00',
	'C92.01',
	'C92.02',
	'C92.1',
	'C92.10',
	'C92.11',
	'C92.12',
	'C92.2',
	'C92.20',
	'C92.21',
	'C92.22',
	'C92.4',
	'C92.40',
	'C92.41',
	'C92.42',
	'C92.5',
	'C92.50',
	'C92.51',
	'C92.52',
	'C92.6',
	'C92.60',
	'C92.61',
	'C92.62',
	'C92.9',
	'C92.90',
	'C92.91',
	'C92.92',
	'C92.A',
	'C92.A0',
	'C92.A1',
	'C92.A2',
	'C92.Z',
	'C92.Z0',
	'C92.Z1',
	'C92.Z2',
	'C93.0',
	'C93.00',
	'C93.01',
	'C93.02',
	'C93.1',
	'C93.10',
	'C93.11',
	'C93.12',
	'C93.3',
	'C93.30',
	'C93.31',
	'C93.32',
	'C93.9',
	'C93.90',
	'C93.91',
	'C93.92',
	'C93.Z',
	'C93.Z0',
	'C93.Z1',
	'C93.Z2',
	'C94.0',
	'C94.00',
	'C94.01',
	'C94.02',
	'C94.2',
	'C94.20',
	'C94.21',
	'C94.22',
	'C94.3',
	'C94.30',
	'C94.31',
	'C94.32',
	'C94.8',
	'C94.80',
	'C94.81',
	'C94.82',
	'C95.0',
	'C95.00',
	'C95.01',
	'C95.02',
	'C95.1',
	'C95.10',
	'C95.11',
	'C95.12',
	'C95.9',
	'C95.90',
	'C95.91',
	'C95.92',
	'D47.1',
]

export const ORGAN_TRANSPLANT_CODES = [
	'Z94.0',
	'Z94.1',
	'Z94.2',
	'Z94.3',
	'Z94.4',
	'Z94.5',
	'Z94.6',
	'Z94.7',
	'Z94.8',
	'Z94.9',
	'Z94.81',
	'Z94.82',
	'Z94.83',
	'Z94.84',
	'Z94.89',
]
