import React, { useCallback, useEffect, useMemo, useState } from 'react'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectVaScaleCatalog } from '../../features/vaScales/selectors'
import { visualAcuityAdditionalValues } from '../../libs/exams'
import { VAScale } from '../../model/vaScales'
import GenericAutocompleteControlled, {
	GenericAutocompleteControlledProps,
} from './system/generic.autocomplete.controlled'

type InheritedAutocompleteProps = Omit<
	GenericAutocompleteControlledProps<VisualAcuityAutocompletePropsOption>,
	'options' | 'isLoading' | 'query' | 'onChangeText'
>

export type VisualAcuityAutocompleteProps = InheritedAutocompleteProps & {
	selectedValue?: string
	isPh?: boolean
	isAdditionalValues?: boolean
	showErrorManually?: boolean
	scale: VAScale
}

export type VisualAcuityAutocompletePropsOption = {
	value: string
	label: string
	key: string
}

const getOptions = (options: string[], isAdditionalValues: boolean) => {
	if (isAdditionalValues) {
		return visualAcuityAdditionalValues.map(x => ({
			value: x.value,
			key: x.value,
			label: i18next.t(x.label),
		}))
	}

	return options.map(x => ({
		value: x,
		label: x,
		key: x,
	}))
}

const VisualAcuityAutocomplete = ({
	fieldName,
	codePropName,
	selectedValue = '',
	placeholder,
	additionalFieldsToUpdate,
	useDefaultEndAdornment,
	disabled,
	isAdditionalValues = false,
	getOptionLabel,
	onSelect,
	saveOnChange,
	className,
	showErrorManually,
	scale,
}: VisualAcuityAutocompleteProps) => {
	const { t } = useTranslation()
	const [query, setQuery] = useState<string>(selectedValue)
	const _options = useSelector(selectVaScaleCatalog(scale))

	const options: VisualAcuityAutocompletePropsOption[] = useMemo(
		() => getOptions(_options, isAdditionalValues),
		[_options, isAdditionalValues],
	)

	const handleChangeText = useCallback((newQuery: string) => {
		setQuery(newQuery)
	}, [])

	const optionsIncludesQuery = (value: string): boolean => {
		const optionValue = options.find(o =>
			o.value.toLowerCase().includes(value.toLowerCase()),
		)
		return query === '' || !!(query && optionValue)
	}

	useEffect(() => {
		setQuery('')
	}, [selectedValue, disabled])

	return (
		<GenericAutocompleteControlled<VisualAcuityAutocompletePropsOption>
			className={className}
			fieldName={fieldName}
			codePropName={codePropName}
			options={options}
			isLoading={false}
			placeholder={placeholder}
			additionalFieldsToUpdate={additionalFieldsToUpdate}
			useDefaultEndAdornment={useDefaultEndAdornment}
			disabled={disabled}
			typeAtLeastCharactersText={t('doctor.typeAtLeast1Char')}
			getOptionLabel={getOptionLabel}
			onSelect={onSelect}
			query={query}
			onChangeText={handleChangeText}
			saveOnChange={saveOnChange}
			showErrorManually={showErrorManually && !optionsIncludesQuery(query)}
			isVisualAcuityField={true}
		/>
	)
}

export default VisualAcuityAutocomplete
