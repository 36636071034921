import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { decodeRole } from '../../decoders/role'
import { UsersState } from '../../model/model'
import { ApiUser } from '../../model/users'
import { usersApi } from '../../services/users'

const initialState: UsersState = {}

const { getUsers } = usersApi.endpoints
export const slice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		_loadUser: (state, { payload }: PayloadAction<ApiUser>) => {
			state[payload.username] = {
				...payload,
				role: decodeRole(payload.role),
			}
		},
		_loadUsers: (_, { payload }: PayloadAction<ApiUser[]>) =>
			payload.reduce((result, user) => {
				result[user.username] = { ...user, role: decodeRole(user.role) }
				return result
			}, {} as UsersState),
		_removeUser: (state, { payload }: PayloadAction<string>) => {
			delete state[payload]
		},
	},
	extraReducers: builder => {
		builder.addMatcher(
			getUsers.matchFulfilled,
			(_, { payload }: PayloadAction<UsersState>) => payload,
		)
	},
})

export default slice.reducer
