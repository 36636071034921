import styled from 'styled-components'

import { Select as MSelect } from '@mui/material'

import ChevronDownIcon from './icons/ChevronDownIcon'

import theme from './theme'
import { pxToRem } from '../libs/style'

const Select = styled(MSelect).attrs({
	variant: 'outlined',
	IconComponent: ChevronDownIcon,
})`
	min-width: ${pxToRem(64)}rem;
	width: 100%;
	line-height: 1.1875;
	height: 100%;
	padding: 0;

	.MuiSelect-iconOutlined {
		z-index: 1;
		color: ${theme.palette.primary.main};
		right: 14px;
		top: auto;
	}

	&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
		border-color: ${theme.palette.grey[500]};
	}

	.MuiOutlinedInput-notchedOutline {
		border-color: ${({ value, error }) =>
			error ? theme.palette.error.main : theme.palette.grey[value ? 800 : 600]};
		border-radius: ${pxToRem(12)}rem;

		&:hover {
			border-color: ${theme.palette.violet[800]};
		}
	}

	.MuiSelect-select {
		&.MuiInputBase-input {
			&.MuiOutlinedInput-input {
				padding: ${theme.spacing(1.5, 8.5, 1.5, 2)};
				min-height: auto;
				color: ${({ value }) => theme.palette.grey[value ? 800 : 600]};
				font-size: ${pxToRem(14)}rem;
				line-height: 1.715;
				border-radius: ${pxToRem(12)}rem;
				z-index: ${theme.zIndex.pageElements};

				& > div.autocompleteOption > span {
					overflow: hidden;
					text-overflow: ellipsis;
				}

				& > div.autocompleteOption > svg {
					display: none;
				}
			}
		}

		&.Mui-disabled {
			&:not(:focus) {
				-webkit-text-fill-color: ${theme.palette.greyCustom.main};
			}

			& ~ .MuiOutlinedInput-notchedOutline {
				background-color: ${theme.palette.grey[100]};
				border-color: ${theme.palette.greyCustom.main};
			}
		}
	}

	.MuiFormLabel-root.Mui-disabled {
		color: ${theme.palette.greyCustom.main};
	}

	.selectClearBtn {
		position: absolute;
		right: ${theme.spacing(4)};
		z-index: ${theme.zIndex.pageElements};
	}

	&.automcompleteFavSelect .MuiSelect-iconOutlined {
		z-index: 1;
	}

	&.automcompleteFavSelect .autocompleteOption button {
		display: none;
	}

	.MuiFormHelperText-root {
		font-size: ${pxToRem(12)};
		line-height: 1.5;
	}

	.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
		z-index: 1;
	}
`

export default Select
