import React from 'react'

import { SvgProps } from '../../model/types'

const ChevronLeft: React.FC<SvgProps> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="15.570406"
			width="8.903904"
			viewBox="0 0 8.903904 15.570406"
			fill="currentColor"
			{...props}
		>
			<path
				clipRule="evenodd"
				d="M 0.12420309,7.4862031 7.4862021,0.12420309 a 0.422,0.422 0 0 1 0.598,0 l 0.696,0.697 a 0.422,0.422 0 0 1 0,0.59700001 l -6.352999,6.367 6.352999,6.3669999 a 0.422,0.422 0 0 1 0,0.597 l -0.696,0.697 a 0.422,0.422 0 0 1 -0.598,0 L 0.12420309,8.0842031 a 0.422,0.422 0 0 1 0,-0.598 z"
			/>
		</svg>
	)
}

export default ChevronLeft
