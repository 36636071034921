import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Id } from '../../model/model'
import { VisualSimulationState } from '../../model/visualSimulation'


const initialState: VisualSimulationState = {}

export const slice = createSlice({
	name: 'visualSimulation',
	initialState,
	reducers: {
		set: (
			state,
			{ payload }: PayloadAction<{ examId: Id }>,
		) => {
			const { examId } = payload
			state[examId] = { active: false, preview: false }
		},
		share: (
			state,
			{ payload }: PayloadAction<{ examId: Id; active: boolean }>,
		) => {
			const { examId, active } = payload
			state[examId] = { active, preview: false }
		},
		setPreview: (
			state,
			{ payload }: PayloadAction<{ examId: Id; preview: boolean }>,
		) => {
			const { examId, preview } = payload
			state[examId] = { ...state[examId], preview }
		},
		clear: (state, { payload }: PayloadAction<Id>) => {
			delete state[payload]
		},
	},
})

export default slice.reducer
