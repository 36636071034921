import { FetchArgs, createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { ChiefComplaintCategory } from '../model/ChiefComplaintCategory'
import { FilterParams, unversionedApiUrl } from './common'

const TAG = 'chief-complaint-categories'

const getChiefComplaintCategoryByCodeQuery = (code: string): FetchArgs => ({
	url: `/system/v2/chiefcomplaintcategory/find/code/${encodeURIComponent(
		code,
	)}`,
})

export const chiefComplaintCategoryApi = createApi({
	reducerPath: 'chiefComplaintCategoryApi',
	tagTypes: [TAG],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getChiefComplaintCategoryByCode: builder.query<
			ChiefComplaintCategory,
			string
		>({
			query: getChiefComplaintCategoryByCodeQuery,
			providesTags: [TAG],
		}),

		getChiefComplaintCategories: builder.query<
			ChiefComplaintCategory[],
			FilterParams
		>({
			query: ({
				filter,
				propNames = ['code', 'description'],
			}: FilterParams) => ({
				url: `/system/v2/chiefcomplaintcategory/paginated?q=${encodeURIComponent(
					filter,
				)}&propNames=${propNames.join(',')}`,
			}),
			providesTags: [TAG],
		}),
	}),
})

export const {
	useGetChiefComplaintCategoriesQuery,
	useGetChiefComplaintCategoryByCodeQuery,
} = chiefComplaintCategoryApi
