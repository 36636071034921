import React from 'react'

import { SvgProps } from '../../model/types'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const ChevronLeftIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="currentColor"
			{...props}
		>
			<path d="M6.9107 10.5892C6.58527 10.2638 6.58527 9.73615 6.9107 9.41074L11.6248 4.69669C11.9502 4.37125 12.4779 4.37125 12.8033 4.69669C13.1287 5.02212 13.1287 5.54976 12.8033 5.8752L8.67843 9.99999L12.8033 14.1247C13.1287 14.4502 13.1287 14.9778 12.8033 15.3033C12.4779 15.6287 11.9502 15.6287 11.6248 15.3033L6.9107 10.5892Z" />
		</svg>
	)
}

export default ChevronLeftIcon
