import React from 'react'

import { SvgProps } from '../../model/types'

const MinusIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			viewBox="0 0 12 3"
			height="20"
			width="20"
			fill="currentColor"
			{...props}
		>
			<path
				d="M0 1.4987C0 1.1305 0.29848 0.832031 0.666667 0.832031H11.3333C11.7015 0.832031 12 1.1305 12 1.4987C12 1.8669 11.7015 2.16536 11.3333 2.16536H0.666667C0.29848 2.16536 0 1.8669 0 1.4987Z"
				fill="white"
			/>
		</svg>
	)
}

export default MinusIcon
