import { WorklistExamsQuery } from '../model/exam'
import { WorklistFiltersType } from '../model/model'
import { convertDateForQueryString } from './time'

export const parseWorklistFiltersToQuery = (
	worklistFilters: WorklistFiltersType,
) => {
	const {
		dateFrom,
		dateTo,
		technician,
		icd10,
		loinc,
		snomed,
		status,
		chartStatus,
		cpt,
	} = worklistFilters
	const filters = {
		...worklistFilters,
		dateFrom: convertDateForQueryString(dateFrom),
		dateTo: dateTo ? convertDateForQueryString(dateTo) : undefined,
		technician: technician
			? joinArray(technician.filter(t => t !== 'all'))
			: undefined,
		icd10: icd10 ? joinArray(icd10.map(i => i.Code)) : undefined,
		loinc: loinc ? joinArray(loinc.map(l => l.code)) : undefined,
		cpt: cpt ? joinArray(cpt.map(c => c.code)) : undefined,
		snomed: snomed ? joinArray(snomed.map(s => s.code)) : undefined,
		status: status ? joinArray(status) : undefined,
		chartStatus:
			chartStatus === 'all' ? undefined : worklistFilters.chartStatus,
	} as Omit<WorklistExamsQuery, 'page'>

	Object.keys(filters).forEach(k => {
		const key = k as keyof typeof filters
		const value = filters[key]

		if (!value && typeof value !== 'boolean') {
			delete filters[k as keyof typeof filters]
		}
	})

	return filters
}

const joinArray = (array: any[]) => array.join(',')
