import { RootState } from '../../model/model'

export const selectSearchTerm = (state: RootState) =>
	state.search.searchTerm

export const selectSearchResults = (state: RootState) =>
	state.search.results

export const selectPatientSearchResults = (state: RootState) =>
	state.search.patientResults
