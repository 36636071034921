import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ClipboardIncomingData } from '../../model/clipboard'
import { ClipboardState } from '../../model/model'

const initialState = null as ClipboardState

export const slice = createSlice({
	name: 'clipboard',
	initialState,
	reducers: {
		setData: (state, { payload }: PayloadAction<ClipboardIncomingData>) =>
			payload,
	},
})

export default slice.reducer
