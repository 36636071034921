import React from 'react'
import styled from 'styled-components'
import AlertSquaredIcon from '../../../styleguide/icons/AlertSquaredIcon'
import theme from '../../../styleguide/theme'

interface Props {
	alertMessage: string
	onClick?: () => void
}

const AlertIconSized = styled(AlertSquaredIcon)`
	margin-right: ${theme.spacing(1)};
	fill: ${theme.palette.error.main};
`

const WrapperAlert = styled.span`
	display: flex;
	align-items: center;
	margin-top: ${theme.spacing(1)};
	margin-left: ${theme.spacing(4)};
`

const AlertMessage = styled.span`
	color: ${theme.palette.secondary.main};
	text-transform: uppercase;
	font-weight: bold;
`

const RefractionAlert: React.FC<Props> = ({ alertMessage, onClick }) => {
	return (
		<WrapperAlert onClick={() => onClick && onClick()}>
			<AlertIconSized />
			<AlertMessage>{alertMessage}</AlertMessage>
		</WrapperAlert>
	)
}

export default RefractionAlert
