import theme from '../styleguide/theme'

export const DIRECTION_LEFT = 'left'
export const DIRECTION_RIGHT = 'right'
export const DIRECTION_UP = 'up'

export const STATUS_PROGRESS = 'progress'
export const STATUS_EVALUATING = 'evaluating'
export const STATUS_TODO = 'todo'
export const STATUS_APPROVED = 'accepted'
export const STATUS_REFUSED = 'refused'
export const STATUS_REJECTED = 'rejected'
export const STATUS_PRESCRIBED = 'PRESCRIBED'
export const STATUS_REFUSED_CL = 'REFUSED'
export const STATUS_TRIAL = 'TRIAL'
export const STATUS_NONE = 'NONE'

export const isRenewCompleted = (status: string) =>
	[STATUS_APPROVED, STATUS_REFUSED, STATUS_REJECTED].includes(status)

export const statusTextMap: any = {
	[STATUS_PROGRESS]: 'renewal.status.inProgress',
	[STATUS_APPROVED]: 'renewal.status.approved',
	[STATUS_REFUSED]: 'renewal.status.refused',
	[STATUS_REJECTED]: 'renewal.status.rejected',
	[STATUS_PRESCRIBED]: 'renewal.status.prescribed',
	[STATUS_REFUSED_CL]: 'renewal.status.refusedCL',
	[STATUS_TRIAL]: 'renewal.status.trial',
	[STATUS_TODO]: 'renewal.status.todo',
}

/** REJECT REASONS **/
export const BLANK = 'blank'
export const BOXES_INSTEAD_RX = 'boxes_instead_rx'
export const SRX_INSTEAD_CLRX = 'srx_instead_clrx'
export const REDNESS_CHECK = 'redness_check'
export const EXAM_TOO_LONG_AGO = 'exam_too_long_ago'
export const VERY_HIGH_RX = 'very_high_rx'
export const FAILED_ACUITY = 'failed_acuity'
export const RENEWING_FROM_ONLINE = 'renewing_from_online'
export const DIFFERENT_RX = 'different_rx'

export const rejectReasonsOptions = [
	BLANK,
	BOXES_INSTEAD_RX,
	SRX_INSTEAD_CLRX,
	REDNESS_CHECK,
	EXAM_TOO_LONG_AGO,
	VERY_HIGH_RX,
	FAILED_ACUITY,
	RENEWING_FROM_ONLINE,
	DIFFERENT_RX,
]

export const rejectReasonsTextMap: any = {
	[BLANK]: 'renewal.singleTest.rejectReasons.blank',
	[BOXES_INSTEAD_RX]: 'renewal.singleTest.rejectReasons.boxesInsteadRx',
	[SRX_INSTEAD_CLRX]: 'renewal.singleTest.rejectReasons.srxInsteadClrx',
	[REDNESS_CHECK]: 'renewal.singleTest.rejectReasons.rednessCheck',
	[EXAM_TOO_LONG_AGO]: 'renewal.singleTest.rejectReasons.examTooLongAgo',
	[VERY_HIGH_RX]: 'renewal.singleTest.rejectReasons.veryHighRx',
	[FAILED_ACUITY]: 'renewal.singleTest.rejectReasons.failedAcuity',
	[RENEWING_FROM_ONLINE]: 'renewal.singleTest.rejectReasons.renewingFromOnline',
	[DIFFERENT_RX]: 'renewal.singleTest.rejectReasons.differentRx',
}

export const rejectReasonsDescriptionMap: any = {
	[BLANK]: 'renewal.singleTest.rejectDescriptions.blank',
	[BOXES_INSTEAD_RX]: 'renewal.singleTest.rejectDescriptions.boxesInsteadRx',
	[SRX_INSTEAD_CLRX]: 'renewal.singleTest.rejectDescriptions.srxInsteadClrx',
	[REDNESS_CHECK]: 'renewal.singleTest.rejectDescriptions.rednessCheck',
	[EXAM_TOO_LONG_AGO]: 'renewal.singleTest.rejectDescriptions.examTooLongAgo',
	[VERY_HIGH_RX]: 'renewal.singleTest.rejectDescriptions.veryHighRx',
	[FAILED_ACUITY]: 'renewal.singleTest.rejectDescriptions.failedAcuity',
	[RENEWING_FROM_ONLINE]:
		'renewal.singleTest.rejectDescriptions.renewingFromOnline',
	[DIFFERENT_RX]: 'renewal.singleTest.rejectDescriptions.differentRx',
}

/** RENEW REASONS **/
export const IN_PERSON = 'in_person'
export const DIFFERENT_CLS = 'different_cls'
export const OCULAR_HISTORY = 'ocular_history'

export const renewReasonsOptions = [
	BLANK,
	IN_PERSON,
	DIFFERENT_CLS,
	OCULAR_HISTORY,
]

export const renewReasonsTextMap: any = {
	[BLANK]: 'renewal.singleTest.renewReasons.blank',
	[IN_PERSON]: 'renewal.singleTest.renewReasons.inPerson',
	[DIFFERENT_CLS]: 'renewal.singleTest.renewReasons.differentCls',
	[OCULAR_HISTORY]: 'renewal.singleTest.renewReasons.ocularHistory',
}

export const renewReasonsDescriptionMap: any = {
	[BLANK]: 'renewal.singleTest.renewDescriptions.blank',
	[IN_PERSON]: 'renewal.singleTest.renewDescriptions.inPerson',
	[DIFFERENT_CLS]: 'renewal.singleTest.renewDescriptions.differentCls',
	[OCULAR_HISTORY]: 'renewal.singleTest.renewDescriptions.ocularHistory',
}

export const statusColorMap: any = {
	[STATUS_PROGRESS]: theme.palette.secondary.main,
	[STATUS_APPROVED]: theme.palette.success.main,
	[STATUS_REFUSED]: theme.palette.error.main,
	[STATUS_REJECTED]: theme.palette.error.main,
	[STATUS_TODO]: '#CCD1D6',
	[STATUS_PRESCRIBED]: theme.palette.success.main,
	[STATUS_REFUSED_CL]: theme.palette.error.main,
	[STATUS_TRIAL]: theme.palette.common.white,
}

export const SPHERE_VALUES = [
	{
		value: 'PL',
		label: 'PL',
	},
	{
		value: '0.25',
		label: '0.25',
	},
	{
		value: '0.50',
		label: '0.50',
	},
	{
		value: '0.75',
		label: '0.75',
	},
	{
		value: '1.00',
		label: '1.00',
	},
	{
		value: '1.25',
		label: '1.25',
	},
	{
		value: '1.50',
		label: '1.50',
	},
	{
		value: '1.75',
		label: '1.75',
	},
	{
		value: '2.00',
		label: '2.00',
	},
	{
		value: '2.25',
		label: '2.25',
	},
	{
		value: '2.50',
		label: '2.50',
	},
	{
		value: '2.75',
		label: '2.75',
	},
	{
		value: '3.00',
		label: '3.00',
	},
	{
		value: '3.25',
		label: '3.25',
	},
	{
		value: '3.50',
		label: '3.50',
	},
	{
		value: '3.75',
		label: '3.75',
	},
	{
		value: '4.00',
		label: '4.00',
	},
	{
		value: '4.25',
		label: '4.25',
	},
	{
		value: '4.50',
		label: '4.50',
	},
	{
		value: '4.75',
		label: '4.75',
	},
	{
		value: '5.00',
		label: '5.00',
	},
	{
		value: '5.25',
		label: '5.25',
	},
	{
		value: '5.50',
		label: '5.50',
	},
	{
		value: '5.75',
		label: '5.75',
	},
	{
		value: '6.00',
		label: '6.00',
	},
	{
		value: '6.25',
		label: '6.25',
	},
	{
		value: '6.50',
		label: '6.50',
	},
	{
		value: '6.75',
		label: '6.75',
	},
	{
		value: '7.00',
		label: '7.00',
	},
	{
		value: '7.25',
		label: '7.25',
	},
	{
		value: '7.50',
		label: '7.50',
	},
	{
		value: '7.75',
		label: '7.75',
	},
	{
		value: '8.00',
		label: '8.00',
	},
	{
		value: '8.25',
		label: '8.25',
	},
	{
		value: '8.50',
		label: '8.50',
	},
	{
		value: '8.75',
		label: '8.75',
	},
	{
		value: '9.00',
		label: '9.00',
	},
	{
		value: '9.25',
		label: '9.25',
	},
	{
		value: '9.50',
		label: '9.50',
	},
	{
		value: '9.75',
		label: '9.75',
	},
	{
		value: '10.00',
		label: '10.00',
	},
]

export const CYLINDER_VALUES = [
	{
		value: 'DS',
		label: 'DS',
	},
	{
		value: '0.25',
		label: '0.25',
	},
	{
		value: '0.50',
		label: '0.50',
	},
	{
		value: '0.75',
		label: '0.75',
	},
	{
		value: '1.00',
		label: '1.00',
	},
	{
		value: '1.25',
		label: '1.25',
	},
	{
		value: '1.50',
		label: '1.50',
	},
	{
		value: '1.75',
		label: '1.75',
	},
	{
		value: '2.00',
		label: '2.00',
	},
	{
		value: '2.25',
		label: '2.25',
	},
	{
		value: '2.50',
		label: '2.50',
	},
	{
		value: '2.75',
		label: '2.75',
	},
	{
		value: '3.00',
		label: '3.00',
	},
	{
		value: '3.25',
		label: '3.25',
	},
	{
		value: '3.50',
		label: '3.50',
	},
	{
		value: '3.75',
		label: '3.75',
	},
	{
		value: '4.00',
		label: '4.00',
	},
	{
		value: '4.25',
		label: '4.25',
	},
	{
		value: '4.50',
		label: '4.50',
	},
	{
		value: '4.75',
		label: '4.75',
	},
	{
		value: '5.00',
		label: '5.00',
	},
	{
		value: '5.25',
		label: '5.25',
	},
	{
		value: '5.50',
		label: '5.50',
	},
	{
		value: '5.75',
		label: '5.75',
	},
	{
		value: '6.00',
		label: '6.00',
	},
]

export const ADD_DESCRIPTION_VALUES = [
	{
		value: 'Low',
		label: 'Low',
	},
	{
		value: 'Lo',
		label: 'Lo',
	},
	{
		value: 'Medium',
		label: 'Medium',
	},
	{
		value: '​Med',
		label: '​Med',
	},
	{
		value: 'Mid',
		label: 'Mid',
	},
	{
		value: 'High',
		label: 'High',
	},
	{
		value: 'Hi',
		label: 'Hi',
	},
	{
		value: 'D',
		label: 'D',
	},
	{
		value: 'N',
		label: 'N',
	},
]

export const ADD_VALUES = [
	{
		value: '0.00',
		label: '0.00',
	},
	{
		value: '0.25',
		label: '0.25',
	},
	{
		value: '0.50',
		label: '0.50',
	},
	{
		value: '0.75',
		label: '0.75',
	},
	{
		value: '1.00',
		label: '1.00',
	},
	{
		value: '1.25',
		label: '1.25',
	},
	{
		value: '1.50',
		label: '1.50',
	},
	{
		value: '1.75',
		label: '1.75',
	},
	{
		value: '2.00',
		label: '2.00',
	},
	{
		value: '2.25',
		label: '2.25',
	},
	{
		value: '2.50',
		label: '2.50',
	},
	{
		value: '2.75',
		label: '2.75',
	},
	{
		value: '3.00',
		label: '3.00',
	},
	{
		value: '3.25',
		label: '3.25',
	},
	{
		value: '3.50',
		label: '3.50',
	},
	{
		value: '3.75',
		label: '3.75',
	},
	{
		value: '4.00',
		label: '4.00',
	},
]

export const PRISM_VALUES = [
	{
		value: '0.00',
		label: '0.00',
	},
	{
		value: '0.25',
		label: '0.25',
	},
	{
		value: '0.50',
		label: '0.50',
	},
	{
		value: '0.75',
		label: '0.75',
	},
	{
		value: '1.00',
		label: '1.00',
	},
	{
		value: '1.25',
		label: '1.25',
	},
	{
		value: '1.50',
		label: '1.50',
	},
	{
		value: '1.75',
		label: '1.75',
	},
	{
		value: '2.00',
		label: '2.00',
	},
	{
		value: '2.25',
		label: '2.25',
	},
	{
		value: '2.50',
		label: '2.50',
	},
	{
		value: '2.75',
		label: '2.75',
	},
	{
		value: '3.00',
		label: '3.00',
	},
	{
		value: '3.25',
		label: '3.25',
	},
	{
		value: '3.50',
		label: '3.50',
	},
	{
		value: '3.75',
		label: '3.75',
	},
	{
		value: '4.00',
		label: '4.00',
	},
	{
		value: '4.25',
		label: '4.25',
	},
	{
		value: '4.50',
		label: '4.50',
	},
	{
		value: '4.75',
		label: '4.75',
	},
	{
		value: '5.00',
		label: '5.00',
	},
	{
		value: '5.25',
		label: '5.25',
	},
	{
		value: '5.50',
		label: '5.50',
	},
	{
		value: '5.75',
		label: '5.75',
	},
	{
		value: '6.00',
		label: '6.00',
	},
	{
		value: '6.25',
		label: '6.25',
	},
	{
		value: '6.50',
		label: '6.50',
	},
	{
		value: '6.75',
		label: '6.75',
	},
	{
		value: '7.00',
		label: '7.00',
	},
	{
		value: '7.25',
		label: '7.25',
	},
	{
		value: '7.50',
		label: '7.50',
	},
	{
		value: '7.75',
		label: '7.75',
	},
	{
		value: '8.00',
		label: '8.00',
	},
	{
		value: '8.25',
		label: '8.25',
	},
	{
		value: '8.50',
		label: '8.50',
	},
	{
		value: '8.75',
		label: '8.75',
	},
	{
		value: '9.00',
		label: '9.00',
	},
	{
		value: '9.25',
		label: '9.25',
	},
	{
		value: '9.50',
		label: '9.50',
	},
	{
		value: '9.75',
		label: '9.75',
	},
	{
		value: '10.00',
		label: '10.00',
	},
	{
		value: '10.25',
		label: '10.25',
	},
	{
		value: '10.50',
		label: '10.50',
	},
	{
		value: '10.75',
		label: '10.75',
	},
	{
		value: '11.00',
		label: '11.00',
	},
	{
		value: '11.25',
		label: '11.25',
	},
	{
		value: '11.50',
		label: '11.50',
	},
	{
		value: '11.75',
		label: '11.75',
	},
	{
		value: '12.00',
		label: '12.00',
	},
	{
		value: '12.25',
		label: '12.25',
	},
	{
		value: '12.50',
		label: '12.50',
	},
	{
		value: '12.75',
		label: '12.75',
	},
	{
		value: '13.00',
		label: '13.00',
	},
	{
		value: '13.25',
		label: '13.25',
	},
	{
		value: '13.50',
		label: '13.50',
	},
	{
		value: '13.75',
		label: '13.75',
	},
	{
		value: '14.00',
		label: '14.00',
	},
	{
		value: '14.25',
		label: '14.25',
	},
	{
		value: '14.50',
		label: '14.50',
	},
	{
		value: '14.75',
		label: '14.75',
	},
	{
		value: '15.00',
		label: '15.00',
	},
	{
		value: '15.25',
		label: '15.25',
	},
	{
		value: '15.50',
		label: '15.50',
	},
	{
		value: '15.75',
		label: '15.75',
	},
	{
		value: '16.00',
		label: '16.00',
	},
	{
		value: '16.25',
		label: '16.25',
	},
	{
		value: '16.50',
		label: '16.50',
	},
	{
		value: '16.75',
		label: '16.75',
	},
	{
		value: '17.00',
		label: '17.00',
	},
	{
		value: '17.25',
		label: '17.25',
	},
	{
		value: '17.50',
		label: '17.50',
	},
	{
		value: '17.75',
		label: '17.75',
	},
	{
		value: '18.00',
		label: '18.00',
	},
	{
		value: '18.25',
		label: '18.25',
	},
	{
		value: '18.50',
		label: '18.50',
	},
	{
		value: '18.75',
		label: '18.75',
	},
	{
		value: '19.00',
		label: '19.00',
	},
	{
		value: '19.25',
		label: '19.25',
	},
	{
		value: '19.50',
		label: '19.50',
	},
	{
		value: '19.75',
		label: '19.75',
	},
	{
		value: '20.00',
		label: '20.00',
	},
]
