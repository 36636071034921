import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import appointmentsActions from '../features/appointments/actions'

import dialogActions from '../features/dialog/actions'
import { selectDialog } from '../features/dialog/selectors'
import { pxToRem } from '../libs/style'
import { TestExamDialogType } from '../model/dialog'
import { useTeloDispatch, useTeloSelector } from '../store'
import Button from '../styleguide/buttons/Button'
import DialogActions from '../styleguide/dialog/DialogActions'
import DialogContent from '../styleguide/dialog/DialogContent'
import DialogContentText from '../styleguide/dialog/DialogContentText'
import theme from '../styleguide/theme'

const DialogContentTextStyled = styled(DialogContentText)`
	min-width: ${pxToRem(299)}rem;
`

const DialogActionsStyled = styled(DialogActions)`
	justify-content: flex-end;
	padding-left: ${theme.spacing(3)};
`

const isTestExamDialog = (dialog: any): dialog is TestExamDialogType =>
	dialog.type === 'testExamDialog'

const TestExamDialog = () => {
	const { t } = useTranslation()
	const dispatch = useTeloDispatch()
	const dialog = useTeloSelector(selectDialog)

	return !isTestExamDialog(dialog) ? null : (
		<>
			<DialogContent>
				<DialogContentTextStyled>
					{t('patient.isTestModal')}
				</DialogContentTextStyled>
				<DialogContentTextStyled>
					{t('patient.isTestModalNote')}
				</DialogContentTextStyled>
			</DialogContent>
			<DialogActionsStyled>
				<Button
					onClick={() => dispatch(dialogActions.closeDialog())}
					variant="outlined"
				>
					{t('app.cancel')}
				</Button>
				<Button
					onClick={() => {
						dispatch(
							appointmentsActions.setIsTestAppointement({
								appointmentId: dialog.appointmentId,
								isTest: dialog.isTest,
							}),
						)
						dispatch(dialogActions.closeDialog())
					}}
					autoFocus
					variant="contained"
				>
					{t('app.confirm')}
				</Button>
			</DialogActionsStyled>
		</>
	)
}

export default TestExamDialog
