import { createApi } from '@reduxjs/toolkit/query/react'
import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from '../services/common'
import { AnesthesiaCategory } from '../model/anesthesiaCategories'
import { Id } from '../model/model'

const TAG = 'anesthesiaCategoriesApi'

export const anesthesiaCategoriesApi = createApi({
	reducerPath: 'anesthesiaCategoriesApi',
	tagTypes: [TAG],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		getAnesthesiaCategories: builder.query<AnesthesiaCategory[], void>({
			query: () => ({
				url: `/v3/anesthesiacategory`,
			}),
			providesTags: result =>
				result
					? [
							...result.map(({ _id }) => ({ type: TAG, _id } as const)),
							{ type: TAG, id: 'LIST' },
					  ]
					: [{ type: TAG, id: 'LIST' }],
		}),

		getEnabledAnesthesiaCategories: builder.query<AnesthesiaCategory[], void>({
			query: () => ({
				url: `/v3/anesthesiacategory?enabled=true`,
			}),
			providesTags: result =>
				result
					? [
							...result.map(({ _id }) => ({ type: TAG, _id } as const)),
							{ type: TAG, id: 'LIST_ENABLED' },
					  ]
					: [{ type: TAG, id: 'LIST_ENABLED' }],
		}),

		getAnesthesiaCategory: builder.query<AnesthesiaCategory, Id>({
			query: _id => ({
				url: `/v3/anesthesiacategory/${_id}`,
			}),
			providesTags: result => (result ? [{ type: TAG, id: result._id }] : []),
		}),

		createAnesthesiaCategory: builder.mutation<
			AnesthesiaCategory,
			Partial<AnesthesiaCategory>
		>({
			query: body => ({
				url: `/v3/anesthesiacategory`,
				method: 'POST',
				body: JSON.stringify(body),
			}),
			invalidatesTags: [{ type: TAG, id: 'LIST' }],
		}),

		patchAnesthesiaCategory: builder.mutation<
			AnesthesiaCategory,
			{ _id: Id; body: Partial<AnesthesiaCategory> }
		>({
			query: ({ _id, body }) => ({
				url: `/v3/anesthesiacategory/${_id}`,
				method: 'PATCH',
				body: JSON.stringify(body),
			}),
			invalidatesTags: [{ type: TAG, id: 'LIST' }],
		}),
	}),
})

export const {
	useGetAnesthesiaCategoriesQuery,
	useCreateAnesthesiaCategoryMutation,
	usePatchAnesthesiaCategoryMutation,
	useGetAnesthesiaCategoryQuery,
	useGetEnabledAnesthesiaCategoriesQuery,
} = anesthesiaCategoriesApi
